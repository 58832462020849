/* istanbul ignore file */
import { FC, useState } from 'react';
import { Container, Icons, Typography } from '../../stories/atoms';
import { Accordion, Banner, Button } from '../../stories/molecules';
import { ActionButton, BannerItemsType } from '../../stories/molecules/banner';
import { Page } from '../../stories/organisms';
import { getUniqueId } from '../../utils';
import { useThemeContext } from '../../global-state/themeContext';

export const Development: FC<React.PropsWithChildren<unknown>> = () => {
  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const { mode } = useThemeContext();

  return (
    <Page
      body={
        <div style={{ position: 'relative' }}>
          <Container data-testid="development" position="center" backgroundColor="baseGray" height="100%">
            <Banner items={bannerItems} onChange={(items) => setBannerItems(items)} />
            <Typography gutterBottom>This is the test page, add anything you like here</Typography>
            <Container margin={[10, 0, 3]}>
              <Typography>Banners</Typography>
            </Container>
            <Container direction="horizontal" space="evenly">
              <Button
                label="Success"
                onClick={() =>
                  setBannerItems((items) => [
                    ...items,
                    {
                      id: getUniqueId(),
                      label: 'Action performed was successful',
                      type: 'success',
                    },
                  ])
                }
              />
              <Button
                label="Info"
                onClick={() =>
                  setBannerItems((items) => [
                    ...items,
                    {
                      id: getUniqueId(),
                      label: 'Information that is useful but not critical',
                      type: 'info',
                    },
                  ])
                }
              />
              <Button
                label="Error and TryAgain"
                onClick={() =>
                  setBannerItems((items) => [
                    ...items,
                    {
                      id: getUniqueId(),
                      label: 'Action could not be completed',
                      type: 'error',
                      actionButton: ActionButton.TryAgain,
                      onTryAgain: () => window.location.reload(),
                    },
                  ])
                }
              />
              <Button
                label="Warning with close icon"
                onClick={() =>
                  setBannerItems((items) => [
                    ...items,
                    {
                      id: getUniqueId(),
                      label: 'System or user feedback that is not critical',
                      type: 'warning',
                      actionButton: ActionButton.Close,
                    },
                  ])
                }
              />
            </Container>
            <Container>
              <Accordion
                headerText="Accordion 1"
                icon={Icons.ASSET_MANAGEMENT}
                accordionDetails={
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit
                    amet blandit leo lobortis eget
                  </Typography>
                }
              ></Accordion>
            </Container>
            <Container
              width="80%"
              backgroundColor={mode === 'dark' ? 'backgroundPaperDark' : 'inherit'}
              padding={[5]}
              margin={[4]}
            ></Container>
          </Container>
        </div>
      }
    />
  );
};
