import {
  avatarUniquenessRequest,
  avatarsRequest,
  fetchAllEmployeesInStore,
  fetchAppCustomersData,
  fetchAreaComparisonData,
  fetchAssetManagementWidgetData,
  fetchAveragePiecesPerReceiptData,
  fetchChatBotData,
  fetchConversionRateWidgetData,
  fetchCustomerFeedbackData,
  fetchEffectiveHoursBudget,
  fetchEffectiveHoursData,
  fetchForecastForKpis,
  fetchHourlyTurnoverWidgetData,
  fetchKpiTargets,
  fetchKpisAreaDetails,
  fetchMessageData,
  fetchNetSalesBudget,
  fetchNewMembers,
  fetchORISData,
  fetchPermissions,
  fetchProductIndex,
  fetchProfitLossData,
  fetchQueueLengthData,
  fetchReceiptSalesAnalysisData,
  fetchReceiptWidgetData,
  fetchRfidCountData,
  fetchRoleMatrix,
  fetchSCOHourlyTurnoverWidgetData,
  fetchSCOShareOfReceiptsData,
  fetchSalesNetPerReceiptData,
  fetchShareOfReceipts,
  fetchSoldProductsPage,
  fetchSptBudget,
  fetchStoreData,
  fetchStoreDetails,
  fetchTakt1Budget,
  fetchTenderWidgetData,
  fetchTopReturningGarmentData,
  fetchTopSellingGarmentData,
  fetchWeatherWidgetData,
  getBlacklistPermissionsData,
  mockRfidApprovalsData,
  mockRfidSalesRetaggingData,
  postBlacklistPermissionsData,
  profileByUpnRequest,
  receiptRequest,
  transactionCountRequest,
  transactionRequest,
  updateProfileRequest,
} from './handlers';

export const browserHandlers = [
  // User
  // profileByUpnRequest({ addDelay: true, isAvatarAssigned: false }),
  // fetchPermissions(true),
  // fetchRoleMatrix(true),
  //
  // getBlacklistPermissionsData(true),
  // postBlacklistPermissionsData(true),
  //
  // Store
  // fetchStoreDetails(true),
  // fetchStoreData(true), // This does not reflect the mocks as expected, change the data in dev database to check the changes.
  //
  // Avatar
  // avatarsRequest(true),
  // avatarUniquenessRequest(true),
  //
  // Transactions
  // transactionRequest(true),
  // transactionCountRequest(true, 500),
  // receiptRequest(true),
  //
  // Employees
  // fetchAllEmployeesInStore(true),
  // updateProfileRequest(true),
  //
  // KPI
  // fetchKpisAreaDetails(true),
  // fetchKpiTargets(true),
  //
  // Budget
  // fetchNetSalesBudget(true),
  // fetchEffectiveHoursBudget(true),
  //
  // Widgets
  // fetchHourlyTurnoverWidgetData(true),
  // fetchSCOHourlyTurnoverWidgetData(true),
  // fetchConversionRateWidgetData(true),
  // fetchTenderWidgetData(true),
  // fetchReceiptWidgetData(true),
  // fetchAssetManagementWidgetData(true),
  // fetchWeatherWidgetData(true),
  // fetchTopSellingGarmentData(true),
  // fetchTopReturningGarmentData(true),
  // fetchShareOfReceipts(true),
  // fetchSCOShareOfReceiptsData(true),
  // fetchNewMembers(true),
  // fetchAppCustomersData(true),
  // fetchEffectiveHoursData(true),
  // fetchSalesNetPerReceiptData(true),
  // fetchAveragePiecesPerReceiptData(true),
  // fetchQueueLengthData(true),
  // fetchForecastForKpis(true),
  // fetchReceiptSalesAnalysisData(true),
  // fetchTakt1Budget(true),
  // fetchSptBudget(true),
  // fetchAreaComparisonData(true),
  // fetchCustomerFeedbackData(true),
  // mockRfidApprovalsData(true),
  // fetchRfidCountData(true),
  // mockRfidSalesRetaggingData(true),
  // fetchORISData(true),
  //
  // Sold Products
  // fetchSoldProductsPage(true),
  // fetchProductIndex(true),
  //
  // Profit and Loss
  // fetchProfitLossData(true),
  //
  // Misc
  // fetchMessageData(true),
  // fetchChatBotData(true),
];

export const serverHandlers = [
  // User
  profileByUpnRequest({}),
  fetchPermissions(),
  fetchRoleMatrix(),

  getBlacklistPermissionsData(),
  postBlacklistPermissionsData(),

  // Store
  fetchStoreDetails(),
  fetchStoreData(),

  // Avatar
  avatarsRequest(),
  avatarUniquenessRequest(),

  // Transactions
  transactionRequest(),
  transactionCountRequest(),
  receiptRequest(),

  // Employees
  fetchAllEmployeesInStore(undefined, true),
  updateProfileRequest(),

  // KPI
  fetchKpisAreaDetails(),
  fetchKpiTargets(),

  // Budget
  fetchNetSalesBudget(),
  fetchEffectiveHoursBudget(),

  // Widgets
  fetchHourlyTurnoverWidgetData(),
  fetchSCOHourlyTurnoverWidgetData(),
  fetchConversionRateWidgetData(),
  fetchTenderWidgetData(),
  fetchReceiptWidgetData(),
  fetchAssetManagementWidgetData(),
  fetchWeatherWidgetData(),
  fetchTopSellingGarmentData(),
  fetchTopReturningGarmentData(),
  fetchShareOfReceipts(),
  fetchSCOShareOfReceiptsData(),
  fetchNewMembers(),
  fetchAppCustomersData(),
  fetchEffectiveHoursData(),
  fetchSalesNetPerReceiptData(),
  fetchAveragePiecesPerReceiptData(),
  fetchQueueLengthData(),
  fetchForecastForKpis(),
  fetchReceiptSalesAnalysisData(),
  fetchTakt1Budget(),
  fetchSptBudget(),
  fetchAreaComparisonData(),
  fetchCustomerFeedbackData(),
  mockRfidApprovalsData(),
  fetchRfidCountData(),
  mockRfidSalesRetaggingData(),
  fetchORISData(),

  // Sold Products
  fetchSoldProductsPage(),
  fetchProductIndex(),

  // Profit and Loss
  fetchProfitLossData(),

  // Misc
  fetchMessageData(),
  fetchChatBotData(),
];
