// TODO: Remove this when fixing the unit tests - BTCOBUI-1952
/* istanbul ignore file */

import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { CenterCoordinates, MarkerDetails } from './Map';

type Props = {
  zoom: number;
  center: CenterCoordinates;
  selectedMarkerId: string | null;
  markers: MarkerDetails[];
  panOnMarkerSelect: boolean;
  onMarkerSelectZoom: number;
  onLoaded: () => void;
  onMarkerClick: (markerId: string) => void;
  getMarkerIconUrl: (markerId?: string) => string | undefined;
};

export const GoogleMapView = ({
  zoom,
  center,
  selectedMarkerId,
  markers,
  panOnMarkerSelect,
  onMarkerSelectZoom,
  onLoaded,
  onMarkerClick,
  getMarkerIconUrl,
}: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  const [mapReference, setMapReference] = useState<google.maps.Map | undefined>();
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const fitBounds = () => {
    if (mapInstance && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach(({ latitude, longitude }) => bounds.extend(new google.maps.LatLng(latitude, longitude)));
      mapInstance.fitBounds(bounds);

      setMapReference(mapInstance);
    }
  };

  const panToMarker = (markerId: string) => {
    const markerDetails = markers.find((marker) => marker.id === markerId);

    if (!panOnMarkerSelect || !mapReference || !markerDetails) return;

    const { latitude, longitude } = markerDetails;

    setTimeout(() => {
      mapReference.setZoom(onMarkerSelectZoom);
      mapReference.panTo({ lat: latitude, lng: longitude });
    }, 0);
  };

  const handleMarkerClick = async (markerId: string) => {
    onMarkerClick(markerId);
    panToMarker(markerId);
  };

  useEffect(() => {
    if (!isLoaded) return;

    onLoaded();
  }, [isLoaded]);

  useEffect(() => {
    fitBounds();
  }, [markers, mapInstance]);

  useEffect(() => {
    if (!selectedMarkerId) return;

    panToMarker(selectedMarkerId);
  }, [selectedMarkerId]);

  return (
    <>
      {isLoaded && markers.length !== 0 && (
        <GoogleMap
          data-testid="google-map-mobile"
          center={{ lat: center.latitude, lng: center.longitude }}
          zoom={zoom}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          onLoad={(map) => setMapInstance(map)}
        >
          {markers.map((marker, index) => {
            if (isNaN(marker.latitude) || isNaN(marker.longitude)) return null;

            const markerIconUrl = getMarkerIconUrl ? getMarkerIconUrl(marker.id) : undefined;

            return (
              <MarkerF
                key={index}
                position={{ lat: marker.latitude, lng: marker.longitude }}
                onClick={() => handleMarkerClick(marker.id)}
                options={markerIconUrl ? { icon: { url: markerIconUrl } } : undefined}
              />
            );
          })}
        </GoogleMap>
      )}
    </>
  );
};
