import Lottie from 'lottie-react';
import { FC, useEffect, useState } from 'react';
import { useThemeContext } from '../../../global-state/themeContext';
import { Sizes } from '../../../theme';
import { Typography, TypographyProps } from '../../atoms';
import { Button, ButtonProps } from '../button/Button';
import { AnimationWrapper } from './Animation.styles';

// Note: The value must match the animation file name
export enum Animations {
  SAD = 'sad',
  LOADING = 'loading',
  COOL = 'cool',
  HAPPY = 'happy',
  CONFUSED = 'confused',
  DEFAULT = 'default',
  NONE = 'none',
}

export type AnimationProps = {
  title: string;
  animation?: Animations;
  subtitle?: string;
  errorText?: string;
  button?: ButtonProps;
  size?: Sizes;
  textConfig?: { title?: TypographyProps; subtitle?: TypographyProps };
  onLoopComplete?: () => void;
};

export const Animation: FC<AnimationProps> = ({
  animation = Animations.DEFAULT,
  title,
  subtitle,
  errorText,
  button,
  size = 'medium',
  textConfig,
  onLoopComplete,
}) => {
  const [animationData, setAnimationData] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);

  const { mode } = useThemeContext();

  useEffect(() => {
    if (animation === Animations.NONE) {
      setHasLoaded(true);
      return;
    }

    const animationURL = mode === 'dark' ? `${animation}_dark.json` : `${animation}.json`;

    import(`./animations/${animationURL}`).then((loadedAnimation) => {
      setAnimationData(loadedAnimation);
      setHasLoaded(true);
    });
  }, [animation, mode]);

  const getSize = () => {
    switch (size) {
      case 'small':
        return '150px';
      default:
      case 'medium':
        return '250px';
      case 'large':
        return '400px';
    }
  };

  if (!hasLoaded) return null;

  return (
    <AnimationWrapper data-testid="animation">
      {animation !== Animations.NONE && (
        <Lottie
          animationData={animationData}
          autoPlay
          loop
          style={{
            height: getSize(),
            width: getSize(),
          }}
          onLoopComplete={onLoopComplete}
        />
      )}

      <Typography type="h2" textAlign="center" {...textConfig?.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography type="body3" textAlign="center" margin={[0, 0, 4, 0]} {...textConfig?.subtitle}>
          {subtitle}
        </Typography>
      )}
      {errorText && (
        <Typography type="button" textAlign="center" margin={[0, 0, 4, 0]}>
          {errorText}
        </Typography>
      )}
      {button && <Button {...button} />}
    </AnimationWrapper>
  );
};
