import { add, sub } from 'date-fns';
import { Profile } from '../../global-state/types';
import { mockRoles } from './role-matrix.mock';
import { mockStores } from './store.mock';

const { storeId: mockStoreId } = mockStores[0];
const userId = 'beae9a57-df2d-4cde-985c-1fa01f307271';

const profileMockData = {
  id: userId,
  firstName: 'Jeff',
  lastName: 'Johnson',
  accountName: 'A110001',
  upn: 'test@hm.com',
  avatar: {
    url: 'https://boxers.azureedge.net/avatars/animals/1875556_orange_sit_pet_feline_ginger_cat_animal.svg',
    lastUpdated: sub(new Date(), { days: 50 }),
  },
  additionalPermissions: {
    box: {
      userPreferences: {
        permissionType: 'RUD',
      },
      settings: {
        permissionType: 'RUD',
      },
      selectStore: {
        permissionType: 'RD',
      },
      transactionSearch: {
        permissionType: 'D',
        endDate: add(new Date(), { days: Math.floor(Math.random() * 100) }).toDateString(),
      },
      storeStatus: {
        permissionType: 'CRUD',
      },
      messages: {
        permissionType: 'RD',
        endDate: add(new Date(), { days: Math.floor(Math.random() * 100) }).toDateString(),
      },
    },
  },
};

export const getMockedEmployees = (employees: number, storeId: string, fixedAssignment: boolean = false) => {
  const mockData: Profile[] = [];

  for (let i = 0; i < employees; i++) {
    const assignedRoles = () => {
      const assignments = [];
      if (fixedAssignment)
        assignments.push({ suamRoleCode: mockRoles[i % 2], locationCode: storeId, isActive: true });
      else {
        for (let y = 0; y < (i % 2) + 1; y++) {
          assignments.push({
            suamRoleCode: mockRoles[Math.floor(Math.random() * mockRoles.length)],
            locationCode: storeId,
            isActive: true,
          });
        }
      }
      return assignments;
    };

    const readMessages = Array.from({ length: 10 }).map(() => Math.floor(Math.random() * 50).toString());

    mockData.push({
      ...profileMockData,
      id: i === 0 ? userId : Math.random().toString(),
      assignments: assignedRoles(),
      readMessages,
      completedOnboardingItems: { items: ['tour', 'transactionSearch'], allCompleted: false },
    });
  }
  return mockData;
};

export const getMockProfile = (storeId = mockStoreId, storeManager?: boolean) =>
  getMockedEmployees(1, storeId, storeManager)[0];
