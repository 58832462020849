const employees = {
  filterRoles: { key: 'employees.filterRoles', defaultValue: 'Filter Roles' },
  manageUser: {
    key: 'employees.manageUser',
    defaultValue: 'Manage user',
  },
  manageUserToolTip: {
    key: 'employees.manageUserToolTip',
    defaultValue: `Edit the user permissions`,
  },
  otherRoles: {
    key: 'employees.otherRoles',
    defaultValue: 'Other roles',
  },
  seeDetails: {
    key: 'common.seeDetails',
    defaultValue: 'See details',
  },
  view: {
    key: 'common.view',
    defaultValue: 'View',
  },
  edit: {
    key: 'employees.edit',
    defaultValue: 'Edit',
  },
  noData: {
    key: 'employees.noData',
    defaultValue: 'Hmm... Thats weird. Seems like we could not find any employees.',
  },
  expectingResult: {
    key: 'employees.expectingResult',
    defaultValue: 'If you were expecting results for store {{currentStore}}, try again or open an incident.',
  },
  error: {
    key: 'employees.error',
    defaultValue: "Oh no! We can't open employees and roles right now.",
  },
  other: {
    key: 'employees.other',
    defaultValue: 'Other',
  },
  otherToolTip: {
    key: 'employees.otherToolTip',
    defaultValue: 'Your coworker doesn’t have a specific role attached to them',
  },
};

const roles = {
  areaManager: {
    key: 'employees.roles.areaManager',
    defaultValue: 'Area Manager',
  },
  countryController: {
    key: 'employees.roles.countryController',
    defaultValue: 'Country Controller',
  },
};

const overviewRolesPermission = {
  manageRoleFor: {
    key: 'overviewRolesPermission.manageRoleFor',
    defaultValue: 'Manage Role For',
  },

  editRole: {
    key: 'overviewRolesPermission.editRole',
    defaultValue: 'Edit Role',
  },

  editPermission: {
    key: 'overviewRolesPermission.editPermission',
    defaultValue: 'Edit Permission',
  },

  header: {
    key: 'overviewRolesPermission.header',
    defaultValue: "{{firstName}} {{lastName}}'s current role is",
  },

  headerMobile: {
    key: 'overviewRolesPermission.headerMobile',
    defaultValue: 'Current role is ',
  },

  headerDetails: {
    key: 'overviewRolesPermission.headerDetails',
    defaultValue:
      'You have several options to add permission to this user. Please select one of the following options available.',
  },

  editRoleDetails: {
    key: 'overviewRolesPermission.editRoleDetails',
    defaultValue:
      'You have the option to promote/demote your coworker with a new role. This implies that the user will get new permissions or restrictions in BOX. Changes will impact all applications that the user is currently using.',
  },

  rolesList: {
    key: 'overviewRolesPermission.rolesList',
    defaultValue: 'These are the different roles:',
  },

  editPermissionDetailsText1: {
    key: 'overviewRolesPermission.editPermissionDetailsText1',
    defaultValue: 'You have the option to add more permission that normally the user wouldn’t have access to.',
  },
  editPermissionDetailsText2: {
    key: 'overviewRolesPermission.editPermissionDetailsText2',
    defaultValue: 'You can also remove current permissions the user have access to.',
  },
  editPermissionDetailsText3: {
    key: 'overviewRolesPermission.editPermissionDetailsText3',
    defaultValue: 'The user title will still remain but you’ll give them more options in software like:',
  },
  appListText: {
    key: 'overviewRolesPermission.appListText',
    defaultValue: 'These are the different applications',
  },
  noPermissionTooltip: {
    key: 'overviewRolesPermission.noPermissionTooltip',
    defaultValue: 'You don’t have premission',
  },
};

const editRolesModal = {
  headerDetails: {
    key: 'editRolesModal.headerDetails',
    defaultValue:
      'You can now add or remove roles to this user. By checking the box and hit save the user will have new permissions that comes with that role. A user can have several roles and user unique permission.',
  },

  contentHeader: {
    key: 'editRolesModal.contentHeader',
    defaultValue: 'User roles, add or remove role(s)',
  },

  saveSettings: {
    key: 'editRolesModal.saveSettings',
    defaultValue: 'Save Settings',
  },

  noRoleSelected: {
    key: 'editRolesModal.noRoleSelected',
    defaultValue: 'A user needs to have atleast one role',
  },

  successNotification: {
    key: 'editRolesModal.successNotification',
    defaultValue: '{{firstName}} {{lastName}} now have a new role.',
  },

  noPermissionsToEdit: {
    key: 'editRolesModal.noPermissionsToEdit',
    defaultValue: 'You have no permissions to edit this role',
  },
};

const editPermissionModal = {
  header: {
    key: 'editPermissionModal.header',
    defaultValue: 'Add permissions for',
  },
  headerDetails: {
    key: 'editPermissionModal.headerDetails',
    defaultValue:
      'Now you can add specific permissions to the user. You have four different options that you can check for each feature, you can check more than one box.',
  },
  permissionHeader: {
    key: 'editPermissionModal.permissionHeader',
    defaultValue: 'permissions',
  },
  savePermission: {
    key: 'editPermissionModal.savePermission',
    defaultValue: 'Save Permission',
  },
  successNotification: {
    key: 'editPermissionModal.successNotification',
    defaultValue: '{{firstName}} {{lastName}} now have new permissions. Go to "see details" to get the overview.',
  },
};

export const seeDetailsModal = {
  detailedInfo: {
    key: 'seeDetailsModal.detailedInfo',
    defaultValue: 'Detailed information',
  },

  additionalPermission: {
    key: 'seeDetailsModal.additionalPermission',
    defaultValue: 'Additional permission',
  },

  timeLimitedPermission: {
    key: 'seeDetailsModal.timeLimitedPermission',
    defaultValue: 'Time limited permission',
  },

  editRolesPermissions: {
    key: 'seeDetailsModal.editRolesPermissions',
    defaultValue: 'Edit Roles/Permissions',
  },

  manageUserButtonText: {
    key: 'seeDetailsModal.manageUserButtonText',
    defaultValue: 'Manage User',
  },

  additionalPermissionInfo: {
    key: 'seeDetailsModal.additionalPermissionInfo',
    defaultValue:
      'You have the option to add more permissions that the user normally wouldn’t have access to. We base it on the CRUD model. Which means the user can Create, Read, Update and Delete.',
  },
  timeLimitedPermissionInfo: {
    key: 'seeDetailsModal.timeLimitedPermissionInfo',
    defaultValue:
      'A user can have permission that are time limited. The date indicates when the permission expires.',
  },
};

export const employeesTranslations = {
  employees,
  roles,
  overviewRolesPermission,
  editRolesModal,
  editPermissionModal,
  seeDetailsModal,
};
