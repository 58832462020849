import styled from 'styled-components';
import { Button } from '../../../../stories/molecules';

export const StyledEmployeeTable = styled.div`
  :last-child {
    padding-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

export const StyledText = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledToolTip = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  justify-content: left;
`;
