export const multiStoreMenuItemTranslation = {
  currentlyWorkingOn: {
    key: 'menuItem.multiStore.currentlyWorkingOn',
    defaultValue: 'Currently working on.',
  },
  switchStore: {
    key: 'menuItem.multiStore.question',
    defaultValue: 'Switch store?',
  },
  switchLanguage: {
    key: 'menuItem.languages.switch',
    defaultValue: 'Switch language?',
  },
};
