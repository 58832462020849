import { ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & .MuiToggleButtonGroup-grouped {
    margin: ${({ theme }) => theme.spacing(0.5)};
    border: 0;
    &.Mui-disabled {
      border: 0;
    }
    &:not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing(2)};
      border-radius: ${({ theme }) => theme.spacing(1)};
    }
    &:first-of-type {
      border-radius: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
