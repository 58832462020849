import {
  IconButtonProps,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Property } from 'csstype';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useThemeContext } from '../../../global-state/themeContext';
import { SortDirection } from '../../../global-state/types';
import { Colors } from '../../../theme';
import { SkeletonLoader } from '../../atoms';
import { StyledPaper, StyledTableWrapper } from './Table.styles';

type Size = 'small' | 'medium';
type RowItem = string | number | JSX.Element;
type RowOptions = {
  size?: Size;
  selectedRowColor?: Colors;
  hoverColor?: Colors;
};

type Sort = {
  columnId: string;
  direction: SortDirection;
};

type PaginationOptions = {
  enabled: boolean;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  defaultPageIndex?: number;
  totalRows?: number;
  nextIconButtonProps?: IconButtonProps;
};

export type Row = { [key: string]: RowItem } & { id: number };

export type Column = {
  id: string;
  name: string | JSX.Element;
  width?: string;
  isSortable?: boolean;
};

export type TableProps = {
  rows: Row[];
  rowOptions?: RowOptions;
  columns: Column[];
  cellSize?: 'small' | 'medium';
  headerColor?: Colors;
  stickyHeader?: boolean;
  captionText?: string;
  useZebraStyles?: boolean;
  selectedRowIndex?: number;
  paginationOptions?: PaginationOptions;
  footer?: JSX.Element;
  maxHeight?: string;
  fixedHeight?: string;
  onRowClick?: (id: number) => void;
  onPageChange?: (pageNo: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  isLoading?: boolean;
  labelRowsPerPage?: string;
  skeletonLoaderOptions?: { numberOfRows: number };
  noBorder?: boolean;
  noHeader?: boolean;
  whiteSpace?: Property.WhiteSpace;
  handleSorting?: (columnId: string, direction: SortDirection) => Row[];
};

export const Table: FC<TableProps> = ({
  rows,
  rowOptions,
  columns,
  cellSize = 'medium',
  headerColor = 'backgroundTableHeader',
  stickyHeader = false,
  captionText,
  useZebraStyles = false,
  selectedRowIndex = -1,
  paginationOptions,
  footer,
  maxHeight,
  fixedHeight,
  onRowClick,
  onPageChange,
  onRowsPerPageChange,
  labelRowsPerPage,
  isLoading,
  skeletonLoaderOptions = { numberOfRows: 15 },
  noBorder = false,
  noHeader = false,
  whiteSpace = 'pre-wrap',
  handleSorting = () => [],
}) => {
  const [sortBy, setSortBy] = useState<Sort | undefined>(undefined);
  const [visibleRowData, setVisibleRowData] = useState<Row[]>(rows);
  const [isKeyPressActive, setIsKeyPressActive] = useState<boolean>();
  const [rowHighlightedIndex, setRowHighlightedIndex] = useState<number>(-1);
  const [selectedIndex, setSelectedIndex] = useState(selectedRowIndex);
  const [rowsPerPage, setRowsPerPage] = useState(paginationOptions?.rowsPerPage || 10);
  const [pageIndex, setPageIndex] = useState(paginationOptions?.defaultPageIndex || 0);

  const { mode } = useThemeContext();
  const defaultRowOptions: Partial<RowOptions> = {
    size: 'medium',
    selectedRowColor: mode === 'dark' ? 'primary' : 'backgroundSelected',
    hoverColor: mode === 'dark' ? 'backgroundSelectedDark' : 'hoverGray',
  };

  const updatedRowOptions = { ...defaultRowOptions, ...rowOptions };

  useEffect(() => {
    setSelectedIndex(selectedRowIndex);
  }, [selectedRowIndex]);

  useEffect(() => {
    if (paginationOptions?.defaultPageIndex === undefined) return;

    setPageIndex(paginationOptions.defaultPageIndex);
  }, [paginationOptions?.defaultPageIndex]);

  useEffect(() => {
    let updatedRows = sortBy?.columnId ? handleSorting(sortBy.columnId, sortBy.direction) : [...rows];
    if (paginationOptions?.enabled) {
      updatedRows = filterByPagination(updatedRows);
    }

    setVisibleRowData(updatedRows);
  }, [pageIndex, rowsPerPage, sortBy, rows]);

  const handleKeyPress = (keyboardEvent: KeyboardEvent<HTMLDivElement>) => {
    const startIndex = pageIndex * rowsPerPage;
    const endIndex = paginationOptions?.enabled ? startIndex + rowsPerPage - 1 : rows.length - 1;
    const isHighlightedRowOutOfRange =
      paginationOptions?.enabled && !(rowHighlightedIndex >= startIndex && rowHighlightedIndex <= endIndex);

    switch (keyboardEvent.key) {
      case 'ArrowUp': {
        setIsKeyPressActive(true);
        if (rowHighlightedIndex > startIndex || isHighlightedRowOutOfRange) {
          setRowHighlightedIndex((rowIndex) => (isHighlightedRowOutOfRange ? startIndex : rowIndex - 1));
        }
        break;
      }
      case 'ArrowDown': {
        setIsKeyPressActive(true);
        if (rowHighlightedIndex < endIndex || isHighlightedRowOutOfRange) {
          setRowHighlightedIndex((rowIndex) => (isHighlightedRowOutOfRange ? startIndex : rowIndex + 1));
        }
        break;
      }
      case 'ArrowLeft': {
        if (paginationOptions?.enabled) {
          setPageIndex((pageNo) => (pageNo > 0 ? pageNo - 1 : 0));
        }
        break;
      }
      case 'ArrowRight': {
        if (paginationOptions?.enabled) {
          const lastPageIndex = Math.ceil(rows.length / rowsPerPage) - 1;
          setPageIndex((pageNo) => (pageNo < lastPageIndex ? pageNo + 1 : lastPageIndex));
        }
        break;
      }
      case 'Enter': {
        setIsKeyPressActive(false);
        handleRowClick(rowHighlightedIndex);
        break;
      }
      default:
        break;
    }
  };

  const handleRowClick = (id: number) => {
    if (onRowClick) {
      setSelectedIndex(id);
      onRowClick(id);
    }
  };

  const handleSort = (columnId: string) => {
    setSortBy((sort) => {
      if (sort?.columnId !== columnId) {
        return { columnId, direction: SortDirection.Ascending };
      }

      return sort.direction === SortDirection.Ascending
        ? { columnId, direction: SortDirection.Descending }
        : { columnId, direction: SortDirection.Ascending };
    });
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPageIndex(page);
    if (onPageChange) onPageChange(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageIndex(0);
    setRowsPerPage(+event.target.value);

    if (onRowsPerPageChange) onRowsPerPageChange(+event.target.value);
  };

  const filterByPagination = (data: Row[]) => {
    const startIndex = pageIndex * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return data.filter((_, index) => index >= startIndex && index < endIndex);
  };

  const getRowIndex = (index: number) => index + pageIndex * rowsPerPage;

  const getColumns = () => {
    return columns.map(({ id, name, isSortable }) => (
      <TableCell key={id} data-testid="table-cell">
        {isSortable ? (
          <TableSortLabel
            active={id === sortBy?.columnId}
            direction={sortBy?.direction}
            onClick={() => handleSort(id)}
          >
            {name}
          </TableSortLabel>
        ) : (
          name
        )}
      </TableCell>
    ));
  };

  const getRows = () => {
    return visibleRowData.map((row, index) => {
      const rowIndex = getRowIndex(index);
      return (
        <TableRow
          data-testid="table-row"
          key={row.id}
          selected={rowIndex === selectedIndex}
          onClick={() => {
            if (onRowClick) {
              setRowHighlightedIndex(rowIndex);
              handleRowClick(rowIndex);
            }
          }}
          hover={!!updatedRowOptions.hoverColor}
          className={
            isKeyPressActive && selectedIndex !== rowIndex && rowHighlightedIndex === rowIndex
              ? 'highlightedRow'
              : undefined
          }
        >
          {columns.map(({ id, width }) => (
            <TableCell data-testid="table-cell" width={width} key={id} size={cellSize}>
              {row[id]}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const getSkeletonLoaderRows = () => {
    return Array.from({ length: skeletonLoaderOptions.numberOfRows }).map((_, index) => (
      <TableRow key={index}>
        {columns.map(({ id, width }) => (
          <TableCell data-testid="skeleton-table-cell" width={width} key={id}>
            <SkeletonLoader animationType="wave" />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  return (
    <StyledPaper>
      <StyledTableWrapper
        columnBackgroundColor={mode === 'dark' ? 'backgroundTableHeaderDark' : headerColor}
        rowHoverColor={updatedRowOptions.hoverColor}
        selectedRowColor={updatedRowOptions.selectedRowColor}
        useZebraStyles={useZebraStyles}
        maxHeight={maxHeight}
        fixedHeight={fixedHeight}
        role="tab"
        tabIndex={0}
        onKeyDown={handleKeyPress}
        noBorder={noBorder}
        whiteSpace={whiteSpace}
      >
        <TableContainer data-testid="table-container">
          <MaterialTable size={updatedRowOptions.size} stickyHeader={stickyHeader ? true : undefined}>
            {captionText && <caption>{captionText}</caption>}
            <TableHead data-testid="table-header">{!noHeader && <TableRow>{getColumns()}</TableRow>}</TableHead>
            <TableBody>{isLoading ? getSkeletonLoaderRows() : getRows()}</TableBody>
            <TableFooter>
              {footer && (
                <TableRow>
                  <TableCell variant="footer" colSpan={columns.length} data-testid="custom-footer">
                    {footer}
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </MaterialTable>
        </TableContainer>
      </StyledTableWrapper>
      {paginationOptions?.enabled && rows.length > 0 && (
        <TablePagination
          data-testid="pagination"
          component="div"
          count={paginationOptions.totalRows || rows.length}
          page={pageIndex}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationOptions.rowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          nextIconButtonProps={paginationOptions?.nextIconButtonProps}
          labelRowsPerPage={labelRowsPerPage}
        />
      )}
    </StyledPaper>
  );
};
