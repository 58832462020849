import { endOfWeek, isSameWeek, isSunday, nextSaturday, previousSunday, startOfWeek } from 'date-fns';

// Using the current date, returns a date range for a week retuns a date range from a Sun to Sat
export const getBussinessWeekRange = (currentDate: Date): { fromDate: Date; toDate: Date } => {
  const isCurrentDateSunday = isSunday(currentDate);

  if (isCurrentDateSunday) {
    const nextSaturdayDate = nextSaturday(currentDate);
    return { fromDate: currentDate, toDate: nextSaturdayDate };
  }

  const previousSundayDate = previousSunday(currentDate);
  const nextSaturdayDate = nextSaturday(currentDate);

  return { fromDate: previousSundayDate, toDate: nextSaturdayDate };
};

export const isInSameWeek = (dayA: Date, dayB: Date | null | undefined) => {
  if (!dayB) {
    return false;
  }

  return isSameWeek(dayA, dayB);
};

export const getWeekBounds = (date: Date) => {
  // Adjust the first day of the week if needed (e.g., set to Sunday hetr)
  const weekStart = startOfWeek(date, { weekStartsOn: 0 }); // Sunday as the first day of the week
  const weekEnd = endOfWeek(date, { weekStartsOn: 0 }); // Saturday as the last day of the week

  return { weekStart, weekEnd };
};
