import { Card } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import useScreenSize from '../../../hooks/useScreenSize';
import { Breakpoints, breakpoints } from '../../../theme';
import { ErrorType, logError } from '../../../utils/splunkLogger';
import { WidgetCommonState } from '../common/widgetCommonState/WidgetCommonState';
import { WidgetType } from '../types';

const colMaxWidths: { [key: number]: number } = {
  1: 500,
  1.5: 650,
  2: 800,
};

type ContainerProps = {
  col: number;
  $isLastChild: boolean;
};

const Container = styled(Card)<ContainerProps>`
  height: 400px;
  overflow-y: hidden;
  border-radius: ${({ theme: { spacing } }) => spacing(4)};

  ${({ col, $isLastChild }) =>
    $isLastChild &&
    `
    max-width: ${colMaxWidths[col] || colMaxWidths[2]}px;
  `}
`;

type ColumnSpec = {
  [key: number]: number;
};

const defaultSpec: ColumnSpec = {
  1: 500,
  1.5: 600,
  2: 900,
};

const screenSpecs: { [key in Breakpoints]?: ColumnSpec } = {
  tabletLandscape: {
    1: 0,
    1.5: 0,
    2: 0,
  },
  desktop: {
    1: 300,
    1.5: 400,
    2: 570,
  },
  desktopLarge: {
    1: 300,
    1.5: 400,
    2: 680,
  },
};

type WidgetCardProps = {
  name: WidgetType;
  col: number;
  order: number;
  highestOrder: number;
  style?: CSSProperties;
};

export const WidgetCard: FC<PropsWithChildren<WidgetCardProps>> = ({
  children,
  order,
  highestOrder,
  col,
  name,
  style,
}) => {
  const { width: screenWidth } = useScreenSize();

  const { values: breakpointValues } = breakpoints;

  const getMinWidth = (currentScreenwidth: number, column: number): number => {
    let minWidth = defaultSpec[column];

    let breakpoint: Breakpoints | null = null;

    if (currentScreenwidth < breakpointValues.desktopLarge) {
      breakpoint = 'desktopLarge';
    }

    if (currentScreenwidth < breakpointValues.desktop) {
      breakpoint = 'desktop';
    }

    if (currentScreenwidth < breakpointValues.tabletLandscape) {
      breakpoint = 'tabletLandscape';
    }

    const screenWidthSpec = breakpoint ? screenSpecs[breakpoint] : undefined;

    if (screenWidthSpec) {
      minWidth = screenWidthSpec[column] || screenWidthSpec[1];
    }

    return minWidth;
  };

  const minWidth = getMinWidth(screenWidth, col);

  return (
    <ErrorBoundary
      FallbackComponent={() => <WidgetCommonState isError errorText={'Widget error - splunk'} />}
      onError={(error, componentStack) =>
        logError({
          error,
          errorType: ErrorType.WIDGET_ERROR_BOUNDARY,
          info: { componentStack, widgetName: name },
        })
      }
    >
      <Container
        data-testid="widget-container"
        data-widget-card-order={order}
        col={col}
        $isLastChild={order === highestOrder}
        style={{
          ...style,
          flex: `${col}`,
          minWidth: minWidth === 0 ? '100%' : `${minWidth}px`,
          order,
        }}
      >
        {children}
      </Container>
    </ErrorBoundary>
  );
};
