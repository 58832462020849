export const selectStoreTranslation = {
  switchStore: {
    key: 'selectStore.switchStore',
    defaultValue: 'Want to switch store?',
  },
  switchStoreButton: {
    key: 'selectStore.switchStoreButton',
    defaultValue: 'Switch store?',
  },
  header: {
    key: 'selectStore.header',
    defaultValue: 'Which store would you like to work on?',
  },
  subHeader: {
    key: 'selectStore.subHeader',
    defaultValue:
      'With Back Office you can toggle between different stores that you are allocated to. Start by selecting the one you want to begin with. If you want to switch store view while logged in just click the drop down menu on the top right corner.',
  },
  storeIdText: {
    key: 'selectStore.storeIdText',
    defaultValue: 'Store ID',
  },
  switchMessage: {
    key: 'selectStore.switchMessage',
    defaultValue: 'Tap the button to go from {{currentStore}} to {{newStore}}. You can switch back at any time.',
  },
  switchingStore: {
    key: 'selectStore.switchingStore',
    defaultValue: 'Switching stores...',
  },
  storeStatus: {
    key: 'selectStore.storeStatus',
    defaultValue: 'Store status',
  },
  findStore: {
    key: 'selectStore.findStore',
    defaultValue: 'Find a store you would like to work on',
  },
  storeSelectionText: {
    key: 'selectStore.storeSelectionText',
    defaultValue:
      'You are currently allocated to {{numberOfStores}} stores. Start by selecting on the map or search the one you want to begin with. You can always switch to another store later',
  },
  selectStore: {
    key: 'selectStore.selectStore',
    defaultValue: 'Select store',
  },
  viewStoresHeader: {
    key: 'selectStore.viewStoresHeader',
    defaultValue: 'Back to store select screen?',
  },
  viewStoresMessage: {
    key: 'selectStore.viewStoresMessage',
    defaultValue:
      'You have access to over <b>{{numberOfStores}}</b> stores. Would you like to go back to the store selection screen to pick a different store?',
  },
  viewStoresButton: {
    key: 'selectStore.viewStoresButton',
    defaultValue: 'View All Stores',
  },
  searchStoreLabel: {
    key: 'selectStore.searchStoreLabel',
    defaultValue: 'Store name or ID',
  },
};
