import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores, useUser } from '../../hooks';
import { Icon, Icons, Typography } from '../../stories/atoms';
import { homeTranslations } from '../../translations';
import { isFailureResponse } from '../../utils';
import {
  ContentWrapper,
  HeadeTitleContainer,
  HeaderButton,
  HeaderContainer,
  InputContainer,
  MessageContainer,
  MessagesList,
  StyledInput,
  StyledSubmitButton,
} from './Chatbot.styles';
import { MessageItem } from './messageItem/MessageItem';

type Message = {
  isSender: boolean;
  text: string;
  prompts?: string[];
};

export type ChatbotProps = {
  isOpen: boolean;
  closeChatbot: () => void;
};

const INIT_MESSAGE = 'hi';

export const Chatbot: FC<ChatbotProps> = ({ isOpen, closeChatbot }) => {
  const { t } = useTranslation();
  const { slider } = homeTranslations;
  const { fetchChatBotData } = useStores();
  const {
    get: {
      profile: { avatar },
    },
  } = useUser();

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const onMessageReceive = (message: string, prompts: string[]) => {
    setMessages((prevMessages) => [...prevMessages, { isSender: false, text: message, prompts }]);
  };

  const sendMessage = async (message: string, isInit: boolean = false) => {
    setIsSendingMessage(true);

    const response = await fetchChatBotData(message);

    if (isFailureResponse(response)) {
      setIsSendingMessage(false);
      return;
    }

    setIsSendingMessage(false);

    const {
      data: { answer, prompts },
    } = response;

    if (isInit) setMessages([]);

    onMessageReceive(answer, prompts);
  };

  const initChat = () => {
    setMessages([]);
    sendMessage(INIT_MESSAGE, true);
  };

  const handlePromptSelect = (prompt: string) => {
    setMessages((prevMessages) => [...prevMessages, { isSender: true, text: prompt }]);
    sendMessage(prompt);
  };

  const handleSubmit = () => {
    if (inputText === '') return;

    setMessages((prevMessages) => [...prevMessages, { isSender: true, text: inputText }]);
    sendMessage(inputText);
    setInputText('');
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code != 'Enter') return;
    handleSubmit();
  };

  useEffect(() => {
    initChat();
  }, []);

  useEffect(() => {
    if (!messageContainerRef.current || messages.length === 0) return;

    const lastMessage = messages[messages.length - 1];

    if (!lastMessage.isSender) return;

    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  }, [messages]);

  return (
    <ContentWrapper data-testid="wrapper" isOpen={isOpen}>
      <HeaderContainer data-testid="header-container">
        <Icon icon={Icons.CHATBOT} />

        <HeadeTitleContainer data-testid="header-title-container">
          <Typography type="body2">Chat Box</Typography>
        </HeadeTitleContainer>

        <HeaderButton onClick={initChat} data-testid="header-refresh-button">
          <Icon icon={Icons.REFRESH} size="small" />
        </HeaderButton>

        <HeaderButton onClick={closeChatbot} data-testid="header-close-button">
          <Icon icon={Icons.CLOSE} size="small" />
        </HeaderButton>
      </HeaderContainer>

      <MessageContainer ref={messageContainerRef} data-testid="message-container">
        <MessagesList>
          {messages.map((message, index) => {
            const { isSender, text, prompts } = message;

            return (
              <div key={index}>
                <MessageItem
                  isSender={isSender}
                  text={text}
                  prompts={prompts}
                  avatar={avatar?.url ? `${avatar?.url}${process.env.REACT_APP_CDN_TOKEN}` : undefined}
                  onPromptSelect={handlePromptSelect}
                />
              </div>
            );
          })}
          {isSendingMessage && <MessageItem isSender={false} text="..." />}
        </MessagesList>
      </MessageContainer>

      <InputContainer data-testid="input-container">
        <StyledInput
          placeholder={t(slider.chatBotPlaceholder.key, slider.chatBotPlaceholder.defaultValue)}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleOnKeyDown}
        />

        <StyledSubmitButton data-testid="submit-button" onClick={() => handleSubmit()}>
          {inputText === '' && <Icon icon={Icons.EDIT} size="small" />}

          {inputText !== '' && <Icon icon={Icons.ARROW} size="small" />}
        </StyledSubmitButton>
      </InputContainer>
    </ContentWrapper>
  );
};
