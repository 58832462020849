import styled, { css, keyframes } from 'styled-components';
import { Typography } from '../typography/Typography';

import type { SkeletonLoaderProps, SkeletonLoaderVariant } from './SkeletonLoader';

const waveAnimation = keyframes`
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const StyledContainer = styled.div<{ width: string; height: string; gutterBottom: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  margin-bottom: ${({ gutterBottom, theme: { spacing } }) => gutterBottom && spacing(gutterBottom)};
`;

export const StyledTypography = styled(Typography)`
  flex: 1;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

export const StyledHeaderTypographyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledListContainer = styled.div<{ variant: SkeletonLoaderVariant }>`
  display: flex;
  flex: 1;
  align-items: center;

  &:last-child {
    width: ${({ variant }) => variant === 'paragraph' && '25%'};
  }
`;

const getBorderRadius = (variant: SkeletonLoaderVariant, showRoundBorders: boolean) => {
  if (variant === 'circle') return '50%';

  if (showRoundBorders) return '10px';

  return null;
};

export const StyledSkeletonLoader = styled.div<SkeletonLoaderProps>`
  height: ${({ height }) => height || '100%'};
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin && margin};
  justify-content: center;
  align-items: center;
  background: ${({
    theme: {
      color,
      palette: { mode },
    },
  }) => (mode === 'dark' ? color.backgroundSelectedDark : color.borderGray)};
  position: relative;
  overflow: hidden;
  animation: ${({ animationType }) =>
    animationType === 'pulse' &&
    css`
      ${pulseAnimation} 1.2s infinite cubic-bezier(0, 0, 0.2, 1)
    `};
  border-radius: ${({ variant, showRoundBorders }) =>
    getBorderRadius(variant as SkeletonLoaderVariant, showRoundBorders as boolean)};

  &::after {
    content: '';
    background: ${({
      theme: {
        color,
        palette: { mode },
      },
      animationType,
    }) =>
      animationType === 'wave' &&
      `linear-gradient(45deg, ${mode === 'dark' ? color.backgroundSelectedDark : color.borderGray} 20%, ${
        color.inActiveGray
      } 60%, ${mode === 'dark' ? color.backgroundSelectedDark : color.borderGray})`};
    position: absolute;
    width: 250%;
    height: 100%;
    animation: ${waveAnimation} 2s infinite ease forwards;
    display: ${({ animationType }) => (animationType === 'pulse' || animationType === 'none') && 'none'};
  }
`;
