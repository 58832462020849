import { Drawer as MuiDrawer } from '@mui/material';
import styled from 'styled-components';

export type DrawerVariants = 'permanent' | 'persistent' | 'temporary';

export const StyledDrawer = styled(MuiDrawer)<{ variant: DrawerVariants; $hasBorderRadius: boolean }>`
  height: ${({ theme: { sizing }, variant }) => variant !== 'temporary' && sizing.page};
  position: ${({ variant }) => variant !== 'temporary' && 'absolute'};

  & > div {
    position: ${({ variant }) => variant !== 'temporary' && 'relative'};
    background-color: ${({ theme: { color, palette }, variant }) =>
      variant !== 'temporary' && (palette.mode === 'dark' ? '#212B36' : color.white)};
    width: ${({ theme: { sizing }, variant }) => variant !== 'temporary' && sizing.sideMenu};
    height: ${({ variant }) => variant !== 'temporary' && '100vh'};
  }

  & > .MuiPaper-root {
    border-radius: ${({ theme: { spacing }, $hasBorderRadius }) => $hasBorderRadius && spacing(7, 7, 0, 0)};
  }
`;
