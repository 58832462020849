import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { useRef, useState } from 'react';
import { msalScopes } from '../constants/msalConfig';
import { ErrorType, logError } from '../utils/splunkLogger';

type TokenInfo = {
  accessToken: string | null;
  uniqueId?: string | null;
  idToken?: string | null;
};

type Output = TokenInfo & {
  fetchTokenInfo: () => Promise<void>;
};

export const useTokenInfo = (): Output => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const redirectInProgress = useRef(false);

  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({
    accessToken: null,
    uniqueId: null,
    idToken: null,
  });

  const fetchTokenInfo = async () => {
    if (redirectInProgress.current) return;

    if (!account) return;

    try {
      const { accessToken, uniqueId, idToken } = await instance.acquireTokenSilent({
        ...msalScopes,
        account: account,
      });

      setTokenInfo({ accessToken, uniqueId, idToken });
    } catch (err) {
      console.log(err);
      logError({
        error: err as Error,
        errorType: ErrorType.AUTH_ERROR,
        info: { message: 'Failed to get token info' },
      });

      if (err instanceof InteractionRequiredAuthError) {
        redirectInProgress.current = true;

        instance.acquireTokenRedirect({
          ...msalScopes,
          account: account,
        });
      }
    }
  };

  return { ...tokenInfo, fetchTokenInfo };
};
