import styled from 'styled-components';
import { Colors } from '../../../theme';
import { Icon } from '../../atoms';
import { Typography } from '../../atoms/typography/Typography';
import { Spacing, getSpacing } from '../../../utils';
import type { LabelPosition } from './Checkbox';

const labelPositionMapping = {
  top: 'column-reverse',
  bottom: 'column',
  left: 'row-reverse',
  right: 'row',
};

export const StyledCheckboxWrapper = styled.div<{ isDisabled?: boolean; margin?: Spacing }>`
  display: inline-flex;
  position: relative;
  align-items: center;
  margin: ${({ margin, theme: { spacing } }) => margin && getSpacing(margin, spacing)};

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    opacity: 0;
    z-index: ${({ theme: { zIndex } }) => zIndex.appBar};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledLabelWrapper = styled.label<{ label?: string; labelPosition?: LabelPosition }>`
  display: inline-flex;
  flex: 1;
  align-items: center;
  flex-direction: ${({ label, labelPosition }) =>
    label && labelPosition ? labelPositionMapping[labelPosition] : null};
  cursor: inherit;
`;

export const StyledTypography = styled(Typography)`
  display: inline-block;
  width: 100%;
  user-select: none;
`;

export const StyledCheckbox = styled.div<{ isDisabled: boolean; labelPosition: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { spacing } }) => spacing(5)};
  width: ${({ theme: { spacing } }) => spacing(5)};
  padding: ${({ theme: { spacing } }) => spacing(0, 0.5)};
  border-radius: ${({ theme: { spacing } }) => spacing(0.75)};
  border: ${({ theme: { color, palette }, isDisabled }) =>
    `1px solid ${
      color && (isDisabled ? color.hoverGray : palette.mode === 'dark' ? color.white : color.primary)
    }`};
  margin: ${({ theme: { spacing }, labelPosition }) =>
    labelPosition === 'left'
      ? spacing(0, 0, 0, 2)
      : labelPosition === 'right'
      ? spacing(0, 2, 0, 0)
      : labelPosition === 'bottom'
      ? spacing(0, 0, 2)
      : spacing(2, 0, 0)};
`;

export const StyledIcon = styled(Icon)<{ isDisabled: boolean; color?: Colors }>`
  color: ${({ theme: { color: themeColor, palette }, color, isDisabled }) =>
    color &&
    (isDisabled ? themeColor.inActiveGray : palette.mode === 'dark' ? themeColor.white : themeColor[color])};
`;

export const StyledLabel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
`;
