import styled from 'styled-components';
import { Container } from '../../stories/atoms';
import { IconWithText } from '../../stories/molecules';

export const columnWidths = {
  salesNet: 14,
  lastWeek: 10,
  lastYear: 10,
  sob: 10,
  minPxWidth: 80,
};

export const FilterHeaderContainer = styled.div`
  height: ${({ theme: { spacing } }) => spacing(12)};
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(5)};
  padding: ${({ theme: { spacing } }) => spacing(0, 8)};
`;

export const ExpandButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledCloseIconWithText = styled(IconWithText)<{ shouldRotate: boolean }>`
  & > :nth-child(1) {
    transform: ${({ shouldRotate }) => shouldRotate && 'rotate(45deg)'};
  }
`;

export const NestedGroupViewContainer = styled.div<{ isMobileView: boolean }>`
  flex: 1;
  overflow: auto;
  margin-bottom: ${({ isMobileView, theme: { spacing } }) => (isMobileView ? spacing(18) : spacing(12))};
`;

export const TableFooterContainer = styled(Container)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  padding: ${({ theme: { spacing } }) => spacing(0, 8)};
`;
