import { FC } from 'react';
import { Typographies } from '../../../theme';
import {
  StyledContainer,
  StyledSkeletonLoader,
  StyledTypography,
  StyledListContainer,
} from './SkeletonLoader.styles';

export type AnimationType = 'pulse' | 'wave' | 'none';
export type SkeletonLoaderVariant = 'text' | 'paragraph' | 'list' | 'rect' | 'square' | 'circle';

export type SkeletonLoaderProps = {
  variant?: SkeletonLoaderVariant;
  height?: string;
  width?: string;
  margin?: string;
  showRoundBorders?: boolean;
  animationType?: AnimationType;
  textType?: Typographies;
  listBulletStyle?: 'circle' | 'square';
  gutterBottom?: number;
  lines?: number;
};

export const SkeletonLoader: FC<React.PropsWithChildren<SkeletonLoaderProps>> = ({
  variant = 'text',
  height = '1vh',
  width = '100%',
  showRoundBorders = false,
  animationType = 'pulse',
  textType = 'body2',
  listBulletStyle,
  gutterBottom = 2,
  lines = 8,
}) => {
  const rows = [...Array(lines).keys()];

  const getSkeletonLoaderElement = (extendedProps?: SkeletonLoaderProps) => (
    <StyledSkeletonLoader
      data-testid="skeleton-loader"
      variant={variant}
      animationType={animationType}
      showRoundBorders={showRoundBorders}
      {...extendedProps}
    />
  );

  const getListElement = () => {
    return rows.map((lineNumber) => (
      <StyledListContainer data-testid="list-container" key={lineNumber} variant={variant}>
        {listBulletStyle &&
          getSkeletonLoaderElement({
            width: '12px',
            height: '12px',
            variant: listBulletStyle === 'square' ? 'square' : 'circle',
            margin: '0 10px 0 0',
          })}
        {getSkeletonLoaderElement({ height, margin: '0 0 2vh 0' })}
      </StyledListContainer>
    ));
  };

  const getParagraphElement = () => {
    return rows.map((lineNumber) => {
      let lineWidth = '100%';

      if (lines > 10 && lineNumber > 0) {
        const minWidth = 60;
        const maxWidth = 90;
        const alternateRowNo = 5;
        const randomWidth = Math.floor(Math.random() * (maxWidth - minWidth + 1) + minWidth);

        lineWidth = lineNumber === 0 || lineNumber % alternateRowNo ? '100%' : `${randomWidth}%`;
      }

      return (
        <StyledListContainer data-testid="paragraph-container" key={lineNumber} variant={variant}>
          {getSkeletonLoaderElement({ height, width: lineWidth })}
        </StyledListContainer>
      );
    });
  };

  const getTextElement = (type?: Typographies) => {
    return (
      <StyledTypography type={type || textType}>{getSkeletonLoaderElement({ height: '1em' })}</StyledTypography>
    );
  };

  return (
    <StyledContainer
      height={variant === 'text' || variant === 'list' || variant === 'paragraph' ? '100%' : height}
      width={variant === 'circle' || variant === 'square' ? height : width}
      gutterBottom={gutterBottom}
      data-testid="skeleton-loader-container"
    >
      {variant === 'list' && getListElement()}
      {variant === 'paragraph' && getParagraphElement()}
      {variant === 'text' && getTextElement()}
      {(variant === 'circle' || variant === 'square' || variant === 'rect') && getSkeletonLoaderElement()}
    </StyledContainer>
  );
};
