/* istanbul ignore file */
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useThemeContext } from '../../../global-state/themeContext';
import { SortDirection } from '../../../global-state/types';
import { SkeletonLoader } from '../../../stories/atoms';
import { Colors } from '../../../theme';
import { Container, PaginatorContainer, TableContainer } from './Table.styles';

type SortConfig<H extends string, T> = {
  [key in H]?: {
    getItemValue?: (item: T) => number | string | Date | undefined | null;
    customSortHandler?: (itemA: T, itemB: T) => number;
  };
};

type RowItem<H> = {
  id: H;
  width?: string;
  content: ReactNode;
};

export type HeaderRowItem<H> = {
  id: H;
  width?: string;
  content: ReactNode;
};

type TableProps<H extends string, T> = {
  headerItems: HeaderRowItem<H>[];
  items: T[];

  isLoading?: boolean;

  sortConfig?: SortConfig<H, T>;
  sortColumnId?: H;
  sortDirection?: SortDirection;

  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  currentPageIndex?: number;
  skeletonRowCount?: number;

  disableHeader?: boolean;
  stickyHeader?: boolean;
  headerColor?: Colors;
  useZebraStyles?: boolean;
  noBorder?: boolean;
  noHeader?: boolean;
  fixedHeight?: string;

  getRowItems: (item: T) => RowItem<H>[];
  onPageIndexChange?: (pageIndex: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  onRowSelect?: (item: T) => void;
  getIsRowSelected?: (item: T) => boolean;
  onSort?: (columnId: H) => void;
};

export function Table<H extends string, T>({
  headerItems,
  items,

  isLoading = false,

  sortConfig,
  sortColumnId,
  sortDirection,

  rowsPerPageOptions,
  rowsPerPage,
  currentPageIndex,
  skeletonRowCount = 15,

  disableHeader = false,
  stickyHeader = false,
  headerColor = 'backgroundTableHeader',
  useZebraStyles = false,
  noBorder = false,
  noHeader,
  fixedHeight,

  getRowItems,
  onPageIndexChange,
  onRowsPerPageChange,
  onRowSelect,
  getIsRowSelected,
  onSort,
}: TableProps<H, T>) {
  const { mode } = useThemeContext();

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const selectedRowColor = mode === 'dark' ? 'primary' : 'backgroundSelected';
  const hoverRowColor = mode === 'dark' ? 'backgroundSelectedDark' : 'hoverGray';
  const columnBackgroundColor = mode === 'dark' ? 'backgroundTableHeaderDark' : headerColor;

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onRowsPerPageChange && onPageIndexChange) {
      onPageIndexChange(0);
      onRowsPerPageChange(parseInt(event.target.value));
    }
  };

  const getSortedItems = (
    itemsToSort: T[],
    columnId: H | undefined,
    direction: SortDirection | undefined,
  ): T[] => {
    const sortedItems = [...itemsToSort];

    if (!columnId || !direction || !sortConfig) return sortedItems;

    const columnSortConfig = sortConfig[columnId];

    if (!columnSortConfig) return sortedItems;

    const { getItemValue, customSortHandler } = columnSortConfig;

    if (customSortHandler) {
      return sortedItems.sort(customSortHandler);
    }

    if (!getItemValue) return sortedItems;

    return sortedItems.sort((itemA, itemB) => {
      const valueA = getItemValue(itemA);
      const valueB = getItemValue(itemB);

      if (valueA === null || valueA === undefined) return 1;

      if (valueB === null || valueB === undefined) return 1;

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        if (valueA === valueB) return 0;
        if (direction === SortDirection.Ascending) return valueA - valueB;
        return valueB - valueA;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (valueA === valueB) return 0;
        if (direction === SortDirection.Ascending) return valueA < valueB ? -1 : 1;
        return valueB < valueA ? -1 : 1;
      }

      return 0;
    });
  };

  const scrollTableToTop = () => {
    const tableContainerEl = tableContainerRef.current;

    if (!tableContainerEl?.scrollTo) return;

    tableContainerEl.scrollTo({ top: 0 });
  };

  const sortedItems = useMemo(
    () => getSortedItems(items, sortColumnId, sortDirection),
    [items, sortColumnId, sortDirection],
  );

  if (sortColumnId && sortDirection && sortConfig) {
    const columnSortConfig = sortConfig[sortColumnId];

    if (columnSortConfig) {
    }
  }

  let itemsPage = sortedItems;
  if (currentPageIndex !== undefined && rowsPerPage !== undefined) {
    itemsPage = sortedItems.slice(currentPageIndex * rowsPerPage, currentPageIndex * rowsPerPage + rowsPerPage);
  }

  useEffect(scrollTableToTop, [currentPageIndex]);

  return (
    <Container>
      <TableContainer
        data-testid="table-container"
        ref={tableContainerRef}
        useZebraStyles={useZebraStyles}
        noBorder={noBorder}
        selectedRowColor={selectedRowColor}
        columnBackgroundColor={columnBackgroundColor}
        rowHoverColor={hoverRowColor}
        isRowSelectable={!!onRowSelect}
        fixedHeight={fixedHeight}
      >
        <MaterialTable stickyHeader={stickyHeader}>
          {/* Table Header */}
          {!noHeader && (
            <TableHead data-testid="table-header">
              {!disableHeader && (
                <TableRow>
                  {headerItems.map((headerItem) => {
                    const { id, content, width } = headerItem;
                    const isSortable = sortConfig && sortConfig[id];

                    return (
                      <TableCell data-testid="table-cell" key={id} width={width}>
                        {isSortable && (
                          <TableSortLabel
                            active={id === sortColumnId}
                            direction={sortDirection}
                            onClick={() => onSort && onSort(id)}
                          >
                            {content}
                          </TableSortLabel>
                        )}

                        {!isSortable && content}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableHead>
          )}

          {/* Skeleton Loader */}
          <TableBody>
            {isLoading &&
              Array.from({ length: skeletonRowCount }).map((_, index) => (
                <TableRow key={index}>
                  {headerItems.map((headerItem) => {
                    return (
                      <TableCell data-testid="table-cell" key={headerItem.id as string} width={headerItem.width}>
                        <SkeletonLoader animationType="wave" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}

            {!isLoading && (
              <>
                {itemsPage.map((item, itemIndex) => {
                  const rowItems = getRowItems(item);

                  return (
                    <TableRow
                      data-testid="table-row"
                      key={itemIndex}
                      selected={getIsRowSelected ? getIsRowSelected(item) : undefined}
                      onClick={() => onRowSelect && onRowSelect(item)}
                      hover={true}
                    >
                      {rowItems.map((rowItem) => (
                        <TableCell data-testid="table-cell" key={rowItem.id} width={rowItem.width}>
                          {rowItem.content}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>

      <PaginatorContainer>
        {currentPageIndex !== undefined && rowsPerPage !== undefined && (
          <TablePagination
            data-testid="pagination"
            component="div"
            count={items.length}
            page={currentPageIndex}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
              if (onPageIndexChange) onPageIndexChange(page);
            }}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </PaginatorContainer>
    </Container>
  );
}
