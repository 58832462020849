import styled from 'styled-components';
import { SelectedFilters as CommonSelectedFilters } from '../employees/role-management/Employees.styles';

const getGridColumns = (isReceiptVisible: boolean) => {
  let layout = '100%';

  if (isReceiptVisible) {
    layout = 'auto 20vw';
  }

  return layout;
};

export const TableReceiptWrapper = styled.div<{ isReceiptVisible: boolean }>`
  display: grid;
  grid-template-columns: ${({ isReceiptVisible }) => getGridColumns(isReceiptVisible)};
  grid-template-rows: 100%;
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;

export const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 8)};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const HeaderFiltersWrapper = styled(FiltersWrapper)`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;

export const SelectedFilters = styled(CommonSelectedFilters)`
  align-items: center;
  row-gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledFilterMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFilterLabel = styled.div`
  width: ${({ theme: { spacing } }) => spacing(50)};
  padding: ${({ theme: { spacing } }) => spacing(4, 2)};
`;

export const StyledFilterCount = styled.div`
  color: ${({ theme: { color } }) => color.textGray};
`;

export const AnimationWrapper = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(2)};
`;
