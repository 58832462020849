import { CircularProgress } from '@mui/material';
import { FC, forwardRef } from 'react';
import { useThemeContext } from '../../../global-state/themeContext';
import { Colors } from '../../../theme';
import { Spacing } from '../../../utils';
import { TypographyProps } from '../../atoms/typography/Typography';
import { IconWithText, IconWithTextProps } from '../icon-with-text/IconWithText';
import { ButtonVariants, StyledButton } from './Button.styles';

type ButtonType = 'button' | 'submit';

type ButtonClick =
  | { onClick: (event: React.MouseEvent<HTMLElement>) => void; disabled?: boolean }
  | { onClick?: (event: React.MouseEvent<HTMLElement>) => void; disabled: true };

type TextColor = { [key in ButtonVariants]: Colors };

export type ButtonContentProps = Omit<IconWithTextProps, 'label' | 'textOptions'> & {
  textOptions?: Omit<TypographyProps, 'label' | 'type'>;
};

export type ButtonProps = {
  variant?: ButtonVariants;
  type?: ButtonType;
  fullWidth?: boolean;
  id?: string;
  label: string;
  isLoading?: boolean;
  buttonContentProps?: ButtonContentProps;
  isDarkMode?: boolean;
  minWidth?: Spacing;
  ref?: React.Ref<HTMLButtonElement>;
} & ButtonClick;

export const Button: FC<React.PropsWithChildren<ButtonProps>> = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      variant = 'primary',
      disabled = false,
      fullWidth = false,
      buttonContentProps,
      isLoading = false,
      label,
      ...props
    },
    ref,
  ) => {
    const { mode } = useThemeContext();
    const isDarkMode = mode === 'dark';

    const textColorMapping: TextColor = {
      primary: 'white',
      secondary: 'primary',
      text: 'primary',
    };

    const hoverColorMapping: TextColor = {
      primary: 'white',
      secondary: 'secondaryTextGray',
      text: 'secondaryTextGray',
    };

    const defaultTextColor = isDarkMode ? 'primary' : disabled ? 'textGray' : textColorMapping[variant];
    const defaultHoverColor = isDarkMode ? 'primary' : disabled ? 'textGray' : hoverColorMapping[variant];

    const updatedProps: IconWithTextProps = {
      ...buttonContentProps,
      textOptions: {
        color: defaultTextColor,
        ...buttonContentProps?.textOptions,
        type: 'button',
        hoverColor: defaultHoverColor,
      },
      label,
    };

    return (
      <StyledButton
        ref={ref}
        data-testid="button"
        type={type}
        fullWidth={fullWidth}
        disabled={disabled || isLoading}
        variant={variant}
        isDarkMode={isDarkMode}
        {...props}
      >
        {isLoading ? <CircularProgress size={16} /> : <IconWithText {...updatedProps} />}
      </StyledButton>
    );
  },
);
