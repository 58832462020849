import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { StoreDetails } from '../../../global-state/types';
import { useStores, useUser } from '../../../hooks';
import { SkeletonLoader, Typography } from '../../../stories/atoms';
import LocationIcon from '../../../stories/atoms/icon/assets/Location.svg';
import LocationActiveIcon from '../../../stories/atoms/icon/assets/LocationActive.svg';
import LocationActiveHM from '../../../stories/atoms/icon/assets/LocationActive_HM.svg';
import LocationHM from '../../../stories/atoms/icon/assets/Location_HM.svg';
import { Animation, Animations } from '../../../stories/molecules';
import { isFailureResponse } from '../../../utils';
import { MapContainer, StoreContainer, StyledContainer, StyledErrorContainer } from './MyStoreMap.styles';

export const MyStoreMap: FC = observer(() => {
  // TODO: remove below line when integrating with BE in [BTCOBUI-2089]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const {
    get: { storesDetails, currentStoreId },
  } = useUser();

  const { fetchNearbyStores } = useStores();

  const [selectedStoreId, setSelectedStoreId] = useState<string>(currentStoreId);
  const [nearByStores, setNearByStores] = useState<{
    data: StoreDetails[];
    isError: boolean;
  }>({
    data: [],
    isError: false,
  });

  const storeMarkers: { lat: number; lng: number; storeId: string; corporateBrandId: number }[] = [];
  if (storesDetails) {
    const currentStore = storesDetails.find((store) => store.storeId === currentStoreId);
    const newObj = {
      lat: Number(currentStore?.latitude),
      lng: Number(currentStore?.longitude),
      storeId: currentStoreId,
      corporateBrandId: currentStore?.corporateBrandId ?? 0,
    };
    // TODO: uncomment when integrating with BE in [BTCOBUI-2089]
    // storeMarkers = nearByStores.data.map(({ longitude, latitude, storeId }) => ({
    //   lat: Number(latitude),
    //   lng: Number(longitude),
    //   storeId,
    //   corporateBrandId,
    // }));
    storeMarkers.push(newObj);
  }

  useEffect(() => {
    if (!currentStoreId) return;
    const fetchResponse = async () => {
      const response = await fetchNearbyStores();

      if (isFailureResponse(response)) {
        setNearByStores({ data: [], isError: true });
        return;
      }

      const { data } = response;
      setNearByStores({ data, isError: false });
    };

    fetchResponse();
  }, [currentStoreId]);

  const center = useMemo(() => {
    if (storeMarkers.length === 0) {
      return { lat: 0, lng: 0 };
    }

    const totalLatitude = storeMarkers.reduce((sum, marker) => sum + marker.lat, 0);
    const totalLongitude = storeMarkers.reduce((sum, marker) => sum + marker.lng, 0);

    const avgLatitude = totalLatitude / storeMarkers.length;
    const avgLongitude = totalLongitude / storeMarkers.length;

    return { lat: avgLatitude, lng: avgLongitude };
  }, [storeMarkers]);

  // TODO: uncomment when integrating with BE in [BTCOBUI-2089]
  // const fitBounds = () => {
  //   if (mapInstance && storeMarkers.length > 0) {
  //     const bounds = new google.maps.LatLngBounds();
  //     storeMarkers.forEach(({ lat, lng }) => bounds.extend(new google.maps.LatLng(lat, lng)));
  //     mapInstance.fitBounds(bounds);
  //   }
  // };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  const handleMapLoad = (map: google.maps.Map) => {
    setMapInstance(map);
  };

  // TODO: uncomment when integrating with BE in [BTCOBUI-2089]
  // useEffect(() => {
  //   fitBounds();
  // }, [storeMarkers, mapInstance]);

  const handleMarkerClick = (storeId: string) => {
    setSelectedStoreId(storeId);
  };

  const getLocationMarkerIcon = (brandId: number, storeId: string) => {
    const isActive = selectedStoreId === storeId;
    return brandId === 0
      ? isActive
        ? LocationActiveHM
        : LocationHM
      : isActive
      ? LocationActiveIcon
      : LocationIcon;
  };

  const ErrorAnimation = () => {
    return (
      <StyledErrorContainer data-testid="error-my-store-widget">
        <Animation title="" animation={Animations.SAD} size="small" />
      </StyledErrorContainer>
    );
  };
  if (nearByStores.isError) return <ErrorAnimation />;

  const defaultView = (
    <StyledContainer height="50%" wrap="nowrap" margin={[4, 5, 2]} data-testid="my-store-widget">
      <MapContainer data-testid="map-container">
        {!isLoaded || storeMarkers.length === 0 ? (
          <SkeletonLoader variant="rect" height="100%" width="100%" />
        ) : (
          <GoogleMap
            zoom={10}
            data-testid="google-map"
            center={center}
            options={{ mapTypeControl: false, streetViewControl: false }}
            mapContainerStyle={{ height: '100%', width: '100%' }}
            onLoad={(map) => {
              handleMapLoad(map);
            }}
          >
            {storeMarkers.map(({ lat, lng, storeId, corporateBrandId }, index) => {
              if (isNaN(lat) || isNaN(lng)) return null;

              return (
                <MarkerF
                  key={index}
                  position={{ lat, lng }}
                  onClick={() => handleMarkerClick(storeId)}
                  options={{
                    icon: {
                      url: getLocationMarkerIcon(corporateBrandId, storeId),
                    },
                  }}
                />
              );
            })}
          </GoogleMap>
        )}
      </MapContainer>
      <StoreContainer padding={[2, 3]} data-testid="store-container">
        <Typography data-testid="store-name" type="button" overflow="visible">
          {storesDetails.find((store) => store.storeId === currentStoreId)?.name}
        </Typography>
        <Typography data-testid="store-address" type="legal" overflow="visible" margin={[1, 0, 2]}>
          {storesDetails.find((store) => store.storeId === currentStoreId)?.address}
        </Typography>
        {/* // TODO: uncomment when integrating with BE in [BTCOBUI-2089] */}
        {/* {nearByStores.data.length !== 0 && (
          <Container direction="horizontal" wrap="nowrap" margin={[1, 0, 0, 0]} space="between" width="100%">
            <Typography>{t(common.nearby.key, common.nearby.defaultValue)}</Typography>
            <Container direction="horizontal" width="80%" position="right" data-testid="nearby-store-container">
              {nearByStores.data.map((store, index) => (
                <Tooltip
                  key={`${store.storeId}-${index}`}
                  position="bottom-start"
                  arrowedToolTip
                  text={
                    <Container padding={[2]}>
                      <Typography color="white">{store.name}</Typography>
                      <Typography color="white" type="legal">
                        {store.address}
                      </Typography>
                    </Container>
                  }
                >
                  <StoreChip
                    onClick={() => {
                      setSelectedStoreId(store.storeId);
                    }}
                  >
                    <Typography>{store.storeId}</Typography>
                  </StoreChip>
                </Tooltip>
              ))}
            </Container>
          </Container>
        )} */}
      </StoreContainer>
    </StyledContainer>
  );

  return defaultView;
});
