import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Forecast,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import { useThemeContext } from '../../../../../global-state/themeContext';
import {
  QueueLengthItem,
  QueueLengthPerformanceItem,
  QueueLengthResponse,
  TillType,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { useAsyncAction } from '../../../../../hooks/useAsyncAction';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { Animation, Animations, DropdownMenu, DynamicTab, TagsList } from '../../../../../stories/molecules';
import { Charts } from '../../../../../stories/molecules/charts/Charts';
import { Typographies, muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { Spacing, getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { StyledCustomLegends } from '../../../Home.styles';
import { ProgressIndicator } from '../../../common/progressIndicator/ProgressIndicator';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { ForecastContainer, StyledSummaryContainer, StyledSummaryItem, Wrapper } from './QueueLengthWidget.styles';

const TILLS_PER_PAGE = 10;

export type QueueLengthProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  isToday: boolean;
  hasPermissionToEditKpi: boolean;
  fromDate?: Date;
  toDate?: Date;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
};

export const QueueLengthWidget: FC<QueueLengthProps> = observer(
  ({ interval, currentDate, isToday, hasPermissionToEditKpi, fromDate, toDate, handleDropdownDetails }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
    const {
      queueLength: queueLengthTranslations,
      common: commonTranslations,
      kpiTargets: kpiTargetsTranslations,
    } = homeTranslations;
    const { fetchQueueLengthData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { mode } = useThemeContext();
    const {
      get: { kpiTargetsProps },
    } = useUser();

    const [tabIndex, setTabIndex] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyQueueLength');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyQueueLength')?.isLocked ?? false;

    const {
      result: queueLengthData,
      isLoading: queueLengthDataLoading,
      isError: queueLengthDataError,
      triggerAction: triggerFetchQueueLength,
    } = useAsyncAction<QueueLengthResponse>();

    const { result: forecastData, triggerAction: triggerFetchForecastData } =
      useAsyncAction<QueueLengthPerformanceItem[]>();

    const fetchQueueLength = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchQueueLengthData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<QueueLengthPerformanceItem>('queueLength');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setPageIndex(0);
      setTabIndex(newTabIndex);
    };

    const handlePageChange = (newPageIndex: number) => {
      setPageIndex(newPageIndex);
    };

    const getFilteredQueueLengthItems = (
      currentQueueLengthData: QueueLengthResponse | null,
      currentTabIndex: number,
    ) => {
      if (!currentQueueLengthData) return [];

      const tillTypes: TillType[] = ['DEFAULT', 'SELFCHECKOUT'];

      let filteredQueueLengthItems: QueueLengthItem[];

      if (currentTabIndex === 0) {
        filteredQueueLengthItems = currentQueueLengthData.queueLength.filter(
          (item) => item.tillType === tillTypes[currentTabIndex] || item.tillType === null,
        );
      } else {
        filteredQueueLengthItems = currentQueueLengthData.queueLength.filter(
          (item) => item.tillType === tillTypes[currentTabIndex],
        );
      }

      filteredQueueLengthItems.sort((a, b) => b.queueCount - a.queueCount);
      return filteredQueueLengthItems;
    };

    const getCurrentPageQueueLengthItems = (
      currentQueueLengthItems: QueueLengthItem[],
      currentPageIndex: number,
    ) => {
      const startIndex = currentPageIndex * TILLS_PER_PAGE;
      const endIndex = startIndex + TILLS_PER_PAGE;

      return currentQueueLengthItems.slice(startIndex, endIndex);
    };

    const calculatePercentage = (part: number, total: number) => (total === 0 ? 0 : part / total) * 100;

    const getOverAllQueueInformation = () => {
      if (!queueLengthData) {
        return {
          totalQueueCount: 0,
          totalTransactions: 0,
          overallQueueLengthPercentage: 0,
          overallExperiencePercentage: 0,
        };
      }

      const { queueLength, totalTransactions, checkoutExperience } = queueLengthData;
      const { happyCheckoutExperienceCount, totalCheckoutExperienceCount } = checkoutExperience;

      const totalQueueCount = queueLength.reduce((acc, item) => acc + item.queueCount, 0);

      const overallQueueLengthPercentage = calculatePercentage(totalQueueCount, totalTransactions);
      const overallExperiencePercentage = calculatePercentage(
        happyCheckoutExperienceCount,
        totalCheckoutExperienceCount,
      );

      return {
        totalQueueCount,
        totalTransactions,
        overallQueueLengthPercentage,
        overallExperiencePercentage,
      };
    };

    const fetchWidgetData = () => {
      triggerFetchQueueLength(fetchQueueLength);
      triggerFetchForecastData(fetchForecastData);
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate]);

    const isEditKPIDisabled = queueLengthDataError || !hasPermissionToEditKpi;

    const filteredQueueLengthItems = getFilteredQueueLengthItems(queueLengthData, tabIndex);

    const filteredDefaultQueueLengthItems = getFilteredQueueLengthItems(queueLengthData, 0);

    const filteredSelfCheckoutQueueLengthItems = getFilteredQueueLengthItems(queueLengthData, 1);

    const currentPageQueueLengthItems = getCurrentPageQueueLengthItems(filteredQueueLengthItems, pageIndex);

    const maxYAxisValue = Math.max(...filteredQueueLengthItems.map((item) => item.queueCount));

    const isNoData = !!queueLengthData && queueLengthData.queueLength.length === 0;

    const numberOfPages = Math.ceil(filteredQueueLengthItems.length / TILLS_PER_PAGE);

    const { totalQueueCount, totalTransactions, overallQueueLengthPercentage, overallExperiencePercentage } =
      getOverAllQueueInformation();

    const forecastTotalQueueCount = !forecastData
      ? 0
      : forecastData.reduce((acc, item) => acc + item.queueCount, 0) + totalQueueCount;

    const forecastTotalTransactions = !forecastData
      ? 0
      : forecastData.reduce((acc, item) => acc + item.totalTransactions, 0) + totalTransactions;

    const forecastQueueLengthPercentage = calculatePercentage(forecastTotalQueueCount, forecastTotalTransactions);

    const targetProgressPercent = target && target != 0 ? (forecastQueueLengthPercentage / target) * 100 : 0;

    const showForecast = isToday && !!target && !!forecastData;

    return (
      <WidgetCardContent
        headerIcon={Icons.PROFILE}
        headerText={t(queueLengthTranslations.title.key, queueLengthTranslations.title.defaultValue)}
        subHeaderText={t(queueLengthTranslations.subTitle.key, queueLengthTranslations.subTitle.defaultValue)}
        isLoading={queueLengthDataLoading}
        isError={queueLengthDataError}
        errorText={t(queueLengthTranslations.error.key, queueLengthTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        lastUpdatedDate={queueLengthData?.lastUpdated}
        toolTipText={t(queueLengthTranslations.toolTip.key, queueLengthTranslations.toolTip.defaultValue)}
        noDataContent={
          <Animation
            size="small"
            title=""
            subtitle={t(queueLengthTranslations.noData.key, queueLengthTranslations.noData.defaultValue)}
            animation={Animations.COOL}
          />
        }
        headerSideContent={
          <DropdownMenu
            data-testid="kpiDropdown"
            dropdownLabel={''}
            buttonContentProps={{
              iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
            }}
            menuItems={[
              {
                key: target ? 'editkpi' : 'addkpi',
                disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
                hasTooltip: isEditKPIDisabled || isTargetLocked,
                tooltipText: (
                  <Container padding={[1]}>
                    <Typography color="white">
                      {!hasPermissionToEditKpi
                        ? t(
                            kpiTargetsTranslations.noPermissionTooltip.key,
                            kpiTargetsTranslations.noPermissionTooltip.defaultValue,
                          )
                        : isTargetLocked
                        ? t(
                            kpiTargetsTranslations.lockedTargetTooltip.key,
                            kpiTargetsTranslations.lockedTargetTooltip.defaultValue,
                          )
                        : t(
                            kpiTargetsTranslations.disabledTooltip.key,
                            kpiTargetsTranslations.disabledTooltip.defaultValue,
                          )}
                    </Typography>
                  </Container>
                ),
                item: (
                  <Typography margin={[0, 2, 0]}>
                    {target
                      ? t(kpiTargetsTranslations.editKpi.key, kpiTargetsTranslations.editKpi.defaultValue)
                      : t(kpiTargetsTranslations.addKpi.key, kpiTargetsTranslations.addKpi.defaultValue)}
                  </Typography>
                ),
              },
            ]}
            minWidth={'0px'}
            isCompact
            initialSelected={[]}
            onSelect={(selectedItem) => {
              handleDropdownDetails('dailyQueueLength', selectedItem[0] as KpiDropdownActions);
            }}
            isOnlyIcon
            allowTooltip
          />
        }
        content={
          <Wrapper data-testid="queue-length-wrapper">
            {showForecast && (
              <ForecastContainer>
                <ProgressIndicator
                  valueType={Forecast.PERCENTAGE}
                  value={forecastQueueLengthPercentage}
                  progressPercentage={targetProgressPercent}
                  valueFormatter={(value) => Math.round(value).toFixed(0)}
                  getStatus={(percent) => {
                    if (percent <= 100) return 'HAPPY';
                    if (percent <= 103) return 'CONFUSED';
                    return 'SAD';
                  }}
                  padding={[1, 2]}
                  margin={[0, 0, 1, 0]}
                />
              </ForecastContainer>
            )}

            <DynamicTab
              tabLabelArray={[
                {
                  tabName: t(
                    queueLengthTranslations.tabDefault.key,
                    queueLengthTranslations.tabDefault.defaultValue,
                  ),
                  isTabVisible: true,
                  isTabDisabled: filteredDefaultQueueLengthItems.length === 0,
                  labelType: 'body3' as Typographies,
                },
                {
                  tabName: t(
                    queueLengthTranslations.tabSelfCheckout.key,
                    queueLengthTranslations.tabSelfCheckout.defaultValue,
                  ),
                  isTabVisible: filteredSelfCheckoutQueueLengthItems.length != 0,
                  labelType: 'body3' as Typographies,
                  labelMargin: [0] as Spacing,
                },
              ]}
              handleTabChange={handleTabChange}
              tabValue={tabIndex}
              variant="scrollable"
              tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
              content={[]}
            />

            <Container margin={[3, 4, 0, 4]} direction="horizontal" space="between">
              {numberOfPages > 1 && (
                <Container>
                  <TagsList
                    items={Array.from(Array(numberOfPages).keys())}
                    activeItemIndex={pageIndex}
                    itemTextRenderer={(_, index) =>
                      `${index * TILLS_PER_PAGE + 1}-${(index + 1) * TILLS_PER_PAGE}`
                    }
                    onTagSelect={(_, index) => handlePageChange(index)}
                  />
                </Container>
              )}

              {target && (
                <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[0]}>
                  {`${t(
                    commonTranslations.targetKPIQueueLength.key,
                    commonTranslations.targetKPIQueueLength.defaultValue,
                  )} ${target}%`}
                </StyledCustomLegends>
              )}
            </Container>

            <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
              {/* <Container wrap="nowrap" data-testid="chart" style={{ height: showForecast ? '45%' : '58%' }}> */}

              <Charts
                chartType="bar"
                isStacked={true}
                xAxisCategories={currentPageQueueLengthItems.map((item) => item.tillId)}
                yAxis={{ max: maxYAxisValue, forceNiceScale: true }}
                chartSeries={[
                  {
                    name: t(
                      queueLengthTranslations.chartToolTip.key,
                      queueLengthTranslations.chartToolTip.defaultValue,
                    ),
                    data: currentPageQueueLengthItems.map((item) => item.queueCount),
                  },
                ]}
                toolTip={{
                  enabled: true,
                  shared: true,
                  intersect: false,
                  marker: { show: true },
                  x: {
                    show: true,
                    formatter: (value) => {
                      return `${t(
                        queueLengthTranslations.tillToolTip.key,
                        queueLengthTranslations.tillToolTip.defaultValue,
                      )}: ${value}`;
                    },
                  },
                }}
                areaChartGradient={{ opacity: 1 }}
                legend={{
                  markers: {
                    radius: 12,
                    offsetY: 1,
                  },
                }}
                xAxis={{
                  labels: {
                    rotate: -45,
                    rotateAlways: isMobile,
                  },
                }}
                chartPlotOptions={{
                  dataLabels: {
                    position: 'top',
                    hideOverflowingLabels: false,
                  },
                }}
                dataLabel={{
                  enabled: true,
                  offsetY: -20,
                  style: {
                    fontSize: isMobile ? '8px' : '12px',
                    fontWeight: '100',
                    colors: [mode === 'dark' ? muiTheme.color.white : muiTheme.color.primary],
                  },
                }}
                isAnimationEnabled={false}
              />
            </Container>

            <StyledSummaryContainer direction="horizontal">
              <StyledSummaryItem>
                <Typography type="body3" margin={[0, 0, 1, 0]}>
                  {overallQueueLengthPercentage.toFixed(2)}%
                </Typography>
                <Typography type="caption">
                  {t(
                    queueLengthTranslations.overallQueueLength.key,
                    queueLengthTranslations.overallQueueLength.defaultValue,
                  )}
                </Typography>
              </StyledSummaryItem>

              {overallExperiencePercentage !== 0 && (
                <StyledSummaryItem>
                  <Typography type="button" margin={[0, 0, 1, 0]}>
                    {overallExperiencePercentage.toFixed(2)}%
                  </Typography>
                  <Typography>
                    {t(
                      queueLengthTranslations.overallExperience.key,
                      queueLengthTranslations.overallExperience.defaultValue,
                    )}
                  </Typography>
                </StyledSummaryItem>
              )}
            </StyledSummaryContainer>
          </Wrapper>
        }
      />
    );
  },
);
