import { useMediaQuery } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { ORISResponse, useAsyncAction, useTransaction } from '../../../../../hooks';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../../../stories/atoms';
import { Charts } from '../../../../../stories/molecules/charts/Charts';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { Translation, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import {
  ChartContainer,
  GridContainer,
  StyledChartIcon,
  StyledChartTypography,
  StyledIcon,
  StyledSection,
} from './OrisCustomersWidget.styles';

type TransactionSectionProps = {
  icon: Icons;
  isMobileView: boolean;
  testId: string;
  value: number;
  text: Translation;
  tooltipText: Translation;
  valueFormatter?: (value: number) => string;
};

const TransactionSection: React.FC<TransactionSectionProps> = ({
  icon,
  isMobileView,
  testId,
  value,
  text,
  tooltipText,
  valueFormatter,
}) => {
  const { t } = useTranslation();

  return (
    <StyledSection data-testid={testId}>
      <Container direction="horizontal" wrap="nowrap" position="center">
        <Icon icon={icon} margin={[0, 2, 0, 0]} size="small" />
        <Typography>{t(text.key, text.defaultValue)}</Typography>
        <Tooltip
          arrowedToolTip
          text={
            <Container padding={[1, 2]}>
              <Typography color="white">{t(tooltipText.key, tooltipText.defaultValue)}</Typography>
            </Container>
          }
        >
          <StyledIcon data-testid="info-icon" icon={Icons.INFO} size="small" margin={[0, 1]} />
        </Tooltip>
      </Container>
      <Typography margin={isMobileView ? [1, 0, 0] : [0]} type="body3">
        {valueFormatter ? valueFormatter(value) : value}
      </Typography>
    </StyledSection>
  );
};

type OrisCustomersWidgetProps = {
  currentDate: Date;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export const OrisCustomersWidget: FC<OrisCustomersWidgetProps> = observer(
  ({ currentDate, interval, fromDate, toDate }) => {
    const { t } = useTranslation();
    const { orisCustomers: orisCustomersTranslations } = homeTranslations;
    const { fetchORISData } = useTransaction();

    const {
      result: orisData,
      isLoading: orisLoading,
      isError: orisError,
      triggerAction: triggerFetchOrisAction,
    } = useAsyncAction<ORISResponse>();

    const fetchData = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchORISData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    useEffect(() => {
      triggerFetchOrisAction(fetchData);
    }, [currentDate, interval]);

    const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

    let oristConversionRate =
      (orisData?.totalORISCustomer ?? 0) === 0
        ? 0
        : ((orisData?.convertedORISCustomers ?? 0) / (orisData?.totalORISCustomer ?? 0)) * 100;
    oristConversionRate = parseFloat(oristConversionRate.toFixed(2));

    const getSaleTimeText = (seconds: number) => {
      const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

      const secondsLabel = t(
        orisCustomersTranslations.duration.seconds.key,
        orisCustomersTranslations.duration.seconds.defaultValue,
      );

      const minutesLabel = t(
        orisCustomersTranslations.duration.minutes.key,
        orisCustomersTranslations.duration.minutes.defaultValue,
      );

      const hoursLabel = t(
        orisCustomersTranslations.duration.hours.key,
        orisCustomersTranslations.duration.hours.defaultValue,
      );

      const secondsText = duration.seconds ? `${duration.seconds} ${secondsLabel}` : `0 ${secondsLabel}`;

      const minutesText = duration.minutes ? `${duration.minutes} ${minutesLabel} ` : '';

      const hoursText = duration.hours ? `${duration.hours} ${hoursLabel} ` : '';

      return `${hoursText}${minutesText}${secondsText}`;
    };

    return (
      <WidgetCardContent
        headerIcon={Icons.PROFILE}
        headerText={t(orisCustomersTranslations.title.key, orisCustomersTranslations.title.defaultValue)}
        subHeaderText={t(orisCustomersTranslations.subTitle.key, orisCustomersTranslations.subTitle.defaultValue)}
        isLoading={orisLoading}
        isError={orisError}
        errorText={t(orisCustomersTranslations.error.key, orisCustomersTranslations.error.defaultValue)}
        toolTipText={t(orisCustomersTranslations.toolTip.key, orisCustomersTranslations.toolTip.defaultValue)}
        isNoData={(orisData?.totalORISCustomer ?? 0) === 0}
        lastUpdatedDate={orisData?.lastUpdated}
        content={
          <Container wrap="nowrap" data-testid="oris-widget-wrapper" padding={[2, 4, 4]} height="100%">
            <ChartContainer data-testid="oris-chart-container" isMobile={isMobileView}>
              <Container direction="horizontal" wrap="nowrap">
                <Icon data-testid="conversion" size="small" icon={Icons.CONVERSION} margin={[0, 2, 0]} />
                <Typography type="button">
                  {t(
                    orisCustomersTranslations.orisConversionRate.text.key,
                    orisCustomersTranslations.orisConversionRate.text.defaultValue,
                  )}
                </Typography>
              </Container>
              <Container
                style={{ position: 'relative' }}
                width="100px"
                height={isMobileView ? '70px' : '85px'}
                data-testid="oris-chart"
              >
                <Charts
                  chartType="donut"
                  chartSeries={[oristConversionRate, 100 - oristConversionRate]}
                  xAxisCategories={['', '']}
                  areaChartGradient={{
                    type: ['solid'],
                  }}
                  chartColors={['validationGreen', 'backgroundSelectedHover']}
                  chartPlotOptions={{
                    customScale: isMobileView ? 1.5 : 1.2,
                    offsetY: 15,
                    donut: {
                      size: '80%',
                      labels: {
                        show: false,
                      },
                    },
                  }}
                  legend={{
                    show: false,
                  }}
                  strokeWidth={[0]}
                />
                <StyledChartTypography type={isMobileView ? 'body2' : 'button'} color="validationGreen">
                  {oristConversionRate}%
                </StyledChartTypography>
                <Tooltip
                  arrowedToolTip
                  text={
                    <Container padding={[1, 2]}>
                      <Typography color="white">
                        {t(
                          orisCustomersTranslations.orisConversionRate.tooltip.key,
                          orisCustomersTranslations.orisConversionRate.tooltip.defaultValue,
                        )}
                      </Typography>
                    </Container>
                  }
                >
                  <StyledChartIcon data-testid="info-icon" icon={Icons.INFO} size="small" margin={[0, 1]} />
                </Tooltip>
              </Container>
            </ChartContainer>

            <GridContainer>
              <TransactionSection
                icon={Icons.PROFILE}
                isMobileView={isMobileView}
                testId="total-transactions-section"
                value={orisData?.totalORISCustomer ?? 0}
                text={orisCustomersTranslations.orisTotalTransactions.text}
                tooltipText={orisCustomersTranslations.orisTotalTransactions.tooltip}
              />
              <TransactionSection
                icon={Icons.LOYALTYCUSTOMER}
                isMobileView={isMobileView}
                testId="total-loyalty-transactions-section"
                value={orisData?.loyalOrisCustomerCount ?? 0}
                text={orisCustomersTranslations.orisTotalLoyaltyTransactions.text}
                tooltipText={orisCustomersTranslations.orisTotalLoyaltyTransactions.tooltip}
              />
              <TransactionSection
                icon={Icons.NEWMEMBER}
                isMobileView={isMobileView}
                testId="total-converted-transactions-section"
                value={orisData?.convertedORISCustomers ?? 0}
                text={orisCustomersTranslations.orisConvertedTransactions.text}
                tooltipText={orisCustomersTranslations.orisConvertedTransactions.tooltip}
              />
              <TransactionSection
                icon={Icons.PROFILETIME}
                isMobileView={isMobileView}
                testId="average-oris-time-section"
                value={orisData?.orisAverageSaleTime ?? 0}
                valueFormatter={(value) => getSaleTimeText(value)}
                text={orisCustomersTranslations.averageOrisTime.text}
                tooltipText={orisCustomersTranslations.averageOrisTime.tooltip}
              />
            </GridContainer>
          </Container>
        }
      />
    );
  },
);
