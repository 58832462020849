import styled from 'styled-components';
import { Button } from '../../../stories/molecules';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StyledPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledButton = styled(Button)`
  padding: ${({ theme: { spacing } }) => spacing(0, 5)};
`;
