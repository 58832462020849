/**
 * Complete list of vendorType can be found here - https://www.figma.com/file/sA2RRySgUtI4GhdkPSbnw2/Components?node-id=2717%3A10174
 **/

// Below are possible VendorTypes
export type VendorType =
  | 'Maestro'
  | 'Mc'
  | 'MASTERCARD'
  | 'VISA'
  | 'Visa'
  | 'UPI'
  | 'RUPAY'
  | 'DINERS'
  | 'Alipay'
  | 'CA'
  | 'Klarna'
  | 'Paypal'
  | 'Applepay'
  | 'Eurocard'
  | 'Amex'
  | 'Swish'
  | 'GiftCard'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24';

export const VendorMapping: { [key in VendorType]: string } = {
  Maestro: 'Maestro',
  Mc: 'Master Card',
  MASTERCARD: 'Master Card',
  VISA: 'Visa',
  Visa: 'Visa',
  RUPAY: 'Rupay',
  Alipay: 'Alipay',
  CA: 'Cash',
  UPI: 'UPI',
  Klarna: 'Klarna',
  Paypal: 'Paypal',
  Applepay: 'Apple Pay',
  Eurocard: 'Euro Card',
  Amex: 'American Express',
  Swish: 'Swish',
  GiftCard: 'GiftCard',
  DINERS: 'Diners',
  20: 'Cash',
  21: 'Cash',
  22: 'Cash',
  23: 'Cash',
  24: 'Cash',
};

export const isSupportedVendorType = (vendorType: string): vendorType is VendorType => {
  return vendorType in VendorMapping;
};
