export const soldProductsTranslations = {
  image: {
    key: 'soldProductsTranslations.image',
    defaultValue: 'Photo',
  },
  name: {
    key: 'soldProductsTranslations.name',
    defaultValue: 'Name',
  },
  departmentId: {
    key: 'soldProductsTranslations.departmentId',
    defaultValue: 'Department ID',
  },
  productId: {
    key: 'soldProductsTranslations.productId',
    defaultValue: 'Product ID',
  },
  season: {
    key: 'soldProductsTranslations.season',
    defaultValue: 'Season',
  },
  piecesRemaining: {
    key: 'soldProductsTranslations.piecesRemaining',
    defaultValue: 'Pieces Remaining',
  },
  price: {
    key: 'soldProductsTranslations.price',
    defaultValue: 'Price',
  },
  salesNet: {
    key: 'soldProductsTranslations.salesNet',
    defaultValue: 'Sales Net',
  },
  piecesSold: {
    key: 'soldProductsTranslations.piecesSold',
    defaultValue: 'Sold Pieces',
  },
  productBasedOn: {
    key: 'soldProductsTranslations.productBasedOn',
    defaultValue: 'Products Based On: ',
  },
  products: {
    key: 'soldProductsTranslations.products',
    defaultValue: 'Products',
  },
  inStock: {
    key: 'soldProductsTranslations.inStock',
    defaultValue: 'In Stock',
  },
  outOfStock: {
    key: 'soldProductsTranslations.outOfStock',
    defaultValue: 'Out Of Stock',
  },
  error: {
    errorTitle: {
      key: 'soldProductsTranslations.error.errorTitle',
      defaultValue: 'Something went wrong!',
    },
    errorSubtitle: {
      key: 'soldProductsTranslations.error.errorSubtitle',
      defaultValue: 'There was an error while fetching the Products. Please try again!',
    },
  },
  noData: {
    title: {
      key: 'soldProductsTranslations.noData.title',
      defaultValue: 'Uh oh!!',
    },
    subtitle: {
      key: 'soldProductsTranslations.noData.subtitle',
      defaultValue: 'No Products sold at the moment',
    },
  },
  productDetailsView: {
    title: {
      key: 'soldProductsTranslations.productDetailsView.title',
      defaultValue: 'Product Details',
    },
    about: {
      key: 'soldProductsTranslations.productDetailsView.about',
      defaultValue: 'About',
    },
    details: {
      key: 'soldProductsTranslations.productDetailsView.details',
      defaultValue: 'Size/Color/Promotions',
    },
    materials: {
      key: 'soldProductsTranslations.productDetailsView.materials',
      defaultValue: 'Materials',
    },
    department: {
      key: 'soldProductsTranslations.productDetailsView.department',
      defaultValue: 'Department',
    },
  },
  pieces: {
    key: 'soldProductsTranslations.pieces',
    defaultValue: 'pieces',
  },
};
