import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, Typography } from '../../../../stories/atoms';
import { addAvatar, avatarSuccess } from '../../../../translations';
import { AvatarPlaceHolder } from '../avatar-placeholder/AvatarPlaceholder';
import { StyledAddAvatarButton, StyledContainer, StyledWrapper, StyledSaveButton } from './AddAvatar.styles';

export type AddAvatarProps = {
  avatarUrl?: string;
  isProfileUpdated: boolean | undefined;
  onClick: () => void;
  onSubmit: () => void;
};

export const AddAvatar: FC<React.PropsWithChildren<AddAvatarProps>> = ({
  avatarUrl,
  isProfileUpdated,
  onClick,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {isProfileUpdated ? (
        <div data-testid="add-avatar-success-screen">
          <AvatarPlaceHolder
            header={t(avatarSuccess.avatarSuccessTitle.key, avatarSuccess.avatarSuccessTitle.defaultValue)}
            icon={Icons.CHECK_MARK_CIRCLE}
            avatarUrl={avatarUrl}
          ></AvatarPlaceHolder>
        </div>
      ) : (
        <StyledWrapper data-testid="add-avatar-first-screen">
          <AvatarPlaceHolder
            header={
              <>
                <Typography type="h3">{t(addAvatar.title.key, addAvatar.title.defaultValue)}</Typography>
                <Typography>{t(addAvatar.subTitle.key, addAvatar.subTitle.defaultValue)}</Typography>
              </>
            }
            avatarUrl={avatarUrl}
          >
            <StyledSaveButton
              label={t(addAvatar.saveYourAvatar.key, addAvatar.saveYourAvatar.defaultValue)}
              onClick={onSubmit}
              data-testid="save-avatar-button"
            />
            <StyledAddAvatarButton
              label={t(addAvatar.changeYourAvatar.key, addAvatar.changeYourAvatar.defaultValue)}
              variant="secondary"
              onClick={onClick}
              data-testid="change-your-avatar-button"
              buttonContentProps={{ iconOptions: { icon: Icons.EDIT, size: 'small' } }}
            />
          </AvatarPlaceHolder>
        </StyledWrapper>
      )}
    </StyledContainer>
  );
};
