import { CSSProperties, ReactNode } from 'react';
import { ObjectWithStringKeys, groupByKey } from '../../utils';

type NestedGroupViewProps<T extends ObjectWithStringKeys, P extends string> = {
  groupingKey: P | null;
  depth: number;
  items: T[];
  groupingKeyValueChain?: string[];
  getNextChildKey: (groupingKey: P) => P | null;
  groupItemRenderer: (groupingKey: P, items: T[], depth: number, groupingKeyValueChain: string[]) => ReactNode;
  itemRenderer: (items: T, depth: number) => ReactNode;
  getChildrenContainerStyle?: (groupingKeyValueChain: string[]) => CSSProperties;
  groupSortFunction?: (a: T[], b: T[]) => number;
  itemSortFunction?: (a: T, b: T) => number;
};

export function NestedGroupView<T extends ObjectWithStringKeys, P extends string>(
  props: NestedGroupViewProps<T, P>,
) {
  const {
    groupingKey,
    depth,
    items,
    groupingKeyValueChain,
    getNextChildKey,
    groupItemRenderer,
    itemRenderer,
    getChildrenContainerStyle,
    groupSortFunction,
    itemSortFunction,
  } = props;

  if (!groupingKey) {
    if (itemSortFunction) {
      items.sort(itemSortFunction);
    }

    return items.map((item) => itemRenderer(item, depth));
  }

  const itemsGroups = groupByKey(items, groupingKey);

  if (groupSortFunction) {
    itemsGroups.sort(groupSortFunction);
  }

  const nextChildKey = getNextChildKey(groupingKey);

  return itemsGroups.map((itemsGroup) => {
    const itemsGroupItem = itemsGroup[0];

    let currentGroupingKeyValueChain: string[] = [];

    if (!groupingKeyValueChain) {
      currentGroupingKeyValueChain = [itemsGroupItem[groupingKey] as string];
    } else {
      currentGroupingKeyValueChain = [...groupingKeyValueChain, itemsGroupItem[groupingKey] as string];
    }

    const childrenContainerStyle = getChildrenContainerStyle
      ? getChildrenContainerStyle(currentGroupingKeyValueChain)
      : undefined;

    return (
      <div key={itemsGroupItem[groupingKey] as string}>
        {groupItemRenderer(groupingKey, itemsGroup, depth, currentGroupingKeyValueChain)}

        <div style={{ ...childrenContainerStyle }}>
          <NestedGroupView
            groupingKey={nextChildKey}
            depth={depth + 1}
            items={itemsGroup}
            groupingKeyValueChain={currentGroupingKeyValueChain}
            getNextChildKey={getNextChildKey}
            groupItemRenderer={groupItemRenderer}
            itemRenderer={itemRenderer}
            getChildrenContainerStyle={getChildrenContainerStyle}
            groupSortFunction={groupSortFunction}
            itemSortFunction={itemSortFunction}
          />
        </div>
      </div>
    );
  });
}
