import styled from 'styled-components';
import { Container, Typography } from '../../stories/atoms';
import { KpiEditTypes, KpiModalViews } from '../../constants';

export const StyledContainer = styled(Container)<{
  isMobileView: boolean;
  view: KpiModalViews;
  shouldRemove: KpiEditTypes;
}>`
  min-width: ${({ theme: { spacing }, isMobileView }) => (isMobileView ? spacing(100) : spacing(125))};
  max-width: ${({ theme: { spacing } }) => spacing(200)};
  padding: ${({ theme: { spacing } }) => spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: ${({ view, shouldRemove }) =>
    view === KpiModalViews.SUCCESS || view === KpiModalViews.ERROR || shouldRemove === KpiEditTypes.REMOVE
      ? 'center'
      : ''};
  & > [data-testid='icon'] {
    margin: ${({ theme: { spacing } }) => spacing(4, 0)};
  }
  & > [data-testid='kpiStatus'] {
    width: 70%;
  }
`;

export const StyledInputField = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 4)};
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & button {
    width: 48%;
  }
`;

export const StyledTypography = styled(Typography)`
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.inherit : color.backgroundWarning};
  border-radius: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.warning : color.warningDark)};
  border: ${({ theme: { color, palette } }) => palette.mode === 'dark' && `1px solid ${color.warning}`};
`;
