import styled from 'styled-components';
import { Container, Icon } from '../../../../../stories/atoms';
import { IconWithText } from '../../../../../stories/molecules';

export const StyledWrapper = styled(Container)`
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
  max-height: ${({ theme: { spacing } }) => spacing(80)};
  overflow-y: auto;
`;

export const StyledSubHeader = styled(Container)`
  border-bottom: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.inActiveGray)};
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(7)};
  height: ${({ theme: { spacing } }) => spacing(7)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
  justify-content: center;
  cursor: pointer;
`;

export const StyledSection = styled(Container)`
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
`;

export const StyledContainer = styled(Container)`
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  &:hover {
    background-color: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.secondaryTextGray : color.backgroundSelected};
  }
`;

export const StyledIconWithText = styled(IconWithText)<{ canClick: boolean }>`
  cursor: ${({ canClick }) => canClick && 'pointer'};
  & > :nth-child(1) {
    transform: rotate(-45deg);
  }
`;
