import styled from 'styled-components';
import { Container, Icon } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { cardShadow } from '../../../theme';

export const KPIContainerWrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing(7)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  background-color: ${({
    theme: {
      color,
      palette: { mode },
    },
  }) => (mode === 'dark' ? color.backgroundPaperDark : color.white)};
  ${cardShadow()};
`;

export const KPIContainer = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(5, 8)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledKPIHeader = styled.div<{ isTabletView: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const Divider = styled.div`
  width: 100%;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.borderGrayDark : color.borderGray};
  height: ${({ theme: { spacing } }) => spacing(0.3)};
`;

export const HeaderIconsContainer = styled.div`
  position: relative;
`;

export const HeaderIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
  justify-content: center;
`;

export const HeaderStarIconContainer = styled.div`
  position: absolute;
  left: ${({ theme: { spacing } }) => spacing(-1.5)};
  top: ${({ theme: { spacing } }) => spacing(-1)};
`;

export const HeaderStarIcon = styled(Icon)``;

export const StyledIconWithText = styled(IconWithText)`
  padding: ${({ theme: { spacing } }) => spacing(1, 2)};
  border: ${({ theme: { spacing } }) => spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  ${cardShadow()};
  & > :nth-child(1) {
    transform: rotate(-45deg);
    color: ${({ theme: { color } }) => color.infoDark};
  }
  & > :nth-child(2) {
    color: ${({ theme: { color } }) => color.infoDark};
  }
`;

export const StyledKPIValueContainer = styled.div<{ isTabletView: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isTabletView }) => (isTabletView ? 'column' : 'row')};
  margin-top: ${({ theme: { spacing } }) => spacing(2)};
`;

export const StyledTargetValue = styled.div<{ isTabletView: boolean }>`
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing(2, 3, 1, 3)};
  flex-direction: ${({ isTabletView }) => (isTabletView ? 'row' : 'column')};
  justify-content: ${({ isTabletView }) => isTabletView && 'space-between'};
  align-items: ${({ isTabletView }) => !isTabletView && 'center'};
`;

export const StyledKPIValue = styled.div<{ isTabletView: boolean }>`
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing(2, 3, 1, 3)};
  flex-direction: ${({ isTabletView }) => (isTabletView ? 'row-reverse' : 'column')};
  justify-content: ${({ isTabletView }) => isTabletView && 'space-between'};
  align-items: ${({ isTabletView }) => !isTabletView && 'center'};
`;

export const StyledKPIProperty = styled.div`
  display: flex;
  align-items: center;
  & > div,
  [data-testid='infoIcon'] {
    color: ${({
      theme: {
        color,
        palette: { mode },
      },
    }) => (mode === 'dark' ? color.inActiveGray : color.textGray)};
  }
`;

export const StyledLockIconContainer = styled(Container)`
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
`;
