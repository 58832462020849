import styled from 'styled-components';
import { StyledDrawerMenuItem } from '../FilterDrawer.styles';

export const StyledMenuItem = styled(StyledDrawerMenuItem)`
  justify-content: space-between;
`;

export const StyledCountIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
