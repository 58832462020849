import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Modal } from '../../../stories/molecules';
import { Container, Icons, Typography, Icon } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { KpiModalViews, KpiType, UpdateKpiRequest, getKPIIcons } from '../../../constants';
import { KpiAreaDetails, KpiTargetsProps, useUser } from '../../../hooks';
import { kpiTargetTransalations } from '../../../translations/kpiTargets';
import { StyledDivider, StyledTypography } from '../KpiTarget.styles';
import { getKpiTargetProps } from '../../../utils';
import {
  StyledIcon,
  StyledCheckboxHeader,
  StyledContainer,
  StyledHeader,
  StyledCheckboxContainer,
  StyledContent,
} from './BulkRemoveModal.styles';

export type BulkRemoveModalProps = {
  isOpen: boolean;
  areaKpiData: KpiAreaDetails[] | null;
  kpiTargetsProps: KpiTargetsProps | null;
  canDeleteKpi: boolean;
  canDeleteAreaCap: boolean;
  isLoading: boolean;
  modalView: KpiModalViews;
  onClose: () => void;
  updatedKpis: (request: { target: UpdateKpiRequest[]; areaCap: UpdateKpiRequest[] }) => void;
};

const BulkRemoveModal: FC<BulkRemoveModalProps> = ({
  isOpen,
  areaKpiData,
  kpiTargetsProps,
  canDeleteKpi,
  canDeleteAreaCap,
  isLoading,
  modalView,
  onClose,
  updatedKpis,
}) => {
  const { t } = useTranslation();
  const {
    get: { currentStoreId },
  } = useUser();

  const { removeKpiButton, removeKpisText, kpiBanner, kpiWidgets, bulkUpdateError, bulkUpdateSuccess, kpiLock } =
    kpiTargetTransalations;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('tabletLandscape'));

  const [bulkRemoveTargetState, setBulkRemoveTargetState] = useState<KpiType[]>([]);
  const [bulkRemoveAreaCapState, setBulkRemoveAreaCapState] = useState<KpiType[]>([]);

  const handleOnChecked = (kpiType: KpiType, isAreaCap: boolean, isChecked: boolean) => {
    const newUpdateStates: KpiType[] = !isAreaCap ? [...bulkRemoveTargetState] : [...bulkRemoveAreaCapState];
    const updateStateindex = newUpdateStates.findIndex((item) => item === kpiType);
    if (!isChecked && updateStateindex !== -1) {
      newUpdateStates.splice(updateStateindex, 1);
    } else {
      newUpdateStates.push(kpiType);
    }
    if (!isAreaCap) {
      setBulkRemoveTargetState(newUpdateStates);
      return;
    }

    setBulkRemoveAreaCapState(newUpdateStates);
  };

  const handleSubmitKpis = () => {
    if (!bulkRemoveTargetState.length && !bulkRemoveAreaCapState.length) {
      return;
    }

    const targetRequest: UpdateKpiRequest[] = [];
    const areaCapRequest: UpdateKpiRequest[] = [];

    bulkRemoveTargetState.forEach((item) => {
      targetRequest.push({
        kpiName: item,
        target: null,
      });
    });

    bulkRemoveAreaCapState.forEach((item) => {
      areaCapRequest.push({
        kpiName: item,
        areaCap: null,
      });
    });

    updatedKpis({ target: targetRequest, areaCap: areaCapRequest });
  };

  const getIsChecked = (kpiType: KpiType, isAreaCap: boolean) => {
    if (!isAreaCap) {
      return bulkRemoveTargetState.includes(kpiType);
    }

    return bulkRemoveAreaCapState.includes(kpiType);
  };

  useEffect(() => {
    if (!isOpen) {
      setBulkRemoveTargetState([]);
      setBulkRemoveAreaCapState([]);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      {modalView === KpiModalViews.INITIAL && (
        <StyledContainer data-testid="bulk-remove-modal-container" isDesktop={isDesktop}>
          <Container wrap="nowrap" direction="horizontal" space="between">
            <StyledHeader>
              <Typography type="h4" margin={[0, 0, 2]}>
                {currentStoreId} - {t(removeKpiButton.key, removeKpiButton.defaultValue)}
              </Typography>
              <Typography type="button" data-testid="header-test">
                {t(removeKpisText.key, {
                  defaultValue: removeKpisText.defaultValue,
                  kpiType: canDeleteAreaCap ? 'Target and Area Cap' : 'Target',
                })}
              </Typography>
            </StyledHeader>
          </Container>
          <StyledDivider />

          <StyledCheckboxContainer isTablet={isTablet}>
            {areaKpiData &&
              areaKpiData.map((kpiAreaDetail) => {
                const { kpiName } = kpiAreaDetail;
                const kpiTargetProps = getKpiTargetProps(kpiTargetsProps, kpiName);

                if (!kpiTargetProps) return null;

                const { isLocked } = kpiTargetProps;
                return (
                  <StyledContent key={kpiName} data-testid={kpiName} padding={[3, 1]}>
                    <StyledCheckboxHeader direction="horizontal">
                      <StyledIcon
                        data-testid="header-icon"
                        icon={Icons[getKPIIcons(kpiName)]}
                        size="small"
                        margin={[0, 2]}
                      />
                      <Typography>{t(kpiWidgets[kpiName].key, kpiWidgets[kpiName].defaultValue)}</Typography>
                      {isLocked && (
                        <StyledTypography
                          data-testid="target-locked"
                          type="body3"
                          padding={[1, 2]}
                          margin={[0, 2, 0]}
                        >
                          {t(kpiLock.locked.key, kpiLock.locked.defaultValue)}
                        </StyledTypography>
                      )}
                    </StyledCheckboxHeader>

                    <Container direction="horizontal" padding={[3, 6]} data-testid="content">
                      <Checkbox
                        isChecked={getIsChecked(kpiName, false)}
                        margin={isMobile ? [0, 4, 0, 0] : [0, 20, 0, 0]}
                        isDisabled={isLocked || !canDeleteKpi}
                        label="Remove Target"
                        onChange={(isChecked) => handleOnChecked(kpiName, false, isChecked)}
                      />
                      {canDeleteAreaCap && (
                        <Checkbox
                          isChecked={getIsChecked(kpiName, true)}
                          isDisabled={false}
                          label="Remove Area Cap"
                          onChange={(isChecked) => handleOnChecked(kpiName, true, isChecked)}
                        />
                      )}
                    </Container>
                  </StyledContent>
                );
              })}
          </StyledCheckboxContainer>
          <Button
            data-testid="remove-kpi-button"
            label={t(removeKpiButton.key, removeKpiButton.defaultValue)}
            fullWidth
            disabled={!bulkRemoveTargetState.length && !bulkRemoveAreaCapState.length}
            onClick={handleSubmitKpis}
            isLoading={isLoading}
          />
        </StyledContainer>
      )}
      {modalView === KpiModalViews.SUCCESS && (
        <StyledContainer position="center" isDesktop={isDesktop}>
          <Icon
            data-testid="success-icon"
            icon={Icons.CHECK_MARK_CIRCLE}
            size="x-large"
            color="validationGreen"
            margin={[10, 0, 5, 0]}
          />
          <Typography type="h2" margin={[0, 0, 0, 0]}>
            {t(bulkUpdateSuccess.key, bulkUpdateSuccess.defaultValue)}
          </Typography>
          <Typography type="h4" margin={[0, 0, 10, 0]}>
            {t(kpiBanner.kpiSuccessBanner.key, kpiBanner.kpiSuccessBanner.defaultValue)}
          </Typography>
        </StyledContainer>
      )}

      {modalView === KpiModalViews.ERROR && (
        <StyledContainer position="center" isDesktop={isDesktop}>
          <Icon data-testid="error-icon" icon={Icons.INFO} size="x-large" color="red" />
          <Typography type="h2" margin={[0, 0, 0, 0]}>
            {t(bulkUpdateError.key, bulkUpdateError.defaultValue)}
          </Typography>
          <Typography textAlign="center" type="h4" margin={[0, 0, 10, 0]}>
            {t(kpiBanner.kpiErrorBanner.key, kpiBanner.kpiErrorBanner.defaultValue)}
          </Typography>
        </StyledContainer>
      )}
    </Modal>
  );
};

export default BulkRemoveModal;
