import { Property } from 'csstype';
import { CSSProperties, FC } from 'react';
import { AllSizes, Colors } from '../../../theme';
import { Spacing } from '../../../utils';
import { Icon, IconProps, TypographyProps } from '../../atoms';
import { Container, StyledTypography } from './IconWithText.styles';

export type PositionType = 'top' | 'right' | 'bottom' | 'left';

export type IconWithTextProps = {
  label?: string | JSX.Element;
  labelPosition?: PositionType;
  position?: Property.JustifyContent;
  onClick?: () => void;
  padding?: Spacing;
  gutterBottom?: boolean;
  iconOptions?: (IconProps & { customIcon?: never }) | (Partial<IconProps> & { customIcon: JSX.Element });
  textOptions?: TypographyProps;
  containerStyles?: CSSProperties;
  spaceBetween?: AllSizes;
  backgroundColor?: Colors;
  hoverColor?: Colors;
};

export const IconWithText: FC<React.PropsWithChildren<IconWithTextProps>> = ({
  label,
  textOptions,
  containerStyles,
  iconOptions,
  labelPosition = 'right',
  position = 'center',
  spaceBetween = 'small',
  ...props
}) => {
  return (
    <Container
      data-testid="icon-with-text"
      labelPosition={labelPosition}
      position={position}
      style={containerStyles}
      {...props}
    >
      {iconOptions && (
        <>
          {iconOptions.customIcon && iconOptions.customIcon}
          {!iconOptions.customIcon && <Icon {...iconOptions} />}
        </>
      )}
      {label && (
        <StyledTypography
          hasIcon={!!iconOptions}
          labelPosition={labelPosition}
          {...textOptions}
          spaceBetween={spaceBetween}
        >
          {label}
        </StyledTypography>
      )}
    </Container>
  );
};
