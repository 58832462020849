import { CSSProperties, FC } from 'react';
import { Colors, Direction } from '../../../theme';
import { Spacing } from '../../../utils';
import { StyledContainer } from './Container.styles';

export type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type Space = 'between' | 'around' | 'evenly';
export type Position = 'top' | 'bottom' | 'left' | 'right' | 'center';

export type ContainerProps = {
  height?: string;
  width?: string;
  margin?: Spacing;
  padding?: Spacing;
  direction?: Direction;
  position?: Position;
  space?: Space;
  wrap?: Wrap;
  backgroundColor?: Colors;
  style?: CSSProperties;
};

export const Container: FC<React.PropsWithChildren<ContainerProps>> = ({
  height,
  width,
  direction = 'vertical',
  margin,
  padding,
  wrap = 'wrap',
  children,
  ...props
}) => {
  return (
    <StyledContainer
      height={height}
      width={width}
      direction={direction}
      wrap={wrap}
      margin={margin}
      padding={padding}
      data-testid="container"
      {...props}
    >
      {children}
    </StyledContainer>
  );
};
