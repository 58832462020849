import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../../../assets/articlePlaceholder.png';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import {
  TopReturningGarmentItem,
  useAsyncAction,
  useSoldProducts,
  useStoreInformation,
} from '../../../../../hooks';
import { Container, Icons, Typography } from '../../../../../stories/atoms';
import { DynamicTab, TabContentProps, TabLabelProps } from '../../../../../stories/molecules';
import { Table } from '../../../../../stories/molecules/tablev2/Table';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { getArticleAssetUrl } from '../../../../../utils/soldProducts';
import { ProductSlider } from '../../../common/productInformationSlider/ProductSlider';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import {
  StyledArticles,
  StyledItem,
  StyledTypography,
  StyledView,
} from '../../customerBaseValue/topSellingGarment/TopSellingGarmentWidget.styles';

type TabType = 'all' | 'store' | 'oris';

type TopReturningGarmentWidgetProps = {
  currentDate: Date;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export const TopReturningGarmentWidget: FC<TopReturningGarmentWidgetProps> = observer(
  ({ currentDate, interval, fromDate, toDate }) => {
    const { fetchTopReturningGarmentData } = useSoldProducts();
    const { currencyCode: storeCurrency } = useStoreInformation();
    const { transactionEventsCount } = useEventContext();
    const [tabIndex, setTabIndex] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
    const [selectedGarment, setSelectedGarment] = useState<TopReturningGarmentItem | null>(null);
    const {
      result: topReturnedGarments,
      isLoading: topReturnedGarmentsLoading,
      isError: topReturnedGarmentsError,
      triggerAction: triggerFetchTopReturnedGarments,
    } = useAsyncAction<TopReturningGarmentItem[]>();

    const { mode } = useThemeContext();
    const { t } = useTranslation();
    const { topReturns: topReturnsTranslations, common } = homeTranslations;

    const fetchTopReturnedGarments = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchTopReturningGarmentData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.topReturningGarments;
    };

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const getPiecesCountByTabType = (tabType: TabType, garmentItem: TopReturningGarmentItem) => {
      if (tabType === 'store') {
        return garmentItem.storeReturnPieces;
      } else if (tabType === 'oris') {
        return garmentItem.onlineReturnPieces;
      } else {
        return garmentItem.storeReturnPieces + garmentItem.onlineReturnPieces;
      }
    };

    const getGarmentsTableContent = (items: TopReturningGarmentItem[], tabType: TabType) => {
      const headerItems = [
        { id: 'item', content: t(common.type.key, common.type.defaultValue), width: '70%' },
        { id: 'count', content: t(common.count.key, common.count.defaultValue), width: '30%' },
      ];

      return (
        <Table
          fixedHeight={'270px'}
          headerItems={headerItems}
          items={items}
          isLoading={topReturnedGarmentsLoading}
          getRowItems={(item) => {
            const imageUrl = getArticleAssetUrl({
              articleId: `${item.productId}${item.articleIdentifier}`,
              assetType: 'descriptiveStillLife',
              rendition: 'medium',
            });

            return [
              {
                id: '1',
                content: (
                  <StyledArticles>
                    <StyledItem
                      data-testid="article-image"
                      src={imageUrl}
                      alt=""
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = articlePlaceholder;
                      }}
                      width="100"
                      height="100"
                    />
                    <Container>
                      <Typography>{item.title}</Typography>
                      <Container direction="horizontal">
                        <Typography>{`${item.whitePrice ?? '-'} ${storeCurrency}`}</Typography>
                        {tabType === 'all' && item.storeReturnPieces !== 0 && (
                          <StyledTypography padding={[1, 2]} margin={[0, 2]} type="caption">
                            {t(
                              topReturnsTranslations.storeText.key,
                              topReturnsTranslations.storeText.defaultValue,
                            )}
                          </StyledTypography>
                        )}
                        {tabType === 'all' && item.onlineReturnPieces !== 0 && (
                          <StyledTypography padding={[1, 2]} margin={[0, 2]} type="caption">
                            {t(
                              topReturnsTranslations.onlineText.key,
                              topReturnsTranslations.onlineText.defaultValue,
                            )}
                          </StyledTypography>
                        )}
                      </Container>
                    </Container>
                  </StyledArticles>
                ),
              },
              {
                id: '2',
                content: <Typography color="textGray">{getPiecesCountByTabType(tabType, item)} pcs</Typography>,
              },
            ];
          }}
          onRowSelect={(item) => {
            setSelectedGarment(item);
          }}
          getIsRowSelected={(item) => {
            return selectedGarment?.productId === item.productId;
          }}
          stickyHeader
          noBorder
          noHeader
          skeletonRowCount={4}
        />
      );
    };

    const getTabsData = (
      items: TopReturningGarmentItem[] | null,
    ): { tabsLabels: TabLabelProps[]; tabsContents: TabContentProps[] } => {
      if (!items) {
        return { tabsLabels: [], tabsContents: [] };
      }

      const tabsProperties: { type: TabType; label: string }[] = [
        { type: 'all', label: t(common.allTabName.key, common.allTabName.defaultValue) },
        {
          type: 'store',
          label: t(topReturnsTranslations.storeReturns.key, topReturnsTranslations.storeReturns.defaultValue),
        },
        {
          type: 'oris',
          label: t(topReturnsTranslations.orisReturns.key, topReturnsTranslations.orisReturns.defaultValue),
        },
      ];

      const getGarmentsByTabType = (tabType: TabType, garmentItems: TopReturningGarmentItem[]) => {
        return garmentItems.filter((item) => {
          if (tabType === 'store') return item.storeReturnPieces > 0 && item.onlineReturnPieces === 0;
          if (tabType === 'oris') return item.onlineReturnPieces > 0 && item.storeReturnPieces === 0;
          return item.storeReturnPieces > 0 || item.onlineReturnPieces > 0; // For 'all'
        });
      };

      const tabsData = tabsProperties.map((tab) => {
        const filteredItems = getGarmentsByTabType(tab.type, items);
        const sortedItems = filteredItems.sort(
          (a, b) => getPiecesCountByTabType(tab.type, b) - getPiecesCountByTabType(tab.type, a),
        );

        return {
          tabLabel: {
            tabName: `${tab.label} (${sortedItems.length})`,
            isTabVisible: sortedItems.length > 0,
          },
          tabContent: {
            tabContent: getGarmentsTableContent(filteredItems, tab.type),
            visible: sortedItems.length > 0,
          },
        };
      });

      const tabsLabels = tabsData.map((data) => data.tabLabel);
      const tabsContents = tabsData.map((data) => data.tabContent);

      return { tabsLabels, tabsContents };
    };
    const fetchWidgetData = () => {
      setSelectedGarment(null);
      triggerFetchTopReturnedGarments(fetchTopReturnedGarments);
    };

    useEffect(() => {
      fetchWidgetData();
    }, [currentDate, interval, transactionEventsCount]);

    const isNodata = topReturnedGarments && topReturnedGarments.length === 0;
    const { tabsLabels, tabsContents } = getTabsData(topReturnedGarments);
    const areTabsVisible = tabsLabels.some((tab) => tab.isTabVisible);

    return (
      <ProductSlider
        isOpen={!!selectedGarment}
        onClose={() => setSelectedGarment(null)}
        productDetails={selectedGarment}
        currencyCode={selectedGarment?.currency || storeCurrency || ''}
      >
        <WidgetCardContent
          headerIcon={Icons.PROFILE}
          headerText={t(topReturnsTranslations.title.key, topReturnsTranslations.title.defaultValue)}
          subHeaderText={t(topReturnsTranslations.subTitle.key, topReturnsTranslations.subTitle.defaultValue)}
          toolTipText={t(topReturnsTranslations.toolTip.key, topReturnsTranslations.toolTip.defaultValue)}
          lastUpdatedDate={lastUpdated}
          isLoading={topReturnedGarmentsLoading}
          isError={topReturnedGarmentsError}
          errorText={t(topReturnsTranslations.error.key, topReturnsTranslations.error.defaultValue)}
          errorRetryHandler={fetchWidgetData}
          isNoData={isNodata || !areTabsVisible}
          content={
            <>
              <StyledView
                wrap="nowrap"
                data-testid="top-returning-garment-overview"
                height="100%"
                hasBorder={true}
              >
                <StyledView data-testid="top-returning-garment-table" hasBorder={false}>
                  <DynamicTab
                    tabLabelArray={tabsLabels}
                    handleTabChange={handleTabChange}
                    tabValue={tabIndex}
                    variant="scrollable"
                    tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
                    content={tabsContents}
                  />
                </StyledView>
              </StyledView>
            </>
          }
        />
      </ProductSlider>
    );
  },
);
