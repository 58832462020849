import styled from 'styled-components';
import { Container, Typography } from '../../../../stories/atoms';
import { Button } from '../../../../stories/molecules';

export const StyledWrapper = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    max-height: 75vh;
  }
`;

export const StyledPermissionWrapper = styled(Container)`
  display: grid;
  grid-template-columns: ${({ theme: { spacing } }) => `repeat(auto-fill, ${spacing(50)})`};
  grid-gap: ${({ theme: { spacing } }) => spacing(6)};
  justify-content: center;
  padding: ${({ theme: { spacing } }) => spacing(4, 0)};
  overflow-y: auto;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedDark : color.baseGray};
`;

export const StyledLabelWrapper = styled.div`
  position: relative;
  top: 0;
`;

export const StyledPageTitle = styled(Typography)`
  max-width: ${({ theme: { spacing } }) => spacing(40)};
`;

export const StyledCheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

export const StyledButtonWrapper = styled(Container)`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  background-image: ${({ theme: { palette } }) =>
    palette.mode === 'dark' && `linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))`};
  padding-top: ${({ theme: { spacing } }) => spacing(10)};
  z-index: ${({ theme: { zIndex } }) => zIndex.modal};
`;

export const StyledBackButton = styled(Button)`
  margin: ${({ theme: { spacing } }) => spacing(0, 3)};
`;

export const StyledContainer = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
`;
export const StyledCheckbox = styled.span`
  padding: ${({ theme: { spacing } }) => spacing(0, 0, 3)};
`;
