import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { RfidSalesRetaggingResponse, useAsyncAction, useTransaction } from '../../../../../hooks';
import { Container, Icons } from '../../../../../stories/atoms';
import { Animation, Animations } from '../../../../../stories/molecules';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { MetricItem } from './MetricItem';
import { MetricItemsContainer, Wrapper } from './RfidSalesRetaggingWidget.styles';

export type RfidSalesRetaggingWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  fromDate?: Date;
  toDate?: Date;
  // handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
};

export const RfidSalesRetaggingWidget: FC<RfidSalesRetaggingWidgetProps> = observer(
  ({
    interval,
    currentDate,
    // hasPermissionToEditKpi,
    fromDate,
    toDate,
    //  handleDropdownDetails
  }) => {
    const { t } = useTranslation();
    const {
      rfidSalesRetagging: rfidSalesRetaggingTranslations,
      // common: commonTranslations,
      // kpiTargets: kpiTargetsTranslations,
    } = homeTranslations;
    const { fetchRfidSalesRetaggingData } = useTransaction();

    // const {
    //   get: { kpiTargetsProps },
    // } = useUser();

    // const target = getKpiTargetValue(kpiTargetsProps, 'dailyQueueLength');
    // const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyQueueLength')?.isLocked ?? false;

    const {
      result: rfidSalesRetagging,
      isLoading: rfidSalesRetaggingLoading,
      isError: rfidSalesRetaggingError,
      triggerAction: triggerFetchRfidSalesRetagging,
    } = useAsyncAction<RfidSalesRetaggingResponse>();

    const fetchRfidSalesRetagging = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchRfidSalesRetaggingData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const fetchWidgetData = () => {
      triggerFetchRfidSalesRetagging(fetchRfidSalesRetagging);
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate]);

    // const isEditKPIDisabled = rfidSalesRetaggingError || !hasPermissionToEditKpi;

    return (
      <WidgetCardContent
        headerIcon={Icons.PROFILE}
        headerText={t(rfidSalesRetaggingTranslations.title.key, rfidSalesRetaggingTranslations.title.defaultValue)}
        subHeaderText={t(
          rfidSalesRetaggingTranslations.subTitle.key,
          rfidSalesRetaggingTranslations.subTitle.defaultValue,
        )}
        toolTipText={t(
          rfidSalesRetaggingTranslations.tooltip.key,
          rfidSalesRetaggingTranslations.tooltip.defaultValue,
        )}
        isLoading={rfidSalesRetaggingLoading}
        isError={rfidSalesRetaggingError}
        errorText={t(rfidSalesRetaggingTranslations.error.key, rfidSalesRetaggingTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        lastUpdatedDate={rfidSalesRetagging?.lastUpdated}
        noDataContent={
          <Animation
            size="small"
            title=""
            subtitle={t(
              rfidSalesRetaggingTranslations.noData.key,
              rfidSalesRetaggingTranslations.noData.defaultValue,
            )}
            animation={Animations.COOL}
          />
        }
        // headerSideContent={
        //   <DropdownMenu
        //     data-testid="kpiDropdown"
        //     dropdownLabel={''}
        //     buttonContentProps={{
        //       iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
        //     }}
        //     menuItems={[
        //       {
        //         key: target ? 'editkpi' : 'addkpi',
        //         disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
        //         hasTooltip: isEditKPIDisabled || isTargetLocked,
        //         tooltipText: (
        //           <Container padding={[1]}>
        //             <Typography color="white">
        //               {!hasPermissionToEditKpi
        //                 ? t(
        //                     kpiTargetsTranslations.noPermissionTooltip.key,
        //                     kpiTargetsTranslations.noPermissionTooltip.defaultValue,
        //                   )
        //                 : isTargetLocked
        //                 ? t(
        //                     kpiTargetsTranslations.lockedTargetTooltip.key,
        //                     kpiTargetsTranslations.lockedTargetTooltip.defaultValue,
        //                   )
        //                 : t(
        //                     kpiTargetsTranslations.disabledTooltip.key,
        //                     kpiTargetsTranslations.disabledTooltip.defaultValue,
        //                   )}
        //             </Typography>
        //           </Container>
        //         ),
        //         item: (
        //           <Typography margin={[0, 2, 0]}>
        //             {target
        //               ? t(kpiTargetsTranslations.editKpi.key, kpiTargetsTranslations.editKpi.defaultValue)
        //               : t(kpiTargetsTranslations.addKpi.key, kpiTargetsTranslations.addKpi.defaultValue)}
        //           </Typography>
        //         ),
        //       },
        //     ]}
        //     minWidth={'0px'}
        //     isCompact
        //     initialSelected={[]}
        //     onSelect={(selectedItem) => {
        //       handleDropdownDetails('dailyQueueLength', selectedItem[0] as KpiDropdownActions);
        //     }}
        //     isOnlyIcon
        //     allowTooltip
        //   />
        // }
        content={
          <Wrapper data-testid="rfid-sales-retagging-wrapper">
            {rfidSalesRetagging && (
              <>
                <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
                  <MetricItemsContainer>
                    <MetricItem
                      icon={Icons.CONVERSION}
                      indicatorColor="primary"
                      titleText={t(
                        rfidSalesRetaggingTranslations.addedSalesTitle.key,
                        rfidSalesRetaggingTranslations.addedSalesTitle.defaultValue,
                      )}
                      descriptionText={t(
                        rfidSalesRetaggingTranslations.addedSalesDescription.key,
                        rfidSalesRetaggingTranslations.addedSalesDescription.defaultValue,
                      )}
                      percentValue={rfidSalesRetagging.addedSales}
                    />

                    <MetricItem
                      icon={Icons.TAG}
                      indicatorColor={rfidSalesRetagging.retaggingRate < 100 ? 'red' : 'validationGreen'}
                      titleText={t(
                        rfidSalesRetaggingTranslations.retaggingRateTitle.key,
                        rfidSalesRetaggingTranslations.retaggingRateTitle.defaultValue,
                      )}
                      descriptionText={t(
                        rfidSalesRetaggingTranslations.retaggingRateDescription.key,
                        rfidSalesRetaggingTranslations.retaggingRateDescription.defaultValue,
                      )}
                      percentValue={rfidSalesRetagging.retaggingRate}
                    />
                  </MetricItemsContainer>
                </Container>
              </>
            )}
          </Wrapper>
        }
      />
    );
  },
);
