import React from 'react';
import { Icons, TypographyProps } from '../../../../stories/atoms';
import { RotateDirection } from '../../../../stories/atoms/icon/Icon.styles';
import { Colors, Direction } from '../../../../theme';
import { Spacing } from '../../../../utils';
import { StyledTrendIconWithText, StyledTypography, StyledWrapper } from './StyledTrendIcon.styles';

interface StyledIconProps {
  originalAmount: number;
  previousAmount: number;
  padding?: Spacing;
  textOptions?: TypographyProps;
  iconTextOptions?: TypographyProps;
  colorText?: boolean;
  direction?: Direction;
  valueFormatter?: (value: number) => string;
}

export const StyledTrendIconComponent: React.FC<StyledIconProps> = ({
  originalAmount,
  previousAmount,
  padding,
  textOptions,
  iconTextOptions,
  colorText = false,
  direction = 'vertical',
  valueFormatter,
}) => {
  const getPercentageDifference = () => {
    const difference = previousAmount === 0 ? 0 : ((originalAmount - previousAmount) / previousAmount) * 100;
    const newobj = {
      value: difference > 0 ? difference.toFixed(1) : (difference * -1).toFixed(1),
      color: difference > 0 ? 'validationGreen' : 'red',
      rotateValue: difference > 0 ? 'right' : 'left',
    };
    return newobj;
  };

  const { value, color, rotateValue } = getPercentageDifference();

  return (
    <StyledWrapper direction={direction}>
      <StyledTypography
        margin={direction === 'horizontal' ? [0, 2, 0, 0] : undefined}
        type="button"
        color={colorText ? (color as Colors) : 'primary'}
        {...textOptions}
      >
        {valueFormatter ? valueFormatter(previousAmount) : previousAmount}
      </StyledTypography>
      <StyledTrendIconWithText
        label={`${value}%`}
        iconOptions={{
          icon: Icons.ARROW,
          size: 'small',
          rotate: rotateValue as RotateDirection,
          color: color as Colors,
        }}
        textOptions={{
          color: color as Colors,
          ...iconTextOptions,
        }}
        padding={padding}
      />
    </StyledWrapper>
  );
};
