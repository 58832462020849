import { Grow, useMediaQuery } from '@mui/material';
import { FC, useRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Delay } from '../../../../constants';
import { Icons, Typography, TypographyProps } from '../../../../stories/atoms';
import { Banner, Button, Modal } from '../../../../stories/molecules';
import { BannerItemsType } from '../../../../stories/molecules/banner';
import { selectAvatar, editAvatar, commonTranslations } from '../../../../translations';
import { AvatarPlaceHolder } from '../avatar-placeholder/AvatarPlaceholder';
import { useThemeContext } from '../../../../global-state/themeContext';
import { muiTheme } from '../../../../theme';
import { AvatarListType } from '../AvatarSettings';
import {
  StyledContainer,
  StyledSelectAvatarContainer,
  StyledListAvatarContainer,
  StyledSaveButton,
  StyledImageContainer,
  StyledAvatarIcon,
  StyledGradient,
  StyledAvatarList,
  IconWrapper,
  StyledDivider,
  EmptyListContainer,
  StyledButtonsWrapper,
  StyledCategoryList,
} from './AvatarSelect.styles';

export type AvatarSelectProps = {
  initialAvatarUrl?: string;
  selectedCategory: string;
  avatarUrlList: string[];
  avatarList: AvatarListType[];
  isSaveBtnDisabled: boolean;
  onClick: (url?: string) => void;
  onCategoryClick: (category: string) => void;
  onCancel: () => void;
  onRetry: () => void;
};

export const AvatarSelect: FC<React.PropsWithChildren<AvatarSelectProps>> = ({
  initialAvatarUrl,
  selectedCategory,
  avatarUrlList,
  avatarList,
  isSaveBtnDisabled,
  onClick,
  onCategoryClick,
  onCancel,
  onRetry,
}) => {
  const initialNoOfAvatars = 50;
  const { t } = useTranslation();
  const [selectedUrl, setSelectedUrl] = useState(initialAvatarUrl);
  const [scrollIndex, setScrollIndex] = useState(1);
  const [showGradient, setShowGradient] = useState(true);
  const [noOfAvatarsPerScroll, setNoOfAvatarsPerScroll] = useState(initialNoOfAvatars);
  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);

  const avatarListRef = useRef<HTMLDivElement>(null);
  const avatarIconRef = useRef<HTMLImageElement>(null);

  const { mode } = useThemeContext();

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  useEffect(() => {
    avatarListRef.current?.addEventListener('scroll', handleScroll);
    const timeoutId = setTimeout(() => setNoOfAvatarsPerScroll(getNoOfAvatarsPerScroll()), Delay.XSmall);

    return () => {
      avatarListRef.current?.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setScrollIndex(1);
  }, [selectedCategory]);

  const getNoOfAvatarsPerScroll = () => {
    const containerStyle = getComputedStyle(avatarListRef.current as HTMLDivElement);
    const avatarIconElement = avatarIconRef.current;
    if (!avatarIconElement) {
      return 0; // Handle the case when avatarIconRef.current is null
    }

    const iconStyle = getComputedStyle(avatarIconElement);

    const paddingLeft = parseInt(containerStyle.paddingLeft);
    const paddingRight = parseInt(containerStyle.paddingRight);
    const paddingTop = parseInt(containerStyle.paddingTop);
    const paddingBottom = parseInt(containerStyle.paddingBottom);

    const iconMarginTop = parseInt(iconStyle.marginTop);
    const iconMarginBottom = parseInt(iconStyle.marginBottom);
    const iconMarginLeft = parseInt(iconStyle.marginLeft);
    const iconMarginRight = parseInt(iconStyle.marginRight);

    const clientHeight = (avatarListRef.current?.clientHeight as number) - (paddingTop + paddingBottom);
    const clientWidth = (avatarListRef.current?.clientWidth as number) - (paddingLeft + paddingRight);
    const avatarHeight = (avatarIconRef.current?.height as number) + iconMarginTop + iconMarginBottom;
    const avatarWidth = (avatarIconRef.current?.width as number) + iconMarginLeft + iconMarginRight;

    const noOfRows = Math.floor(clientHeight / avatarHeight) + 1;
    const noOfColumns = Math.floor(clientWidth / avatarWidth);

    return noOfRows * noOfColumns;
  };

  const handleScroll = () => {
    const scrollTop = avatarListRef.current?.scrollTop as number;
    const scrollHeight = avatarListRef.current?.scrollHeight as number;
    const clientHeight = avatarListRef.current?.clientHeight as number;
    const offset = 50; // added offset to calculate bottom of the page - offset.

    const showMoreIcons = scrollHeight - offset < scrollTop + clientHeight;

    setScrollIndex((index) => (showMoreIcons ? index + 1 : index));

    if (showMoreIcons) {
      setShowGradient(false);
    } else {
      setShowGradient(scrollHeight !== scrollTop + clientHeight);
    }
  };

  const isButtonDisabled = isSaveBtnDisabled || initialAvatarUrl === selectedUrl;

  const selectAvatarButtons = () => {
    return (
      <>
        <StyledSaveButton
          label={t(selectAvatar.saveAvatarButton.key, selectAvatar.saveAvatarButton.defaultValue)}
          buttonContentProps={{
            iconOptions: {
              icon: Icons.EDIT,
              size: 'small',
            },
            textOptions: { color: isButtonDisabled ? 'textGray' : 'white' },
          }}
          data-testid="save-button"
          fullWidth
          onClick={() => onClick(selectedUrl)}
          disabled={isButtonDisabled}
          isDarkMode={mode === 'dark'}
        />
        <Button
          label={t(commonTranslations.cancel.key, commonTranslations.cancel.defaultValue)}
          data-testid="cancel-button"
          variant="secondary"
          fullWidth
          onClick={onCancel}
        />
      </>
    );
  };

  const avatarListContainer = () => {
    return (
      <StyledListAvatarContainer data-testid="avatar-list" ref={avatarListRef}>
        {avatarUrlList.length ? (
          <StyledAvatarList>
            <StyledImageContainer>
              {avatarUrlList?.slice(0, noOfAvatarsPerScroll * scrollIndex).map((url, index) => {
                return (
                  <Grow key={`${url}-${index}-${selectedCategory}`} in={true} timeout={Delay.Medium}>
                    <IconWrapper
                      className={url === selectedUrl ? 'selected' : undefined}
                      onClick={() => setSelectedUrl(url)}
                    >
                      <StyledAvatarIcon
                        data-testid="avatar-icon"
                        src={`${url}${process.env.REACT_APP_CDN_TOKEN}`}
                        className={url === selectedUrl ? 'selected' : undefined}
                        ref={avatarIconRef}
                      />
                    </IconWrapper>
                  </Grow>
                );
              })}
            </StyledImageContainer>
          </StyledAvatarList>
        ) : (
          <EmptyListContainer data-testid="avatar-list-empty">
            <Typography type="h3" textAlign="center">
              <Trans
                i18nKey={selectAvatar.noAvatarsFound.key}
                defaults={selectAvatar.noAvatarsFound.defaultValue}
                components={{ br: <br /> }}
              />
            </Typography>
            <StyledSaveButton
              onClick={onRetry}
              disabled={false}
              isDarkMode={mode === 'dark'}
              label={t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue)}
              buttonContentProps={{ iconOptions: { icon: Icons.REFRESH, color: 'white' } }}
            />
          </EmptyListContainer>
        )}
      </StyledListAvatarContainer>
    );
  };

  return (
    <>
      {!isMobile ? (
        <StyledContainer data-testid="avatar-select-screen">
          <Banner items={bannerItems} onChange={(items) => setBannerItems(items)} />
          <StyledSelectAvatarContainer>
            <AvatarPlaceHolder
              header={t(selectAvatar.title.key, selectAvatar.title.defaultValue)}
              avatarUrl={selectedUrl}
              isCompact
            >
              {selectAvatarButtons()}
            </AvatarPlaceHolder>
          </StyledSelectAvatarContainer>
          <StyledDivider orientation="vertical" />
          {avatarListContainer()}
          <StyledGradient data-testid="gradient-bar" isVisible={showGradient} />
        </StyledContainer>
      ) : (
        <Modal
          isOpen={true}
          isFullScreen
          transitionType="slide"
          ariaDescribedby="change-avatar-modal"
          handleClose={onCancel}
          hasCloseIcon={false}
        >
          <Typography type="h2" textAlign="center" padding={[8, 0, 4]}>
            {t(editAvatar.changeAvatarButton.key, editAvatar.changeAvatarButton.defaultValue)}
          </Typography>
          <StyledCategoryList>
            {avatarList.map(({ category }) => {
              const selectedProps: Partial<TypographyProps> =
                category === selectedCategory ? { color: 'red', borderBottomColor: 'red' } : {};

              return (
                <Button
                  label={t(`avatarSettings.category.${category}`, category)}
                  key={category}
                  variant="text"
                  buttonContentProps={{ textOptions: selectedProps }}
                  onClick={() => onCategoryClick(category)}
                />
              );
            })}
          </StyledCategoryList>
          {avatarListContainer()}
          <StyledButtonsWrapper>{selectAvatarButtons()}</StyledButtonsWrapper>
        </Modal>
      )}
    </>
  );
};
