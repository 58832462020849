import styled from 'styled-components';
import { cardShadow } from '../../../theme';
import { columnWidths } from '../ProductCategory.styles';

export const Container = styled.div`
  min-height: ${({ theme: { spacing } }) => spacing(12)};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
  justify-content: flex-end;
  padding: ${({ theme: { spacing } }) => spacing(0, 5)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  ${cardShadow()};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2, 0)};
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: ${({ theme: { spacing } }) => spacing(8)};
  box-sizing: border-box;
  min-width: ${columnWidths.minPxWidth}px;
`;

export const SobContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: ${columnWidths.minPxWidth}px;
`;

export const ProgressLineContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  margin-top: ${({ theme: { spacing } }) => spacing(1)};
`;

export const StyledDropdownIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: ${({ theme: { spacing } }) => spacing(2)};
`;

export const StyledArrowIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: ${({ theme: { spacing } }) => spacing(2)};
`;

export const MobileContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileContainer = styled.div`
  position: relative;
  flex: 1;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};

  ${cardShadow()};
`;

export const MobileTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme: { spacing } }) => spacing(4, 0, 4, 0)};
  margin: ${({ theme: { spacing } }) => spacing(0, 2, 0, 2)};
  border-bottom: ${({ theme: { color } }) => `1px solid ${color.borderGray}`};
`;

export const MobileValuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme: { spacing } }) => spacing(3)};
`;

export const MobileValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
