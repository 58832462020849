import styled from 'styled-components';

type ContentWrapperProps = {
  isOpen: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  flex-direction: column;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundDark : color.white};
  box-shadow: ${({ theme: { palette } }) =>
    palette.mode === 'dark' ? '-20px 20px 40px -4px #0000003D' : '0px 2px 11px #ababab'};
  overflow: hidden;
  animation: fadeIn 200ms ease-out;

  ${({ theme: { breakpoints } }) => breakpoints.up('tabletPortrait')} {
    max-height: 70vh;
    max-width: 400px;
    position: relative;
    border-radius: ${({ theme: { spacing } }) => spacing(3)};
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : '#fbf9f7'};
  height: ${({ theme }) => theme.spacing(15)};
  padding: ${({ theme }) => theme.spacing(0, 5)};
`;

export const HeadeTitleContainer = styled.div`
  flex: 1;
  margin-left: ${({ theme: { spacing } }) => spacing(5)};
`;

export const HeaderButton = styled.div`
  margin-left: ${({ theme: { spacing } }) => spacing(5)};
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: ${({ theme }) => theme.spacing(4, 4)};
`;

export const MessagesList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.spacing(18)};
  padding: ${({ theme }) => theme.spacing(3)};
  box-sizing: border-box;
`;

export const StyledSubmitButton = styled.button`
  height: ${({ theme }) => theme.spacing(10)};
  width: ${({ theme }) => theme.spacing(10)};
  right: ${({ theme }) => theme.spacing(3)};
  top: ${({ theme }) => `calc((100% - ${theme.spacing(10)}) / 2)`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  position: absolute;
  background: none;
  cursor: pointer;

  > div {
    color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.primary};
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9e9;
  padding: ${({ theme }) => theme.spacing(0, 3)};
`;

export const StyledButton = styled.button`
  padding: ${({ theme }) => theme.spacing(0)};
  height: ${({ theme }) => theme.spacing(15)};
  width: ${({ theme }) => theme.spacing(15)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  background-color: #dfe4e7;
  border: none;
  float: right;
  cursor: pointer;
`;
