// TODO: Remove this when fixing the unit tests - BTCOBUI-1952
// TODO: Add type definitions for TMap [BTCOBUI-2597]
/* istanbul ignore file */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import { useEffect, useRef, useState } from 'react';
import { CenterCoordinates, MarkerDetails } from './Map';

type Props = {
  zoom: number;
  center: CenterCoordinates;
  selectedMarkerId: string;
  markers: MarkerDetails[];
  panOnMarkerSelect: boolean;
  onMarkerSelectZoom: number;
  onLoaded: () => void;
  onMarkerClick: (markerId: string) => void;
  getMarkerIconUrl: (markerId?: string) => string | undefined;
};

export const TencentMapView = ({
  zoom,
  center,
  selectedMarkerId,
  markers,
  panOnMarkerSelect,
  onMarkerSelectZoom,
  onLoaded,
  onMarkerClick,
  getMarkerIconUrl,
}: Props) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [mapInstance, setMapInstance] = useState<any>(null);
  const markerLayerRef = useRef<any>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const loadScript = () => {
    (window as any).tencentInitMap = function initMap() {
      if (!(window as any).TMap) {
        return;
      }
      onLoaded();
      setIsScriptLoaded(true);
    };

    const tencentLoadCallbackScript = document.createElement('script');
    tencentLoadCallbackScript.innerHTML = ` function tencentLoadCallback() {
      tencentInitMap();
    }`;
    document.body.appendChild(tencentLoadCallbackScript);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${
      process.env.REACT_APP_TENCENT_MAPS_API_KEY || ''
    }&callback=tencentLoadCallback`;
    document.body.appendChild(script);
  };

  const renderMarkers = () => {
    if (!mapInstance) return;

    if (markerLayerRef.current) {
      markerLayerRef.current.setGeometries([]);
    }

    const markerStyles: { [key: string]: any } = {};

    markers.forEach((marker) => {
      const { id } = marker;

      markerStyles[id] = new (window as any).TMap.MarkerStyle({
        width: 25,
        height: 35,
        src: getMarkerIconUrl(id),
        anchor: { x: 16, y: 32 },
      });
    });

    markerLayerRef.current = new (window as any).TMap.MultiMarker({
      map: mapInstance,
      styles: markerStyles,
      geometries: markers.map((marker) => {
        return {
          id: marker.id,
          styleId: marker.id,
          position: new (window as any).TMap.LatLng(marker.latitude, marker.longitude),
        };
      }),
    });

    markerLayerRef.current.on('click', handleMarkerClick);
  };

  const panToMarker = (markerId: string) => {
    const markerDetails = markers.find((marker) => marker.id === markerId);

    if (!panOnMarkerSelect || !mapInstance || !markerDetails) return;

    mapInstance.easeTo({
      center: new (window as any).TMap.LatLng(markerDetails.latitude, markerDetails.longitude),
      zoom: onMarkerSelectZoom,
    });
  };

  const handleMarkerClick = async (event: any) => {
    const markerId = event?.geometry?.id;

    if (!markerId) return;

    onMarkerClick(markerId);
  };

  useEffect(() => {
    loadScript();
  }, []);

  useEffect(() => {
    if (!isScriptLoaded) return;

    const mapContainerEl = mapContainerRef.current;

    if (!mapContainerEl || mapInstance) return;

    const newMapInstance = new (window as any).TMap.Map(mapContainerEl, {
      center: new (window as any).TMap.LatLng(center.latitude, center.longitude),
      zoom: zoom,
      viewMode: '2D',
    });

    setMapInstance(newMapInstance);
  }, [isScriptLoaded]);

  useEffect(() => {
    if (!mapInstance) return;

    const latLngBounds = new (window as any).TMap.LatLngBounds();

    markers.forEach((marker) => {
      latLngBounds.extend(new (window as any).TMap.LatLng(marker.latitude, marker.longitude));
    });

    mapInstance.fitBounds(latLngBounds, { padding: 70 });
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    renderMarkers();
  }, [markers, mapInstance]);

  useEffect(() => {
    if (!selectedMarkerId) return;

    panToMarker(selectedMarkerId);
    renderMarkers();
  }, [selectedMarkerId]);

  return <div style={{ width: '100%', height: '100%' }} ref={mapContainerRef}></div>;
};
