import { userController } from './userController';
import { transactionController } from './transactionController';

export const globalController = {
  userController,
  transactionController,
};

export * from './userController';
export * from './transactionController';
