export type AtLeast<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type Spacing = [number, number?, number?, number?];

export type ModalProps<T> =
  | {
      isOpen: true;
      modalData: T;
    }
  | { isOpen: false; modalData?: T };

export type FailureResponse = {
  status: number;
  error: string;
};

export type SuccessResponse<T> = {
  status: number;
  data: T;
};

export type PatchOperation = {
  op: 'add' | 'set' | 'remove' | 'replace' | 'incr';
  value: unknown;
  path: string;
};

export type Translation = {
  key: string;
  defaultValue?: string;
};

export function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
  return k in x;
}
