import styled from 'styled-components';
import { cardShadow } from '../../../theme';

export const StyledOpeningHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme: { spacing } }) => spacing(4)};
  ${cardShadow()};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  margin: ${({ theme: { spacing } }) => spacing(0, 5)};
`;

export const StyledOpeningHoursWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
