import { subDays } from 'date-fns';
import { Message, MessageType } from '../../global-state/types';

const messageTypes = Object.values(MessageType);

export const getMockMessages = (noOfMessages = 100): Message[] => {
  return Array.from({ length: noOfMessages }).map((_, index) => {
    const type = messageTypes[Math.floor(Math.random() * messageTypes.length)];
    const message: Message = {
      id: index.toString(),
      created: subDays(new Date(), Math.floor(Math.random() * -5)).toISOString(),
      type,
    };

    return message;
  });
};
