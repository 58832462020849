import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';

import { useThemeContext } from '../../../global-state/themeContext';
import { color } from '../../../theme';
import { Icon, Icons } from '../../atoms';

export type SpeedDialActionDetails<T> = { text: T; icon: ReactNode; tooltipText: string };

type SpeedDialButtonProps<T> = {
  actions: SpeedDialActionDetails<T>[];
  onAction: (actionName: T) => void;
};

export const SpeedDialButton = <T extends string>(props: SpeedDialButtonProps<T>) => {
  const { actions, onAction } = props;
  const [open, setOpen] = useState(false);

  const { mode } = useThemeContext();

  const handleOnClick = () => {
    setOpen(!open);
  };

  const handleActionOnClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, text: T) => {
    e.preventDefault();
    onAction(text);
  };

  const isDarkMode = mode === 'dark';

  const fabColor = isDarkMode ? color.backgroundPaperDark : color.borderGray;
  const tooltipBackgroundColor = isDarkMode ? color.secondaryTextGray : color.primary;
  const tooltipTextColor = isDarkMode ? color.white : color.white;

  return (
    <SpeedDial
      data-testid="speed-dial-button"
      ariaLabel="SpeedDial Button"
      FabProps={{ style: { backgroundColor: fabColor } }}
      icon={<SpeedDialIcon icon={<Icon icon={Icons.PLUS} />} />}
      onClick={handleOnClick}
      open={open}
    >
      {actions.map((action) => {
        const { text, icon, tooltipText } = action;

        return (
          <SpeedDialAction
            data-testid={`speed-dial-button-action-${text}`}
            FabProps={{ style: { backgroundColor: fabColor } }}
            key={text}
            icon={icon}
            tooltipTitle={tooltipText}
            tooltipOpen
            onClick={(e) => handleActionOnClick(e, text)}
            sx={{
              '.MuiSpeedDialAction-staticTooltipLabel': {
                backgroundColor: tooltipBackgroundColor,
                color: tooltipTextColor,
                width: 'max-content',
                padding: '3px 8px 3px 8px',
                borderRadius: '8px',
                height: '28px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              },
            }}
          />
        );
      })}
    </SpeedDial>
  );
};
