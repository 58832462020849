import React, { useCallback, useState } from 'react';
import { TextField, Rating } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon, Typography, Icons } from '../../stories/atoms';
import { Animations, Animation } from '../../stories/molecules';
import { FeedbackData, useStores } from '../../hooks';
import { isFailureResponse } from '../../utils';
import { feedbackTranslations } from '../../translations';
import { debounce } from '../../utils/debounce';
import {
  ContentWrapper,
  HeaderContainer,
  HeaderTitleContainer,
  HeaderButton,
  FeedBackContainer,
  StyledButton,
} from './FeedbackForm.styles';

const ANIMATION_DURATION = 5000;

export type FeedbackFormProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { sendFeedbackData } = useStores();
  const { t } = useTranslation();

  const { title, ratings, comments, errorMessage, successMessage, submitButton } = feedbackTranslations;

  const sendFeedback = async () => {
    const dateTime = new Date().toISOString();
    const feedbackData: FeedbackData = {
      rating: rating || 0,
      comment,
      dateTime,
    };

    const response = await sendFeedbackData(feedbackData);

    if (isFailureResponse(response)) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, ANIMATION_DURATION);

      return;
    }

    setSubmitted(true);
    setRating(0);
    setComment('');

    setTimeout(() => {
      setSubmitted(false);
    }, ANIMATION_DURATION);
  };

  // Use useCallback to ensure that the debounced function doesn't change on every render.
  // Add debounce to avoid multiple requests in a short time.
  const handleSubmit = useCallback(debounce(sendFeedback, 500), [rating, comment]);

  const isFormVisible = !submitted && !error;
  const animationProps = error
    ? { subtitle: t(errorMessage.key, errorMessage.defaultValue), animation: Animations.SAD }
    : { subtitle: t(successMessage.key, successMessage.defaultValue), animation: Animations.HAPPY };

  return (
    <ContentWrapper data-testid="feeback-form-wrapper" isOpen={isOpen}>
      <HeaderContainer data-testid="header-container">
        <Icon icon={Icons.FEEDBACK} />
        <HeaderTitleContainer data-testid="header-title-container">
          <Typography type="body2">{t(title.key, title.defaultValue)}</Typography>
        </HeaderTitleContainer>
        <HeaderButton onClick={onClose} data-testid="header-close-button">
          <Icon icon={Icons.CLOSE} size="small" />
        </HeaderButton>
      </HeaderContainer>

      <FeedBackContainer>
        {isFormVisible && (
          <>
            <>
              <Typography type="body2">{t(ratings.key, ratings.defaultValue)}</Typography>
              <Rating
                data-testid="rating-input"
                name="rating"
                value={rating}
                precision={0.5}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </>
            <TextField
              data-testid="comments-input"
              label={<Typography type="body1">{t(comments.key, comments.defaultValue)}</Typography>}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              sx={{ marginTop: '12px' }}
            />
            <StyledButton
              data-testid="submit-button"
              label={t(submitButton.key, submitButton.defaultValue)}
              onClick={handleSubmit}
              disabled={comment === '' && rating === null}
            />
          </>
        )}
        {!isFormVisible && <Animation title="" {...animationProps} size="small" />}
      </FeedBackContainer>
    </ContentWrapper>
  );
};
