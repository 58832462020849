import { TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: ${({
      theme: {
        color,
        palette: { mode },
      },
    }) => (mode === 'dark' ? color.white : color.textGray)};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${({
        theme: {
          color,
          palette: { mode },
        },
      }) => (mode === 'dark' ? color.white : color.borderGray)};
    }
  }
`;
