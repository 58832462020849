export const baseUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://gateway.hapi.hmgroup.com/boxers-gateway/v1'
    : process.env.REACT_APP_ENV === 'test'
    ? 'https://gateway-test.hapi.hmgroup.com/boxers-gateway/v1'
    : 'https://gateway-test.hapi.hmgroup.com/dev/boxers-gateway/v1';

export const baseUrlCDN = 'https://boxers.azureedge.net';
export const localhost = 'http://0.0.0.0:8000';

export const dreamCatcherUrl = 'https://assets.hm.com/v2';
export const dreamCatcherSecret = 'dreamcatcher';

// Transaction Search
export const transactionEndpoint = '/transactions';
export const countEndpoint = '/count';
export const receiptEndpoint = '/receipt';
export const tenderWidgetEndpoint = '/tenders';
export const registeredReceiptsWidgetEndpoint = '/registeredReceipts';
export const hourlyTurnoverWidgetEndpoint = '/netSales';
export const conversionRateWidgetEndpoint = '/conversionRate';
export const topSellingGarmentEndpoint = '/topSellingGarments';
export const topReturnedGarmentEndpoint = '/topReturningGarments';
export const shareOfReceiptsEndpoint = '/shareOfReceipts';
export const newMembersEndpoint = '/newMembers';
export const appCustomersEndpoint = '/appCustomers';
export const averageNetSalePerReceiptEndpoint = '/averageNetSalePerReceipt';
export const budgetPlannedEndpoint = '/budgetAndPlanned';
export const effectiveHoursEndpoint = '/effectiveHours';
export const receiptSalesAnalysisEndpoint = '/receiptSalesAnalysis';
export const averagePiecesPerReceipt = '/averagePiecesPerReceipt';
export const queueLengthEndpoint = '/queueLength';
export const takt1Endpoint = '/takt1';
export const sptEndpoint = '/spt';
export const customerFeedbackEndpoint = '/customerFeedback';
export const rfidSalesRetaggingEndpoint = '/salesAndRetagging';
export const scoEndpoint = '/sco';
export const rfidEndpoint = '/rfid';
export const rfidApprovalsEndpoint = '/approvals';
export const rfidCountEndpoint = '/count';
export const orisCustomersEndpoint = '/orisCustomers';

// Sold Products
export const soldProductsEndpoint = '/sold-products';

// Translations
export const translationEndpoint = '/translations/';
export const translationMappingEndpoint = '/translation-mapping'; // TODO: update this with the correct endpoint

// Profiles
export const profilesEndpoint = '/profiles';
export const updateProfileEndpoint = '/profiles/update';
export const avatarIsUniqueEndpoint = '/profiles/avatars/isUnique';
export const messagesEndpoint = '/profiles/:upn/messages';

// CDN
export const avatarsEndpoint = '/avatars';

// Stores
export const storeEndpoint = '/stores';
export const highlightedWidgetsEndpoint = '/highlightedWidgets';
export const updateHighlightedWidgetsEndpoint = '/highlightWidgets';
export const profitAndLossEndpoint = '/profitAndLoss';

// AssetManagement Widget
export const assetManagementWidgetEndpoint = '/assetManagementWidget';

// Weather Widget
export const weatherWidgetEndpoint = '/weatherWidget';

// Authorization model
export const authorizationEndpoint = '/authorization-model';
export const permissionsEndpoint = '/permissions';
export const blacklistPermissions = '/blacklist-permissions';

// Events
export const registerEndpoint = '/events/register';

// Backoffice AI
export const backofficeAIEndpoint = '/backofficeAI';

// Forecast
export const forecastEndpoint = '/forecast';

// KPI
export const areaKpiEndpoint = '/areaKpis';
export const targetEndpoint = '/kpiTargets';
export const targetLockEndpoint = '/locks';
export const areaCapEndpoint = '/:storeId/areaCaps';
export const areaTargetEndpoint = '/:storeId/kpis/:kpiName';

// Feedback
export const feedBackEndpoint = '/feedback';

// Area
export const areaComparisonEndpoint = '/areaComparison';
