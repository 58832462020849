export const productCategoryTranslations = {
  loading: {
    title: {
      key: 'productCategoryTranslations.loading.title',
      defaultValue: 'Fetching Indexes!!!',
    },
  },
  error: {
    title: {
      key: 'productCategoryTranslations.error.title',
      defaultValue: 'Something went wrong!',
    },
    subTitle: {
      key: 'productCategoryTranslations.error.subTitle',
      defaultValue: 'There was an error while fetching the Indexes. Please try again!',
    },
  },
  noData: {
    title: {
      key: 'productCategoryTranslations.noData.title',
      defaultValue: 'Uh oh!!',
    },
    subtitle: {
      key: 'productCategoryTranslations.noData.subtitle',
      defaultValue: 'No Product Categories at the moment',
    },
  },
  datePrefix: {
    key: 'productCategoryTranslations.datePrefix',
    defaultValue: 'Category based on:',
  },
  garmentGroup: {
    viewAllbutton: {
      key: 'productCategoryTranslations.garmentGroup.viewAllbutton',
      defaultValue: 'All Garment Group',
    },
    title: {
      key: 'productCategoryTranslations.garmentGroup.title',
      defaultValue: 'Group Garment by',
    },
    allText: {
      key: 'productCategoryTranslations.garmentGroup.allText',
      defaultValue: 'All',
    },
  },
  filter: {
    index: {
      key: 'productCategoryTranslations.filter.index',
      defaultValue: 'Index',
    },
    subIndex: {
      key: 'productCategoryTranslations.filter.subIndex',
      defaultValue: 'Sub Index',
    },
    allFilters: {
      key: 'productCategoryTranslations.filter.allFilters',
      defaultValue: 'All Filters',
    },
    clearAllFilters: {
      key: 'productCategoryTranslations.filter.clearAllFilters',
      defaultValue: 'Clear All',
    },
    entriesText: {
      key: 'productCategoryTranslations.filter.entriesText',
      defaultValue: 'Product Items',
    },
    drawerDate: {
      key: 'productCategoryTranslations.filter.drawerDate',
      defaultValue: 'Date',
    },
    drawerSort: {
      key: 'productCategoryTranslations.filter.drawerSort',
      defaultValue: 'Sort By',
    },
    sortAscending: {
      key: 'productCategoryTranslations.filter.drawerSort',
      defaultValue: 'Lowest Sales Net',
    },
    sortDescending: {
      key: 'productCategoryTranslations.filter.drawerSort',
      defaultValue: 'Highest Sales Net',
    },
  },
  tableHeader: {
    category: {
      key: 'productCategoryTranslations.tableHeader.category',
      defaultValue: 'Category',
    },
    salesNet: {
      key: 'productCategoryTranslations.tableHeader.salesNet',
      defaultValue: 'Sales Net',
    },
    lastWeek: {
      key: 'productCategoryTranslations.tableHeader.lastWeek',
      defaultValue: 'LW',
    },
    lastYear: {
      key: 'productCategoryTranslations.tableHeader.lastYear',
      defaultValue: 'LY',
    },
    sob: {
      key: 'productCategoryTranslations.tableHeader.sob',
      defaultValue: 'SoB',
    },
  },
  tableFooter: {
    total: {
      key: 'productCategoryTranslations.tableFooter.total',
      defaultValue: 'Total',
    },
    lastWeek: {
      key: 'productCategoryTranslations.tableHeader.lastWeek',
      defaultValue: 'Total LW',
    },
    lastYear: {
      key: 'productCategoryTranslations.tableHeader.lastYear',
      defaultValue: 'Total LY',
    },
    sob: {
      key: 'productCategoryTranslations.tableHeader.sob',
      defaultValue: 'Total SoB',
    },
  },
};
