import { messagesEndpoint, profilesEndpoint, updateProfileEndpoint } from '../constants';
import { Message, Profile } from '../global-state/types';
import { PatchOperation } from '../utils';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';
import { useUser } from './useController';

export const useProfile = () => {
  const {
    get: { currentStoreId },
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest();

  const fetchProfile = async (upn: string) => {
    const profileResponse = await authAxiosRequest<Profile>(`${profilesEndpoint}/${upn}`);

    return profileResponse;
  };

  const createProfile = async (role: string) => {
    const response = await authAxiosRequest<Profile>(`${profilesEndpoint}/`, {
      method: 'POST',
      data: {
        role,
      },
    });

    return response;
  };

  const fetchAllEmployeesInStore = async (storeId: string) => {
    const employees = await authAxiosRequest<Profile[]>(`${profilesEndpoint}/`, {
      params: {
        storeId,
      },
    });

    return employees;
  };

  const fetchMessages = async (upn: string) => {
    const response = await authAxiosRequest<Message[]>(messagesEndpoint.replace(':upn', upn));

    return response;
  };

  const updateProfile = async (upn: string, operations: PatchOperation[]) => {
    const updateProfileResponse = await authAxiosRequest<Profile>(updateProfileEndpoint, {
      method: 'PUT',
      data: {
        upn,
        operations,
        storeId: currentStoreId,
      },
    });

    return updateProfileResponse;
  };

  return { fetchProfile, createProfile, updateProfile, fetchAllEmployeesInStore, fetchMessages };
};
