import { FC } from 'react';
import { Colors } from '../../../theme';
import { StyledBadge } from './Badge.styles';

export type BadgeProps = {
  backgroundColor?: Colors;
  width?: string;
};

export const Badge: FC<React.PropsWithChildren<BadgeProps>> = ({
  backgroundColor = 'borderGray',
  width,
  children,
}) => {
  return (
    <StyledBadge data-testid="badge" backgroundColor={backgroundColor} width={width}>
      {children}
    </StyledBadge>
  );
};
