export const errors = {
  notificationError: {
    key: 'error.notificationError',
    defaultValue: 'Whoops, we messed up, sorry about that! Please try again.',
  },
  permissionError: {
    key: 'error.permissionError',
    defaultValue: 'We couldn’t save the updates, please try again.',
  },
  applicationUnavailable: {
    key: 'error.applicationUnavailable',
    defaultValue: 'Oh no! We can’t show you the Back office application right now.',
  },
  sorry: {
    key: 'error.sorry',
    defaultValue: 'Sorry, we messed up!',
  },
  tryAgain: {
    key: 'error.tryAgain',
    defaultValue: 'We are sad that this happened. Try again or come back later.',
  },
  nothingWrong: {
    key: 'error.nothingWrong',
    defaultValue: 'It’s not you, it’s us — but we’re on it!',
  },
  pageNotFound: {
    title: {
      key: 'error.pageNotFound.title',
      defaultValue: 'We seem to be lost.',
    },
    subtitle: {
      key: 'error.pageNotFound.subtitle',
      defaultValue: 'This page does not exist or you might not have access to see it.',
    },
  },
  storeNotAssigned: {
    title: {
      key: 'error.storeNotAssigned.title',
      defaultValue: 'You are not assigned to a store',
    },
    subtitle: {
      key: 'error.storeNotAssigned.subtitle',
      defaultValue: 'If this is wrong, please reach out your manager.',
    },
  },
  noStoresFound: {
    title: {
      key: 'error.noStoresFound.title',
      defaultValue: 'No stores found',
    },
    subtitle: {
      key: 'error.noStoresFound.subtitle',
      defaultValue: 'We couldn’t find any stores for you. Please reach out to your manager.',
    },
  },
};
