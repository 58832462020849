import styled from 'styled-components';

export type ContainerProps = {
  isSender: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${({ isSender }) => (isSender ? 'flex-end' : 'flex-start')};
`;

export const BodyContainer = styled.div<ContainerProps>`
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  margin-left: ${({ theme: { spacing }, isSender }) => (!isSender ? spacing(3) : spacing(0))};
  margin-right: ${({ theme: { spacing }, isSender }) => (!isSender ? spacing(0) : spacing(3))};
`;

export const MessageContainer = styled.div<ContainerProps>`
  padding: ${({ theme }) => theme.spacing(3, 3)};
  border-radius: ${({ theme: { spacing } }) => spacing(6)};
  border-top-left-radius: ${({ theme: { spacing }, isSender }) => (!isSender ? spacing(0) : spacing(6))};
  border-top-right-radius: ${({ theme: { spacing }, isSender }) => (isSender ? spacing(0) : spacing(6))};
  background-color: ${({ isSender, theme: { palette, color } }) =>
    isSender ? (palette.mode === 'light' ? color.primary : color.textGray) : '#d9d9d9'};
  box-shadow: ${({ theme: { palette } }) => (palette.mode === 'light' ? '0px 1px 5px #ddd' : 'none')};

  > div {
    color: ${({ isSender, theme: { color, palette } }) => !isSender && palette.mode === 'dark' && color.primary};
  }
`;

export const StyledUserAvatar = styled.img`
  display: flex;
  height: ${({ theme }) => theme.spacing(6)};
`;

export const PromptsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing(5)};
  cursor: pointer;
`;

export const PromptItem = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 3)};
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.white : color.backgroundDark)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  box-sizing: border-box;
`;
