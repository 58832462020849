import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

export const StyledTable = styled.div`
  width: 100%;
`;
