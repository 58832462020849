import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { StyledTextField } from './Search.styles';

export type SearchProps<T> = {
  fieldLabel: string;
  options: T[];
  disabled?: boolean;
  clearable?: boolean;
  markerOption?: T | null;
  getOptionLabel: (option: T) => string;
  onSelect: (value: T | null) => void;
  filterFunction?: (options: T[], inputString: string) => T[];
};

export const Search = <T,>({
  fieldLabel,
  options,
  disabled = false,
  clearable = true,
  markerOption = null,
  onSelect,
  getOptionLabel,
  filterFunction,
}: SearchProps<T>) => {
  const [inputValue, setInputValue] = useState('');
  const [dropDownValue, setDropDownValue] = useState<T | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnChange = (_: React.SyntheticEvent<Element, Event>, value: T | null) => {
    setDropDownValue(value);
    setInputValue('');
    onSelect(value);
  };

  useEffect(() => {
    if (markerOption) {
      setDropDownValue(markerOption);
    }
  }, [markerOption]);

  return (
    <Autocomplete
      data-testid="search-container"
      filterOptions={filterFunction ? (_options, _) => filterFunction(_options, inputValue) : undefined}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <StyledTextField
          data-testid="search-text-field"
          {...params}
          value={inputValue}
          label={fieldLabel}
          variant="outlined"
          onChange={handleInputChange}
        />
      )}
      value={dropDownValue}
      renderOption={(props, option) => <li {...props}>{getOptionLabel(option)}</li>}
      onChange={handleOnChange}
      PaperComponent={(props) => <Paper {...props} elevation={3} />}
      onBlur={() => setInputValue('')}
      disabled={disabled}
      disableClearable={!clearable}
    />
  );
};
