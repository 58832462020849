import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from '@mui/material';
import { Icon, Icons, Typography } from '../../../../stories/atoms';
import { Checkbox } from '../../../../stories/molecules';
import { Profile } from '../../../../global-state/types';
import { commonTranslations, employeesTranslations } from '../../../../translations';
import { useUser } from '../../../../hooks';
import { getSuamRoles, isEditRoleCheckBoxDisabled, RoleMapping } from '../../../../utils';
import { EmployeeNotificationType } from '../employee-edit-permissions-view/EmployeeEditPermissionView';
import { muiTheme } from '../../../../theme';
import {
  StyledRoleContent,
  CheckboxesWrapper,
  StyledButtonWrapper,
  StyledEditRoleView,
  StyledErrorTypography,
  StyledSaveButton,
  StyledBackButton,
} from './EmployeeEditRoleView.styles';

export type EmployeeEditRoleViewProps = {
  employee: Profile;
  handleCancel: () => void;
  handleSubmit: (isSuccess: boolean, type: EmployeeNotificationType) => void;
};

const { back } = commonTranslations;
const {
  editRolesModal: { contentHeader, saveSettings, noRoleSelected, noPermissionsToEdit },
} = employeesTranslations;

export const EmployeeEditRoleView: FC<React.PropsWithChildren<EmployeeEditRoleViewProps>> = observer(
  ({ employee, handleCancel, handleSubmit }) => {
    const [employeeRoles, setEmployeeRoles] = useState<string[]>([]);

    const { assignments: employeeAssignments } = employee;

    const {
      get: { roleMatrix, profile, currentStoreId },
    } = useUser();
    const { t } = useTranslation();
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

    useEffect(() => {
      setEmployeeRoles(getSuamRoles(employee, currentStoreId));
    }, [employeeAssignments, currentStoreId]);

    const handleSave = async () => {
      handleSubmit(true, 'editRole');
    };

    const getIsDisabled = isEditRoleCheckBoxDisabled(profile, currentStoreId, employeeRoles, roleMatrix);

    return (
      <StyledEditRoleView data-testid="edit-role-view">
        <StyledRoleContent>
          <Typography type="body3" padding={[0, 0, 8, 0]}>
            {t(contentHeader.key, contentHeader.defaultValue)}
          </Typography>
          <CheckboxesWrapper>
            {Object.keys(roleMatrix.box.roleManagement).map((role) => {
              // Can we get mapping direct from backend instead?
              const suamRole = Object.keys(RoleMapping)[Object.values(RoleMapping).indexOf(role)];
              const isCheckboxDisabled = getIsDisabled;
              return (
                <div key={role}>
                  <Checkbox
                    label={role}
                    labelPosition={isMobileView ? 'right' : 'top'}
                    isChecked={employeeRoles.includes(suamRole)}
                    isDisabled={isCheckboxDisabled}
                    labelColor={isCheckboxDisabled ? 'inActiveGray' : 'primary'}
                    onChange={(isChecked) =>
                      isChecked
                        ? setEmployeeRoles([...employeeRoles, suamRole])
                        : setEmployeeRoles(employeeRoles.filter((key) => key !== suamRole))
                    }
                    tooltip={{
                      text: (
                        <Typography type="legal" padding={[2]} color="white">
                          {isCheckboxDisabled
                            ? t(noPermissionsToEdit.key, noPermissionsToEdit.defaultValue)
                            : `${role}`}
                        </Typography>
                      ),
                      children: (
                        <Icon
                          data-testid="infoIcon"
                          icon={Icons.INFO}
                          size="small"
                          color={isCheckboxDisabled ? 'inActiveGray' : 'primary'}
                          margin={[0, 1, 0, 0]}
                        />
                      ),
                    }}
                    iconColor={employeeRoles.length === 0 ? 'red' : 'primary'}
                  />
                </div>
              );
            })}
          </CheckboxesWrapper>
        </StyledRoleContent>
        <StyledButtonWrapper>
          <StyledBackButton
            label={t(back.key, back.defaultValue)}
            variant="secondary"
            onClick={handleCancel}
            data-testid="back-button"
          />
          <StyledSaveButton
            label={t(saveSettings.key, saveSettings.defaultValue)}
            onClick={handleSave}
            data-testid="save-button"
          />

          {employeeRoles.length === 0 && (
            <StyledErrorTypography color="red" padding={[2, 0, 0, 0]} type="caption">
              {t(noRoleSelected.key, noRoleSelected.defaultValue)}
            </StyledErrorTypography>
          )}
        </StyledButtonWrapper>
      </StyledEditRoleView>
    );
  },
);
