import { TooltipProps as MuiToolTipProps } from '@mui/material';
import { FC, forwardRef, ReactElement } from 'react';
import { StyledTooltip } from './Tooltip.styles';

export type TooltipProps = {
  text: JSX.Element;
  children: ReactElement;
  isVisible?: boolean;
  arrowedToolTip?: boolean;
  position?: MuiToolTipProps['placement'];
};

export const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = forwardRef<JSX.Element, TooltipProps>(
  ({ children, isVisible = true, text, arrowedToolTip, position }, ref) => {
    if (!isVisible) return children;

    return (
      <StyledTooltip data-testid="tooltip" title={text} arrow={arrowedToolTip} placement={position} ref={ref}>
        {children}
      </StyledTooltip>
    );
  },
);
