import styled from 'styled-components';
import { Container } from '../../../stories/atoms';
import { cardShadow } from '../../../theme';

export const StyledContainer = styled(Container)`
  ${cardShadow()};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
`;

export const MapContainer = styled.div<{ isMobile?: boolean }>`
  height: 80%;
  > div {
    border-top-right-radius: ${({ theme: { spacing } }) => spacing(4)};
    border-top-left-radius: ${({ theme: { spacing } }) => spacing(4)};
  }
`;

export const StoreContainer = styled(Container)`
  border-bottom-right-radius: ${({ theme: { spacing } }) => spacing(4)};
  border-bottom-left-radius: ${({ theme: { spacing } }) => spacing(4)};
`;

// TODO: uncomment when integrating with BE in [BTCOBUI-2089]
// export const StoreChip = styled.div`
//   background-color: ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.white : color.primary)};
//   border-radius: ${({ theme: { spacing } }) => spacing(3)};
//   padding: ${({ theme: { spacing } }) => spacing(1, 2)};
//   cursor: pointer;
//   margin: ${({ theme: { spacing } }) => spacing(1, 1, 0, 0)};
//   > div {
//     color: ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.primary : color.white)};
//   }
// `;

export const StyledErrorContainer = styled.div`
  display: flex;
  align-items: center;
  ${cardShadow()};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  margin: ${({ theme: { spacing } }) => spacing(5)};
  justify-content: space-between;
`;
