import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile, SortDirection } from '../../../../global-state/types';
import { useUser } from '../../../../hooks';
import { Icon, Icons, Tooltip, Typography } from '../../../../stories/atoms';
import { Button, Row, Table } from '../../../../stories/molecules';
import { commonTranslations, employeesTranslations, profileTranslations } from '../../../../translations';
import { RoleMapping, SuamRole } from '../../../../utils';
import { StyledButton, StyledEmployeeTable, StyledText, StyledToolTip } from './EmployeeTable.styles';

export type ButtonType = 'details' | 'edit';
export type RoleData = { key: SuamRole | 'other'; employees: Profile[] };

const {
  employees: { view, edit, otherRoles, manageUser, manageUserToolTip, other, otherToolTip },
} = employeesTranslations;
const { fullName, name, upn: emailTranslation, details } = profileTranslations;
const { yes, no, you } = commonTranslations;

export const EmployeeTable: FC<
  React.PropsWithChildren<{
    tableData: RoleData;
    handleItemClick: (employee: Profile, buttonType: ButtonType) => void;
  }>
> = ({ tableData, handleItemClick }) => {
  const { t } = useTranslation();
  const {
    get: { profile, currentStoreId, userPermissions },
  } = useUser();

  const [rows, setRows] = useState<Row[]>([]);

  const canEditPermissions = userPermissions.employeePermissions.canUpdate;

  const columns = [
    { id: 'name', name: t(name.key, name.defaultValue), width: '20%', isSortable: true },
    { id: 'upn', name: t(emailTranslation.key, emailTranslation.defaultValue), width: '20%', isSortable: true },
    { id: 'otherRoles', name: t(otherRoles.key, otherRoles.defaultValue), width: '20%', isSortable: true },
    { id: 'details', name: t(details.key, details.defaultValue), width: '20%', isSortable: false },
    {
      id: 'manageUser',
      name: (
        <StyledToolTip>
          <Typography>{t(manageUser.key, manageUser.defaultValue)}</Typography>
          <Tooltip
            text={
              <Typography textAlign="center" color="white">
                {t(manageUserToolTip.key, manageUserToolTip.defaultValue)}
              </Typography>
            }
            position="bottom"
          >
            <Icon data-testid="infoIcon" icon={Icons.INFO} margin={[0, 2]} size="small" />
          </Tooltip>
        </StyledToolTip>
      ),
      width: '20%',
      isSortable: false,
    },
  ];

  const getAssignmentLength = (employee: Profile) => {
    return (
      employee.assignments
        ?.filter(({ locationCode }) => locationCode === currentStoreId)
        .map(({ suamRoleCode }) => suamRoleCode) || []
    ).length;
  };

  useEffect(() => {
    if (!tableData.employees.length) return;
    const sortedData = tableData.employees.sort(sortObjects('firstName', SortDirection.Descending));
    const updatedRows = getEmployeeRows(sortedData);
    setRows(updatedRows);
  }, [tableData.employees]);

  const getEmployeeRows = (employeeArray: Profile[]) => {
    return employeeArray.map((employee, index) => {
      const { firstName, lastName, upn, id } = employee;

      return {
        id: index,
        name:
          id === profile.id
            ? `${t(fullName.key, { defaultValue: fullName.defaultValue, firstName, lastName })} 
            (${t(you.key, you.defaultValue)})`
            : t(fullName.key, { defaultValue: fullName.defaultValue, firstName, lastName }),
        upn,
        otherRoles: getAssignmentLength(employee) > 1 ? t(yes.key, yes.defaultValue) : t(no.key, no.defaultValue),
        details: (
          <StyledButton
            label={t(view.key, view.defaultValue)}
            buttonContentProps={{ textOptions: { wrapText: true } }}
            onClick={() => handleItemClick(employee, 'details')}
            variant="text"
          />
        ),
        manageUser:
          id === profile.id || !canEditPermissions ? (
            ''
          ) : (
            <Button
              label={t(edit.key, edit.defaultValue)}
              onClick={() => handleItemClick(employee, 'edit')}
              variant="text"
              buttonContentProps={{ textOptions: { wrapText: true } }}
            />
          ),
      };
    });
  };

  const sortObjects = (property: keyof Profile, order: SortDirection) => {
    return (a: Profile, b: Profile) => {
      if (a !== undefined && b !== undefined && a[property] !== undefined && b[property] !== undefined) {
        if (property === 'assignments') {
          const lengthA = getAssignmentLength(a);
          const lengthB = getAssignmentLength(b);
          return order === 'asc' ? lengthA - lengthB : lengthB - lengthA;
        }
        const aValue = a[property] as string;
        const bValue = b[property] as string;
        return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      return 0;
    };
  };

  const sortData = (columnId: string, direction: SortDirection) => {
    // if Column id is name we take firstname as sorting property, similarly if it is other roles we take assignments
    const profileProperty =
      columnId === 'name' ? 'firstName' : columnId === 'otherRoles' ? 'assignments' : columnId;

    if (columnId) {
      const sortedData = tableData.employees.sort(sortObjects(profileProperty as keyof Profile, direction));
      const updatedRows = getEmployeeRows(sortedData);
      return updatedRows;
    }
    return rows;
  };

  return (
    <StyledEmployeeTable data-testid="employee-table">
      <StyledText>
        <Typography type="h4">
          {tableData.key === 'other'
            ? t(other.key, other.defaultValue)
            : t(`employees.roles.${RoleMapping[tableData.key]}`, RoleMapping[tableData.key])}
        </Typography>
        <Tooltip
          text={
            <Typography padding={[2]} color="white">
              {tableData.key === 'other'
                ? t(otherToolTip.key, otherToolTip.defaultValue)
                : t(`employees.roles.${RoleMapping[tableData.key]}`, RoleMapping[tableData.key])}
            </Typography>
          }
          position="bottom-start"
        >
          <Icon data-testid="infoIcon" icon={Icons.INFO} margin={[0, 2]} />
        </Tooltip>
      </StyledText>
      <Table
        useZebraStyles
        rows={rows}
        columns={columns}
        cellSize="small"
        handleSorting={(columnId, direction) => sortData(columnId, direction)}
      />
    </StyledEmployeeTable>
  );
};
