import { FC } from 'react';
import { Icons } from '../../../stories/atoms';
import { BackDropContainer, OverlayContainer, StyledWrapper, StyledIcon } from './ContainerDrawer.styles';

export type ContainerDrawerProps = {
  isOpen: boolean;
  onClose?: () => void;
  sliderContent: React.ReactNode;
  width: string;
};

export const ContainerDrawer: FC<React.PropsWithChildren<ContainerDrawerProps>> = ({
  isOpen,
  onClose,
  children,
  sliderContent,
  width,
}) => {
  return (
    <StyledWrapper data-testid="slider-wrapper" direction="horizontal" width="100%" wrap="nowrap" height="100%">
      {children}
      <BackDropContainer
        data-testid="backdrop-container"
        isSliderOpen={isOpen}
        onClick={() => onClose && onClose()}
        onKeyDown={() => onClose && onClose()}
        role="button"
        tabIndex={0}
      ></BackDropContainer>
      <OverlayContainer data-testid="slider-overlay" isSliderOpen={isOpen} width={width}>
        <StyledIcon
          size="small"
          data-testid="close-overlay-icon"
          icon={Icons.CLOSE}
          margin={[3]}
          onClick={() => onClose && onClose()}
        />
        {sliderContent}
      </OverlayContainer>
    </StyledWrapper>
  );
};
