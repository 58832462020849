import { ChipOwnProps, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerFeedback } from '../../../../../hooks';
import { Chip, Container, Icons, Typography } from '../../../../../stories/atoms';
import { IconWithText, Modal } from '../../../../../stories/molecules';
import { color, muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { getStarColor, ratingFilters } from '../../../../../utils/customerFeedback';
import { StyledChipGroup, StyledFeedbackText, StyledResponseHeader } from './ViewResponsesModal.styles';

export type ViewResponsesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  feedbacks: CustomerFeedback[];
};

export const ViewResponsesModal: FC<ViewResponsesModalProps> = ({ isOpen, onClose, feedbacks }) => {
  const [selectedFilter, setSelectedFilter] = useState<number | null>(null);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const { customerFeedback } = homeTranslations;

  // Reset selected filter when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(null);
    }
  }, [isOpen]);

  const filteredFeedback = selectedFilter
    ? feedbacks.filter((feedback) => feedback.rating === selectedFilter)
    : feedbacks;

  const handleChipClick = (filterId: number) => {
    setSelectedFilter((prev) => (prev === filterId ? null : filterId));
  };

  return (
    <Modal isOpen={isOpen} transitionType="slide" ariaDescribedby="view-responses-modal" handleClose={onClose}>
      <Container padding={isMobile ? [7] : [4, 7]} width={isMobile ? '100%' : '50vw'}>
        <Typography type="h4">
          {t(customerFeedback.modalHeaders.key, customerFeedback.modalHeaders.defaultValue)}
        </Typography>
        <Typography margin={[0, 0, 2, 0]}>
          {t(customerFeedback.modalSubHeaders.key, customerFeedback.modalSubHeaders.defaultValue)}
        </Typography>
        <StyledResponseHeader isMobile={isMobile}>
          <div>
            <Typography type="h4">{filteredFeedback.length}</Typography>
            <Typography color="textGray">
              {t(customerFeedback.totalResponse.key, customerFeedback.totalResponse.defaultValue)}
            </Typography>
          </div>
          <StyledChipGroup isMobile={isMobile}>
            <Typography>{t(customerFeedback.filterBy.key, customerFeedback.filterBy.defaultValue)} </Typography>
            <div>
              {ratingFilters.map((filter) => (
                <Chip
                  key={filter.id}
                  label={filter.label}
                  color={filter.color as ChipOwnProps['color']}
                  onClick={() => handleChipClick(filter.id)}
                  variant={selectedFilter === filter.id ? 'filled' : 'outlined'}
                />
              ))}
            </div>
          </StyledChipGroup>
        </StyledResponseHeader>

        <Container
          wrap="nowrap"
          margin={[4, 0]}
          padding={[2]}
          style={{
            border: `1px solid ${color.borderGray}`,
            borderRadius: '6px',
            maxHeight: !isMobile ? '300px' : 'unset',
            height: !isMobile ? '300px' : 'unset',
            overflowY: 'auto',
          }}
        >
          {filteredFeedback.map((feedback, index) => (
            <StyledFeedbackText key={index} data-testid="feedback-entries">
              <IconWithText
                iconOptions={{
                  icon: Icons.STAR_FILLED,
                  color: getStarColor(feedback.rating),
                  onClick: () => null,
                  size: 'small',
                }}
                padding={[2]}
                label={feedback.text}
              />
            </StyledFeedbackText>
          ))}
        </Container>
      </Container>
    </Modal>
  );
};
