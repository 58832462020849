import { CircularProgress, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  KpiAreaValueType,
  KpiDropdownActions,
  KpiType,
  UpdateKpiRequest,
  getKPIIcons,
  getUnit,
} from '../../../constants';
import { KpiAreaDetails, useStoreInformation, useUser } from '../../../hooks';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { muiTheme } from '../../../theme';
import { kpiTargetTransalations } from '../../../translations';
import { convertToKebabCase, formatAmountWithCurrency } from '../../../utils';
import KpiDropdown from '../kpiDropdown/KpiDropdown';
import {
  Divider,
  HeaderIcon,
  HeaderIconsContainer,
  HeaderStarIcon,
  HeaderStarIconContainer,
  KPIContainer,
  KPIContainerWrapper,
  StyledIconWithText,
  StyledKPIHeader,
  StyledKPIProperty,
  StyledKPIValue,
  StyledKPIValueContainer,
  StyledLockIconContainer,
  StyledTargetValue,
} from './KpiCard.styles';

type KpiCardHeaderProps = {
  kpiName: KpiType;
  isKpiHighlighted: boolean;
  kpiText: string;
  areaButton: JSX.Element;
};

const KpiCardHeader: FC<KpiCardHeaderProps> = ({ kpiName, isKpiHighlighted, kpiText, areaButton }) => (
  <div>
    <HeaderIconsContainer>
      <HeaderIcon icon={Icons[getKPIIcons(kpiName)]} size="small" margin={[0, 2, 0, 0]} />
      {isKpiHighlighted && (
        <HeaderStarIconContainer>
          <HeaderStarIcon icon={Icons.STAR_FILLED} size="small" data-testid="star-filled-icon" />
        </HeaderStarIconContainer>
      )}
    </HeaderIconsContainer>
    <Typography type="h4" data-testid="widget-title" overflow={'visible'} margin={[0, 2, 0, 0]}>
      {kpiText}
    </Typography>
    {areaButton}
  </div>
);

type KpiTooltipProps = {
  tooltipText: string;
};

// Get Tooltip for KPI Property
const KpiTooltip: FC<KpiTooltipProps> = ({ tooltipText }) => {
  return (
    <Tooltip
      position="bottom-start"
      arrowedToolTip
      text={
        <Container padding={[2]}>
          <Typography color="white">
            <Trans components={{ b: <b /> }}>{tooltipText}</Trans>
          </Typography>
        </Container>
      }
    >
      <Icon data-testid="info-icon" icon={Icons.INFO} size="small" margin={[0, 1]} />
    </Tooltip>
  );
};

export type KPICardProps = {
  kpiDetails: KpiAreaDetails;
  isKpiHighlighted: boolean;
  isTargetLocked: boolean;
  target: number | null;
  updateLockLoading: boolean;
  onAreaViewClick: (isOpen: boolean) => void;
  handleKpiLock: (request: UpdateKpiRequest[]) => void;
  handleSelectDropdown: (dropdownValue: KpiDropdownActions) => void;
};

const KpiCard: FC<KPICardProps> = ({
  kpiDetails,
  isKpiHighlighted,
  isTargetLocked,
  target,
  updateLockLoading,
  onAreaViewClick,
  handleKpiLock,
  handleSelectDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const {
    get: { userPermissions },
  } = useUser();
  const { kpiName, kpiValues: kpiAreaValues } = kpiDetails;
  const areaCap = kpiAreaValues.find((item) => item.kpiProperty === 'areaCap');
  const areaCapValue = areaCap ? areaCap?.kpiValue : null;

  const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const { kpiWidgets, notSet, kpiProperties, kpiLock, areaView } = kpiTargetTransalations;

  const { currencyCode: storeCurrency, areaCode } = useStoreInformation();

  const canEditKpi = userPermissions.kpiTargets.canUpdate;
  const canRemoveKpi = userPermissions.kpiTargets.canDelete;
  const canEditAreaCap = userPermissions.areaCaps.canUpdate;
  const canRemoveAreaCap = userPermissions.areaCaps.canDelete;
  const canHighlightKpi = userPermissions.highlightWidget.canUpdate;

  const canEditAny = canEditKpi || canEditAreaCap || canHighlightKpi || canRemoveKpi || canRemoveAreaCap;

  // Get formatted KPI Value
  const getKpiFormattedValue = (value: number, isAreaCap: boolean) => {
    const unit = getUnit(kpiName, storeCurrency);
    const newValue =
      unit === storeCurrency
        ? formatAmountWithCurrency(value, i18n.language, storeCurrency || '')
        : value.toLocaleString() + (unit ?? '');

    return isAreaCap ? `+${newValue}` : newValue;
  };

  const handleLock = () => {
    const request = [
      {
        kpiName,
        isLocked: !isTargetLocked,
      },
    ];
    handleKpiLock(request);
  };

  // Area View Button
  const areaViewButton = (
    <StyledIconWithText
      data-testid="area-view-button"
      label={t(areaView.key, areaView.defaultValue)}
      textOptions={{
        color: 'infoDark',
      }}
      iconOptions={{
        icon: Icons.ARROW,
        color: 'infoDark',
        size: 'small',
      }}
      onClick={() => onAreaViewClick(true)}
      backgroundColor="white"
      labelPosition="left"
    />
  );

  return (
    <KPIContainerWrapper key={kpiName} data-testid={`${convertToKebabCase(kpiName)}-card`}>
      <KPIContainer>
        <StyledKPIHeader isTabletView={isTablet} data-testid="kpi-content-header">
          <KpiCardHeader
            kpiName={kpiName}
            isKpiHighlighted={isKpiHighlighted}
            kpiText={t(kpiWidgets[kpiName].key, kpiWidgets[kpiName].defaultValue)}
            areaButton={areaViewButton}
          />
          {canEditAny && (
            <KpiDropdown
              isTargetLocked={isTargetLocked}
              target={target}
              areaCapValue={areaCapValue}
              isKpiHighlighted={isKpiHighlighted}
              sendKpiDropdownDetails={handleSelectDropdown}
            />
          )}
        </StyledKPIHeader>
        <StyledKPIValueContainer isTabletView={isTablet} data-testid="kpi-content-container">
          <StyledTargetValue isTabletView={isTablet} key={`${kpiName}-target-key`} data-testid="targetValue">
            <Container direction="horizontal" position="center">
              <Typography
                margin={[0, 1, 2, 0]}
                type={isTablet ? 'h4' : 'body2'}
                color={target ? 'primary' : 'textGray'}
                data-testid="widget-kpi-value"
              >
                {target ? getKpiFormattedValue(target, false) : t(notSet.key, notSet.defaultValue)}
              </Typography>
              <StyledLockIconContainer
                padding={[1, 2]}
                margin={[0, 0, 1, 1]}
                width="100px"
                position="center"
                backgroundColor="backgroundSelectedHover"
              >
                {updateLockLoading ? (
                  <CircularProgress data-testid="loading-overlay-loader" size={16} thickness={3} />
                ) : (
                  <IconWithText
                    data-testid="locked-container"
                    label={
                      isTargetLocked
                        ? t(kpiLock.locked.key, kpiLock.locked.defaultValue)
                        : t(kpiLock.unlocked.key, kpiLock.unlocked.defaultValue)
                    }
                    iconOptions={{
                      icon: isTargetLocked ? Icons.LOCK : Icons.UNLOCK,
                      size: 'small',
                    }}
                    onClick={handleLock}
                  />
                )}
              </StyledLockIconContainer>
            </Container>
            {!isTablet && (
              <StyledKPIProperty data-testid="widget-kpi-property">
                <Typography>
                  {t(kpiProperties.target.title.key, kpiProperties.target.title.defaultValue)}
                </Typography>
                <KpiTooltip
                  tooltipText={t(kpiProperties.target.tooltip.key, {
                    defaultValue: kpiProperties.target.tooltip.defaultValue,
                    areaName: areaCode,
                  })}
                />
              </StyledKPIProperty>
            )}
          </StyledTargetValue>

          {kpiAreaValues.map((value) => {
            const { kpiProperty, kpiValue } = value;
            return (
              <div key={`${kpiName}-${kpiProperty}-key`}>
                <StyledKPIValue isTabletView={isTablet}>
                  <Typography
                    margin={[0, 1, 2, 0]}
                    color={value.kpiValue ? 'primary' : 'textGray'}
                    data-testid="widget-kpi-value"
                  >
                    {kpiValue !== null
                      ? getKpiFormattedValue(kpiValue, kpiProperty === KpiAreaValueType.AREA_CAP)
                      : t(notSet.key, notSet.defaultValue)}
                  </Typography>
                  <StyledKPIProperty data-testid="widget-kpi-property">
                    <Typography>
                      {t(kpiProperties[kpiProperty].title.key, kpiProperties[kpiProperty].title.defaultValue)}
                    </Typography>
                    <KpiTooltip
                      tooltipText={t(kpiProperties[kpiProperty].tooltip.key, {
                        defaultValue: kpiProperties[kpiProperty].tooltip.defaultValue,
                        areaName: areaCode,
                      })}
                    />
                  </StyledKPIProperty>
                </StyledKPIValue>
                {isTablet && kpiProperty !== KpiAreaValueType.AREA_CAP && <Divider />}
              </div>
            );
          })}
        </StyledKPIValueContainer>
      </KPIContainer>
    </KPIContainerWrapper>
  );
};

export default KpiCard;
