import styled from 'styled-components';
import { Sizes } from '../../../../theme';

export const StyledContainer = styled.div<{ isCompact: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isCompact }) => (isCompact ? 'center' : 'space-between')};
  text-align: center;
  height: 100%;
`;

export const StyledHeaderContainer = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
  width: 100%;
`;

export const StyledAvatarImage = styled.img<{ avatarSize: Sizes }>`
  height: ${({
    theme: {
      sizing: { avatarSize },
    },
    avatarSize: size,
  }) => avatarSize[size]};
  width: ${({
    theme: {
      sizing: { avatarSize },
    },
    avatarSize: size,
  }) => avatarSize[size]};
  margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
`;

export const NoAvatarIcon = styled(StyledAvatarImage)`
  background-color: ${({ theme }) => theme.color.borderGray};
  border-radius: 50%;
`;
