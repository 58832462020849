import styled from 'styled-components';
import { Typography } from '../../../stories/atoms';

export const NavigationWrapper = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: space-between;
`;

export const NavigationIcons = styled.div`
  display: flex;
`;

export const ReceiptWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  border: 1px solid ${({ theme: { color } }) => color.borderGray};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundDark : color.white};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2, 2, 0, 2)};
  width: ${({
    theme: {
      sizing: { receiptWidth },
      breakpoints,
    },
  }) => (breakpoints.down('tabletLandscape') ? `auto` : receiptWidth)};
`;

export const ReceiptImageWrapper = styled.div`
  border: 1px solid ${({ theme: { color } }) => color.borderGray};
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  overflow-y: auto;
`;

export const NoReceiptData = styled(ReceiptImageWrapper)`
  padding: ${({ theme }) => theme.spacing(0, 6)};
`;

export const SkeletonImageWrapper = styled.div`
  border: 1px solid ${({ theme: { color } }) => color.borderGray};
  padding: ${({ theme }) => theme.spacing(1)};
  height: 100%;
  overflow-y: auto;
`;

export const ReceiptDashLine = styled.div<{ paddingRight: string }>`
  height: ${({ theme }) => theme.spacing(5)};
  overflow: hidden;
  padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
`;

export const ReceiptItemContainer = styled.div<{ paddingLeft: string; paddingRight: string }>`
  display: flex;
  padding: 2px 0;
  white-space: pre-wrap;
  padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
`;

export const ReceiptItem = styled(Typography)<{ flexBasis: string; textAlign: string }>`
  flex-basis: ${({ flexBasis }) => flexBasis && flexBasis};
  text-align: ${({ textAlign }) => textAlign && textAlign};
`;
