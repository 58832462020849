export const widgetAccessControlTranslations = {
  title: {
    key: 'widgetAccessControlTranslations.title',
    defaultValue: 'Widget Access Control',
  },
  subTitle: {
    key: 'widgetAccessControlTranslations.subTitle',
    defaultValue: 'Select Widgets to show for stores in area',
  },
  loading: {
    title: {
      key: 'widgetAccessControlTranslations.loading.title',
      defaultValue: 'Fetching Settings!!!',
    },
  },
  error: {
    title: {
      key: 'widgetAccessControlTranslations.error.title',
      defaultValue: 'Something went wrong!',
    },
    subTitle: {
      key: 'widgetAccessControlTranslations.error.subTitle',
      defaultValue: 'There was an error while fetching the widget settings. Please try again!',
    },
  },
  permissionLoadError: {
    title: {
      key: 'widgetAccessControlTranslations.permissionLoadError.title',
      defaultValue: 'Something went wrong!',
    },
    subTitle: {
      key: 'widgetAccessControlTranslations.permissionLoadError.subTitle',
      defaultValue: 'There was a problem loading the updated settings.',
    },
  },
  restoreButtonText: {
    key: 'widgetAccessControlTranslations.restoreButtonText',
    defaultValue: 'Restore Settings',
  },
  saveButtonText: {
    key: 'widgetAccessControlTranslations.saveButtonText',
    defaultValue: 'Save Settings',
  },
  updateSuccessMessage: {
    key: 'widgetAccessControlTranslations.updateSuccessMessage',
    defaultValue: 'Widget settings have been successfully updated!',
  },
  updateErrorMessage: {
    key: 'widgetAccessControlTranslations.updateErrorMessage',
    defaultValue: 'Unable to modify the settings. Please try again!',
  },
  switchEnabled: {
    key: 'widgetAccessControlTranslations.switchEnabled',
    defaultValue: 'Enabled',
  },
  switchDisabled: {
    key: 'widgetAccessControlTranslations.switchDisabled',
    defaultValue: 'Disabled',
  },
};
