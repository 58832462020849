// Below are existing SUAM roles
export type SuamRole = 'SV' | 'SM' | 'EV' | 'SC' | 'FM';

export const RoleMapping: { [key in SuamRole]: string } = {
  SC: 'storeController',
  SM: 'storeManager',
  SV: 'storeVisual',
  FM: 'departmentManager',
  EV: 'storeEmployee',
};
export const isSupportedSuamRole = (suamRoleCode: string): suamRoleCode is SuamRole => {
  return suamRoleCode in RoleMapping;
};

export const isAreaManager = (jobRole: string | undefined) => {
  return jobRole === 'JRO11';
};

export const isCountryController = (jobRole: string | undefined) => {
  return jobRole === 'JRO79' || jobRole === 'JRO52';
};

export enum StoreRoles {
  AREA_MANAGER = 'areaManager',
  AREA_VISUAL = 'areaVisual',
  AREA_CONTROLLER = 'areaController',
  AREA_HR = 'areaHR',
  COUNTRY_CONTROLLER = 'countryController',
  SALES_MARKET_MANAGER = 'salesMarketManager',
  SALES_MARKET_CONTROLLER = 'salesMarketController',
  SALES_MARKET_HR = 'salesMarketHR',
  SALES_MARKET_VISUAL = 'salesMarketVisual',
  SALES_MARKET_OPERATION = 'salesMarketOperation',
  REGIONAL_CONTROLLER = 'regionalController',
  REGIONAL_MANAGER = 'regionalManager',
  REGIONAL_VISUAL = 'regionalVisual',
  REGIONAL_HR = 'regionalHR',
  REGIONAL_STORE_OPERATION = 'regionalStoreOperation',
  REGIONAL_OTHER = 'regionalOther',
  STORE_CONTROLLER = 'storeController',
  STORE_MANAGER = 'storeManager',
  STORE_VISUAL = 'storeVisual',
  DEPARTMENT_MANAGER = 'departmentManager',
  OTHER = 'other',
}
