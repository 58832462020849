import { ChipOwnProps, Chip as MuiChip } from '@mui/material';
import React, { FC } from 'react';

export type ChipProps = {
  label: React.ReactNode;
  color: ChipOwnProps['color'];
  variant?: 'filled' | 'outlined';
  onClick?: () => void;
  onDelete?: () => void;
  customDeleteIcon?: React.ReactElement;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

export const Chip: FC<ChipProps> = ({
  label,
  color,
  variant = 'outlined',
  onClick,
  onDelete,
  customDeleteIcon,
  disabled = false,
  size = 'medium',
}) => {
  return (
    <MuiChip
      data-testid="chip"
      label={label}
      color={color}
      variant={variant}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={customDeleteIcon}
      disabled={disabled}
      size={size}
    />
  );
};
