import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Property } from 'csstype';
import styled from 'styled-components';
import { Colors } from '../../../theme';
import { Typography } from '../../atoms';
import { Button } from '../button/Button';

export const StyledButton = styled(Button)`
  max-height: ${({ theme }) => theme.spacing(8)};
  > div {
    max-width: 100%;
  }
`;

export const StyledMenu = styled(Menu)<{
  $shouldIndentChildren: boolean;
  $backgroundColor?: { hover: Colors; selected: Colors; selectedHover?: Colors };
  $maxHeight?: Property.MaxHeight;
  $maxWidth?: Property.MaxWidth;
  $minWidth?: Property.MinWidth;
  $isCompact?: boolean;
  $allowTooltip?: boolean;
}>`
  .MuiPaper-root {
    min-width: ${({ $minWidth }) => $minWidth && $minWidth};
    max-height: ${({ $maxHeight }) => $maxHeight && $maxHeight};
    max-width: ${({ $maxWidth }) => $maxWidth && $maxWidth};

    .MuiMenu-list {
      padding: ${({ theme }) => theme.spacing(0)};
      &:first-child {
        margin-top: ${({ theme, $isCompact }) => !$isCompact && theme.spacing(2)};
      }
      &:last-child {
        margin-bottom: ${({ theme, $isCompact }) => !$isCompact && theme.spacing(2)};
      }
    }
    .MuiMenuItem-root {
      padding: ${({ theme }) => theme.spacing(0, 4)};
      &:hover {
        background-color: ${({ theme: { palette, color } }) =>
          palette.mode === 'dark' ? color.backgroundSelectedDark : color.hoverGray};
      }

      &:not(:first-child) {
        padding: ${({ theme, $shouldIndentChildren }) => $shouldIndentChildren && theme.spacing(0, 4, 0, 8)};
      }
    }
    .Mui-selected {
      background-color: ${({ theme: { palette, color } }) =>
        palette.mode === 'dark' ? color.backgroundSelectedDark : color.hoverGray};
    }
    .Mui-disabled {
      pointer-events: ${({ $allowTooltip }) => $allowTooltip && 'auto'};
    }
  }
`;

export const TitleWrapper = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const StyledMenuItem = styled(MenuItem)<{
  $backgroundColor?: Colors;
}>`
  background-color: ${({ theme: { color }, $backgroundColor }) => $backgroundColor && color[$backgroundColor]};
`;
