export const mockAvatars = `<?xml version="1.0" encoding="utf-8"?>
<EnumerationResults ServiceEndpoint="https://boxersaccount.blob.core.windows.net/" ContainerName="avatars">
    <Blobs>
        <Blob>
            <Name>animals/1155444_bear_enjoy_face_happy_icon.svg</Name>
            <VersionId>2022-06-16T12:27:43.5630593Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939CCA3401</Etag>
                <Content-Length>5356</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>PV7ZDT2qr8XT2Ms50w5Zeg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1623953_christmas_deer_horn_moose_reindeer_icon.svg</Name>
            <VersionId>2022-06-16T12:27:42.7795074Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C527D7C</Etag>
                <Content-Length>4138</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>dC8011cZvwvaukbVIJxPZA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875556_orange_sit_pet_feline_ginger_cat_animal.svg</Name>
            <VersionId>2022-06-16T12:27:42.7475252Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C4DC334</Etag>
                <Content-Length>6420</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>gB5hYuMqQUv8ugfYn50W9Q==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875557_cat_cute_lie_pet_feline_kitty_animal.svg</Name>
            <VersionId>2022-06-16T12:27:42.7535214Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C4EAD6E</Etag>
                <Content-Length>5069</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>UDmvJOacb7qNFSLhQTJ57A==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875558_stretch_walk_feline_pet_happy_cat_animal.svg</Name>
            <VersionId>2022-06-16T12:27:42.7925002Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C54A00A</Etag>
                <Content-Length>6876</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>FU1mKVfMMi/v+hkVDBtQSw==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875559_play_walk_animal_trick_cat_feline_pet.svg</Name>
            <VersionId>2022-06-16T12:27:42.9144299Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C6713EB</Etag>
                <Content-Length>5801</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>7m89funPXWc2S7ti8e6EJQ==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875560_belly_cat_feline_pet_cute_play_animal.svg</Name>
            <VersionId>2022-06-16T12:27:42.9374168Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C6ABCD8</Etag>
                <Content-Length>5955</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>0I/3Ovnsinaz8yps1lrX+A==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875561_animal_feline_leg_pet_clean_back_cat.svg</Name>
            <VersionId>2022-06-16T12:27:42.9124317Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C66C5C9</Etag>
                <Content-Length>6496</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>SmLhGULEdy0nveMB6FUCxA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875562_back_cat_animal_lie_pet_feline_rest.svg</Name>
            <VersionId>2022-06-16T12:27:42.9274225Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C690F69</Etag>
                <Content-Length>5512</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>GqgHEZdYHVCvacjqOT1kFw==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875563_cat_nap_sleep_animal_pet_feline_rest.svg</Name>
            <VersionId>2022-06-16T12:27:42.9693989Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C6F9E25</Etag>
                <Content-Length>5338</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>i8HOKlQfIlY7BYDFl0xmIQ==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875564_ball_thread_toy_yarn.svg</Name>
            <VersionId>2022-06-16T12:27:42.9853889Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:42 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:42 GMT</Last-Modified>
                <Etag>0x8DA4F939C720EC1</Etag>
                <Content-Length>6664</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>1zQi4QrWVNMOmvqnC2e04w==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875565_happy_animal_cat_content_feline_pet_purr.svg</Name>
            <VersionId>2022-06-16T12:27:43.0313633Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C78EB8E</Etag>
                <Content-Length>5083</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>ESfFfJYjNJ5JBtQbS4vDZA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875566_animal_cat_cute_feline_pet_icon.svg</Name>
            <VersionId>2022-06-16T12:27:43.0413570Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C7A71F6</Etag>
                <Content-Length>5815</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>A38Y2OVigaWFeFZOeHXE3w==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875566_cute_play_pet_feline_cat_animal_smile.svg</Name>
            <VersionId>2022-06-16T12:27:43.0363608Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C79AEDA</Etag>
                <Content-Length>5815</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>A38Y2OVigaWFeFZOeHXE3w==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875567_cute_walk_animal_stand_cat_feline_pet.svg</Name>
            <VersionId>2022-06-16T12:27:43.0443550Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C7AE719</Etag>
                <Content-Length>5504</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>swo9uhr0ePCtqNaJTkMHRA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875568_stretch_animal_yawn_cat_feline_pet_lazy.svg</Name>
            <VersionId>2022-06-16T12:27:43.0493530Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C7BAA47</Etag>
                <Content-Length>6966</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>Xv9Y97J/nz0NZgR3Oy2C/Q==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875569_animal_sleep_nap_cat_rest_feline_pet.svg</Name>
            <VersionId>2022-06-16T12:27:43.0473535Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C7B833F</Etag>
                <Content-Length>7054</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>yGy6kVIroDgvnSPoA9B/rw==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>animals/1875570_pet_hunt_cat_animal_catch_mouse_feline.svg</Name>
            <VersionId>2022-06-16T12:27:43.0683416Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:27:43 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:27:43 GMT</Last-Modified>
                <Etag>0x8DA4F939C7EB718</Etag>
                <Content-Length>6783</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>WgEpf3RZUoFnY7zvSUzdDg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344891_templar_fantasy_hammer_crusader_knight_rpg_paladin.svg</Name>
            <VersionId>2022-06-16T12:28:15.7496246Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFF97BB6</Etag>
                <Content-Length>9805</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>N9aVyw3EeMxN4YiIbVDUlg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344892_necromancer_fantasy_wizard_skull_game_rpg_magic.svg</Name>
            <VersionId>2022-06-16T12:28:15.7886011Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFF6E3B</Etag>
                <Content-Length>13490</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>wxsupkDBtkw3OWQX1i+E5w==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344893_fantasy_archer_rpg_arrow_ranger_hunter.svg</Name>
            <VersionId>2022-06-16T12:28:15.7566205Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFA8CFD</Etag>
                <Content-Length>12821</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>tUYbiapFSLgd6AtJfkbHbg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344894_magic_spell_fantasy_crack_disaster_earthquake_game.svg</Name>
            <VersionId>2022-06-16T12:28:15.7476249Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFF9068D</Etag>
                <Content-Length>11023</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>q+yLtau3W87W5TbITIxF1A==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344895_wind_fantasy_game_storm_spell_magic.svg</Name>
            <VersionId>2022-06-16T12:28:15.7646158Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFBC54E</Etag>
                <Content-Length>9550</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>JBphfOUAKMj2UpJ0SvxWLA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344896_spell_magic_fantasy_vine_thorn_game_plant.svg</Name>
            <VersionId>2022-06-16T12:28:15.7816052Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFE5CF4</Etag>
                <Content-Length>10069</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>Q2lrXX+2DqP4LVC+Pojh0w==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344897_game_fantasy_lightning_shock_spell_magic.svg</Name>
            <VersionId>2022-06-16T12:28:15.7866031Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFF202F</Etag>
                <Content-Length>10377</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>VMf/KHnVH5qCdCqMQWDPeA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344898_magic_fantasy_ball_game_lightning_spell.svg</Name>
            <VersionId>2022-06-16T12:28:15.7806061Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFE35ED</Etag>
                <Content-Length>14571</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>u36ExYRqMIeJeitDNA2h/Q==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344899_holy_fantasy_game_light_spell_magic.svg</Name>
            <VersionId>2022-06-16T12:28:15.7886011Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93AFFF6E3B</Etag>
                <Content-Length>9729</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>52umCvarOYcb4t0xdCHDow==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344900_crystals_fantasy_ice_game_spell_magic.svg</Name>
            <VersionId>2022-06-16T12:28:15.8545626Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93B0097EDA</Etag>
                <Content-Length>4341</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>Ms3mpzrfacUokWDf9UanoA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344901_spell_fantasy_flame_game_fire_wall_magic.svg</Name>
            <VersionId>2022-06-16T12:28:15.8045924Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93B001B7D6</Etag>
                <Content-Length>11977</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>xpNWII+3AP8EcNHsLHHcSg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>fantasy/6344902_game_sea_captain_corsair_pirate_rpg.svg</Name>
            <VersionId>2022-06-16T12:28:15.8155873Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:15 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:15 GMT</Last-Modified>
                <Etag>0x8DA4F93B0038C61</Etag>
                <Content-Length>8942</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>1kiQPcdeLxsMC6DHAaHlRg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>nature/1856075_coconut_fruit_healthy_dessert_food_organic_fruits.svg</Name>
            <VersionId>2022-06-16T12:28:49.3273918Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:49 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:49 GMT</Last-Modified>
                <Etag>0x8DA4F93C3FD0B3E</Etag>
                <Content-Length>2737</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>rxzqpnW5jtN3e9yS712WVQ==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>nature/1856076_organic_grape_fruits_fruit_healthy_dessert_food.svg</Name>
            <VersionId>2022-06-16T12:28:49.3303915Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:49 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:49 GMT</Last-Modified>
                <Etag>0x8DA4F93C3FD806B</Etag>
                <Content-Length>5251</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>UeVYKmQI/T3Rh6w/lgdWpw==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>nature/1856077_food_watermelon_organic_dessert_healthy_fruit_fruits.svg</Name>
            <VersionId>2022-06-16T12:28:49.3573756Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:49 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:49 GMT</Last-Modified>
                <Etag>0x8DA4F93C4019E7C</Etag>
                <Content-Length>2883</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>xlOIYQTzVPSQmCwC1wHJiA==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>nature/1856078_dragon_fresh_fruits_healthy_dessert_food_organic_fruit.svg</Name>
            <VersionId>2022-06-16T12:28:49.3633719Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:49 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:49 GMT</Last-Modified>
                <Etag>0x8DA4F93C40288B7</Etag>
                <Content-Length>10109</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>JK6iTgj4RrCndLSabJN95w==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
        <Blob>
            <Name>nature/1856079_healthy_blackberries_organic_food_dessert_fruit_fruits.svg</Name>
            <VersionId>2022-06-16T12:28:49.3883579Z</VersionId>
            <IsCurrentVersion>true</IsCurrentVersion>
            <Properties>
                <Creation-Time>Thu, 16 Jun 2022 12:28:49 GMT</Creation-Time>
                <Last-Modified>Thu, 16 Jun 2022 12:28:49 GMT</Last-Modified>
                <Etag>0x8DA4F93C40658BB</Etag>
                <Content-Length>6203</Content-Length>
                <Content-Type>image/svg+xml</Content-Type>
                <Content-Encoding />
                <Content-Language />
                <Content-CRC64 />
                <Content-MD5>x5s8alj07e9J9/A/iWbVRg==</Content-MD5>
                <Cache-Control />
                <Content-Disposition />
                <BlobType>BlockBlob</BlobType>
                <AccessTier>Hot</AccessTier>
                <AccessTierInferred>true</AccessTierInferred>
                <LeaseStatus>unlocked</LeaseStatus>
                <LeaseState>available</LeaseState>
                <ServerEncrypted>true</ServerEncrypted>
            </Properties>
            <OrMetadata />
        </Blob>
    </Blobs>
    <NextMarker />
</EnumerationResults>`;
