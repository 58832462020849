export const supportedLanguages = [
  'en-US', // English - US
  'en-GB', // English - UK
  'da-DK', // Danish
  'de-DE', // German
  'sv-SE', // Swedish
  'es-ES', // Spanish - Spain
  'it-IT', // Italian
  'sl-SI', // Slovenian
  'fr-FR', // French
  'nl-NL', // Dutch - Netherlands
  'is-IS', // Icelandic
  'fi-FI', // Finnish
  'pt-PT', // Portuguese - Portugal
  'pl-PL', // Polish
  'kk-KZ', // Kazakh
  'hu-HU', // Hungarian
  'sk-SK', // Slovak
  'ro-RO', // Romanian
  'et-EE', // Estonian
  'lt-LT', // Lithuanian
  'lv-LV', // Latvian
  'ko-KR', // Korean
  'ja-JP', // Japanese
  'el-GR', // Greek
  'bg-BG', // Bulgarian
  'th-TH', // Thai,

  'zh-CN', // Chinese (simplified)
  'zh-HK', // Chinese (traditional)
  'vi-VN', // Vietnamese
  // 'tl-PH', // Filipino - NOT WORKING
  'ms-MY', // Malay
  'af-ZA', // Afrikaans
  'uk-UA', // Ukranian,
  'sq-AL', // Albanian
  'hr-HR', // Croatian
  'bs-BA', // Bosnian
  'mk-MK', // Macedonian
  'ka-GE', // Georgian
  'sr-RS', // Serbian
  'ar', // Arabic
  'tr-TR', // Turkish
  'hi-IN', // Hindi
  'nb-NO', // Norwegian
];
