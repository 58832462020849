import { Typography } from '../../../../../../stories/atoms';
import { Charts } from '../../../../../../stories/molecules/charts/Charts';
import { Colors } from '../../../../../../theme';
import { Container, LabelContainer } from './DifferenceChart.styles';

type DifferenceChartProps = {
  percentValue: number;
  color: Colors;
  isMobile: boolean;
};

export const DifferenceChart = ({ percentValue, color, isMobile }: DifferenceChartProps) => {
  return (
    <Container>
      <Charts
        chartType="donut"
        chartSeries={[percentValue, 100 - percentValue]}
        xAxisCategories={['', '']}
        areaChartGradient={{
          type: ['solid'],
        }}
        chartColors={[color, 'backgroundSelectedHover']}
        chartPlotOptions={{
          customScale: isMobile ? 1.2 : 1.4,
          offsetY: 15,
          donut: {
            size: '80%',
            labels: {
              show: false,
            },
          },
        }}
        legend={{
          show: false,
        }}
        strokeWidth={[0]}
      />

      <LabelContainer>
        <Typography type={isMobile ? 'h3' : 'button'}>{percentValue}%</Typography>
      </LabelContainer>
    </Container>
  );
};
