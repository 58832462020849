import styled from 'styled-components';

export const StyledView = styled.div<{ isMobile: boolean }>`
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
  height: 100%;
  padding: ${({ isMobile, theme: { spacing } }) => (isMobile ? spacing(1, 0, 0) : spacing(2, 4))};
  display: flex;
  flex-direction: column;
`;

export const StyledRightContainerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  align-items: ${({ isMobile }) => isMobile && 'flex-end'};
`;

export const StyledTrendContainer = styled(StyledRightContainerWrapper)<{ isLeftElement?: boolean }>`
  margin: ${({ theme: { spacing }, isLeftElement }) => isLeftElement && spacing(0, 2, 0, 0)};
  padding: ${({ theme: { spacing } }) => spacing(1, 0)};
  justify-content: center;
  align-items: center;
`;
