import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { transactionSearchTranslations } from '../../../../../translations';
import { Icon, Icons, Typography } from '../../../../atoms';
import { StyledCountIconWrapper, StyledMenuItem } from './FilterSpecItem.styles';

type Props = {
  label: string;
  isSelected: boolean;
  count?: number;
  onClick?: () => void;
};

export const FilterSpecItem: FC<Props> = ({ label, isSelected, count, onClick }) => {
  const { t } = useTranslation();

  const { selected } = transactionSearchTranslations;
  return (
    <StyledMenuItem onClick={onClick}>
      <Typography color={isSelected ? 'red' : undefined}>{label}</Typography>
      <StyledCountIconWrapper>
        {count && (
          <Typography padding={[0, 3, 0, 0]}>
            {count} {t(selected.key, selected.defaultValue)}
          </Typography>
        )}
        <Icon data-testid="arrow" icon={Icons.ARROW} />
      </StyledCountIconWrapper>
    </StyledMenuItem>
  );
};
