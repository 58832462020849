import { BudgetPlannedData } from '../hooks';

export const computeBudgetPlannedData = (
  today: number | null | undefined,
  budgetPlannedData: BudgetPlannedData | null,
) => {
  const todayValue = today ? today : 0;
  const budgetValue = budgetPlannedData ? budgetPlannedData.budget : 0;
  const plannedValue = budgetPlannedData ? budgetPlannedData.planned : 0;

  const budgetCalc = budgetValue === 0 ? 0 : Math.abs(100 - (todayValue / budgetValue) * 100);
  const plannedCalc = plannedValue === 0 ? 0 : Math.abs(100 - (todayValue / plannedValue) * 100);

  return { todayValue, budgetValue, plannedValue, budgetCalc, plannedCalc };
};
