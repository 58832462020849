import { getStoreOperationHours } from '../utils';
import { useUser } from './useController';

export const useStoreOpeningHours = () => {
  const {
    get: { currentStoreId, stores },
  } = useUser();

  const getStoreOpeningHours = (currentDate: Date) => {
    const getStoreData = stores.find((store) => store.storeId === currentStoreId);

    const { openingHour, closingHour } = getStoreOperationHours(currentDate, getStoreData?.openingHours);

    return { storeOpeningHour: openingHour, storeClosingHour: closingHour };
  };

  return { getStoreOpeningHours };
};
