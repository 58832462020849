import { observable } from 'mobx';
import { Profile, RoleMatrix, Store, Message, StoreDetails } from '../types';
import { KpiType } from '../../constants';
import { UserPermissions } from '../../utils';
import { KpiTargetsProps } from '../../hooks';

export const userStore = observable({
  keyToken: '',
  storesDetails: [] as StoreDetails[],
  stores: [] as Store[],
  currentStoreId: '',
  previousStoreId: '',
  userId: undefined as string | undefined,
  profile: {} as Profile,
  employees: [] as Profile[],
  roleMatrix: {} as RoleMatrix,
  messages: [] as Message[],
  hasCompletedNewOnboardingItem: false,
  highlightedKpiTargets: [] as KpiType[],
  userPermissions: {} as UserPermissions,
  kpiTargetsProps: null as KpiTargetsProps | null,
});

export type UserStore = typeof userStore;
