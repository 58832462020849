import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Icons, Typography } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { commonTranslations, transactionSearchTranslations } from '../../../translations';
import { StyledButton } from '../ProfitAndLoss.styles';

type PLFilterProps = {
  selectedMonths: string[];
  monthMap: Record<string, string>;
  onClose: (month: string) => void;
  onReset: () => void;
};

const PLFilter: FC<PLFilterProps> = ({ selectedMonths, monthMap, onClose, onReset }) => {
  const { t } = useTranslation();
  const { selectedFilters: selectedFilterTranslation } = commonTranslations;
  const { clearFilter } = transactionSearchTranslations;

  const selectedMonthNames = selectedMonths.map((month) => monthMap[month]);

  const handleClose = (month: string) => {
    const monthKey = Object.keys(monthMap).find((key) => monthMap[key] === month);
    if (monthKey) onClose(monthKey);
  };

  return (
    <Container wrap="nowrap" data-testid="profit-loss-filters-wrapper">
      <Container direction="horizontal" space="between">
        <Typography>{t(selectedFilterTranslation.key, selectedFilterTranslation.defaultValue)}</Typography>
        <IconWithText
          data-testid="clear-all-button"
          label={t(clearFilter.key, clearFilter.defaultValue)}
          iconOptions={{
            icon: Icons.TRASHBIN,
            size: 'small',
          }}
          textOptions={{
            type: 'button',
          }}
          labelPosition="left"
          onClick={onReset}
          padding={[0, 0, 0, 2]}
        />
      </Container>
      <Container direction="horizontal">
        {selectedMonthNames.map((month) => (
          <StyledButton
            data-testid="selected-month"
            key={month}
            label={month}
            padding={[1, 3]}
            iconOptions={{
              icon: Icons.CLOSE,
              size: 'small',
              margin: [0, 2],
            }}
            textOptions={{
              type: 'button',
              color: 'white',
            }}
            labelPosition="left"
            margin={[1, 2, 1, 0]}
            onClick={() => handleClose(month)}
          />
        ))}
      </Container>
    </Container>
  );
};

export default PLFilter;
