import styled from 'styled-components';
import { Container, Icon, Typography } from '../../../stories/atoms';
import { cardShadow } from '../../../theme';

export const StyledContainer = styled(Container)`
  padding: ${({ theme: { spacing } }) => spacing(8)};
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
`;

export const StyledHeader = styled.div`
  display: flex;
  margin-bottom: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledTypography = styled(Typography)`
  display: contents;
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  overflow: scroll;
  ${cardShadow()};
`;
