import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getWeek, format } from 'date-fns';
import { homeTranslations, commonTranslations } from '../../../translations';
import { useUser } from '../../../hooks';
import { Typography } from '../../../stories/atoms';
import { Animation, Animations } from '../../../stories/molecules';
import { StoreOpeningHours, OpeningHourExceptions, DefaultOpeningHours, Days } from '../../../global-state/types';
import { StyledOpeningHoursContainer, StyledOpeningHoursWrapper } from './StoreOpeningHoursWidget.styles';
export type StoreOpeningHoursWidgetProps = {
  isError?: boolean;
};

export const StoreOpeningHoursWidget: FC<StoreOpeningHoursWidgetProps> = ({ isError }) => {
  const [isOpeningHours, setIsOpeningHours] = useState<StoreOpeningHours[]>();

  const { t } = useTranslation();
  const { slider } = homeTranslations;
  const numOfWeek = getWeek(new Date());

  const {
    get: { currentStoreId, stores },
  } = useUser();

  useEffect(() => {
    const getStoreData = stores.find((store) => store.storeId === currentStoreId);
    if (getStoreData) {
      const storeDefaultOpeningHours: DefaultOpeningHours = JSON.parse(JSON.stringify(getStoreData.openingHours));
      const storeOpeningHoursExceptions: OpeningHourExceptions = JSON.parse(
        JSON.stringify(getStoreData.openingHourExceptions),
      );

      const currentDay = new Date();
      const weekArray = [];
      for (let i = 1; i <= 7; i++) {
        const firstDay = currentDay.getDate() - currentDay.getDay() + i;
        const dayOfWeek = new Date(currentDay.setDate(firstDay)).toISOString().slice(0, 10);

        weekArray.push({
          weekday: format(new Date(dayOfWeek), 'eee').toLocaleLowerCase(),
          date: dayOfWeek,
        });
      }
      const weekDataArray = weekArray.map((eachDay) => {
        const dataObject = {
          day: eachDay.weekday,
          exceptions: storeOpeningHoursExceptions?.item?.find((item) => item.date === eachDay.date),
          defaultOpeningHours: storeDefaultOpeningHours[eachDay.weekday],
        };
        return dataObject;
      });
      setIsOpeningHours(weekDataArray);
    }
  }, []);

  const ErrorAnimation = () => {
    return (
      <StyledOpeningHoursContainer data-testid="error-opening-hours-widget">
        <Animation title="" animation={Animations.SAD} size="small" />
      </StyledOpeningHoursContainer>
    );
  };
  if (isError) return <ErrorAnimation />;

  return (
    <StyledOpeningHoursContainer data-testid="opening-hours-widget">
      <Typography type="button">
        {t(slider.storeOpeningHours.key, slider.storeOpeningHours.defaultValue)}, W {numOfWeek}
      </Typography>
      {isOpeningHours?.map((openingHours) => (
        <StyledOpeningHoursWrapper key={openingHours.day}>
          {openingHours.exceptions && (
            <>
              <Typography type="body3" color="red">
                {t(
                  commonTranslations[openingHours.day as Days].key,
                  commonTranslations[openingHours.day as Days].defaultValue,
                )}
              </Typography>
              <Typography type="body3" color="red">
                {openingHours.exceptions.isClosedAllDay
                  ? `${t(commonTranslations.close.key, commonTranslations.close.defaultValue).toUpperCase()}`
                  : `${openingHours.exceptions.openingHours.opens} - ${openingHours.exceptions.openingHours.closes}`}
              </Typography>
            </>
          )}
          {!openingHours.exceptions && (
            <>
              <Typography type="body3">
                {t(
                  commonTranslations[openingHours.day as Days].key,
                  commonTranslations[openingHours.day as Days].defaultValue,
                )}
              </Typography>
              <Typography type="body3">
                {openingHours.defaultOpeningHours.open.opens} - {openingHours.defaultOpeningHours.open.closes}
              </Typography>
            </>
          )}
        </StyledOpeningHoursWrapper>
      ))}
    </StyledOpeningHoursContainer>
  );
};
