/* istanbul ignore file */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { baseUrl, localhost, translationEndpoint } from './constants';
import { supportedLanguages } from './constants/i18n';

const loadPath =
  process.env.REACT_APP_USE_LOCAL_TRANSLATION === 'true' ? localhost : `${baseUrl}${translationEndpoint}`;

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    supportedLngs: supportedLanguages,
    fallbackLng: 'en-US',
    ns: ['common'],
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // include &version=<version> to use specific translation version
      loadPath:
        process.env.REACT_APP_ENV === 'production'
          ? `${loadPath}?language={{lng}}&namespace={{ns}}&client=box`
          : process.env.REACT_APP_ENV === 'test'
          ? `${loadPath}?language={{lng}}&namespace={{ns}}&client=box`
          : `${loadPath}?language={{lng}}&namespace={{ns}}&client=box&version=1.2`,
    },
  });

export default i18n;
