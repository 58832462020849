import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { BreakdownCostDetails, SummaryCostDetails } from '../../../hooks';
import { Container, Icons } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { Spacing } from '../../../utils';
import { PLCardContent } from '../plCardContent/PLCardContent';
import { StyledDivider, StyledIcon, StyledPLCardContainer } from '../ProfitAndLoss.styles';

type PLExpandCardProps = {
  content: BreakdownCostDetails[] | BreakdownCostDetails;
  compareCostDetails: SummaryCostDetails;
  costName: string;
  isParent: boolean;
  isExpanded?: boolean;
  onExpand?: (value: { [key: string]: boolean }) => void;
  onViewDetailsClick: (details: BreakdownCostDetails) => void;
};

export const PLExpandCard: FC<PLExpandCardProps> = ({
  content,
  compareCostDetails,
  costName,
  isParent,
  isExpanded = false,
  onExpand,
  onViewDetailsClick,
}) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const handleExpand = () => {
    if (onExpand && costName) {
      onExpand({
        [costName]: !isExpanded,
      });
    }
  };

  const getPadding = (): Spacing => {
    if (isMobile) {
      return isParent ? [3, 2] : [2, 0];
    }
    return isParent ? [4, 3] : [2];
  };

  const isArray = Array.isArray(content);

  const getplCardContent = () => {
    if (!Array.isArray(content)) {
      return content;
    }

    return content.reduce(
      (acc, item) => {
        acc.actual += item.actual;
        acc.lastYear += item.lastYear ?? 0;
        acc.budget += item.budget ?? 0;
        return acc;
      },
      { actual: 0, lastYear: 0, budget: 0 },
    );
  };

  const plcardcontent = getplCardContent();

  return (
    <StyledPLCardContainer
      data-testid="profit-loss-expand-card"
      position={!isParent ? 'center' : undefined}
      direction="horizontal"
      padding={getPadding()}
      width="100%"
      wrap="nowrap"
      margin={isParent ? [0, 0, 3, 0] : [0]}
      isParent={isParent}
    >
      {isParent && (
        <StyledIcon
          data-testid="expand-icon"
          icon={Icons.DROPDOWN}
          size={isMobile ? 'small' : 'medium'}
          margin={isMobile ? [1] : [1, 2]}
          isExpanded={isExpanded}
          onClick={handleExpand}
        />
      )}
      <Container wrap="nowrap" width="100%" position="bottom">
        <PLCardContent
          costDetails={plcardcontent}
          costName={isArray ? costName : content.name}
          isParent={isParent}
          onViewDetailsClick={onViewDetailsClick}
          compareCostDetails={compareCostDetails}
        />
        {isExpanded && (
          <>
            {isParent && <StyledDivider />}
            {isArray &&
              content.map((item) => (
                <PLExpandCard
                  key={item.name}
                  costName={item.name}
                  compareCostDetails={compareCostDetails}
                  content={item}
                  isParent={false}
                  onViewDetailsClick={onViewDetailsClick}
                />
              ))}
          </>
        )}
      </Container>
    </StyledPLCardContainer>
  );
};
