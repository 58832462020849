import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useProfile, useUser } from '../../../hooks';
import { Icons, Typography } from '../../../stories/atoms';
import { Page } from '../../../stories/organisms/page/Page';
import { isFailureResponse, ModalProps, getUniqueId, isSupportedSuamRole, RoleMapping } from '../../../utils';
import { employeesTranslations, commonTranslations, errors, sideMenuTranslations } from '../../../translations';
import { Animation, DropdownMenu, IconWithText, Modal, Animations } from '../../../stories/molecules';
import { Profile } from '../../../global-state/types';
import { ActionButton, Banner, BannerItemsType } from '../../../stories/molecules/banner';
import { useThemeContext } from '../../../global-state/themeContext';
import { NotFound } from '../../not-found/NotFound';
import { EmployeeOverviewModal, EmployeeNotificationProps } from './employee-overview-modal/EmployeeOverviewModal';
import { FilterWrapper, SelectedFilters, StyledWrapper } from './Employees.styles';
import { ButtonType, EmployeeTable, RoleData } from './employee-table/EmployeeTable';
import { EmployeeDetailsModal } from './employee-details-modal/EmployeeDetailsModal';
import { EmployeesLoader } from './employees-loader/EmployeesLoader';

const {
  employees: { filterRoles, expectingResult, noData, error, other },
} = employeesTranslations;
const { selectedFilters, tryAgain } = commonTranslations;
const { employees: employeesHeader } = sideMenuTranslations;

export const Employees = observer(() => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roleTable, setRoleTable] = useState<RoleData[]>([]);
  const [filterKeys, setFilterKeys] = useState<string[]>([]);
  const [modal, setModal] = useState<ModalProps<Profile> & { buttonType?: ButtonType }>({ isOpen: false });
  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [notification, setNotification] = useState<Omit<EmployeeNotificationProps, 'employeeInfo' | 'isSuccess'>>({
    type: 'editPermissions',
  });
  const {
    get: { currentStoreId, employees, roleMatrix, profile },
    set,
  } = useUser();
  const { fetchAllEmployeesInStore } = useProfile();

  const { t } = useTranslation();
  const { mode } = useThemeContext();

  useEffect(() => {
    if (!currentStoreId) return;
    setIsLoading(true);

    const filterEmployeesByRoles = async () => {
      const employeeResponse = await fetchAllEmployeesInStore(currentStoreId);
      if (isFailureResponse(employeeResponse)) {
        setIsError(true);
        return;
      }
      set({ employees: employeeResponse.data });
      setIsLoading(false);
    };
    filterEmployeesByRoles();
  }, [currentStoreId]);

  useEffect(() => {
    if (!roleMatrix.box || !Object.keys(roleMatrix.box).length) return;

    const filteredRoleTable: RoleData[] = [];
    employees.forEach((employee) => {
      if (employee?.isDeveloper) return;
      employee.assignments?.forEach(({ suamRoleCode, locationCode }) => {
        const isSupportedRole = isSupportedSuamRole(suamRoleCode);
        const isAssignmentInRightStore = locationCode === currentStoreId;

        if (isAssignmentInRightStore) {
          const tableIndex = filteredRoleTable.findIndex(({ key }) =>
            isSupportedRole ? key === suamRoleCode : key === 'other',
          );
          if (tableIndex > -1) filteredRoleTable[tableIndex].employees.push(employee);
          else {
            filteredRoleTable.push({
              key: isSupportedRole ? suamRoleCode : 'other',
              employees: [employee],
            });
          }
        }
      });
    });

    // Sort the tables by role name
    filteredRoleTable.sort(({ key: prevKey }, { key: nextKey }) => {
      if (prevKey < nextKey) return 1;
      if (prevKey > nextKey) return -1;
      /* istanbul ignore next */
      return 0;
    });
    setRoleTable(filteredRoleTable);
  }, [employees, roleMatrix]);

  const getFilterItems = () => {
    const filters = [
      { key: 'all', item: t('employees.roles.all', 'All') },
      roleTable.map(({ key }) => ({
        key,
        item:
          key === 'other'
            ? t(other.key, other.defaultValue)
            : t(`employees.roles.${RoleMapping[key]}`, RoleMapping[key]),
      })),
    ].flat();
    return filters;
  };

  const resetPinAndCloseModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const handleModalClose = () => {
    setModal({ ...modal, isOpen: false });
  };

  const handleItemClick = (modalData: Profile, buttonType: ButtonType) =>
    (buttonType === 'edit' || buttonType === 'details') && setModal({ modalData, buttonType, isOpen: true });

  const employeeModal = (modalData: Profile, buttonType: ButtonType) => {
    let renderModal;
    if (buttonType === 'details') {
      renderModal = (
        <EmployeeDetailsModal
          employee={modalData}
          isCurrentUser={profile.id === modalData.id}
          handleClose={() => setModal({ ...modal, isOpen: false })}
          handleEditPermissions={() => setModal({ ...modal, buttonType: 'edit' })}
        />
      );
    }

    if (buttonType === 'edit') {
      renderModal = (
        <EmployeeOverviewModal
          employee={modalData}
          isModalOpen={modal.isOpen}
          handleClose={resetPinAndCloseModal}
          handleNotification={({ isSuccess, type, employeeInfo }) => {
            setNotification({ type });
            showBanners(isSuccess, employeeInfo);
          }}
          isCurrentUser={profile.id === modalData.id}
        />
      );
    }
    return renderModal;
  };

  const renderErrorAnimation = (
    errorKey: string,
    errorDefaultValue: string,
    subTitleKey: string,
    subTitleDefaultValue: string,
    subTitleParams?: { currentStore: string },
  ) => (
    <Page
      body={
        <Animation
          title={t(errorKey, errorDefaultValue)}
          subtitle={t(subTitleKey, subTitleDefaultValue, subTitleParams)}
          animation={errorKey === errors.nothingWrong.key ? Animations.SAD : Animations.CONFUSED}
          button={{
            label: t(tryAgain.key, tryAgain.defaultValue),
            onClick: () => window.location.reload(),
            buttonContentProps: {
              iconOptions: {
                icon: Icons.REFRESH,
                color: 'white',
              },
            },
          }}
        />
      }
    />
  );

  if (isError) {
    return renderErrorAnimation(
      error.key,
      error.defaultValue,
      errors.nothingWrong.key,
      errors.nothingWrong.defaultValue,
    );
  }

  if (!roleTable.length && !employees.length && !isLoading) {
    return renderErrorAnimation(
      noData.key,
      noData.defaultValue,
      expectingResult.key,
      expectingResult.defaultValue,
      { currentStore: currentStoreId },
    );
  }

  const pathSegments = window.location.pathname.split('/');
  const urlStoreId = pathSegments[pathSegments.indexOf('employees') + 1];

  if (urlStoreId !== currentStoreId) {
    return <NotFound />;
  }

  const allFilterKey = filterKeys.length === 1 && filterKeys.includes('all');

  const showBanners = (isSuccess: boolean, employeeInfo: Profile) => {
    const firstName = employeeInfo?.firstName;
    const lastName = employeeInfo?.lastName;

    const successLabel =
      notification.type === 'editPermissions'
        ? t(employeesTranslations.editPermissionModal.successNotification.key, {
            defaultValue: employeesTranslations.editPermissionModal.successNotification.defaultValue,
            firstName,
            lastName,
          })
        : t(employeesTranslations.editRolesModal.successNotification.key, {
            defaultValue: employeesTranslations.editRolesModal.successNotification.defaultValue,
            firstName,
            lastName,
          });

    const errorLabel = t(errors.permissionError.key, errors.permissionError.defaultValue);

    setBannerItems((items) => [
      ...items,
      {
        id: getUniqueId(),
        label: isSuccess ? successLabel : errorLabel,
        type: isSuccess ? 'success' : 'error',
        actionButton: !isSuccess ? ActionButton.Close : undefined,
      },
    ]);
  };

  return (
    <Page
      header={
        <FilterWrapper>
          <DropdownMenu
            multiSelect
            dropdownLabel={t(filterRoles.key, filterRoles.defaultValue)}
            menuItems={getFilterItems()}
            menuPosition="left"
            checkbox
            indeterminateKey="all"
            initialSelected={filterKeys}
            onSelect={(selectedItemKeys) => setFilterKeys(selectedItemKeys)}
            backgroundColor={{
              hover: mode === 'dark' ? 'backgroundPaperDark' : 'borderGray',
              selected: mode === 'dark' ? 'backgroundPaperDark' : 'backgroundSelected',
            }}
          />
        </FilterWrapper>
      }
      body={
        <div data-testid="employees">
          {isLoading && <EmployeesLoader />}
          <Modal
            isOpen={modal.isOpen}
            handleClose={handleModalClose}
            transitionType="slide"
            ariaDescribedby="overview-role-modal"
          >
            {modal?.modalData && modal?.buttonType && employeeModal(modal?.modalData, modal?.buttonType)}
          </Modal>
          {notification?.type && (
            <Banner isSticky items={bannerItems} onChange={(items) => setBannerItems(items)} />
          )}
          {!isLoading && (
            <StyledWrapper>
              <Typography type="h3" textAlign="center">
                {t(employeesHeader.key, employeesHeader.defaultValue)}
              </Typography>
              {filterKeys.length > 0 && !allFilterKey && (
                <SelectedFilters data-testid="selected-filters">
                  <Typography type="h4">{t(selectedFilters.key, selectedFilters.defaultValue)}</Typography>
                  {roleTable.map(
                    ({ key }) =>
                      filterKeys.includes(key) && (
                        <IconWithText
                          key={key}
                          label={
                            key === 'other'
                              ? t(other.key, other.defaultValue)
                              : t(`employees.roles.${RoleMapping[key]}`, RoleMapping[key])
                          }
                          iconOptions={{
                            icon: Icons.CLOSE,

                            onClick: () => setFilterKeys(filterKeys.filter((filterKey) => filterKey !== key)),
                            margin: [0, 2],
                            size: 'small',
                          }}
                          backgroundColor={mode === 'dark' ? 'primary' : 'white'}
                          labelPosition="left"
                          hoverColor="borderGray"
                          padding={[0, 0, 0, 2]}
                        />
                      ),
                  )}
                </SelectedFilters>
              )}
              {roleTable.map(
                (role) =>
                  (!filterKeys.length || allFilterKey || filterKeys.includes(role.key)) && (
                    <EmployeeTable tableData={role} key={role.key} handleItemClick={handleItemClick} />
                  ),
              )}
            </StyledWrapper>
          )}
        </div>
      }
    />
  );
});

export default Employees;
