import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Delay } from '../../../constants';
import { LocalStorageKey } from '../../../constants/localStorageKey';
import { useUser, useLocalStorage } from '../../../hooks';
import { Container, Icons, Typography } from '../../../stories/atoms';
import { Animation, Modal } from '../../../stories/molecules';
import { commonTranslations } from '../../../translations';
import { selectStoreTranslation } from '../../../translations/selectStore';
import { StyledCloseButton, StyledContainer, StyledSwitchButton } from './SwitchStoreModal.styles';

export type ChangeStoreModalProps = {
  isOpen: boolean;
  changedStoreId: string;
  redirectUrl: string;
  onClose: () => void;
};

export const SwitchStoreModal: FC<ChangeStoreModalProps> = observer(
  ({ isOpen, changedStoreId, redirectUrl, onClose }) => {
    const {
      get: { currentStoreId, stores },
      set,
    } = useUser();
    const [showAnimation, setShowAnimation] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setItem } = useLocalStorage();

    const { switchMessage, switchingStore } = selectStoreTranslation;
    const currentStore = stores.find((store) => store.storeId === currentStoreId);
    const newStore = stores.find((store) => store.storeId === changedStoreId);

    const handleOnClick = () => {
      setItem(LocalStorageKey.SelectedStore, changedStoreId);
      set({ currentStoreId: changedStoreId });
      setShowAnimation(true);

      setTimeout(() => {
        onClose();
        navigate(redirectUrl);
      }, Delay.Medium);
    };

    return (
      <>
        <Modal
          isOpen={isOpen}
          isFullScreen={showAnimation}
          transitionType="slide"
          ariaDescribedby="change-store-modal"
          handleClose={onClose}
        >
          {showAnimation ? (
            <Animation title={t(switchingStore.key, switchingStore.defaultValue)} />
          ) : (
            <StyledContainer>
              <Typography type="h2" textAlign="center">
                {t(selectStoreTranslation.switchStore.key, selectStoreTranslation.switchStore.defaultValue)}
              </Typography>
              <Typography margin={[8, 0]}>
                {t(switchMessage.key, switchMessage.defaultValue, {
                  currentStore: currentStore?.popularName,
                  newStore: newStore?.popularName,
                })}
              </Typography>
              <Container direction="horizontal" padding={[0, 0, 5, 0]} position="center">
                <StyledCloseButton
                  onClick={onClose}
                  label={t(commonTranslations.close.key, commonTranslations.close.defaultValue)}
                  variant="secondary"
                  data-testid="close-button"
                />
                <StyledSwitchButton
                  data-testid="switch-store-button"
                  onClick={handleOnClick}
                  label={t(
                    selectStoreTranslation.switchStoreButton.key,
                    selectStoreTranslation.switchStoreButton.defaultValue,
                  )}
                  buttonContentProps={{
                    iconOptions: { icon: Icons.SWITCH, color: 'white', size: 'small' },
                    textOptions: { color: 'white' },
                  }}
                />
              </Container>
            </StyledContainer>
          )}
        </Modal>
      </>
    );
  },
);
