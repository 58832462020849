import { ChatBotType } from '../../hooks/useStores';

const mockAnswers = [
  'Box is a single Page web application',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  'Aliquam eu arcu nisl',
  'Etiam in vestibulum dui. Morbi ultricies justo ut tellus dignissim ultricies',
];

const getMockAnswers = () => {
  return mockAnswers[Math.floor(Math.random() * mockAnswers.length)];
};

export const getMockChatBotAnswers = () => {
  const data: ChatBotType = {
    answer: getMockAnswers(),
    prompts: [],
  };
  return data;
};
