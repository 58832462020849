import styled from 'styled-components';
import { Direction } from '../../../theme';

export const StyledWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4, 0)};
`;

export const StyledContainer = styled.div<{ direction: Direction }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  > div {
    margin-right: ${({ direction, theme }) => direction === 'horizontal' && theme.spacing(3)};
    :last-child {
      margin-right: 0;
    }
  }
`;
