import { FC } from 'react';
import { AccordionDetails } from '@mui/material';
import { Icon, Icons, Typography } from '../../atoms';
import { StyledIcon } from '../card/Card.styles';
import { Colors } from '../../../theme';
import { StyledAccordion, StyledAccordionSummary } from './Accordion.styles';

export type AccordionProps = {
  headerText: string;
  accordionDetails: JSX.Element;
  icon?: Icons;
  isCompletedIcon?: Icons;
  iconColor?: Colors;
  isCompleted?: boolean;
  disabled?: boolean;
  isExpanded?: boolean;
  isLocked?: boolean;
  lockedDetails?: JSX.Element;
  handleExpanded?: () => void;
};

export const Accordion: FC<AccordionProps> = ({
  headerText,
  accordionDetails,
  icon,
  isCompletedIcon,
  iconColor,
  isCompleted = false,
  disabled = false,
  isExpanded = false,
  isLocked = false,
  handleExpanded = () => {},
  lockedDetails,
}) => {
  const handleChange = () => {
    handleExpanded();
  };

  return (
    <StyledAccordion
      data-testid="accordion"
      elevation={0}
      disabled={disabled}
      expanded={isExpanded}
      onChange={handleChange}
    >
      <StyledAccordionSummary
        expandIcon={<Icon icon={Icons.DROPDOWN} size="small" margin={[0, 2]} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        data-testid="accordion-header"
      >
        {icon && <StyledIcon data-testid="header-icon" icon={icon} size="small" margin={[0, 2]} />}
        <Typography>{headerText}</Typography>
        {isLocked && lockedDetails}
        {isCompleted && (
          <Icon
            data-testid="check-mark-icon"
            icon={isCompletedIcon ? isCompletedIcon : Icons.CHECK_MARK}
            size="small"
            color={iconColor ? iconColor : 'validationGreen'}
            margin={[0, 4]}
          />
        )}
      </StyledAccordionSummary>
      <AccordionDetails data-testid="accordion-details">{accordionDetails}</AccordionDetails>
    </StyledAccordion>
  );
};
