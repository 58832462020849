import { SuamRole } from '../../utils';

export const mockRoleMatrix = {
  box: {
    id: 'b953ec1d-0f48-4683-a5b9-62267270c417',
    modelKey: 'box-default',
    roleManagement: {
      departmentManager: {
        departmentManager: 'R',
        storeController: null,
        storeEmployee: 'RU',
        storeManager: null,
        storeVisual: 'R',
        admin: null,
        fieldTechnician: 'R',
        other: 'R',
      },
      storeController: {
        departmentManager: null,
        storeController: 'R',
        storeEmployee: 'RU',
        storeManager: null,
        storeVisual: 'R',
        admin: null,
        fieldTechnician: 'R',
        other: 'R',
      },
      storeManager: {
        departmentManager: 'RU',
        storeController: 'RU',
        storeEmployee: 'RU',
        storeManager: 'R',
        storeVisual: 'RU',
        admin: null,
        fieldTechnician: 'R',
        other: 'RU',
      },
      storeVisual: {
        departmentManager: null,
        storeController: null,
        storeEmployee: null,
        storeManager: null,
        storeVisual: null,
        admin: null,
        fieldTechnician: null,
        other: null,
      },
      storeEmployee: {
        departmentManager: null,
        storeController: null,
        storeEmployee: null,
        storeManager: null,
        storeVisual: null,
        admin: null,
        fieldTechnician: null,
        other: null,
      },
      admin: {
        departmentManager: 'R',
        storeController: 'R',
        storeEmployee: 'R',
        storeManager: 'RU',
        storeVisual: 'R',
        admin: 'R',
        fieldTechnician: 'RU',
        other: 'R',
      },
    },
    featurePermissions: {
      transactionSearch: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
      userPreferences: {
        permissions: {
          storeController: 'RU',
          storeManager: 'RU',
          storeVisual: 'RU',
          departmentManager: 'RU',
          storeEmployee: 'RU',
          admin: 'RU',
          globalSecurity: null,
          other: 'RU',
        },
        isActive: true,
      },
      salesDashboard: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: false,
      },
      tenderView: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: false,
      },
      advanceTransactionSearch: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: false,
      },
      employees: {
        permissions: {
          storeController: 'RU',
          storeManager: 'RU',
          storeVisual: null,
          departmentManager: null,
          storeEmployee: null,
          admin: 'RU',
          globalSecurity: 'R',
          other: null,
        },
        isActive: true,
      },
      settings: {
        permissions: {
          storeController: 'RU',
          storeManager: 'RU',
          storeVisual: 'RU',
          departmentManager: 'RU',
          storeEmployee: null,
          admin: 'RU',
          globalSecurity: null,
          other: 'RU',
        },
        isActive: false,
      },
      messages: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
      selectStore: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
      avatars: {
        permissions: {
          storeController: 'RU',
          storeManager: 'RU',
          storeVisual: 'RU',
          departmentManager: 'RU',
          storeEmployee: 'RU',
          admin: 'RU',
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
      roleMatrixManagement: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: 'CRUD',
          other: null,
        },
        isActive: true,
      },
      receiptFooterTextPerMarket: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'RU',
          globalSecurity: null,
          other: null,
        },
        isActive: false,
      },
      receiptFooterTextPerStore: {
        permissions: {
          storeController: 'RU',
          storeManager: 'RU',
          storeVisual: null,
          departmentManager: null,
          storeEmployee: null,
          admin: 'RU',
          globalSecurity: null,
          other: null,
        },
        isActive: false,
      },
      supportAndFAQ: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: 'R',
          globalSecurity: 'R',
          other: 'R',
        },
        isActive: false,
      },
      creditAndLegal: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: 'R',
          globalSecurity: 'R',
          other: 'R',
        },
        isActive: false,
      },
      onboarding: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: 'R',
          globalSecurity: 'R',
          other: 'R',
        },
        isActive: true,
      },
      customerBaseValueWidgets: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: 'R',
          globalSecurity: 'R',
          other: 'R',
        },
        isActive: true,
      },
      salesEfficiencyWidgets: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: 'R',
          globalSecurity: 'R',
          other: null,
        },
        isActive: true,
      },
      transactionWidgets: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: 'R',
          other: null,
        },
        isActive: true,
      },
      assetManagementWidget: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: null,
          departmentManager: 'R',
          storeEmployee: null,
          admin: null,
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
      averageSalesWidgets: {
        permissions: {
          storeController: 'R',
          storeManager: 'R',
          storeVisual: null,
          departmentManager: 'R',
          storeEmployee: null,
          admin: 'R',
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
      storeStatus: {
        permissions: {
          storeController: 'R',
          storeManager: 'RU',
          storeVisual: 'R',
          departmentManager: 'R',
          storeEmployee: 'R',
          admin: null,
          globalSecurity: null,
          other: null,
        },
        isActive: true,
      },
    },
  },
};

export const mockRoles: SuamRole[] = ['SM', 'SC', 'SV', 'EV', 'FM'];
