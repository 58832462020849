import styled from 'styled-components';
import { Button } from '../../stories/molecules';
import { Container } from '../../stories/atoms';

type ContentWrapperProps = {
  isOpen: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  flex-direction: column;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundDark : color.white};
  box-shadow: ${({ theme: { palette } }) =>
    palette.mode === 'dark' ? '-20px 20px 40px -4px #0000003D' : '0px 2px 11px #ababab'};
  overflow: hidden;
  animation: fadeIn 200ms ease-out;

  ${({ theme: { breakpoints } }) => breakpoints.up('tabletPortrait')} {
    max-height: ${({ theme }) => theme.spacing(85)};
    max-width: ${({ theme }) => theme.spacing(100)};
    position: relative;
    border-radius: ${({ theme: { spacing } }) => spacing(3)};
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 5)};
  height: ${({ theme }) => theme.spacing(15)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.backgroundTableHeader};
`;

export const HeaderTitleContainer = styled.div`
  flex: 1;
  margin-left: ${({ theme: { spacing } }) => spacing(5)};
`;

export const HeaderButton = styled.div`
  margin-left: ${({ theme: { spacing } }) => spacing(5)};
  cursor: pointer;
`;

export const FeedBackContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  height: 100%;
  overflow-y: scroll;
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
