import styled from 'styled-components';
import { cardShadow } from '../../../theme';

export const StyledTemperature = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledWeatherContainer = styled.div`
  display: flex;
  align-items: center;
  ${cardShadow()};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  margin: ${({ theme: { spacing } }) => spacing(5)};
  justify-content: space-between;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${({ theme: { spacing } }) => spacing(4)};
`;

export const WeatherIcon = styled.img`
  width: ${({ theme }) => theme.spacing(18)};
`;
