import { format } from 'date-fns';
import { Profile, DefaultOpeningHours, OpeningHours } from '../global-state/types';

export const getCurrentAssignment = (profile: Profile, currentStoreId: string) => {
  // try to find specific assigment for current store. Default to first assigmnet if no match found
  let currentAssignment = profile.assignments?.find((assignment) => assignment.locationCode === currentStoreId);

  if (!currentAssignment && profile.assignments) {
    currentAssignment = profile.assignments[0];
  }

  return currentAssignment;
};

export type OpeningClosingeHours = {
  openingHour: number;
  closingHour: number;
};

export const getStoreOperationHours = (
  currentDate: Date,
  storeOpeningHours?: DefaultOpeningHours,
): OpeningClosingeHours => {
  if (!storeOpeningHours) return { openingHour: 0, closingHour: 24 };

  const getHour = (openingString: string) => {
    const hour = parseInt(openingString.slice(0, 2));

    return isNaN(hour) ? null : hour;
  };

  const getMinutes = (openingString: string) => {
    const minutes = parseInt(openingString.slice(3));

    return isNaN(minutes) ? null : minutes;
  };

  const currentDay = format(currentDate, 'iii').toLowerCase();

  let openingHours: OpeningHours | null = null;

  for (const key in storeOpeningHours) {
    if (key === currentDay) openingHours = storeOpeningHours[key];
  }

  if (openingHours === null) return { openingHour: 0, closingHour: 23 };

  const { open } = openingHours;
  const { opens, closes } = open;

  const openingHour = getHour(opens) || 9;

  const closingHour = getHour(closes) || 23;
  const closingMinutes = getMinutes(closes) || 0;

  return {
    openingHour,
    closingHour: closingMinutes > 0 ? closingHour + 1 : closingHour,
  };
};

export const getIsWithinOperationHours = (
  timeUnit: string,
  storeOpeningHour: number,
  storeClosingHour: number,
) => {
  const hour = parseInt(timeUnit) + 1;
  return hour > storeOpeningHour && hour <= storeClosingHour + 1;
};
