/* istanbul ignore file */
import styled from 'styled-components';
import { Colors } from '../../../theme';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TableContainer = styled.div<{
  columnBackgroundColor: Colors | undefined;
  rowHoverColor: Colors | undefined;
  selectedRowColor: Colors | undefined;
  useZebraStyles: boolean;
  isRowSelectable: boolean;
  noBorder?: boolean;
  fixedHeight?: string;
}>`
  height: ${({ fixedHeight }) => fixedHeight && fixedHeight};
  flex: ${({ fixedHeight }) => (fixedHeight ? 'none' : '1')};
  overflow: scroll;

  th {
    background-color: ${({ columnBackgroundColor, theme: { color } }) =>
      columnBackgroundColor && color[columnBackgroundColor]};
    border-bottom: ${({ noBorder }) => noBorder && 'none'};
  }

  tbody {
    tr {
      cursor: ${({ isRowSelectable }) => (isRowSelectable ? 'pointer' : 'inherit')};
      background-color: ${({ theme: { color, palette } }) =>
        palette.mode === 'dark' ? color.backgroundPaperDark : color.white};

      &:nth-child(2n) {
        background-color: ${({ theme: { color, palette }, useZebraStyles }) => {
          return useZebraStyles && (palette.mode === 'dark' ? color.backgroundPaperDark : color.baseGray);
        }};
      }

      &.MuiTableRow-root.MuiTableRow-hover:hover,
      &.MuiTableRow-root.MuiTableRow-hover.highlightedRow {
        background-color: ${({ rowHoverColor, theme: { color } }) => rowHoverColor && color[rowHoverColor]};
      }

      &.MuiTableRow-root.Mui-selected {
        background-color: ${({ selectedRowColor, theme: { color } }) =>
          selectedRowColor && color[selectedRowColor]};
      }
    }
    td {
      &.MuiTableCell-root {
        border-bottom: ${({ noBorder }) => noBorder && 'none'};
      }
    }
  }
`;

export const PaginatorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
