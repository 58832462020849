/* eslint-disable no-console */
import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';

// Browser check variables
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Msal Configurations
// If these tokens should not be exposed, add them as env variables instead
export const msalConfig: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    clientId: 'b557fd18-7616-409c-99b5-2c5b64d2e120',
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          default:
            break;
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
    },
  },
};

// Add scopes for id/access token
export const msalScopes = {
  scopes: ['https://box.az.hmgroup.com/.default'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphEndpoints = {
  myProfile: 'https://graph.microsoft.com/v1.0/me',
};
