/* istanbul ignore file */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'HMSans-Regular', sans-serif;
    font-family: 'HMSerif-Regular', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:not(.user-is-tabbing) button:focus,
    &:not(.user-is-tabbing) svg:focus,
    &:not(.user-is-tabbing) input:focus,
    &:not(.user-is-tabbing) select:focus,
    &:not(.user-is-tabbing) textarea:focus {
      outline: none;
    }

    input {
      font-family: ${({ theme: { typography } }) => typography.body2.fontFamily};
      font-weight: ${({ theme: { typography } }) => typography.body2.fontWeight};
    }
  }
`;
