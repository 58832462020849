import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../../constants';
import { useEventContext } from '../../../../../../global-state/eventContext';
import { RfidCountResponse, useAsyncAction, useTransaction } from '../../../../../../hooks';
import { Icons, Typography } from '../../../../../../stories/atoms';
import { homeTranslations } from '../../../../../../translations';
import { isFailureResponse } from '../../../../../../utils';
import { WidgetCardContent } from '../../../../common/widgetCardContent/WidgetCardContent';
import {
  CircleSection,
  FlexColumn,
  StyledIcon,
  StyledIconContainer,
  StyledSection,
  Wrapper,
} from '../RfidWidgets.styles';

export type RfidCountWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  fromDate?: Date;
  toDate?: Date;
};

export const RfidCountWidget: FC<RfidCountWidgetProps> = observer(
  ({ interval, currentDate, fromDate, toDate }) => {
    const { t } = useTranslation();
    const { rfidCount: rfidCountTranslations } = homeTranslations;

    const { fetchRfidCountData } = useTransaction();
    const { transactionEventsCount } = useEventContext();

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      result: rfidCountData,
      isLoading: rfidCountDataLoading,
      isError: rfidCountDataError,
      triggerAction: triggerFetchRfidCountData,
    } = useAsyncAction<RfidCountResponse>();

    const fetchData = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchRfidCountData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data;
    };

    const fetchWidgetData = () => {
      if (interval === StorePerformanceInterval.WEEKLY) return;
      triggerFetchRfidCountData(fetchData);
    };

    useEffect(() => {
      fetchWidgetData();
    }, [currentDate, interval, transactionEventsCount]);

    const isNoData =
      !rfidCountData ||
      [rfidCountData.rfidCount, rfidCountData.rfidTimeSpent, rfidCountData.rfidScannersUsed].every(
        (value) => value === 0 || value === undefined,
      );

    return (
      <WidgetCardContent
        headerIcon={Icons.PROFILE}
        headerText={t(rfidCountTranslations.title.key, rfidCountTranslations.title.defaultValue)}
        subHeaderText={t(rfidCountTranslations.subTitle.key, rfidCountTranslations.subTitle.defaultValue)}
        isLoading={rfidCountDataLoading}
        isError={rfidCountDataError}
        errorText={t(rfidCountTranslations.error.key, rfidCountTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        isWeeklyViewDisabled={interval === StorePerformanceInterval.WEEKLY}
        lastUpdatedDate={lastUpdated}
        toolTipText={t(rfidCountTranslations.toolTip.key, rfidCountTranslations.toolTip.defaultValue)}
        content={
          <Wrapper data-testid="rfid-count-wrapper">
            {rfidCountData?.rfidCount && (
              <StyledSection data-testid="counts-section">
                <FlexColumn>
                  <StyledIconContainer>
                    <StyledIcon icon={Icons.HOURLASS} size="small" />
                    <Typography type="button" margin={[0, 2, 0]}>
                      {t(
                        rfidCountTranslations.countText.title.key,
                        rfidCountTranslations.countText.title.defaultValue,
                      )}
                    </Typography>
                  </StyledIconContainer>

                  <Typography>
                    {t(
                      rfidCountTranslations.countText.subTitle.key,
                      rfidCountTranslations.countText.subTitle.defaultValue,
                    )}
                  </Typography>
                </FlexColumn>
                <CircleSection>
                  <Typography type="h4">{rfidCountData?.rfidCount}</Typography>
                </CircleSection>
              </StyledSection>
            )}
            {rfidCountData?.rfidTimeSpent && (
              <StyledSection data-testid="time-spent-section">
                <FlexColumn>
                  <StyledIconContainer>
                    <StyledIcon icon={Icons.CLOCK} size="small" />
                    <Typography type="button" margin={[0, 2, 0]}>
                      {t(
                        rfidCountTranslations.timeText.title.key,
                        rfidCountTranslations.timeText.title.defaultValue,
                      )}
                    </Typography>
                  </StyledIconContainer>

                  <Typography>
                    {t(
                      rfidCountTranslations.timeText.subTitle.key,
                      rfidCountTranslations.timeText.subTitle.defaultValue,
                    )}
                  </Typography>
                </FlexColumn>
                <CircleSection>
                  <Typography type="h4">{rfidCountData?.rfidTimeSpent}</Typography>
                </CircleSection>
              </StyledSection>
            )}
            {rfidCountData?.rfidScannersUsed && (
              <StyledSection data-testid="scanners-section">
                <FlexColumn>
                  <StyledIconContainer>
                    <StyledIcon icon={Icons.SCAN} size="small" />
                    <Typography type="button" margin={[0, 2]}>
                      {t(
                        rfidCountTranslations.scannerText.title.key,
                        rfidCountTranslations.scannerText.title.defaultValue,
                      )}
                    </Typography>
                  </StyledIconContainer>

                  <Typography>
                    {t(
                      rfidCountTranslations.scannerText.subTitle.key,
                      rfidCountTranslations.scannerText.subTitle.defaultValue,
                    )}
                  </Typography>
                </FlexColumn>

                <CircleSection>
                  <Typography
                    type="h4"
                    color={
                      rfidCountData?.rfidScannersUsed && rfidCountData?.rfidScannersUsed < 2
                        ? 'red'
                        : 'validationGreen'
                    }
                  >
                    {rfidCountData?.rfidScannersUsed}
                  </Typography>
                </CircleSection>
              </StyledSection>
            )}
          </Wrapper>
        }
      />
    );
  },
);
