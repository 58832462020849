import { Radio, Typography } from '@mui/material';
import { StyledDrawerMenuItem } from '../FilterDrawer.styles';

export type RadioListOption = {
  key: string;
  title: string;
};

type Props = {
  options: RadioListOption[];
  selectedOptionKey: null | string;
  onSelect: (key: string) => void;
};

export const RadiolListContent = ({ options, selectedOptionKey, onSelect }: Props) => {
  return (
    <>
      {options.map((option) => {
        const { key, title } = option;

        return (
          <StyledDrawerMenuItem key={key} onClick={() => onSelect(key)}>
            <Radio checked={selectedOptionKey === key} />
            <Typography>{title}</Typography>
          </StyledDrawerMenuItem>
        );
      })}
    </>
  );
};
