import styled from 'styled-components';
import { Card, Collapse } from '@mui/material';
import { getSpacing, Spacing } from '../../../utils';
import type { Layout, WidgetProps } from './Grid';

export const GridContainer = styled.div<{
  padding?: Spacing;
  gridGap?: string;
  columns?: number;
  rowHeight?: string;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 6}, 1fr);
  grid-auto-flow: row;
  grid-auto-rows: ${({ rowHeight, theme: { spacing } }) => rowHeight || spacing(29)};
  grid-gap: ${({ gridGap, theme: { spacing } }) => gridGap || spacing(6)};
  padding: ${({ padding, theme: { spacing } }) => getSpacing(padding || [6], spacing)};
`;

export const StyledCard = styled(Card)<WidgetProps>`
  background: ${({ backgroundcolor, theme: { color } }) => backgroundcolor && color[backgroundcolor]};
  padding: ${({ theme: { spacing }, padding }) => getSpacing(padding || [0], spacing)};
  height: 100%;
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
`;

export const StyledCollapse = styled(Collapse)<Partial<Layout>>`
  grid-column-start: ${({ x }) => x};
  grid-column-end: ${({ x, width }) => (width && x ? width + x : undefined)};
  grid-row-start: ${({ y }) => y};
  grid-row-end: ${({ y, height }) => (height && y ? height + y : undefined)};
  .MuiCollapse-vertical {
    height: 100%;
  }
`;
