import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../../assets/articlePlaceholder.png';
import { TopReturningGarmentItem, TopSellingGarmentItem } from '../../../../hooks';
import { Typography } from '../../../../stories/atoms';
import { ContainerDrawer } from '../../../../stories/molecules/container-drawer/ContainerDrawer';
import { homeTranslations, soldProductsTranslations } from '../../../../translations';
import { getArticleAssetUrl } from '../../../../utils/soldProducts';
import {
  StyledAboutInfo,
  StyledDetailsCard,
  StyledDetailsProperty,
  StyledDrawerContainer,
  StyledProductImage,
} from './ProductSlider.styles';

type ProductSliderProps = {
  isOpen: boolean;
  productDetails?: TopSellingGarmentItem | TopReturningGarmentItem | null;
  onClose?: () => void;
  currencyCode?: string;
};

export const ProductSlider: FC<React.PropsWithChildren<ProductSliderProps>> = ({
  productDetails,
  isOpen,
  onClose,
  currencyCode,
  children,
}) => {
  const { t } = useTranslation();
  const { topReturns } = homeTranslations;

  const getTopSellingProperties = (item: TopSellingGarmentItem) => [
    {
      label: t(soldProductsTranslations.piecesSold.key, soldProductsTranslations.piecesSold.defaultValue),
      value: item?.soldPieces ?? '-',
    },
    {
      label: t(soldProductsTranslations.salesNet.key, soldProductsTranslations.salesNet.defaultValue),
      value: `${parseFloat(item?.moneyGenerated).toFixed(1)} ${currencyCode ?? ''}`,
    },
  ];

  const getTopReturningProperties = (item: TopReturningGarmentItem) => [
    {
      label: t(topReturns.returnedPieces.key, topReturns.returnedPieces.defaultValue),
      value: item.onlineReturnPieces + item.storeReturnPieces,
    },
    {
      label: t(topReturns.returnType.key, topReturns.returnType.defaultValue),
      value:
        item.onlineReturnPieces && item.storeReturnPieces
          ? `${t(topReturns.onlineText.key, topReturns.onlineText.defaultValue)}, ${t(
              topReturns.storeText.key,
              topReturns.storeText.defaultValue,
            )}`
          : item.onlineReturnPieces
          ? t(topReturns.onlineText.key, topReturns.onlineText.defaultValue)
          : t(topReturns.storeText.key, topReturns.storeText.defaultValue),
    },
  ];

  const getIsTopSellingGarments = (
    item: TopSellingGarmentItem | TopReturningGarmentItem,
  ): item is TopSellingGarmentItem => {
    return (item as TopSellingGarmentItem).soldPieces !== undefined;
  };

  const productProperties = !productDetails
    ? []
    : getIsTopSellingGarments(productDetails)
    ? getTopSellingProperties(productDetails)
    : getTopReturningProperties(productDetails);

  const imageUrl = productDetails
    ? getArticleAssetUrl({
        articleId: `${productDetails?.productId}${productDetails?.articleIdentifier}`,
        assetType: 'descriptiveStillLife',
        rendition: 'medium',
      })
    : undefined;

  return (
    <ContainerDrawer
      isOpen={isOpen}
      onClose={onClose}
      sliderContent={
        <StyledDrawerContainer>
          <Typography type="button" margin={[0, 0, 3, 0]}>
            {t(
              soldProductsTranslations.productDetailsView.title.key,
              soldProductsTranslations.productDetailsView.title.defaultValue,
            )}
          </Typography>
          <div data-testid="product-details-view">
            <StyledProductImage
              data-testid="product-image"
              src={imageUrl}
              alt=""
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = articlePlaceholder;
              }}
            />
            <StyledAboutInfo data-testid="product-info">
              <div>
                <Typography type="body3" margin={[0, 0, 2, 0]}>
                  {productDetails?.title}
                </Typography>
                <Typography type="body3">{`${productDetails?.whitePrice} ${currencyCode}`} </Typography>
              </div>

              <Typography textAlign="left" color="textGray" type="legal">
                {t(soldProductsTranslations.productId.key, soldProductsTranslations.productId.defaultValue)}:{' '}
                {productDetails?.productId}
              </Typography>
            </StyledAboutInfo>

            <StyledDetailsCard data-testid="product-details">
              {productProperties.map((property, index) => (
                <StyledDetailsProperty key={index}>
                  <Typography>{property.label}</Typography>
                  <Typography>{property.value}</Typography>
                </StyledDetailsProperty>
              ))}
            </StyledDetailsCard>
          </div>
        </StyledDrawerContainer>
      }
      width="250px"
    >
      {children}
    </ContainerDrawer>
  );
};
