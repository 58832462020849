// Dont include this file in Unit test coverage
/* istanbul ignore file */

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ErrorCodes } from '../constants/errorMessages';
import { baseUrl, localhost } from '../constants/apiUrls';
import { FailureResponse, SuccessResponse } from './types';

/**
 * A generic Axios request, which returns the response of the request made.
 * Use in api hooks, with axiosRequest\<ResponseDataType>()
 * @param endpoint Endpoint URL to which the request will be made
 * @param config AxiosRequestConfig, used to set headers, body, etc.
 */
export const axiosRequest = async <T>(
  endpoint: string,
  config?: AxiosRequestConfig,
): Promise<SuccessResponse<T> | FailureResponse> => {
  try {
    const useLocalServer = process.env.REACT_APP_USE_LOCAL_SERVER === 'true';

    const response = await axios.request<T>({
      url: endpoint,
      baseURL: useLocalServer ? localhost : baseUrl,
      ...config,
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    const { response, request } = error as AxiosError;
    const statusCode: number = response?.status || request?.status || ErrorCodes.NOT_FOUND;
    const errorMessage =
      response?.data?.error || response?.data || request?.data || 'Something went wrong and no reason provided';
    return {
      status: statusCode,
      error: errorMessage,
    };
  }
};
