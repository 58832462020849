import styled from 'styled-components';
import { Container, Icon } from '../../../../atoms';

export const StyledDropDownContainer = styled.div`
  position: relative;
`;

export const StyledMessageInfo = styled(Container)`
  flex: 1;
  align-items: flex-start;
  color: ${({ theme: { color } }) => color.primary};
  white-space: pre-line;
`;

export const StyledLoadMore = styled.li`
  padding: ${({ theme: { spacing } }) => spacing(2)};
  position: sticky;
  bottom: 0;
  background: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  background-image: ${({ theme: { palette } }) =>
    palette.mode === 'dark' && `linear-gradient(rgba(255,255,255,0.09),rgba(255,255,255,0.09))`};
`;

export const StyledCheckIcon = styled(Icon)`
  padding: ${({ theme: { spacing } }) => spacing(2)};
  font-size: ${({ theme: { spacing } }) => spacing(8)};

  :hover {
    border-radius: 50%;
    background: ${({ theme: { color } }) => color.borderGray};
    cursor: pointer;
  }
`;

export const StyledHeaderContainer = styled(Container)`
  position: sticky;
  top: 0;
  z-index: ${({ theme: { zIndex } }) => zIndex.appBar};
  background: ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.primary : color.white)};
  border-bottom: ${({ theme: { color } }) => `1px solid ${color.borderGray}`};
`;

export const StyledAnimationContainer = styled(Container)`
  width: ${({ theme: { spacing } }) => spacing(80)};
`;

export const StyledBadge = styled.div`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(-1)};
  top: ${({ theme: { spacing } }) => spacing(-1)};
`;
