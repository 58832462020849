import styled from 'styled-components';
import { Container, Icon, Typography } from '../../stories/atoms';
import { Button } from '../../stories/molecules';

export const StyledContainer = styled(Container)`
  padding: ${({ theme: { spacing } }) => spacing(8, 15)};
  ${({ theme: { breakpoints } }) => breakpoints.up('tabletPortrait')} {
    min-width: ${({ theme: { spacing } }) => spacing(150)};
  }

  max-width: ${({ theme: { spacing } }) => spacing(125)};
  align-items: center;
  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    padding-top: ${({ theme: { spacing } }) => spacing(10)};
  }
`;

export const StyledCloseButton = styled(Button)`
  margin-right: ${({ theme: { spacing } }) => spacing(4)};
  flex: 1;
`;

export const StyledSignoutButton = styled(Button)`
  flex: 1;
`;

export const StyledTypography = styled(Typography)`
  ${({ theme: { breakpoints } }) => breakpoints.down('tabletPortrait')} {
    line-height: 1;
  }
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(35)};
  height: ${({ theme: { spacing } }) => spacing(35)};
`;
