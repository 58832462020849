import { MenuItem, Slide, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { add, isToday, sub } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { TransactionFilterType } from '../../../constants';
import { useTransactions } from '../../../hooks';
import { Drawer, Icons, Typography } from '../../../stories/atoms';
import { Button, Checkbox, IconWithText } from '../../../stories/molecules';
import { DateRangePicker } from '../../../stories/molecules/date-range-picker/DateRangePicker';
import { muiTheme } from '../../../theme';
import { transactionSearchTranslations } from '../../../translations';
import { filterTranslationMapping } from '../TransactionSearch';
import {
  StyledAllFiltersModal,
  StyledButtonWrapper,
  StyledCheckboxWrapper,
  StyledCountTypography,
  StyledDatePickerWrapper,
  StyledFiltersWrapper,
  StyledFooter,
  StyledIcon,
} from './AllFiltersMenu.styles';
import { FilterItemRow } from './FilterItemRow';

export type AllFiltersMenuProps = {
  isOpen: boolean;
  selectedDates: [Date, Date];
  onDateChange: (dates: [Date, Date]) => void;
  handleFilters: (selectedItemKeys: string[]) => void;
  onClose?: () => void;
  onClearAllFilters?: () => void;
  initialSelectedFilterKeys: string[];
};

type ActiveView =
  | 'MainView'
  | TransactionFilterType.PaymentType
  | TransactionFilterType.OperatorId
  | TransactionFilterType.ReceiptSubType;

export const AllFiltersMenu: FC<React.PropsWithChildren<AllFiltersMenuProps>> = ({
  isOpen,
  onClose,
  selectedDates,
  onDateChange,
  initialSelectedFilterKeys,
  handleFilters,
  onClearAllFilters,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(isOpen);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<ActiveView>('MainView');
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>(initialSelectedFilterKeys);
  const [minDate, setMinDate] = useState<Date>(sub(new Date(), { months: 18 }));
  const [maxDate, setMaxDate] = useState<Date>();

  const menuRef = useRef(null);

  const {
    get: { totalCount, quickFilterData },
  } = useTransactions();

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  useEffect(() => {
    setSelectedFilterKeys(initialSelectedFilterKeys);
  }, [initialSelectedFilterKeys]);

  useEffect(() => {
    setIsFilterMenuOpen(isOpen);
  }, [isOpen]);

  const { t } = useTranslation();

  const {
    datePicker,
    clearAllFilterButton,
    transactions: transactionsTranslation,
    doneButton,
    allFilters,
  } = transactionSearchTranslations;

  const handleClose = () => {
    setIsFilterMenuOpen(false);
    setActiveMenu('MainView');
    setIsDatePickerOpen(false);
    if (onClose) onClose();
  };

  const handleClearAllFilters = () => {
    if (onClearAllFilters) onClearAllFilters();
  };

  const handleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Enter')
    ) {
      return;
    }

    handleClose();
  };

  const handleDateChange = (date: [Date, Date]) => {
    onDateChange(date);
    handleDatePicker();
  };

  const handleMenuItemClick = (item: string) => {
    const updatedFilterKeys = selectedFilterKeys.includes(item)
      ? selectedFilterKeys.filter((filterKey) => filterKey !== item)
      : [...selectedFilterKeys, item];

    setSelectedFilterKeys(updatedFilterKeys);
    handleFilters(updatedFilterKeys);
  };

  const getDynamicFilterTypes = () => {
    return quickFilterData
      ? (Object.keys(quickFilterData) as TransactionFilterType[]).filter((key) => {
          return (
            key !== TransactionFilterType.OperatorId &&
            quickFilterData[key].length > 0 &&
            quickFilterData[key].some(({ count }) => +count > 0)
          );
        })
      : [];
  };

  return (
    <Drawer variant="temporary" position="right" isOpen={isFilterMenuOpen} onClose={() => handleClose()}>
      <Box
        data-testid="filters-menu"
        sx={{ width: isMobile ? '100vw' : 500, overflowX: 'hidden' }}
        role="presentation"
        onKeyDown={toggleDrawer()}
      >
        <StyledIcon
          data-testid="closeIcon"
          margin={[5, 5, 0, 5]}
          icon={Icons.CLOSE}
          size="medium"
          onClick={handleClose}
        />
        <CSSTransition
          in={activeMenu === 'MainView'}
          timeout={0}
          classNames="menu-primary"
          unmountOnExit
          nodeRef={menuRef}
        >
          <StyledAllFiltersModal>
            <Typography textAlign="center" type="h4">
              {t(allFilters.key, allFilters.defaultValue)}
            </Typography>
            <StyledFiltersWrapper data-testid="all-filters">
              {quickFilterData &&
                getDynamicFilterTypes().map((key) => (
                  <FilterItemRow
                    key={key}
                    data-testid={`${key}-filter`}
                    label={t(filterTranslationMapping[key].key, filterTranslationMapping[key].defaultValue)}
                    color={
                      quickFilterData[key].some(({ item }) => selectedFilterKeys.includes(item))
                        ? 'red'
                        : 'primary'
                    }
                    isSelectedCountShown={quickFilterData[key].some(({ item }) =>
                      selectedFilterKeys.includes(item),
                    )}
                    count={quickFilterData[key]
                      .filter(({ item }) => selectedFilterKeys.includes(item))
                      .length.toString()}
                    handleClick={() => setActiveMenu(key as ActiveView)}
                  />
                ))}
              <StyledDatePickerWrapper>
                <FilterItemRow
                  data-testid="datepicker-filter"
                  label={t(datePicker.key, datePicker.defaultValue)}
                  color={isToday(selectedDates[0]) ? 'primary' : 'red'}
                  handleClick={handleDatePicker}
                  icon={Icons.DROPDOWN}
                  rotate={isDatePickerOpen ? 'up' : undefined}
                />
                {isDatePickerOpen && (
                  <DateRangePicker
                    isOpen={isDatePickerOpen}
                    inputSelectedDates={selectedDates}
                    onAccept={handleDateChange}
                    onChange={(dates) => {
                      if (!dates[0]) return;
                      setMinDate(sub(dates[0], { days: 5 }));
                      setMaxDate(add(dates[0], { days: 5 }));
                    }}
                    onClose={() => {
                      setMinDate(sub(new Date(), { months: 18 }));
                      setMaxDate(undefined);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    disableFutureDates
                    type="integrated"
                  />
                )}
              </StyledDatePickerWrapper>
            </StyledFiltersWrapper>
          </StyledAllFiltersModal>
        </CSSTransition>
        {quickFilterData &&
          getDynamicFilterTypes().map((key) => (
            <Slide
              key={key}
              in={activeMenu === (key as ActiveView)}
              direction="right"
              timeout={{ enter: 300, exit: 0 }}
              data-testid="menu-secondary"
              mountOnEnter
              unmountOnExit
              ref={menuRef}
            >
              <div data-testid="secondary-menu">
                <IconWithText
                  label={t(filterTranslationMapping[key].key, filterTranslationMapping[key].defaultValue)}
                  labelPosition="right"
                  iconOptions={{ icon: Icons.ARROW, rotate: 'up' }}
                  spaceBetween="xxl"
                  textOptions={{
                    type: 'h4',
                    padding: [0, 16],
                  }}
                  padding={[0, 12]}
                  onClick={() => setActiveMenu('MainView')}
                />
                <StyledCheckboxWrapper data-testid="menu-wrapper">
                  {quickFilterData[key].map(({ item, count }) => (
                    <MenuItem
                      key={item}
                      selected={selectedFilterKeys.includes(item)}
                      onClick={(event) => {
                        event.preventDefault();
                        handleMenuItemClick(item);
                      }}
                      disableRipple
                      disabled={+count === 0}
                    >
                      <Checkbox
                        onChange={() => null}
                        label={item as string}
                        isChecked={selectedFilterKeys.includes(item)}
                      />
                      <StyledCountTypography>{count}</StyledCountTypography>
                    </MenuItem>
                  ))}
                </StyledCheckboxWrapper>
              </div>
            </Slide>
          ))}
        <StyledFooter>
          <Typography data-testid="transaction-count" margin={[4, 0]}>
            {totalCount} {t(transactionsTranslation.key, transactionsTranslation.defaultValue)}
          </Typography>
          <StyledButtonWrapper>
            <Button
              label={t(clearAllFilterButton.key, clearAllFilterButton.defaultValue)}
              variant="secondary"
              onClick={handleClearAllFilters}
              fullWidth
            />
            <Button
              label={t(doneButton.key, doneButton.defaultValue)}
              onClick={handleClose}
              buttonContentProps={{
                textOptions: { color: 'white' },
              }}
              fullWidth
            />
          </StyledButtonWrapper>
        </StyledFooter>
      </Box>
    </Drawer>
  );
};
