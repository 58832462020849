import { DateRangePicker } from '../../../date-range-picker/DateRangePicker';

export type RadioListOption = {
  key: string;
  title: string;
};

type Props = {
  currentDates: [Date, Date];
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onAccept: (dates: [Date, Date]) => void;
  onChange?: ((dates: [Date, Date]) => void) | undefined;
  onClose?: (() => void) | undefined;
};

export const DateRangeContent = ({ currentDates, minDate, maxDate, onAccept, onChange, onClose }: Props) => {
  return (
    <div data-testid="date-range-content-container">
      <DateRangePicker
        inputSelectedDates={currentDates}
        onAccept={onAccept}
        onChange={onChange}
        onClose={onClose}
        minDate={minDate}
        maxDate={maxDate}
        disableFutureDates
        type="integrated"
      />
    </div>
  );
};
