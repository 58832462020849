import { observable } from 'mobx';
import { RegisteredReceiptsItem } from '../../hooks';
import { Column } from '../../stories/molecules/table/Table';
import { PageInfo, Transaction, TransactionFilter } from '../types';

export const transactionStore = observable({
  table: {
    headers: [] as Column[],
    rows: [] as Transaction[],
    pagination: {
      currentPageNumber: 1,
      pageSize: 100,
      rowsPerPageOptions: [100, 250, 500],
      nextBusinessDayDate: undefined,
      countFromOldPartition: undefined,
      nextTransactionSubType: null,
    } as PageInfo,
  },
  totalCount: 0,
  quickFilterData: null as TransactionFilter,
  selectedReceipt: [] as string[],
  isFetchingCount: true,
  isFetchingTransaction: true,
  isReceiptError: false,
  isTransactionError: false,
  isCountError: false,
  isOperatorIdError: false,
  isReceiptSubTypeError: false,
  isPaymentTypeError: false,
  receiptWidgetTable: {
    headers: [] as Column[],
    rows: [] as RegisteredReceiptsItem[],
  },
  isTransactionsConnectedToWidgetData: false,
  selectedFilters: [] as string[],
});

export type TransactionStore = typeof transactionStore;
