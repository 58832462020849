import { rest } from 'msw';
import { authorizationEndpoint, baseUrl } from '../../constants';
import { mockRoleMatrix } from '../mocks';

export const fetchRoleMatrix = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${authorizationEndpoint}/:client`, (req, res, ctx) => {
    const { client } = req.params;

    return res(
      ctx.status(200),
      ctx.json(client === 'box' && mockRoleMatrix.box),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
