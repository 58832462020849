export const addAvatar = {
  title: {
    key: 'avatarSettings.addAvatar.title',
    defaultValue: 'Create your Unique Avatar',
  },
  subTitle: {
    key: 'avatarSettings.addAvatar.subTitle',
    defaultValue:
      'This avatar seems to like you. You can select it or change it to the one that best represents you! Let’s make your profile stand out!',
  },
  changeYourAvatar: {
    key: 'avatarSettings.addAvatar.changeYourAvatar',
    defaultValue: 'Change avatar',
  },
  saveYourAvatar: {
    key: 'avatarSettings.addAvatar.saveYourAvatar',
    defaultValue: 'Select this avatar',
  },
};

export const avatarSuccess = {
  avatarSuccessTitle: {
    key: 'avatarSettings.avatarSuccess.title',
    defaultValue: 'All settled!',
  },
};

export const editAvatar = {
  title: {
    key: 'avatarSettings.editAvatar.title',
    defaultValue: 'Edit Avatar',
  },
  currentAvatar: {
    key: 'avatarSettings.editAvatar.currentAvatar',
    defaultValue: 'This is your current avatar',
  },
  changeAvatarButton: {
    key: 'avatarSettings.editAvatar.changeAvatarButton',
    defaultValue: 'Change your avatar',
  },
};

export const selectAvatar = {
  title: {
    key: 'avatarSettings.selectAvatar.title',
    defaultValue: 'Select an avatar that you like',
  },
  saveAvatarButton: {
    key: 'avatarSettings.selectAvatar.saveAvatarButton',
    defaultValue: 'Save your avatar',
  },
  noAvatarsFound: {
    key: 'avatarSettings.noAvatarsFound',
    defaultValue: "Oh no, we are so sorry!<br/>We can't find the avatars at the moment.",
  },
};

export const banners = {
  avatarCreated: {
    key: 'avatarSettings.banners.avatarCreated',
    defaultValue: 'Your avatar has been successfully created',
  },
  avatarUpdated: {
    key: 'avatarSettings.banners.avatarUpdated',
    defaultValue: 'Your avatar has been updated',
  },
  avatarCreationFailed: {
    key: 'avatarSettings.banners.avatarCreationFailed',
    defaultValue: 'We could not create your avatar. Please try again or come back later',
  },
  avatarUpdateFailed: {
    key: 'avatarSettings.banners.avatarUpdateFailed',
    defaultValue: 'We could not update your avatar. Please try again or come back later',
  },
  avatarsFetchFailed: {
    key: 'avatarSettings.banners.avatarsFetchFailed',
    defaultValue: 'Something has gone wrong and we can’t show the avatars',
  },
  avatarNotUnique: {
    key: 'avatarSettings.banners.avatarNotUnique',
    defaultValue: 'Your avatar should be unique. Choose a different one',
  },
};
