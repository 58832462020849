export const kpiTargetTransalations = {
  headerText: {
    key: 'kpiTarget.headerText',
    defaultValue: 'You can <b>{{accessLevel}}</b> {{kpiProperty}} for the widgets.',
  },
  areaView: {
    key: 'kpiTarget.areaView',
    defaultValue: 'Area',
  },
  bulkUpdate: {
    key: 'kpiTarget.bulkUpdate',
    defaultValue: 'Update Multiple',
  },
  updateKpisText: {
    key: 'kpiTarget.updateKpisText',
    defaultValue: 'Select widgets to update {{kpiType}}',
  },
  updateKpiButton: {
    key: 'kpiTarget.updateKpiButton',
    defaultValue: 'Update KPIs',
  },
  removeKpiButton: {
    key: 'kpiTarget.removeKpiButton',
    defaultValue: 'Remove KPIs',
  },
  removeKpisText: {
    key: 'kpiTarget.removeKpisText',
    defaultValue: 'Select widgets to remove {{kpiType}}',
  },
  fetchingKpis: {
    key: 'kpiTarget.fetchingKpis',
    defaultValue: 'Fetching KPIs!!!',
  },
  kpiProperties: {
    target: {
      title: {
        key: 'kpiTarget.kpiProperties.target.title',
        defaultValue: 'Target',
      },
      tooltip: {
        key: 'kpiTarget.kpiProperties.target.tooltip',
        defaultValue: 'Target KPI for current store',
      },
    },
    recommendedTarget: {
      title: {
        key: 'kpiTarget.kpiProperties.recommendedTarget.title',
        defaultValue: 'Recommended Target',
      },
      tooltip: {
        key: 'kpiTarget.kpiProperties.recommendedTarget.tooltip',
        defaultValue: 'KPI based on historic data for current store + Area Cap',
      },
    },
    areaAverage: {
      title: {
        key: 'kpiTarget.kpiProperties.areaAverage.title',
        defaultValue: 'Area Average',
      },
      tooltip: {
        key: 'kpiTarget.kpiProperties.areaAverage.tooltip',
        defaultValue: 'Average Target for all stores in area {{areaName}}',
      },
    },
    areaMin: {
      title: {
        key: 'kpiTarget.kpiProperties.areaMin.title',
        defaultValue: 'Area Min',
      },
      tooltip: {
        key: 'kpiTarget.kpiProperties.areaMin.tooltip',
        defaultValue: 'Minimum Target in {{areaName}}',
      },
    },
    areaMax: {
      title: {
        key: 'kpiTarget.kpiProperties.areaMax.title',
        defaultValue: 'Area Max',
      },
      tooltip: {
        key: 'kpiTarget.kpiProperties.areaMax.tooltip',
        defaultValue: 'Maximum Target in {{areaName}}',
      },
    },
    areaCap: {
      title: {
        key: 'kpiTarget.kpiProperties.areaCap.title',
        defaultValue: 'Area Cap',
      },
      tooltip: {
        key: 'kpiTarget.kpiProperties.areaCap.tooltip',
        defaultValue: 'Added target level to recommended KPI target',
      },
    },
  },
  kpiWidgets: {
    dailyConversionRate: {
      key: 'kpiTarget.kpiWidgets.dailyConversionRate',
      defaultValue: 'Daily Conversion Rate',
    },
    dailyNewMembers: {
      key: 'kpiTarget.kpiWidgets.dailyNewMembers',
      defaultValue: 'Daily New Members',
    },
    dailyTurnOver: {
      key: 'kpiTarget.kpiWidgets.dailyTurnOver',
      defaultValue: 'Daily Sales Net',
    },
    dailyShareOfReceipts: {
      key: 'kpiTarget.kpiWidgets.dailyShareOfReceipts',
      defaultValue: 'Daily Share of Receipts',
    },
    dailyQueueLength: {
      key: 'kpiTarget.kpiWidgets.dailyQueueLength',
      defaultValue: 'Daily Queue Length',
    },
    dailySCOTurnOver: {
      key: 'kpiTarget.kpiWidgets.dailySCOTurnOver',
      defaultValue: 'Daily SCO Sales Net',
    },
    dailySCOShareOfReceipts: {
      key: 'kpiTarget.kpiWidgets.dailySCOShareOfReceipts',
      defaultValue: 'SCO Share of Receipts',
    },
  },
  kpiDropdown: {
    removeKpi: {
      key: 'kpiTarget.kpiDropdown.removeKpi',
      defaultValue: 'Remove KPI',
    },
    addAreaCap: {
      key: 'kpiTarget.kpiDropdown.addAreaCap',
      defaultValue: 'Add Area Cap',
    },
    removeAreaCap: {
      key: 'kpiTarget.kpiDropdown.removeAreaCap',
      defaultValue: 'Remove Area Cap',
    },
    highlightKpi: {
      key: 'kpiTarget.kpiDropdown.highlightKpi',
      defaultValue: 'Highlight Area KPI',
    },
    unhighlightKpi: {
      key: 'kpiTarget.kpiDropdown.unhighlightKpi',
      defaultValue: 'Unhighlight Area KPI',
    },
    cannotEditTooltip: {
      key: 'kpiTarget.kpiDropdown.cannotEditTooltip',
      defaultValue: 'You cannot remove {{kpiProperty}}',
    },
    noPermissionTooltipAreaCap: {
      key: 'kpiTarget.kpiDropdown.noPermissionTooltipAreaCap',
      defaultValue: 'You do not have permission to edit/add/remove Area Cap',
    },
    cannotHighlightAreaKPITooltip: {
      key: 'kpiTarget.kpiDropdown.cannotHighlightAreaKPITooltip',
      defaultValue: 'You do not have permission to highlight/unhighlight Area KPI',
    },
  },
  notSet: {
    key: 'kpiTarget.notSet',
    defaultValue: 'Not Set',
  },
  bulkUpdateSuccess: {
    key: 'home.common.bulkUpdateSuccess',
    defaultValue: 'Success!',
  },
  bulkUpdateError: {
    key: 'home.common.bulkUpdateError',
    defaultValue: 'Oops!',
  },
  error: {
    errorTitle: {
      key: 'kpiTarget.error.errorTitle',
      defaultValue: 'Something went wrong!',
    },
    errorSubtitle: {
      key: 'kpiTarget.error.errorSubtitle',
      defaultValue: 'There was an error while fetching the KPIs. Please try again!',
    },
  },
  noData: {
    key: 'kpiTarget.noData',
    defaultValue: 'No targets found!',
  },
  kpiAccordion: {
    setTarget: {
      key: 'kpiTarget.kpiAccordion.setTarget',
      defaultValue: 'Set Target',
    },
    targetPlaceholder: {
      key: 'kpiTarget.kpiAccordion.targetPlaceholder',
      defaultValue: 'Enter Target',
    },
    setAreaCap: {
      key: 'kpiTarget.kpiAccordion.setAreaCap',
      defaultValue: 'Set Area Cap',
    },
    areaCapPlaceholder: {
      key: 'kpiTarget.kpiAccordion.areaCapPlaceholder',
      defaultValue: 'Enter Area Cap',
    },
  },
  kpiBanner: {
    kpiSuccessBanner: {
      key: 'kpiTarget.kpiBanner.kpiSuccessBanner',
      defaultValue: 'KPI goals have been updated successfully!',
    },
    kpiErrorBanner: {
      key: 'kpiTarget.kpiBanner.kpiErrorBanner',
      defaultValue: 'Something went wrong while updating KPIs. Please try again!',
    },
    storesErrorBanner: {
      key: 'kpiTarget.kpiBanner.storesErrorBanner',
      defaultValue: 'Something went wrong while fetching Stores. Please try again!',
    },
  },
  kpiLock: {
    locked: {
      key: 'kpiTarget.kpiLock.locked',
      defaultValue: 'Locked',
    },
    unlocked: {
      key: 'kpiTarget.kpiLock.unlocked',
      defaultValue: 'Unlocked',
    },
    lockErrorBanner: {
      key: 'kpiTarget.kpiLock.lockErrorBanner',
      defaultValue: 'Locking/unlocking target was not successful. Please try again!',
    },
    noPermissionBanner: {
      key: 'kpiTarget.kpiLock.noPermissionBanner',
      defaultValue: 'You do not have permission to lock/unlock Targets!',
    },
    lockSuccessBanner: {
      key: 'kpiTarget.kpiLock.lockSuccessBanner',
      defaultValue: 'KPI target has been successfully locked!',
    },
    unlockSuccessBanner: {
      key: 'kpiTarget.kpiLock.unlockSuccessBanner',
      defaultValue: 'KPI target has been successfully unlocked!',
    },
  },
  kpiHighlight: {
    highlightedBanner: {
      key: 'kpiTarget.kpiHighlight.highlighted',
      defaultValue: 'Area KPI has been highlighted successfully!',
    },
    unhighlightedBanner: {
      key: 'kpiTarget.kpiHighlight.unhighlighted',
      defaultValue: 'Area KPI has been removed from highlighted widgets successfully!',
    },
    highlightedErrorBanner: {
      key: 'kpiTarget.kpiHighlight.highlightedErrorBanner',
      defaultValue: 'Unable to modify the highlighting status of the Area KPI. Please try again!',
    },
  },
  areaViewModal: {
    subtitleText1: {
      key: 'kpiTarget.areaViewModal.subtitleText1',
      defaultValue: 'KPI Target for',
    },
    subtitleText2: {
      key: 'kpiTarget.areaViewModal.subtitleText2',
      defaultValue: 'for all stores in {{areaName}}',
    },
    storeid: {
      key: 'kpiTarget.areaViewModal.storeid',
      defaultValue: 'Store Id',
    },
    currentTarget: {
      key: 'kpiTarget.areaViewModal.currentTarget',
      defaultValue: 'Current Target',
    },
  },
};
