import Switch, { SwitchProps } from '@mui/material/Switch';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div<{ labelPosition: 'right' | 'left' }>`
  margin: ${({ theme: { spacing }, labelPosition }) =>
    labelPosition === 'right' ? spacing(0, 0, 0, 5) : spacing(0, 5, 0, 0)};
`;

export const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(6),
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: theme.spacing(1),

    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: `translateX(${theme.spacing(4)})`,
      color: theme.color.white,
      '& .MuiSwitch-thumb': {
        color: theme.color.white,
        borderColor: theme.color.white,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'light' ? theme.color.primary : theme.color.borderGrayDark,
        opacity: 1,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      opacity: 0.3,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: `${theme.palette.mode === 'light' ? theme.color.white : 'transparent'}`,
    border: `1px solid ${theme.palette.mode === 'light' ? theme.color.primary : theme.color.white}`,
    boxShadow: 'none',
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'transparent',
    opacity: 1,
    border: `1px solid ${theme.palette.mode === 'light' ? theme.color.primary : theme.color.white}`,
    borderRadius: 26 / 2,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
