import { Tabs } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '../../atoms';
import { Colors } from '../../../theme';

export type TabAlignment = 'space-evenly' | 'space-around' | 'center' | 'space-between';

export const StyledTypography = styled(Typography)`
  text-transform: none;
`;

export const StyledTabs = styled(Tabs)<{ alignment?: TabAlignment; tabbackgroundcolor?: Colors }>`
  &.MuiTabs-root {
    background-color: ${({ tabbackgroundcolor, theme }) => tabbackgroundcolor && theme.color[tabbackgroundcolor]};
    .MuiTabs-flexContainer {
      justify-content: ${({ alignment }) => alignment && alignment};
    }
  }
`;
