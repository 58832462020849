export const logoutTranslation = {
  title: {
    key: 'logout.title',
    defaultValue: 'Leaving so soon!',
  },
  subTitle: {
    key: 'logout.subTitle',
    defaultValue: 'Are you sure you want to sign out?',
  },
};
