import styled from 'styled-components';
import { muiTheme } from '../../../../theme';
import { Container } from '../../../atoms';
import { BannerItemType } from './BannerItem';

const {
  color: { success, error, warning, info },
} = muiTheme;

const typeBackgroundMapping = {
  success,
  error,
  warning,
  info,
};

export const StyledContainer = styled(Container)<{ type: BannerItemType }>`
  background: ${({ type }) => type && typeBackgroundMapping[type]};
  padding: ${({ theme: { spacing } }) => spacing(3, 4)};
  z-index: ${({ theme: { zIndex } }) => zIndex.snackbar};
  & > div {
    color: ${({ theme }) => theme.color.primary};
    & div {
      color: ${({ theme }) => theme.color.primary};
    }
  }
  & > button > div > div {
    color: ${({ theme }) => theme.color.primary};
  }
`;
