import { KpiType } from '../constants';
import { KpiDistribution, KpiTargetProps, KpiTargetsProps } from '../hooks';

export const getKpiTargetValue = (kpiTargetsProps: KpiTargetsProps | null, kpiName: KpiType): number | null => {
  if (!kpiTargetsProps) return null;

  const kpiTargetProps = kpiTargetsProps[kpiName];

  if (!kpiTargetProps) return null;

  return kpiTargetProps.target;
};

export const getKpiTargetProps = (
  kpiTargetsProps: KpiTargetsProps | null,
  kpiName: KpiType,
): KpiTargetProps | null => {
  if (!kpiTargetsProps) return null;

  const kpiTargetProps = kpiTargetsProps[kpiName];

  if (!kpiTargetProps) return null;

  return kpiTargetProps;
};

export const getKpiDistribution = (
  kpiTargetsProps: KpiTargetsProps | null,
  kpiName: KpiType,
): KpiDistribution[] => {
  if (!kpiTargetsProps) return [];

  const kpiTargetProps = kpiTargetsProps[kpiName];

  if (!kpiTargetProps) return [];

  return kpiTargetProps.distributions;
};
