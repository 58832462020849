export const homeTranslations = {
  onboarding: {
    title: {
      key: 'home.onboarding.title',
      defaultValue: 'Back Office Onboarding',
    },
    subTitle: {
      key: 'home.onboarding.subTitle',
      defaultValue: 'Follow the steps and get to know Back office',
    },
    complete: {
      key: 'home.onboarding.complete',
      defaultValue: 'Onboarding completed!',
    },
  },
  tenderOverview: {
    title: {
      key: 'home.tenderOverview.title',
      defaultValue: 'Payment Methods',
    },
    subTitle: {
      key: 'home.tenderOverview.subTitle',
      defaultValue: 'All transactions divided into tenders',
    },
    tender: {
      key: 'home.tenderOverview.tender',
      defaultValue: 'Tender',
    },
    error: {
      key: 'home.tenderOverview.error',
      defaultValue: 'We can´t show the payment methods widget right now.',
    },
    toolTip: {
      key: 'home.tenderOverview.toolTip',
      defaultValue: 'Transactions based on payment type',
    },
  },
  assetManagement: {
    title: {
      key: 'home.assetManagement.title',
      defaultValue: 'Asset Management',
    },
    subTitle: {
      key: 'home.assetManagement.subTitle',
      defaultValue: 'All workstations in store',
    },
    name: {
      key: 'home.assetManagement.name',
      defaultValue: 'Name',
    },
    latestTransaction: {
      key: 'home.assetManagement.latestTransaction',
      defaultValue: 'Latest Transaction',
    },
    totalAmount: {
      key: 'home.assetManagement.totalAmount',
      defaultValue: 'Total Amount',
    },
    userLog: {
      key: 'home.assetManagement.userLog',
      defaultValue: 'User Log',
    },
    status: {
      key: 'home.assetManagement.status',
      defaultValue: 'Status',
    },
    workstations: {
      key: 'home.assetManagement.workstations',
      defaultValue: 'Workstations',
    },
    active: {
      key: 'home.assetManagement.active',
      defaultValue: 'Active',
    },
    closed: {
      key: 'home.assetManagement.closed',
      defaultValue: 'Closed',
    },
    offline: {
      key: 'home.assetManagement.offline',
      defaultValue: 'Error',
    },
    warningTooltip: {
      key: 'home.assetManagement.warningTooltip',
      defaultValue: 'Till is open but warning encountered',
    },
    error: {
      key: 'home.assetManagement.error',
      defaultValue: 'We can´t show the asset management widget right now.',
    },
    toolTip: {
      key: 'home.assetManagement.toolTip',
      defaultValue: 'An overview of tills in the store',
    },
  },
  registeredReceipts: {
    title: {
      key: 'home.registeredReceipts.title',
      defaultValue: 'Registered Receipts Overview',
    },
    subTitle: {
      key: 'home.registeredReceipts.subTitle',
      defaultValue: 'All transactions divided into registered receipts',
    },
    error: {
      key: 'home.registeredReceipts.error',
      defaultValue: 'We can´t show the registered receipts widget right now.',
    },
    toolTip: {
      key: 'home.registeredReceipts.toolTip',
      defaultValue: 'Transactions based on transaction type',
    },
    header: {
      key: 'home.registeredReceipts.header',
      defaultValue: 'Total Transactions:',
    },
    saleHeader: {
      key: 'home.registeredReceipts.saleHeader',
      defaultValue: 'Sales',
    },
    returnHeader: {
      key: 'home.registeredReceipts.returnHeader',
      defaultValue: 'Voids and Returns',
    },
  },
  storeOverview: {
    key: 'home.storeOverview',
    defaultValue: 'Store Overview',
  },
  common: {
    error: {
      key: 'home.common.error',
      defaultValue: 'There was an error loading this widget',
    },
    noData: {
      key: 'home.common.nodata',
      defaultValue: 'No transaction has been made yet.',
    },
    count: {
      key: 'home.common.count',
      defaultValue: 'Count',
    },
    type: {
      key: 'home.common.type',
      defaultValue: 'Type',
    },
    dateBasedData: {
      key: 'home.common.dateBasedData',
      defaultValue: 'Data based on:',
    },
    noReceipts: {
      key: 'home.common.noReceipts',
      defaultValue: 'No receipts have been created yet.',
    },
    noSCOReceipts: {
      key: 'home.common.noSCOReceipts',
      defaultValue: 'No SCO receipts have been created yet.',
    },
    noWeeklyData: {
      key: 'home.common.noWeeklyData',
      defaultValue: 'No weekly data available for this widget',
    },
    loading: {
      key: 'home.common.loading',
      defaultValue: 'Loading',
    },
    targetKPINewMember: {
      key: 'home.common.targetKPINewMember',
      defaultValue: 'Target KPI New Member: ',
    },
    targetKPIHourly: {
      key: 'home.common.targetKPIHourly',
      defaultValue: 'Target KPI Net Sales: ',
    },
    targetKPICR: {
      key: 'home.common.targetKPICR',
      defaultValue: 'Target KPI for CR: ',
    },
    targetKPINMShare: {
      key: 'home.common.targetKPINMShare',
      defaultValue: 'Target KPI NM Share: ',
    },
    targetKPISOR: {
      key: 'home.common.targetKPISOR',
      defaultValue: 'Target KPI SOR: ',
    },
    targetKPIQueueLength: {
      key: 'home.common.targetKPIQueueLength',
      defaultValue: 'Target KPI Queue Length: ',
    },
    allTabName: {
      key: 'home.common.allTabName',
      defaultValue: 'All',
    },
    today: {
      key: 'home.common.today',
      defaultValue: 'Today',
    },
    yesterday: {
      key: 'home.common.yesterday',
      defaultValue: 'Yesterday',
    },
    lastWeek: {
      key: 'home.common.lastWeek',
      defaultValue: 'Last Week',
    },
    lastYear: {
      key: 'home.common.lastYear',
      defaultValue: 'Last Year',
    },
    thanActual: {
      key: 'home.common.thanActual',
      defaultValue: 'than Actual',
    },
    thanBudget: {
      key: 'home.common.thanBudget',
      defaultValue: 'than Budget',
    },
    thanPlanned: {
      key: 'home.common.thanPlanned',
      defaultValue: 'than Planned',
    },
    nearby: {
      key: 'home.common.nearby',
      defaultValue: 'Nearby',
    },
    expandAll: {
      key: 'home.common.expandAll',
      defaultValue: 'Expand All',
    },
    collapseAll: {
      key: 'home.common.collapseAll',
      defaultValue: 'Collapse All',
    },
    forecast: {
      key: 'home.common.forecast',
      defaultValue: 'Forecast',
    },
    hourlyTarget: {
      key: 'home.common.hourlyTarget',
      defaultValue: 'Hourly Target',
    },
    showAreaSwitch: {
      key: 'home.common.showAreaSwitch',
      defaultValue: 'Show Area Widget',
    },
    hideAreaSwitch: {
      key: 'home.common.hideAreaSwitch',
      defaultValue: 'Hide Area Widget',
    },
    lastUpdated: {
      key: 'home.common.lastUpdated',
      defaultValue: 'Last Updated',
    },
  },
  datePicker: {
    weekLabel: {
      key: 'home.common.datePicker.weekLabel',
      defaultValue: 'Week',
    },
    radioLabel1: {
      key: 'home.common.datePicker.radioLabel1',
      defaultValue: 'Select a Date',
    },
    radioLabel2: {
      key: 'home.common.datePicker.radioLabel2',
      defaultValue: 'Select a Week',
    },
  },
  banners: {
    translationError: {
      key: 'home.banners.translationError',
      defaultValue: 'We are facing some troubles translating to your desired language.',
    },
    onBoardingSuccess: {
      key: 'home.banners.onBoardingSuccess',
      defaultValue:
        'Nice work, you have just completed an onboarding item! Visit the onboarding list on the home screen to view your progress.',
    },
    serviceAlerts: {
      key: 'home.banners.serviceAlerts',
      defaultValue: 'We are sorry, but due to some technical issues, some of the data is not updated.',
    },
  },
  slider: {
    generalInformation: {
      key: 'home.slider.generalInformation',
      defaultValue: 'General Information',
    },
    storeOpeningHours: {
      key: 'home.slider.storeOpeningHours',
      defaultValue: 'Opening hours',
    },
    chatBotPlaceholder: {
      key: 'home.slider.chatBotPlaceholder',
      defaultValue: 'Ask a question',
    },
  },
  budgetPlanned: {
    title: {
      key: 'home.budgetPlanned.title',
      defaultValue: 'Budget vs Planned',
    },
    budget: {
      key: 'home.budgetPlanned.budget',
      defaultValue: 'Budget',
    },
    planned: {
      key: 'home.budgetPlanned.planned',
      defaultValue: 'Planned',
    },
    budgetDropdown: {
      key: 'home.budgetPlanned.budgetDropdown',
      defaultValue: 'Show Budget/Planned',
    },
    error: {
      key: 'home.budgetPlanned.error',
      defaultValue: 'Something went wrong!',
    },
    noPermissionBudgetTooltip: {
      key: 'home.budgetPlanned.noPermissionBudgetTooltip',
      defaultValue: 'You do not have permission to view Budget/Planned',
    },
  },
  hourlyTurnover: {
    title: {
      key: 'home.hourlyTurnover.title',
      defaultValue: 'Sales Net',
    },
    subTitle: {
      key: 'home.hourlyTurnover.subTitle',
      defaultValue: 'Hourly net sales',
    },
    error: {
      key: 'home.hourlyTurnover.error',
      defaultValue: 'We can´t show the sales net widget right now.',
    },
    totalSales: {
      key: 'home.hourlyTurnover.totalSales',
      defaultValue: 'Total Sales',
    },
    toolTip: {
      key: 'home.hourlyTurnover.toolTip',
      defaultValue: 'Hourly net sales in store',
    },
  },
  scoHourlyTurnover: {
    title: {
      key: 'home.scoHourlyTurnover.title',
      defaultValue: 'SCO - Sales Net',
    },
    subTitle: {
      key: 'home.scoHourlyTurnover.subTitle',
      defaultValue: 'Self Service Checkout Performance',
    },
    error: {
      key: 'home.scoHourlyTurnover.error',
      defaultValue: 'We can´t show the SCO sales net widget right now.',
    },
    totalSales: {
      key: 'home.scoHourlyTurnover.totalSales',
      defaultValue: 'Total SCO Sales',
    },
    scoShare: {
      key: 'home.scoHourlyTurnover.scoShare',
      defaultValue: 'Share of SCO',
    },
    toolTip: {
      key: 'home.scoHourlyTurnover.toolTip',
      defaultValue: 'Hourly Self service checkout Net sales',
    },
  },
  conversionRate: {
    title: {
      key: 'home.conversionRate.title',
      defaultValue: 'Conversion rate',
    },
    subTitle: {
      key: 'home.conversionRate.subTitle',
      defaultValue: 'The amount of non paying & paying customers.',
    },
    error: {
      key: 'home.conversionRate.error',
      defaultValue: 'We can´t show the conversion widget right now.',
    },
    average: {
      key: 'home.conversionRate.average',
      defaultValue: 'Average',
    },
    totalVisitors: {
      key: 'home.conversionRate.totalVisitors',
      defaultValue: 'Total Visitors',
    },
    numberOfTills: {
      key: 'home.conversionRate.numberOfTills',
      defaultValue: 'Utilized Tills',
    },
    payingCustomers: {
      key: 'home.conversionRate.payingCustomers',
      defaultValue: 'Paying',
    },
    nonPayingCustomers: {
      key: 'home.conversionRate.nonPayingCustomers',
      defaultValue: 'Non Paying',
    },
    toolTip: {
      key: 'home.conversionRate.toolTip',
      defaultValue:
        'Quick overview of number of paying vs non-paying customers. The Forecast is shown in percentage',
    },
    visitors: {
      key: 'home.conversionRate.visitors',
      defaultValue: 'Visitors',
    },
  },
  salesNet: {
    title: {
      key: 'home.salesNet.title',
      defaultValue: 'Sales',
    },
    subTitle: {
      key: 'home.salesNet.subTitle',
      defaultValue: 'Weekly Net sales in store',
    },
    error: {
      key: 'home.salesNet.error',
      defaultValue: 'We can´t show the sales widget right now.',
    },
    weekly: {
      key: 'home.salesNet.weekly',
      defaultValue: 'Weekly',
    },
    actual: {
      key: 'home.salesNet.actual',
      defaultValue: 'Actual',
    },
    toolTip: {
      key: 'home.salesNet.toolTip',
      defaultValue: 'Net Sales in store for the past week. The Forecast is shown in currency.',
    },
  },
  topSelling: {
    title: {
      key: 'home.topSelling.title',
      defaultValue: 'Top selling garment',
    },
    subTitle: {
      key: 'home.topSelling.subTitle',
      defaultValue: 'In the store right now',
    },
    error: {
      key: 'home.topSelling.error',
      defaultValue: 'We can´t show the top selling garment widget right now.',
    },
    toolTip: {
      key: 'home.topSelling.toolTip',
      defaultValue: 'Top Selling Garments sold in the store',
    },
  },
  topReturns: {
    title: {
      key: 'home.topReturns.title',
      defaultValue: 'Returns',
    },
    subTitle: {
      key: 'home.topReturns.subTitle',
      defaultValue: 'Return Product Insights',
    },
    error: {
      key: 'home.topReturns.error',
      defaultValue: 'We can´t show the top returns garment widget right now.',
    },
    toolTip: {
      key: 'home.topReturns.toolTip',
      defaultValue: 'Store or ORIS Returned Garments in the store',
    },
    storeReturns: {
      key: 'home.topReturns.storeReturns',
      defaultValue: 'Store Returns',
    },
    orisReturns: {
      key: 'home.topReturns.orisReturns',
      defaultValue: 'ORIS Returns',
    },
    storeText: {
      key: 'home.topReturns.storeText',
      defaultValue: 'Store',
    },
    onlineText: {
      key: 'home.topReturns.onlineText',
      defaultValue: 'Online',
    },
    returnedPieces: {
      key: 'home.topReturns.returnedPieces',
      defaultValue: 'Returned pieces',
    },
    returnType: {
      key: 'home.topReturns.returnType',
      defaultValue: 'Return type',
    },
  },
  orisCustomers: {
    title: {
      key: 'home.orisCustomers.title',
      defaultValue: 'ORIS',
    },
    subTitle: {
      key: 'home.orisCustomers.subTitle',
      defaultValue: 'Online Return in Store',
    },
    error: {
      key: 'home.orisCustomers.error',
      defaultValue: 'We can´t show the top returns garment widget right now.',
    },
    toolTip: {
      key: 'home.orisCustomers.toolTip',
      defaultValue:
        'Tracks the efficiency of processing online returns in-store, customer conversions, loyalty member participation, and time taken.',
    },
    orisConversionRate: {
      text: {
        key: 'home.orisCustomers.orisConversionRate.text',
        defaultValue: 'ORIS Conversion Rate',
      },
      tooltip: {
        key: 'home.orisCustomers.orisConversionRate.tooltip',
        defaultValue:
          'This represents the percentage of online returns that are successfully converted into new purchases or exchanges during the visit.',
      },
    },
    orisTotalTransactions: {
      text: {
        key: 'home.orisCustomers.orisTotalTransactions.text',
        defaultValue: 'Total Transactions',
      },
      tooltip: {
        key: 'home.orisCustomers.orisTotalTransactions.tooltip',
        defaultValue:
          'The total number of online returns processed in-store, indicating the overall volume of returns handled.',
      },
    },
    orisConvertedTransactions: {
      text: {
        key: 'home.orisCustomers.orisConvertedTransactions.text',
        defaultValue: 'Converted Transactions',
      },
      tooltip: {
        key: 'home.orisCustomers.orisConvertedTransactions.tooltip',
        defaultValue:
          'This measures how many online returns were converted into sales, reflecting successful upselling or exchanges.',
      },
    },
    orisTotalLoyaltyTransactions: {
      text: {
        key: 'home.orisCustomers.orisTotalLoyaltyTransactions.text',
        defaultValue: 'Total Loyalty Transactions',
      },
      tooltip: {
        key: 'home.orisCustomers.orisTotalLoyaltyTransactions.tooltip',
        defaultValue:
          'The total number of returns made by loyalty program members, indicating engagement from frequent customers.',
      },
    },
    averageOrisTime: {
      text: {
        key: 'home.orisCustomers.averageOrisTime.text',
        defaultValue: 'Average Time',
      },
      tooltip: {
        key: 'home.orisCustomers.averageOrisTime.tooltip',
        defaultValue:
          'The average time taken to process each online return in-store, including any additional purchases or exchanges',
      },
    },
    duration: {
      seconds: {
        key: 'home.orisCustomers.duration.seconds',
        defaultValue: 'seconds',
      },
      minutes: {
        key: 'home.orisCustomers.duration.minutes',
        defaultValue: 'minutes',
      },
      hours: {
        key: 'home.orisCustomers.duration.hours',
        defaultValue: 'hours',
      },
    },
  },
  newMembers: {
    title: {
      key: 'home.newMembers.title',
      defaultValue: 'New Members',
    },
    subTitle: {
      key: 'home.newMembers.subTitle',
      defaultValue: 'New H&M Member Recruited',
    },
    error: {
      key: 'home.newMembers.error',
      defaultValue: 'We can´t show the new members widget right now.',
    },
    toolTip: {
      key: 'home.newMembers.toolTip',
      defaultValue: 'Hourly view of New Members in store. The Forecast is shown in numbers',
    },
    totalNewMembers: {
      key: 'home.newMembers.totalNewMembers',
      defaultValue: 'Total New Members',
    },
    totalNMShare: {
      key: 'home.newMembers.totalNMShare',
      defaultValue: 'Total New Member',
    },
    unrecruited: {
      key: 'home.newMembers.unrecruited',
      defaultValue: 'Total Unrecruited',
    },
  },
  receiptSales: {
    title: {
      key: 'home.receiptSales.title',
      defaultValue: 'Efficiency',
    },
    subTitle: {
      key: 'home.receiptSales.subTitle',
      defaultValue: 'Net Revenue and Units Sold',
    },
    error: {
      key: 'home.receiptSales.error',
      defaultValue: 'We can´t show the efficiency widget right now.',
    },
    toolTip: {
      key: 'home.receiptSales.toolTip',
      defaultValue: 'Takt1: Sales Net/Receipts, SPT: Sold Pieces/Receipts',
    },
    takt1: {
      key: 'home.receiptSales.takt1',
      defaultValue: 'Takt1',
    },
    spt: {
      key: 'home.receiptSales.spt',
      defaultValue: 'SPT',
    },
    budgetTakt: {
      key: 'home.receiptSales.budgetTakt',
      defaultValue: 'Show Budget/Planned - Takt1',
    },
    budgetSpt: {
      key: 'home.receiptSales.budgetSpt',
      defaultValue: 'Show Budget/Planned - SPT',
    },
  },
  piecesPerReceipt: {
    title: {
      key: 'home.piecesPerReceipt.title',
      defaultValue: 'Pieces per receipt',
    },
    subTitle: {
      key: 'home.piecesPerReceipt.subTitle',
      defaultValue: 'Pieces Sold per Receipt',
    },
    error: {
      key: 'home.piecesPerReceipt.error',
      defaultValue: 'We can´t show the pieces per receipt widget right now.',
    },
    toolTip: {
      key: 'home.piecesPerReceipt.toolTip',
      defaultValue: 'Sold Pieces per receipt in store',
    },
    headerText: {
      key: 'home.piecesPerReceipt.headerText',
      defaultValue: 'Pieces/Receipt',
    },
  },
  effectiveHours: {
    title: {
      key: 'home.effectiveHours.title',
      defaultValue: 'Effective Hours',
    },
    subTitle: {
      key: 'home.effectiveHours.subTitle',
      defaultValue: 'Productive Time Tracker',
    },
    error: {
      key: 'home.effectiveHours.error',
      defaultValue: 'We can´t show the effective hours right now.',
    },
  },
  averagePurchase: {
    title: {
      key: 'home.averagePurchase.title',
      defaultValue: 'Average Purchase',
    },
    subTitle: {
      key: 'home.averagePurchase.subTitle',
      defaultValue: 'Sales Net per Receipt ',
    },
    error: {
      key: 'home.averagePurchase.error',
      defaultValue: 'We can´t show the average purchase widget right now.',
    },
    toolTip: {
      key: 'home.averagePurchase.toolTip',
      defaultValue: 'Sales Net per Receipt',
    },
  },
  shareOfReceipts: {
    title: {
      key: 'home.shareOfReceipts.title',
      defaultValue: 'Member Share Of Receipts',
    },
    subTitle: {
      key: 'home.shareOfReceipts.subTitle',
      defaultValue: 'SoRs for ',
    },
    error: {
      key: 'home.shareOfReceipts.error',
      defaultValue: 'We can´t show the member share of receipts widget right now.',
    },
    sor: {
      key: 'home.shareOfReceipts.sor',
      defaultValue: 'Share of receipts',
    },
    toolTip: {
      key: 'home.shareOfReceipts.toolTip',
      defaultValue: 'Share of Receipts for the Member transactions. The Forecast is shown in percentage.',
    },
    totalMemberTransactions: {
      key: 'home.shareOfReceipts.totalMemberTransactions',
      defaultValue: 'Total Member Transactions',
    },
    totalShareOfReceipts: {
      key: 'home.shareOfReceipts.totalShareOfReceipts',
      defaultValue: 'Total Share Of Receipts',
    },
  },
  scoShareOfReceipts: {
    title: {
      key: 'home.scoShareOfReceipts.title',
      defaultValue: 'SCO - Share of Receipts',
    },
    subTitle: {
      key: 'home.scoShareOfReceipts.subTitle',
      defaultValue: 'SCO SoRs for ',
    },
    error: {
      key: 'home.scoShareOfReceipts.error',
      defaultValue: 'We can´t show the SCO share of receipts widget right now.',
    },
    sor: {
      key: 'home.scoShareOfReceipts.sor',
      defaultValue: 'SCO Share of receipts',
    },
    toolTip: {
      key: 'home.scoShareOfReceipts.toolTip',
      defaultValue: 'Share of Receipts for the SCO transactions. The Forecast is shown in percentage.',
    },
    totalSCOTransactions: {
      key: 'home.scoShareOfReceipts.totalSCOTransactions',
      defaultValue: 'Total SCO Transactions',
    },
    totalShareOfReceipts: {
      key: 'home.scoShareOfReceipts.totalShareOfReceipts',
      defaultValue: 'SCO Share Of Receipts',
    },
  },
  queueLength: {
    title: {
      key: 'home.queueLength.title',
      defaultValue: 'Queue Length',
    },
    subTitle: {
      key: 'home.queueLength.subTitle',
      defaultValue: 'Till queue count',
    },
    error: {
      key: 'home.queueLength.error',
      defaultValue: 'We can´t show the queue lenght widget right now.',
    },
    noData: {
      key: 'home.queueLength.noData',
      defaultValue: 'No Queues - Keep up the good work.',
    },
    toolTip: {
      key: 'home.queueLength.toolTip',
      defaultValue: 'This is the number of customers waiting in a queue.',
    },
    chartToolTip: {
      key: 'home.queueLength.chartToolTip',
      defaultValue: 'Overall Queue Length and Experience',
    },
    tillToolTip: {
      key: 'home.queueLength.tillToolTip',
      defaultValue: 'Till',
    },
    tabDefault: {
      key: 'home.queueLength.tabDefault',
      defaultValue: 'Regular Tills',
    },
    tabSelfCheckout: {
      key: 'home.queueLength.tabSelfCheckout',
      defaultValue: 'Self-service Checkout',
    },
    overallQueueLength: {
      key: 'home.queueLength.overallQueueLength',
      defaultValue: 'Overall Queue Length',
    },
    overallExperience: {
      key: 'home.queueLength.overallExperience',
      defaultValue: 'Overall Experience',
    },
  },
  appCustomers: {
    title: {
      key: 'home.appCustomers.title',
      defaultValue: 'App Customers',
    },
    subTitle: {
      key: 'home.appCustomers.subTitle',
      defaultValue: 'App-based Customers',
    },
    toolTip: {
      key: 'home.appCustomers.toolTip',
      defaultValue: 'Number of new customer who have made purchases directly through our mobile app',
    },
    error: {
      key: 'home.appCustomers.error',
      defaultValue: 'We can´t show the app customers widget right now.',
    },
    newCustomersText: {
      key: 'home.appCustomers.newCustomersText',
      defaultValue: 'New App Customers',
    },
  },
  rfidCount: {
    title: {
      key: 'home.rfidCount.title',
      defaultValue: 'RFID Count',
    },
    subTitle: {
      key: 'home.rfidCount.subTitle',
      defaultValue: 'Counts, Time Spent, and Scanners',
    },
    toolTip: {
      key: 'home.rfidCount.toolTip',
      defaultValue: 'Displays total counts, time spent, and scanners used.',
    },
    error: {
      key: 'home.rfidCount.error',
      defaultValue: "We can't show the RFID Count widget right now.",
    },
    countText: {
      title: {
        key: 'home.rfidCount.countText.title',
        defaultValue: 'Counts',
      },
      subTitle: {
        key: 'home.rfidCount.countText.subTitle',
        defaultValue: 'Total inventory counted. Values below 0 indicate immediate discrepancies.',
      },
    },
    timeText: {
      title: {
        key: 'home.rfidCount.timeText.title',
        defaultValue: 'Time Spent',
      },
      subTitle: {
        key: 'home.rfidCount.timeText.subTitle',
        defaultValue: 'Calculate total hours and average time spent for comprehensive time analysis.',
      },
    },
    scannerText: {
      title: {
        key: 'home.rfidCount.scannerText.title',
        defaultValue: 'Scanners used',
      },
      subTitle: {
        key: 'home.rfidCount.scannerText.subTitle',
        defaultValue: 'Shows a warning if fewer than 2 scanners are used (minimum of 2 required).',
      },
    },
  },
  customerFeedback: {
    title: {
      key: 'home.customerFeedback.title',
      defaultValue: 'Customer Satisfaction',
    },
    subTitle: {
      key: 'home.customerFeedback.subTitle',
      defaultValue: 'CSAT Summary',
    },
    toolTip: {
      key: 'home.customerFeedback.toolTip',
      defaultValue: 'Customer Satisfaction Score along with the responses',
    },
    error: {
      key: 'home.customerFeedback.error',
      defaultValue: 'We can´t show the customer feedback widget right now.',
    },
    customerResponses: {
      key: 'home.customerFeedback.customerResponses',
      defaultValue: 'Customer Responses',
    },
    viewAllResponses: {
      key: 'home.customerFeedback.viewAllResponses',
      defaultValue: 'View All Responses',
    },
    modalHeaders: {
      key: 'home.customerFeedback.modalHeaders',
      defaultValue: 'Customer responses',
    },
    modalSubHeaders: {
      key: 'home.customerFeedback.modalSubHeaders',
      defaultValue: 'View All Customer Response',
    },
    totalResponse: {
      key: 'home.customerFeedback.totalResponse',
      defaultValue: 'Total Response',
    },
    filterBy: {
      key: 'home.customerFeedback.filterBy',
      defaultValue: 'Filter By: ',
    },
    noData: {
      key: 'home.customerFeedback.noData',
      defaultValue: 'No customer feedback available yet.',
    },
  },
  areaComparison: {
    title: {
      key: 'home.areaComparison.title',
      defaultValue: 'Area',
    },
    subTitle: {
      key: 'home.areaComparison.subTitle',
      defaultValue: 'Weekly and Annual Data with Area',
    },
    toolTip: {
      key: 'home.areaComparison.toolTip',
      defaultValue: 'Target comparisons between stores in the area.',
    },
    error: {
      key: 'home.areaComparison.error',
      defaultValue: 'We can´t show the area comparison widget right now.',
    },
    equationGroup: {
      sales: {
        key: 'home.areaComparison.equationGroup.sales',
        defaultValue: 'Sales',
      },
      salesEquation: {
        key: 'home.areaComparison.equationGroup.salesEquation',
        defaultValue: 'Sales Equation',
      },
      efficiency: {
        key: 'home.areaComparison.equationGroup.efficiency',
        defaultValue: 'Efficiency',
      },
    },
  },
  rfidSalesRetagging: {
    title: {
      key: 'home.rfidSalesRetagging.title',
      defaultValue: 'RFID Sales',
    },
    subTitle: {
      key: 'home.rfidSalesRetagging.subTitle',
      defaultValue: 'Added Sales and Retagging',
    },
    tooltip: {
      key: 'home.rfidSalesRetagging.tooltip',
      defaultValue:
        'Shows the set sales target percentage and retagging compliance, where 100% indicates optimal performance.',
    },
    error: {
      key: 'home.rfidSalesRetagging.error',
      defaultValue: `We can't show the RFID Sales widget right now.`,
    },
    noData: {
      key: 'home.rfidSalesRetagging.noData',
      defaultValue: 'No RFID sales and retagging.',
    },
    addedSalesTitle: {
      key: 'home.rfidSalesRetagging.addedSalesTitle',
      defaultValue: 'Added Sales',
    },
    addedSalesDescription: {
      key: 'home.rfidSalesRetagging.addedSalesDescription',
      defaultValue:
        'Indicates the targeted percentage increase in sales, helping to evaluate business growth against set sales goals.',
    },
    retaggingRateTitle: {
      key: 'home.rfidSalesRetagging.retaggingRateTitle',
      defaultValue: 'Retagging Rate',
    },
    retaggingRateDescription: {
      key: 'home.rfidSalesRetagging.retaggingRateDescription',
      defaultValue: 'Percentage of inventory correctly retagged; 100% indicates complete compliance',
    },
  },
  rfidApprovals: {
    title: {
      key: 'home.rfidApprovals.title',
      defaultValue: 'RFID Approvals',
    },
    subTitle: {
      key: 'home.rfidApprovals.subTitle',
      defaultValue: 'Inventory Discrepancy and Adjustment',
    },
    tooltip: {
      key: 'home.rfidApprovals.toolTip',
      defaultValue:
        'Displays the difference between actual and expected inventory counts, indicating potential shortages (negative) or overstock (positive).',
    },
    error: {
      key: 'home.rfidApprovals.error',
      defaultValue: `We can't show the RFID approvals widget right now.`,
    },
    noData: {
      key: 'home.rfidApprovals.noData',
      defaultValue: 'No RFID approvals data available.',
    },
    negativeTitle: {
      key: 'home.rfidApprovals.negativeTitle',
      defaultValue: 'Negative Difference',
    },
    negativeDescription: {
      key: 'home.rfidApprovals.negativeDescription',
      defaultValue:
        'Indicates the number of inventory items missing compared to the expected count, highlighting potential loss or shortages.',
    },
    negativeMissingPieces: {
      key: 'home.rfidApprovals.negativeMissingPieces',
      defaultValue: 'Missing Pieces',
    },
    positiveTitle: {
      key: 'home.rfidApprovals.positiveTitle',
      defaultValue: 'Positive Difference',
    },
    positiveDescription: {
      key: 'home.rfidApprovals.positiveDescription',
      defaultValue:
        'Indicates the number of additional inventory items found beyond expectations, suggesting possible overstock or inventory miscounts.',
    },
    positiveExtraPieces: {
      key: 'home.rfidApprovals.positiveExtraPieces',
      defaultValue: 'Extra Pieces',
    },
    approvalRate: {
      key: 'home.rfidApprovals.approvalRate',
      defaultValue: 'Approval Rate',
    },
  },
  widgetCategory: {
    allWidgets: {
      key: 'home.widgetCategory.allWidgets',
      defaultValue: 'All Widgets',
    },
    highlightedWidgets: {
      key: 'home.widgetCategory.highlightedWidgets',
      defaultValue: 'Highlighted Widgets',
    },
    salesEfficiency: {
      key: 'home.widgetCategory.salesEfficiency',
      defaultValue: 'Sales & Efficiency',
    },
    customerBaseValue: {
      key: 'home.widgetCategory.customerBaseValue',
      defaultValue: 'Customer Base Value',
    },
    customerExperience: {
      key: 'home.widgetCategory.customerExperience',
      defaultValue: 'Customer Experience',
    },
    transactions: {
      key: 'home.widgetCategory.transactions',
      defaultValue: 'Transactions',
    },
  },
  kpiTargets: {
    addKpi: {
      key: 'home.kpiTargets.addKpi',
      defaultValue: 'Add KPI',
    },
    editKpi: {
      key: 'home.kpiTargets.editKpi',
      defaultValue: 'Edit KPI',
    },
    addkpiForNMShare: {
      key: 'home.kpiTargets.addkpiForNMShare',
      defaultValue: 'Add KPI for NM Share',
    },
    editkpiForNMShare: {
      key: 'home.kpiTargets.editkpiForNMShare',
      defaultValue: 'Edit KPI for NM Share',
    },
    addkpiForSOR: {
      key: 'home.kpiTargets.addkpiForSOR',
      defaultValue: 'Add KPI for SOR',
    },
    editkpiForSOR: {
      key: 'home.kpiTargets.editkpiForSOR',
      defaultValue: 'Edit KPI for SOR',
    },
    editAreaCap: {
      key: 'home.kpiTargets.editAreaCap',
      defaultValue: 'Edit Area Cap',
    },
    labelText: {
      key: 'home.kpiTargets.labelText',
      defaultValue: 'Enter {{modalType}} value for {{widgetName}}',
    },
    removeLabelText: {
      key: 'home.kpiTargets.removeLabelText',
      defaultValue: 'Are you sure you want to remove {{modalType}} for {{widgetName}}?',
    },
    errorHelperText1: {
      key: 'home.kpiTargets.errorHelperText1',
      defaultValue: 'No special characters or alphabets are allowed!',
    },
    errorHelperText2: {
      key: 'home.kpiTargets.errorHelperText2',
      defaultValue: '{{modalType}} value cannot be more than 10 digits',
    },
    errorHelperText3: {
      key: 'home.kpiTargets.errorHelperText3',
      defaultValue: '{{modalType}} value cannot be more than 100',
    },
    errorHelperText4: {
      key: 'home.kpiTargets.errorHelperText4',
      defaultValue: 'This field is required!',
    },
    errorHelperText5: {
      key: 'home.kpiTargets.errorHelperText5',
      defaultValue: 'Value cannot be 0. You can choose to remove target instead',
    },
    successText: {
      key: 'home.kpiTargets.successText',
      defaultValue: 'The {{modalType}} for {{widgetName}} widget has been {{operation}} successfully.',
    },
    errorText: {
      key: 'home.kpiTargets.errorText',
      defaultValue: 'The {{modalType}} could not be updated. Please try again!',
    },
    disabledTooltip: {
      key: 'home.kpiTargets.disabledTooltip',
      defaultValue: 'No receipts created to add/edit KPI',
    },
    noPermissionTooltip: {
      key: 'home.kpiTargets.noPermissionTooltip',
      defaultValue: 'You do not have permission to edit/add KPI',
    },
    lockedTargetTooltip: {
      key: 'home.kpiTargets.lockedTargetTooltip',
      defaultValue: 'Target is locked!',
    },
    note: {
      key: 'home.kpiTargets.note',
      defaultValue: ' Note: Editing Area Cap will affect all stores in the area',
    },
  },
};
