import styled from 'styled-components';
import { Button } from '../../../../stories/molecules';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(20)};
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletPortrait')} {
    flex-direction: column;
  }
`;

export const StyledEditAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(10)};
  height: ${({
    theme: {
      sizing: {
        avatarSettings: { card },
      },
    },
  }) => card.height};
  width: ${({
    theme: {
      sizing: {
        avatarSettings: { card },
      },
    },
  }) => card.width};
  margin: ${({ theme }) => theme.spacing(10)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedDark : color.backgroundSelected};
`;

export const StyledChangeAvatar = styled(Button)<{ isDarkMode: boolean }>`
  svg {
    color: ${({ theme: { color }, isDarkMode }) => (isDarkMode ? color.primary : color.white)};
  }
`;

export const StyledInputContainer = styled.div`
  width: 100%;
  height: 30%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
