import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../global-state/themeContext';
import { Icons, Typography } from '../../../../stories/atoms';
import { editAvatar } from '../../../../translations';
import { sideMenuTranslations } from '../../../../translations/navigation';
import { AvatarPlaceHolder } from '../avatar-placeholder/AvatarPlaceholder';
import {
  StyledCardWrapper,
  StyledChangeAvatar,
  StyledContainer,
  StyledEditAvatarContainer,
  StyledWrapper,
} from './EditAvatar.styles';

type EditAvatarProps = {
  canEditAvatar: boolean;
  avatarUrl: string;
  firstName?: string;
  lastName?: string;
  upn?: string;
  onUpdateAvatar: () => void;
};

export const EditAvatar: FC<React.PropsWithChildren<EditAvatarProps>> = ({
  canEditAvatar,
  avatarUrl,
  onUpdateAvatar,
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  const headerElement = (
    <>
      <Typography type="h3">
        {t(sideMenuTranslations.avatarManagement.key, sideMenuTranslations.avatarManagement.defaultValue)}
      </Typography>
    </>
  );

  const editAvatarElement = (
    <StyledEditAvatarContainer>
      <AvatarPlaceHolder
        header={t(editAvatar.title.key, editAvatar.title.defaultValue)}
        subHeader={t(editAvatar.currentAvatar.key, editAvatar.currentAvatar.defaultValue)}
        avatarUrl={avatarUrl}
      >
        <StyledWrapper>
          <StyledChangeAvatar
            label={t(editAvatar.changeAvatarButton.key, editAvatar.changeAvatarButton.defaultValue)}
            buttonContentProps={{
              iconOptions: {
                icon: Icons.EDIT,
                size: 'small',
              },
            }}
            data-testid="update-avatar-button"
            isDarkMode={mode === 'dark'}
            onClick={onUpdateAvatar}
            disabled={!canEditAvatar}
          />
        </StyledWrapper>
      </AvatarPlaceHolder>
    </StyledEditAvatarContainer>
  );

  return (
    <StyledContainer data-testid="edit-avatar-screen">
      {headerElement}
      <StyledCardWrapper>
        <div data-testid="edit-avatar-card">{editAvatarElement}</div>
      </StyledCardWrapper>
    </StyledContainer>
  );
};
