/* istanbul ignore file */
import styled from 'styled-components';
import { Icons, Tooltip, Typography, TypographyProps } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { formatAmountWithSeparator } from '../../../utils';

export const StyledTrendIconWithText = styled(IconWithText)`
  & > :nth-child(1) {
    color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.white};
  }
  & > :nth-child(2) {
    color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.white};
  }
`;

type TrendContentProps = {
  value: number;
  diff: number;
  percentageDiff: number;
  textOptions?: TypographyProps;
};

export const TrendContent = (props: TrendContentProps) => {
  const { value, diff, percentageDiff, textOptions } = props;

  return (
    <Tooltip position="top-end" text={<Typography color="white">{formatAmountWithSeparator(value)}</Typography>}>
      <div>
        <StyledTrendIconWithText
          label={`${percentageDiff}%`}
          iconOptions={{
            icon: Icons.ARROW,
            size: 'small',
            rotate: diff > 0 ? 'right' : 'left',
            color: diff > 0 ? 'validationGreen' : 'red',
          }}
          textOptions={{
            ...textOptions,
            color: diff > 0 ? 'validationGreen' : 'red',
          }}
        />
      </div>
    </Tooltip>
  );
};
