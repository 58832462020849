import styled from 'styled-components';
import { Icon } from '../../stories/atoms';

export const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

export const StyledHeader = styled.div`
  border-bottom: ${({ theme: { color } }) => `1px solid ${color.borderGray}`};
  padding: ${({ theme }) => theme.spacing(4)};
  display: flex;
  align-items: center;
`;
