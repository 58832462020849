import { rest } from 'msw';
import {
  avatarIsUniqueEndpoint,
  avatarsEndpoint,
  baseUrl,
  baseUrlCDN,
  profilesEndpoint,
  updateProfileEndpoint,
} from '../../constants';
import { getMockProfile, mockAvatars, getMockedEmployees } from '../mocks';
import { mockStores } from '../mocks/store.mock';

const { storeId } = mockStores[0];

export const profileByUpnRequest = ({
  addDelay,
  isAvatarAssigned = true,
}: {
  addDelay?: boolean;
  isAvatarAssigned?: boolean;
}) =>
  rest.get(`${baseUrl}${profilesEndpoint}/:upn`, (_req, res, ctx) => {
    let mockProfile = getMockProfile(storeId);
    if (!isAvatarAssigned) mockProfile = { ...mockProfile, avatar: undefined };

    return res(
      ctx.status(200),
      ctx.json(mockProfile),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchAllEmployeesInStore = (addDelay?: boolean, fixedAssignment?: boolean) =>
  rest.get(`${baseUrl}${profilesEndpoint}`, (req, res, ctx) => {
    const id = req.url.searchParams.get('storeId') as string;

    const mockData = getMockedEmployees(10, id, fixedAssignment);

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const avatarsRequest = (addDelay?: boolean) =>
  rest.get(`${baseUrlCDN}${avatarsEndpoint}`, async (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.xml(mockAvatars),
      ctx.set('Content-Type', 'application/xml'),
      ctx.delay(addDelay ? 1000 : 0),
    );
  });

export const avatarUniquenessRequest = (addDelay?: boolean, isUnique = true) =>
  rest.get(`${baseUrl}${avatarIsUniqueEndpoint}`, (_req, res, ctx) => {
    const mockData = { isUnique };

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 1000 : 0),
    );
  });

export const updateProfileRequest = (addDelay?: boolean) =>
  rest.put(`${baseUrl}${updateProfileEndpoint}`, (_req, res, ctx) => {
    const mockData = getMockProfile(storeId);

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
