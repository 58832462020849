import { Assignment } from '../global-state/types';

export const roleInExtensionAttribute = {
  '1': 'SM',
  '2': 'FM',
  '3': 'SC',
  '4': 'SV',
  '5': 'EV',
} as const;

export const parseJWTToken = <T>(token: string): T => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(atob(base64));
};

export const isJWTTokenValid = (expirationTime: number) => {
  return expirationTime > Date.now();
};

// See ExtensionAttribute7 format: https://hmgroup.atlassian.net/wiki/spaces/XDIM/pages/609003110/Using+extensionAttribute7+to+authorize+Store+Workers
export const parseExtensionAttributeAssignements = (extensionAttribute7?: string) => {
  const assignments: Assignment[] = [];
  if (!extensionAttribute7) return assignments;

  extensionAttribute7.split('|').forEach((assignment) => {
    const [suamRoleID, storeNumber, , , countryCode] = assignment.split(':');

    const suamRole = roleInExtensionAttribute[suamRoleID as unknown as keyof typeof roleInExtensionAttribute];
    const storeId = countryCode + storeNumber;

    assignments.push({ suamRoleCode: suamRole, locationCode: storeId, isActive: true });
  });

  return assignments;
};
