import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

type TagButtonProps = {
  isActive: boolean;
};

export const TagButton = styled.button<TagButtonProps>`
  height: ${({ theme }) => theme.spacing(6)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ isActive, theme: { color, palette } }) =>
    isActive
      ? palette.mode === 'dark'
        ? color.backgroundSelectedHover
        : color.backgroundTableHeader
      : palette.mode === 'dark'
      ? color.backgroundPaperDark
      : color.white};
  border: 1px solid
    ${({ theme: { palette, color } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  cursor: pointer;
`;
