import styled from 'styled-components';
import { Icon, Typography } from '../../stories/atoms';
import { Colors } from '../../theme';

export const StyledCategoryContainer = styled.div`
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing(2, 8)};
  justify-content: space-between;
  > div {
    display: flex;
  }
`;

export const StyledIcon = styled(Icon)<{ isSelected: boolean }>`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ isSelected, theme: { color, palette } }) =>
    isSelected
      ? palette.mode === 'dark'
        ? color.secondaryTextGray
        : color.backgroundSelected
      : palette.mode === 'light'
      ? color.borderGray
      : color.backgroundSelectedHover};
  justify-content: center;
`;

export const StyledDropdown = styled.div`
  > button {
    border: 1px solid ${({ theme: { color } }) => color.borderGray};
    padding: ${({ theme: { spacing } }) => spacing(0, 2)};
    border-radius: ${({ theme: { spacing } }) => spacing(2)};
    height: ${({ theme: { spacing } }) => spacing(8)};
  }
`;

export const StyledCustomLegends = styled(Typography)<{ legendColor: Colors }>`
  &:before {
    content: '';
    display: inline-block;
    width: ${({ theme: { spacing } }) => spacing(3)};
    height: ${({ theme: { spacing } }) => spacing(3)};
    border-radius: ${({ theme: { spacing } }) => spacing(3)};
    margin: ${({ theme: { spacing } }) => spacing(0, 1)};
    align-items: center;
    line-height: 0;
    background-color: ${({ theme: { color }, legendColor }) => color && color[legendColor]};
  }
`;

export const StyledSwitchWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing }, isMobile }) => isMobile && spacing(2, 0)};
`;
