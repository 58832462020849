import { Property } from 'csstype';
import { Colors, Typographies } from '../../../theme';
import { Spacing } from '../../../utils';
import { StyledTypography } from './Typography.styles';

export type TypographyProps = {
  color?: Colors;
  hoverColor?: Colors;
  hoverBodderBottomColor?: Colors;
  gutterBottom?: boolean;
  borderBottomColor?: Colors;
  padding?: Spacing;
  margin?: Spacing;
  type?: Typographies;
  darkMode?: boolean;
  wrapText?: boolean;
  italic?: boolean;
  letterSpacing?: Property.LetterSpacing;
  textAlign?: Property.TextAlign;
  whiteSpace?: Property.WhiteSpace;
  decoration?: Property.TextDecoration;
  overflow?: Property.Overflow;
};

export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({
  children,
  type = 'body2',
  color,
  whiteSpace = 'pre-wrap',
  overflow = 'hidden',
  ...props
}) => {
  return (
    <StyledTypography
      data-testid="typography"
      type={type}
      color={color}
      whiteSpace={whiteSpace}
      overflow={overflow}
      {...props}
    >
      {children}
    </StyledTypography>
  );
};
