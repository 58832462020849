import { Divider } from '@mui/material';
import styled, { css } from 'styled-components';
import { Button } from '../../../../stories/molecules';

const lightModeGradient = css`
  background: ${({ theme: { color } }) => `
  linear-gradient(
    0deg,
    ${color.white} 0%,
    rgba(250, 249, 248, 0.95) 8%, 
    rgba(250, 249, 248, 0.5) 54%,
    rgba(250, 249, 248, 0.08) 100%
  );  `};
`;

const darkModeGradient = css`
  background: ${({ theme: { color } }) => `
  linear-gradient(
    0deg,
    ${color.backgroundDark} 0%,
  rgba(33, 43, 54, 0.95) 8%,
  rgba(33, 43, 54, 0.5) 54%,
  rgba(33, 43, 54, 0.08) 100%
  );  `};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledSelectAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const StyledListAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: ${({ theme: { spacing } }) => spacing(5, 0, 0, 20)};
  overflow-y: auto;

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    padding: ${({ theme: { spacing } }) => spacing(2, 0, 0, 10)};
  }
`;

export const StyledSaveButton = styled(Button)<{ isDarkMode: boolean; disabled: boolean }>`
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
  svg {
    color: ${({ theme: { color }, isDarkMode, disabled }) =>
      disabled ? color.primary : isDarkMode ? color.primary : color.white};
  }
`;

export const StyledImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledAvatarIcon = styled.img`
  margin: ${({ theme: { spacing } }) => spacing(3)};
  height: ${({ theme }) => theme.sizing.avatarSize.small};
  width: ${({ theme }) => theme.sizing.avatarSize.small};
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }

  &.selected {
    transform: scale(1.2);
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  border-radius: 50%;

  &.selected {
    background-color: ${({ theme: { color } }) => color.baseGray};
    box-shadow: ${({ theme: { color } }) => `0 0 10px ${color.primary}`};
  }
`;

export const StyledAvatarList = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(5)};
  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    padding: ${({ theme: { spacing } }) => spacing(2)};
  }
`;

export const StyledGradient = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 10%;
  width: 50%;
  ${({ theme: { palette } }) => (palette.mode === 'dark' ? darkModeGradient : lightModeGradient)};
  transition: opacity 1s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
`;

export const StyledDivider = styled(Divider)`
  height: 90%;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' && color.backgroundTableHeaderDark};
`;

export const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  > :last-child {
    margin-top: ${({ theme }) => theme.spacing(5)};
  }
`;

export const StyledButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  padding: ${({ theme: { spacing } }) => spacing(6)};
`;

export const StyledCategoryList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: ${({ theme }) => theme.spacing(0, 5)};
`;
