import { GetBlacklistPermissionsResponse, PermissionItemsMap } from '../../hooks/useUserPermissions';

export const mockPermissions: PermissionItemsMap = {
  transactionSearchPage: 'R',
  employeesAndRolesPage: 'R',
  avatarManagementPage: 'R',
  kpiTargetsPage: 'R',
  chatBoxPage: 'R',
  profitAndLossPage: 'R',
  newMembersWidget: 'R',
  appCustomers: 'R',
  shareOfReceiptWidget: 'R',
  topSellingGarmentsWidget: 'R',
  salesPerformanceWidget: 'R',
  conversionRateWidget: 'R',
  registeredReceiptsWidget: 'R',
  tenderOverviewWidget: 'R',
  assetManagementWidget: 'R',
  avgPiecesPerReceiptWidget: 'R',
  avgPurchasePerReceiptWidget: 'R',
  effectiveHoursWidget: 'R',
  areaComparisonWidget: 'R',
  customerFeedBackWidget: 'R',
  takt1Widget: 'R',
  sptWidget: 'R',
  personalSettings: 'CRUD',
  kpiTargets: 'CRUD',
  areaCaps: 'CRUD',
  lockTargets: 'CRUD',
  highlightWidget: 'CRUD',
  financialPlanned: 'R',
  financialBudget: 'R',
  employeePermissions: 'RU',
  soldProductsPage: 'CRUD',
  productIndexSubindex: 'CRUD',
  blacklistPermissionsForArea: 'CRUD',
  queueLengthWidget: 'CRUD',
  scoShareOfReceipt: 'CRUD',
  scoSalesPerformance: 'CRUD',
  orisCustomerWidget: 'CRUD',
  rfidApprovalsWidget: 'R',
};

export const getBlacklistPermissionsMockResponse: GetBlacklistPermissionsResponse = {
  areaCode: 'SE Mälardalen',
  permissions: ['conversionRateWidget', 'topSellingGarmentsWidget', 'newMembersWidget'],
};

export const postBlacklistPermissionsMockResponse: GetBlacklistPermissionsResponse = {
  areaCode: 'SE Mälardalen',
  permissions: ['conversionRateWidget'],
};
