import { PickersLayout } from '@mui/x-date-pickers';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(6)};

  > div {
    .PrivatePickersSlideTransition-root {
      min-width: ${({ theme }) => theme.spacing(100)};
    }
  }
`;

export const StyledPickersLayout = styled(PickersLayout)(({ theme: { color, palette } }) => {
  const commonColor = palette.mode === 'dark' ? color.borderGray : color.textGray;
  const disabledColor = palette.mode === 'dark' ? color.borderGrayDark : color.inActiveGray;

  return {
    '.MuiDayCalendar-weekNumberLabel, .MuiDayCalendar-weekDayLabel, .MuiDayCalendar-weekNumber': {
      color: commonColor,
    },
    '.MuiDayCalendar-weekContainer .Mui-disabled': {
      color: disabledColor,
    },
  };
});
