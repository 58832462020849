import styled from 'styled-components';
import { Container } from '../../../stories/atoms';
import { Button } from '../../../stories/molecules';

export const StyledContainer = styled(Container)`
  padding: ${({ theme: { spacing } }) => spacing(2, 10)};
  max-width: ${({ theme: { spacing } }) => spacing(125)};

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    padding-top: ${({ theme: { spacing } }) => spacing(10)};
  }
`;

export const StyledCloseButton = styled(Button)`
  margin-right: ${({ theme: { spacing } }) => spacing(4)};
  flex: 1;
`;

export const StyledSwitchButton = styled(Button)`
  flex: 1;
`;
