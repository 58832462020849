import { ReactNode } from 'react';
import { Icons } from '../../../atoms';
import { IconWithText } from '../../icon-with-text/IconWithText';
import { Container, TagsContainer } from './FilterTagsWidget.styles';

type Props = {
  filterTags: ReactNode[];
  isClearButtonVisible?: boolean;
  clearButtonText?: string;
  clearButtonHandler?: () => void;
};

export const FilterTagsWidget = ({
  filterTags,
  isClearButtonVisible = false,
  clearButtonText,
  clearButtonHandler,
}: Props) => {
  return (
    <Container>
      <TagsContainer>{filterTags}</TagsContainer>

      {isClearButtonVisible && (
        <IconWithText
          onClick={clearButtonHandler}
          label={clearButtonText}
          iconOptions={{
            icon: Icons.TRASHBIN,

            margin: [0, 2],
            size: 'small',
          }}
          labelPosition="left"
          padding={[2, 0, 2, 2]}
        />
      )}
    </Container>
  );
};
