import { useRef, useState } from 'react';

type State<T> = {
  isLoading: boolean;
  isError: boolean;
  result: T | null;
};

type AsyncAction<T> = () => Promise<T>;

export const useAsyncAction = <T>(persistPreviousState: boolean = false) => {
  const [state, setState] = useState<State<T>>({
    isLoading: false,
    isError: false,
    result: null,
  });

  const actionId = useRef(0);

  const triggerAction = async (action: AsyncAction<T>) => {
    if (persistPreviousState) {
      setState({ ...state, isLoading: true });
    } else {
      setState({ isLoading: true, isError: false, result: null });
    }

    actionId.current = actionId.current + 1;
    const currentActionId = actionId.current;

    return action()
      .then((result) => {
        if (actionId.current !== currentActionId) return;
        setState({ isLoading: false, isError: false, result });
      })
      .catch((error) => {
        console.log(error);
        if (actionId.current !== currentActionId) return;
        setState({ isLoading: false, isError: true, result: null });
      });
  };

  return { ...state, triggerAction };
};
