import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Drawer, Icons, Typography } from '../../stories/atoms';
import { useStores, useUser, WeatherResponse } from '../../hooks';
import { homeTranslations } from '../../translations';
import { isFailureResponse } from '../../utils';
import { StyledHeader, StyledIcon } from './HomepageSlider.styles';
import { WeatherWidget } from './weatherWidget/WeatherWidget';
import { StoreOpeningHoursWidget } from './storeOpeningHoursWidget/StoreOpeningHoursWidget';
import { MyStoreMap } from './myStoreMap/MyStoreMap';

export type HomePageSliderProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const HomePageSlider: FC<React.PropsWithChildren<HomePageSliderProps>> = ({ isOpen, onClose }) => {
  const { fetchWeatherWidgetData } = useStores();
  const {
    get: { currentStoreId },
  } = useUser();

  const [weatherDetails, setWeatherDetails] = useState<{
    data: WeatherResponse;
    isError: boolean;
  }>({
    data: {} as WeatherResponse,
    isError: false,
  });

  const [openingHoursDetails, setOpeningHoursDetail] = useState<{
    isError: boolean;
  }>({
    isError: false,
  });

  const [isSliderOpen, setIsSliderOpen] = useState(isOpen);

  useEffect(() => {
    setIsSliderOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!currentStoreId) return;

    const fetchWeatherData = async () => {
      const response = await fetchWeatherWidgetData();

      if (isFailureResponse(response)) {
        setWeatherDetails({ data: {} as WeatherResponse, isError: true });
        return;
      }

      const { data } = response;

      setWeatherDetails({ data, isError: false });
    };

    fetchWeatherData();
  }, [currentStoreId]);

  useEffect(() => {
    setOpeningHoursDetail({ isError: false });
  }, [currentStoreId]);

  const { slider } = homeTranslations;
  const { t } = useTranslation();

  const handleClose = () => {
    setIsSliderOpen(false);
    if (onClose) onClose();
  };

  return (
    <Drawer variant="temporary" position="right" isOpen={isSliderOpen} onClose={() => handleClose()}>
      <Box data-testid="slider" sx={{ width: 350 }}>
        <StyledHeader>
          <div>
            <Typography type="h4">
              {t(slider.generalInformation.key, slider.generalInformation.defaultValue)}
            </Typography>
            <Typography color="secondaryTextGray">{currentStoreId}</Typography>
          </div>
          <StyledIcon data-testid="closeIcon" icon={Icons.CLOSE} size="small" onClick={handleClose} />
        </StyledHeader>
        <MyStoreMap />
        <WeatherWidget data={weatherDetails.data} isError={weatherDetails.isError} />
        <StoreOpeningHoursWidget isError={openingHoursDetails.isError}></StoreOpeningHoursWidget>
      </Box>
    </Drawer>
  );
};
