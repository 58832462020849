import { createContext, useContext, useRef, useState } from 'react';
import { getEventSource } from '../utils';
import { baseUrl, registerEndpoint } from '../constants';

type EventContextType = {
  transactionEventsCount: number;
  permissionEvents: MessageEvent<string>[];
  registerEvent: (upn: string, storeId?: string) => void;
};

const EventContext = createContext<EventContextType>({} as EventContextType);
const useEventContext = () => useContext(EventContext);

const EventProvider = ({ children }: { children: React.ReactNode }) => {
  const [transactionEventsCount, setTransactionEventsCount] = useState<number>(0);
  const [permissionEvents, setPermissionEvents] = useState<MessageEvent<string>[]>([]);

  const eventSourceRef = useRef<EventSource | null>(null);

  const registerEvent = async (upn: string, storeId?: string) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    eventSourceRef.current = getEventSource(`${baseUrl}${registerEndpoint}?upn=${upn}&storeId=${storeId}`);

    eventSourceRef.current.addEventListener('newTransactions', () => {
      setTransactionEventsCount((prev) => prev + 1);
    });
    eventSourceRef.current.addEventListener('permission', (event: MessageEvent<string>) => {
      setPermissionEvents((prev) => [...prev, event]);
    });
  };

  return (
    <EventContext.Provider value={{ transactionEventsCount, permissionEvents, registerEvent }}>
      {children}
    </EventContext.Provider>
  );
};

export { useEventContext, EventProvider };
