import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { intlFormat } from 'date-fns';
import { Typography } from '../../../../stories/atoms';
import { Button } from '../../../../stories/molecules';
import { Profile } from '../../../../global-state/types';
import { profileTranslations, commonTranslations, employeesTranslations } from '../../../../translations';
import { useUser } from '../../../../hooks';
import { isSupportedSuamRole, RoleMapping } from '../../../../utils';
import {
  StyledContainer,
  StyledTypography,
  StyledBody,
  ProfileData,
  StyledInfo,
  StyledPermissions,
  StyledTimeLimitedPermissions,
  StyledButtonWrapper,
  StyledPermissionsList,
  StyledWrapper,
  StyledText,
} from './EmployeeDetailsModal.styles';

type EmployeeDetailsModalProps = {
  employee: Profile;
  isCurrentUser: boolean;
  handleClose: () => void;
  handleEditPermissions: () => void;
};

type PermissionsContentProps = {
  header: string;
  permissionsInfo: string;
  permissions: {
    [key: string]: StorePermissions;
  };
  isTimeLimitPermissions?: boolean;
};

type StorePermissions = {
  addPerms: string[];
  timelimitPerms: string[];
};

const { fullName, role: roleTranslation, staffId } = profileTranslations;
const { close } = commonTranslations;
const {
  seeDetailsModal: {
    detailedInfo,
    additionalPermission: additionalPermTranslations,
    timeLimitedPermission: timeLimitPermTranslations,
    additionalPermissionInfo,
    timeLimitedPermissionInfo,
    manageUserButtonText,
  },
  employees: { other },
} = employeesTranslations;

const { name, upn: emailTranslation } = profileTranslations;

export const EmployeeDetailsModal: FC<React.PropsWithChildren<EmployeeDetailsModalProps>> = ({
  employee,
  isCurrentUser,
  handleClose,
  handleEditPermissions,
}) => {
  const { firstName, lastName, assignments, upn, id, additionalPermissions } = employee;

  const { t, i18n } = useTranslation();
  const {
    get: { currentStoreId, userPermissions },
  } = useUser();

  const canEditPermissions = userPermissions.employeePermissions.canUpdate;

  const shouldShowManageUserButton = !isCurrentUser && canEditPermissions;

  const selectedEmployeeRole = [
    [
      ...new Set(
        assignments
          ?.filter(({ locationCode }) => locationCode === currentStoreId)
          .map(({ suamRoleCode }) => suamRoleCode),
      ),
    ].map(
      (suamRoleCode, index) =>
        (index ? ', ' : '') +
        (isSupportedSuamRole(suamRoleCode)
          ? t(`employees.roles.${RoleMapping[suamRoleCode]}`, RoleMapping[suamRoleCode])
          : t(other.key, other.defaultValue)),
    ),
  ];

  const filteredPermissions =
    additionalPermissions &&
    Object.entries(additionalPermissions).reduce(
      (storePermissions: { [key: string]: StorePermissions }, [storeKey, storeValues]) => {
        const allPermissions = Object.keys(storeValues).reduce(
          (permissions: { addPerms: string[]; timelimitPerms: string[] }, key) => {
            if (
              additionalPermissions &&
              additionalPermissions[storeKey][key] &&
              additionalPermissions[storeKey][key].endDate
            )
              permissions.timelimitPerms.push(key);
            else permissions.addPerms.push(key);
            return permissions;
          },
          { addPerms: [], timelimitPerms: [] },
        );

        const hasCurrentStoreIdKey = Object.keys(additionalPermissions).includes(currentStoreId);

        if (hasCurrentStoreIdKey) {
          storePermissions[currentStoreId] = allPermissions;
        } else if (storeKey === 'box' && !hasCurrentStoreIdKey) {
          // Falllback to box permissions if currentStoreId is not present
          storePermissions.box = allPermissions;
        }

        return storePermissions;
      },
      {},
    );

  const PermissionsContent = ({
    header,
    permissionsInfo,
    permissions,
    isTimeLimitPermissions,
  }: PermissionsContentProps) => {
    return (
      <>
        <StyledTypography type="h4">{header}</StyledTypography>
        <Typography gutterBottom>{permissionsInfo}</Typography>

        {Object.keys(permissions).map((permission) => (
          <StyledBody key={permission}>
            <StyledPermissionsList isTimeLimitPermissions={isTimeLimitPermissions}>
              {permissions[permission][!isTimeLimitPermissions ? 'addPerms' : 'timelimitPerms'].map((key) => (
                <StyledWrapper key={key} data-testid="permission-list">
                  <StyledText>
                    <Typography>{t(`common.pages.${key}`, key)}</Typography>
                    <Typography padding={[0, 0, 0, 1]}>
                      ({additionalPermissions && additionalPermissions[permission][key]?.permissionType})
                    </Typography>
                  </StyledText>
                  <Typography padding={[0, 0, 0, 4]}>
                    {additionalPermissions &&
                      additionalPermissions[permission][key]?.endDate &&
                      intlFormat(
                        new Date(additionalPermissions[permission][key]?.endDate as string),
                        {},
                        {
                          locale: i18n.language,
                        },
                      )}
                  </Typography>
                </StyledWrapper>
              ))}
            </StyledPermissionsList>
          </StyledBody>
        ))}
      </>
    );
  };

  const showAdditionalPermissions =
    filteredPermissions &&
    ((currentStoreId && filteredPermissions[currentStoreId]?.addPerms.length > 0) ||
      filteredPermissions.box?.addPerms.length > 0);

  const showTimeLimitedPermissions =
    filteredPermissions &&
    ((currentStoreId && filteredPermissions[currentStoreId]?.timelimitPerms.length > 0) ||
      filteredPermissions.box?.timelimitPerms.length > 0);

  return (
    <StyledContainer data-testid="employee-details-modal">
      <StyledInfo>
        <StyledTypography type="h4">{t(detailedInfo.key, detailedInfo.defaultValue)}</StyledTypography>
        <StyledBody>
          <ProfileData>
            <Typography>{t(name.key, name.defaultValue)}</Typography>
            <Typography>
              {t(fullName.key, {
                defaultValue: fullName.defaultValue,
                firstName,
                lastName,
              })}
            </Typography>
          </ProfileData>
          <ProfileData>
            <Typography>{t(roleTranslation.key, roleTranslation.defaultValue)}</Typography>
            <Typography>{selectedEmployeeRole}</Typography>
          </ProfileData>
          <ProfileData>
            <Typography>{t(staffId.key, staffId.defaultValue)}</Typography>
            <Typography>{id}</Typography>
          </ProfileData>
          <ProfileData>
            <Typography>{t(emailTranslation.key, emailTranslation.defaultValue)}</Typography>
            <Typography>{upn}</Typography>
          </ProfileData>
        </StyledBody>
      </StyledInfo>

      {showAdditionalPermissions && (
        <StyledPermissions data-testid="additional-permissions">
          <PermissionsContent
            permissions={filteredPermissions}
            header={t(additionalPermTranslations.key, additionalPermTranslations.defaultValue)}
            permissionsInfo={t(additionalPermissionInfo.key, additionalPermissionInfo.defaultValue)}
          />
        </StyledPermissions>
      )}

      {showTimeLimitedPermissions && (
        <StyledTimeLimitedPermissions data-testid="time-limited-permissions">
          <PermissionsContent
            permissions={filteredPermissions}
            header={t(timeLimitPermTranslations.key, timeLimitPermTranslations.defaultValue)}
            permissionsInfo={t(timeLimitedPermissionInfo.key, timeLimitedPermissionInfo.defaultValue)}
            isTimeLimitPermissions
          />
        </StyledTimeLimitedPermissions>
      )}

      <StyledButtonWrapper>
        <Button label={t(close.key, close.defaultValue)} variant="secondary" onClick={handleClose} />
        {shouldShowManageUserButton && (
          <Button
            label={t(manageUserButtonText.key, manageUserButtonText.defaultValue)}
            onClick={handleEditPermissions}
          />
        )}
      </StyledButtonWrapper>
    </StyledContainer>
  );
};
