import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KpiDropdownActions } from '../../../constants';
import { useUser } from '../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../stories/atoms';
import { DropdownMenu } from '../../../stories/molecules';
import { homeTranslations, kpiTargetTransalations } from '../../../translations';

export type KpiDropdownProps = {
  isTargetLocked: boolean;
  target: number | null;
  areaCapValue: number | null;
  isKpiHighlighted: boolean;
  sendKpiDropdownDetails: (dropdownValue: KpiDropdownActions) => void;
};

const KpiDropdown: FC<KpiDropdownProps> = ({
  isTargetLocked,
  target,
  areaCapValue,
  isKpiHighlighted,
  sendKpiDropdownDetails,
}) => {
  const { t } = useTranslation();
  const {
    get: { userPermissions },
  } = useUser();
  const canEditKpi = userPermissions.kpiTargets.canUpdate;
  const canRemoveKpi = userPermissions.kpiTargets.canDelete;
  const canEditAreaCap = userPermissions.areaCaps.canUpdate;
  const canRemoveAreaCap = userPermissions.areaCaps.canDelete;
  const canHighlightKpi = userPermissions.highlightWidget.canUpdate;

  const isRemoveKpiDisabled = isTargetLocked || target === null || !canRemoveKpi;

  const { kpiDropdown } = kpiTargetTransalations;
  const { kpiTargets } = homeTranslations;

  return (
    <DropdownMenu
      data-testid="kpi-dropdown"
      dropdownLabel={''}
      buttonContentProps={{
        iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
      }}
      minWidth={'0px'}
      isCompact
      initialSelected={[]}
      onSelect={(selectedItem) => {
        sendKpiDropdownDetails(selectedItem[0] as KpiDropdownActions);
      }}
      isOnlyIcon
      allowTooltip
      menuItems={[
        {
          key: target === null ? 'addKpi' : 'editKpi',
          disabled: isTargetLocked || !canEditKpi,
          hasTooltip: isTargetLocked || !canEditKpi,
          item: (
            <>
              <Icon data-testid="infoIcon" icon={Icons.EDIT} size="small" margin={[0, 1, 0, 0]} />
              <Typography margin={[0, 2, 0]}>
                {target === null
                  ? t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)
                  : t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)}
              </Typography>
            </>
          ),
          tooltipText: (
            <Container padding={[1]}>
              <Typography color="white">
                {isTargetLocked
                  ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                  : t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)}
              </Typography>
            </Container>
          ),
        },
        {
          key: 'removeKpi',
          disabled: isRemoveKpiDisabled,
          hasTooltip: isRemoveKpiDisabled,
          item: (
            <>
              <Icon data-testid="infoIcon" icon={Icons.TRASHBIN} size="small" margin={[0, 1, 0, 0]} />
              <Typography margin={[0, 2, 0]}>
                {t(kpiDropdown.removeKpi.key, kpiDropdown.removeKpi.defaultValue)}
              </Typography>
            </>
          ),
          tooltipText: (
            <Container padding={[1]}>
              <Typography color="white">
                {isTargetLocked ? (
                  <>{t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)}</>
                ) : target === null ? (
                  <Trans components={{ b: <b /> }}>
                    {t(kpiDropdown.cannotEditTooltip.key, {
                      defaultValue: kpiDropdown.cannotEditTooltip.defaultValue,
                      kpiProperty: 'KPI',
                    })}
                  </Trans>
                ) : (
                  t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                )}
              </Typography>
            </Container>
          ),
        },
        {
          key: areaCapValue === null ? 'addAreaCap' : 'editAreaCap',
          disabled: !canEditAreaCap,
          hasTooltip: !canEditAreaCap,
          tooltipText: (
            <Container padding={[1]}>
              <Typography color="white">
                {t(
                  kpiDropdown.noPermissionTooltipAreaCap.key,
                  kpiDropdown.noPermissionTooltipAreaCap.defaultValue,
                )}
              </Typography>
            </Container>
          ),
          item: (
            <>
              <Icon data-testid="infoIcon" icon={Icons.EDIT} size="small" margin={[0, 1, 0, 0]} />
              <Typography margin={[0, 2, 0]}>
                {areaCapValue === null
                  ? t(kpiDropdown.addAreaCap.key, kpiDropdown.addAreaCap.defaultValue)
                  : t(kpiTargets.editAreaCap.key, kpiTargets.editAreaCap.defaultValue)}
              </Typography>
            </>
          ),
        },
        {
          key: 'removeAreaCap',
          disabled: !canRemoveAreaCap || areaCapValue === null,
          hasTooltip: !canRemoveAreaCap || areaCapValue === null,
          tooltipText: (
            <Container padding={[1]}>
              <Typography color="white">
                {areaCapValue === null && (
                  <Trans components={{ b: <b /> }}>
                    {t(kpiDropdown.cannotEditTooltip.key, {
                      defaultValue: kpiDropdown.cannotEditTooltip.defaultValue,
                      kpiProperty: 'Area Cap',
                    })}
                  </Trans>
                )}
                {!canRemoveAreaCap &&
                  t(
                    kpiDropdown.noPermissionTooltipAreaCap.key,
                    kpiDropdown.noPermissionTooltipAreaCap.defaultValue,
                  )}
              </Typography>
            </Container>
          ),
          item: (
            <>
              <Icon data-testid="infoIcon" icon={Icons.TRASHBIN} size="small" margin={[0, 1, 0, 0]} />
              <Typography margin={[0, 2, 0]}>
                {t(kpiDropdown.removeAreaCap.key, kpiDropdown.removeAreaCap.defaultValue)}
              </Typography>
            </>
          ),
        },
        {
          key: isKpiHighlighted ? 'unHighlightAreaKpi' : 'highlightAreaKpi',
          disabled: !canHighlightKpi,
          hasTooltip: !canHighlightKpi,
          tooltipText: (
            <Container padding={[1]}>
              <Typography color="white">
                {t(
                  kpiDropdown.cannotHighlightAreaKPITooltip.key,
                  kpiDropdown.cannotHighlightAreaKPITooltip.defaultValue,
                )}
              </Typography>
            </Container>
          ),
          item: (
            <>
              <Icon
                data-testid="highlightedIcon"
                icon={isKpiHighlighted ? Icons.STAR : Icons.STAR_FILLED}
                size="small"
                margin={[0, 1, 0, 0]}
              />

              <Typography margin={[0, 2, 0]}>
                {isKpiHighlighted
                  ? t(kpiDropdown.unhighlightKpi.key, kpiDropdown.unhighlightKpi.defaultValue)
                  : t(kpiDropdown.highlightKpi.key, kpiDropdown.highlightKpi.defaultValue)}
              </Typography>
            </>
          ),
        },
      ]}
    />
  );
};

export default KpiDropdown;
