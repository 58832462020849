import { useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KpiAreaValueType, KpiModalViews, KpiType, UpdateKpiRequest } from '../../../constants';
import { KpiAreaDetails, KpiTargetsProps, useUser } from '../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../stories/atoms';
import { Modal } from '../../../stories/molecules';
import { muiTheme } from '../../../theme';
import { homeTranslations } from '../../../translations';
import { kpiTargetTransalations } from '../../../translations/kpiTargets';
import { getKpiTargetProps } from '../../../utils';
import { StyledDivider } from '../KpiTarget.styles';
import {
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledIconWithText,
  StyledKPIAccordionContainer,
} from './BulkUpdateModal.styles';
import KpiAccordion from './kpiAccordion/KpiAccordion';

type UpdateState = UpdateKpiRequest & {
  isUpdated: boolean;
  isValid: boolean;
};

export type KpiAccordionsExpandedState = {
  [key in KpiType]: boolean;
};

const defaultKpiAccordionsExpandedState: KpiAccordionsExpandedState = {
  dailyConversionRate: false,
  dailyShareOfReceipts: false,
  dailyNewMembers: false,
  dailyTurnOver: false,
  dailyQueueLength: false,
  dailySCOShareOfReceipts: false,
  dailySCOTurnOver: false,
};

export type BulkUpdateModalProps = {
  isOpen: boolean;
  areaKpiData: KpiAreaDetails[] | null;
  kpiTargetsProps: KpiTargetsProps | null;
  canEditKpi: boolean;
  canEditAreaCap: boolean;
  isLoading: boolean;
  modalView: KpiModalViews;
  onClose: () => void;
  updatedKpis: (request: { target: UpdateKpiRequest[]; areaCap: UpdateKpiRequest[] }) => void;
};

const BulkUpdateModal: FC<BulkUpdateModalProps> = ({
  isOpen,
  areaKpiData,
  kpiTargetsProps,
  canEditKpi,
  canEditAreaCap,
  modalView,
  isLoading,
  onClose,
  updatedKpis,
}) => {
  const { t } = useTranslation();
  const {
    get: { currentStoreId },
  } = useUser();

  const { bulkUpdate, updateKpisText, updateKpiButton, kpiBanner, bulkUpdateError, bulkUpdateSuccess } =
    kpiTargetTransalations;
  const { common } = homeTranslations;
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('tabletLandscape'));

  const [expandData, setExpandData] = useState<KpiAccordionsExpandedState>(defaultKpiAccordionsExpandedState);
  const [updatedTarget, setUpdatedtarget] = useState<UpdateState[]>([]);
  const [updatedAreaCap, setUpdatedAreaCap] = useState<UpdateState[]>([]);

  const expandAll = Object.values(expandData).every(Boolean);

  const handleAccordionExpand = (value: { [key in string]: boolean }) => {
    setExpandData((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const handleAllExpanded = () => {
    setExpandData({
      dailyConversionRate: !expandAll,
      dailyShareOfReceipts: !expandAll,
      dailyNewMembers: !expandAll,
      dailyTurnOver: !expandAll,
      dailyQueueLength: !expandAll,
      dailySCOShareOfReceipts: !expandAll,
      dailySCOTurnOver: !expandAll,
    });
  };

  // Updates KPI values based on area cap or target.
  const handleKpiChange = (
    updatedValue: number | null,
    isValid: boolean,
    isUpdated: boolean,
    kpiName: KpiType,
    isAreaCap: boolean,
  ) => {
    const newUpdateStates: UpdateState[] = !isAreaCap ? [...updatedTarget] : [...updatedAreaCap];

    // Remove the item if it is not updated
    const updateStateindex = newUpdateStates.findIndex((item) => item.kpiName === kpiName);
    if (updateStateindex !== -1) newUpdateStates.splice(updateStateindex, 1);

    // Add the item if it is updated
    if (isUpdated) {
      newUpdateStates.push({
        isValid,
        isUpdated,
        kpiName,
        areaCap: isAreaCap ? updatedValue : undefined,
        target: !isAreaCap ? updatedValue : undefined,
      });
    }

    if (!isAreaCap) {
      setUpdatedtarget(newUpdateStates);
      return;
    }

    setUpdatedAreaCap(newUpdateStates);
  };

  // handle submit kpis
  const handleSubmitKpis = () => {
    if (updatedTarget.length === 0 && updatedAreaCap.length === 0) {
      return;
    }

    const targetRequest: UpdateKpiRequest[] = [];
    const areaCapRequest: UpdateKpiRequest[] = [];

    updatedTarget.forEach((item) => {
      targetRequest.push({
        kpiName: item.kpiName,
        target: item.target,
      });
    });

    updatedAreaCap.forEach((item) => {
      areaCapRequest.push({
        kpiName: item.kpiName,
        areaCap: item.areaCap,
      });
    });

    updatedKpis({ target: targetRequest, areaCap: areaCapRequest });
  };

  const isUpdated = updatedTarget.some((item) => item.isUpdated) || updatedAreaCap.some((item) => item.isUpdated);
  const isValid = updatedTarget.every((item) => item.isValid) && updatedAreaCap.every((item) => item.isValid);

  const isDisabled = !(isUpdated && isValid);

  useEffect(() => {
    if (!isOpen) {
      setExpandData(defaultKpiAccordionsExpandedState);
      setUpdatedAreaCap([]);
      setUpdatedtarget([]);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      {modalView === KpiModalViews.INITIAL && (
        <StyledContainer data-testid="bulk-update-initial-container">
          <Container wrap="nowrap" direction="horizontal" space="between">
            <StyledHeader>
              <Typography type="h4" margin={[0, 0, 2]}>
                {currentStoreId} - {t(bulkUpdate.key, bulkUpdate.defaultValue)}
              </Typography>
              <Typography type="button">
                {t(updateKpisText.key, {
                  defaultValue: updateKpisText.defaultValue,
                  kpiType: canEditAreaCap ? 'Target and Area Cap' : 'Target',
                })}
              </Typography>
            </StyledHeader>
            <StyledIconWithText
              label={
                expandAll
                  ? t(common.collapseAll.key, common.collapseAll.defaultValue)
                  : t(common.expandAll.key, common.expandAll.defaultValue)
              }
              shouldRotate={expandAll ? false : true}
              iconOptions={{
                icon: expandAll ? Icons.CHECKBOX_INDETERMINATE : Icons.CLOSE,
                size: 'small',
                rotate: 'up',
              }}
              textOptions={{
                type: 'button',
              }}
              onClick={handleAllExpanded}
              hoverColor="borderGray"
              padding={[0, 0, 0, 2]}
            />
          </Container>
          <StyledDivider />

          <StyledKPIAccordionContainer isTablet={isTablet}>
            {areaKpiData &&
              areaKpiData.map((kpiAreaDetail) => {
                const { kpiName, kpiValues } = kpiAreaDetail;

                const kpiTargetProps = getKpiTargetProps(kpiTargetsProps, kpiName);

                if (!kpiTargetProps) return null;

                const { target, isLocked } = kpiTargetProps;

                const areaCap =
                  kpiValues?.find((item) => item.kpiProperty === KpiAreaValueType.AREA_CAP)?.kpiValue || null;

                return (
                  <KpiAccordion
                    isModalOpen={isOpen}
                    key={kpiName}
                    kpiName={kpiName}
                    target={target}
                    areaCap={areaCap}
                    isLocked={isLocked}
                    canEditKpi={canEditKpi}
                    canEditAreaCap={canEditAreaCap}
                    isAccordionExpanded={expandData[kpiName]}
                    onkpiExpand={handleAccordionExpand}
                    onKpiChange={handleKpiChange}
                  />
                );
              })}
          </StyledKPIAccordionContainer>
          <StyledButton
            data-testid="update-kpi-button"
            label={t(updateKpiButton.key, updateKpiButton.defaultValue)}
            fullWidth
            disabled={isDisabled}
            onClick={handleSubmitKpis}
            isLoading={isLoading}
          />
        </StyledContainer>
      )}
      {modalView === KpiModalViews.SUCCESS && (
        <StyledContainer position="center" isDesktop={isDesktop} data-testid="bulk-update-success-container">
          <Icon
            data-testid="successIcon"
            icon={Icons.CHECK_MARK_CIRCLE}
            size="x-large"
            color="validationGreen"
            margin={[10, 0, 5, 0]}
          />
          <Typography type="h2" margin={[0, 0, 0, 0]}>
            {t(bulkUpdateSuccess.key, bulkUpdateSuccess.defaultValue)}
          </Typography>
          <Typography type="h4" margin={[0, 0, 10, 0]}>
            {t(kpiBanner.kpiSuccessBanner.key, kpiBanner.kpiSuccessBanner.defaultValue)}
          </Typography>
        </StyledContainer>
      )}

      {modalView === KpiModalViews.ERROR && (
        <StyledContainer position="center" isDesktop={isDesktop} data-testid="bulk-update-error-container">
          <Icon data-testid="errorIcon" icon={Icons.INFO} size="x-large" color="red" />
          <Typography type="h2" margin={[0, 0, 0, 0]}>
            {t(bulkUpdateError.key, bulkUpdateError.defaultValue)}
          </Typography>
          <Typography type="h4" margin={[0, 0, 10, 0]}>
            {t(kpiBanner.kpiErrorBanner.key, kpiBanner.kpiErrorBanner.defaultValue)}
          </Typography>
        </StyledContainer>
      )}
    </Modal>
  );
};

export default BulkUpdateModal;
