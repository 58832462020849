import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Icons, Typography } from '../../../../stories/atoms';
import { Animation, Animations } from '../../../../stories/molecules';
import { commonTranslations, homeTranslations } from '../../../../translations';
import { StyledContainer } from './WidgetCommonState.styles';

const ErrorComponent = ({
  text,
  retryButtonText,
  retryButtonHandler,
}: {
  text: string;
  retryButtonText: string;
  retryButtonHandler?: () => void;
}) => {
  return (
    <StyledContainer data-testid="error-view">
      <Animation
        title=""
        animation={Animations.SAD}
        size="small"
        errorText={text}
        button={
          !retryButtonHandler
            ? undefined
            : {
                label: retryButtonText,

                onClick: () => retryButtonHandler && retryButtonHandler(),
                buttonContentProps: {
                  iconOptions: {
                    size: 'small',
                    icon: Icons.REFRESH,
                    color: 'white',
                  },
                },
              }
        }
      />
    </StyledContainer>
  );
};

const LoadingComponent = ({ text }: { text: string }) => {
  return (
    <Container data-testid="loading-view" position="center" height="100%">
      <CircularProgress size={24} thickness={3} />
      <Typography type="button" textAlign="center" padding={[4, 0, 0]}>
        {text}
      </Typography>
    </Container>
  );
};

const NoDataComponent = ({ text }: { text: string }) => {
  return (
    <Container data-testid="no-data" position="center" height="100%">
      <Typography type="button" textAlign="center">
        {text}
      </Typography>
    </Container>
  );
};

export type WidgetStateProps = {
  isLoading?: boolean;

  isError?: boolean;
  errorText?: string;
  errorRetryHandler?: () => void;

  isNoData?: boolean;
  customNoDataText?: string;
  isNoWeeklyData?: boolean;
};

export const WidgetCommonState: FC<WidgetStateProps> = ({
  isLoading,

  isError,
  errorText,
  errorRetryHandler,

  isNoData,
  isNoWeeklyData,
  customNoDataText,
}) => {
  const { t } = useTranslation();

  const noDataText = customNoDataText
    ? customNoDataText
    : isNoWeeklyData
    ? t(homeTranslations.common.noWeeklyData.key, homeTranslations.common.noWeeklyData.defaultValue)
    : t(homeTranslations.common.noReceipts.key, homeTranslations.common.noReceipts.defaultValue);

  return (
    <>
      {isError && errorText && (
        <ErrorComponent
          text={errorText}
          retryButtonText={t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue)}
          retryButtonHandler={errorRetryHandler}
        />
      )}

      {isLoading && (
        <LoadingComponent
          text={t(homeTranslations.common.loading.key, homeTranslations.common.loading.defaultValue)}
        />
      )}

      {isNoData && <NoDataComponent text={noDataText} />}
    </>
  );
};
