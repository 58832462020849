import { format, intlFormat, isToday, startOfToday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SoldProductItem, useAsyncAction, useSoldProducts } from '../../hooks';
import i18n from '../../i18n';
import { Icons, Typography } from '../../stories/atoms';
import { Animation, Animations, IconWithText } from '../../stories/molecules';
import { DatePicker } from '../../stories/molecules/date-picker/DatePicker';
import { Page } from '../../stories/organisms';
import { commonTranslations, soldProductsTranslations, transactionSearchTranslations } from '../../translations';
import { isFailureResponse } from '../../utils';
import { localeMap } from '../../utils/language';
import {
  FiltersWrapper,
  HeaderFiltersWrapper,
  ProductDetailsSliderContainer,
  SelectedFiltersArea,
  SubHeaderWrapper,
  TableWrapper,
} from './SoldProductsPage.styles';
import { ProductTable } from './productTable/ProductTable';
import { ProductDetailsSlider } from './slider/ProductDetailsSlider';

const { clearFilter: clearFilterTranslations } = transactionSearchTranslations;
const { selectedFilters: selectedFilterTranslation } = commonTranslations;

export const SoldProductsPage: FC = observer(() => {
  const { t } = useTranslation();
  const { fetchSoldProducts } = useSoldProducts();

  const [currentDate, setCurrentDate] = useState<Date>(startOfToday());
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>([]);
  const selectedFilterRef = useRef<HTMLDivElement>(null);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const {
    result: soldProductItems,
    isLoading: soldProductItemsLoading,
    isError: soldProductItemsError,
    triggerAction: triggerGetSoldProductItems,
  } = useAsyncAction<SoldProductItem[]>();

  const getSoldProducts = async () => {
    const response = await fetchSoldProducts(i18n.language, format(currentDate, 'yyyy-MM-dd'));

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const resetHandler = () => {
    setSelectedFilterKeys([]);
    setCurrentDate(startOfToday());
  };

  const handleSliderClose = () => {
    setSelectedProductId(null);
  };

  useEffect(() => {
    setSelectedProductId(null);

    triggerGetSoldProductItems(getSoldProducts);
  }, [currentDate]);

  const selectedRangeFilterText = `${intlFormat(currentDate, {}, { locale: i18n.language })}`;
  const isAnyFilterSelected = selectedFilterKeys.length > 0 || !isToday(currentDate);

  const selectedSoldProductItem =
    (soldProductItems || []).find((item) => item.productId === selectedProductId) || null;

  const isNoData = !soldProductItemsLoading && soldProductItems && soldProductItems.length === 0;

  return (
    <Page
      header={
        <HeaderFiltersWrapper>
          <DatePicker
            inputSelectedDate={currentDate}
            label={format(currentDate, 'dd-MMM-yyyy', { locale: localeMap[i18n.language] })}
            onChange={(date) => setCurrentDate(date)}
            isDropdown
            disableFutureDates
          />
        </HeaderFiltersWrapper>
      }
      body={
        <div>
          <SubHeaderWrapper>
            <SelectedFiltersArea data-testid="selected-filters" ref={selectedFilterRef}>
              {isAnyFilterSelected && (
                <Typography data-testid="selected-filter-text" type="body3">
                  {t(selectedFilterTranslation.key, selectedFilterTranslation.defaultValue)}
                </Typography>
              )}
              {isToday(currentDate) ? (
                <Typography data-testid="today-date" padding={[2, 0]}>
                  {t(
                    soldProductsTranslations.productBasedOn.key,
                    soldProductsTranslations.productBasedOn.defaultValue,
                  )}{' '}
                  {intlFormat(currentDate, {}, { locale: i18n.language })}
                </Typography>
              ) : (
                <IconWithText
                  label={selectedRangeFilterText}
                  iconOptions={{
                    icon: Icons.CLOSE,
                    color: 'white',
                    onClick: () => setCurrentDate(startOfToday()),
                    margin: [0, 2],
                    size: 'small',
                  }}
                  backgroundColor="primary"
                  textOptions={{ color: 'white' }}
                  labelPosition="left"
                  padding={[2, 0, 2, 2]}
                />
              )}
            </SelectedFiltersArea>
            <FiltersWrapper>
              {!soldProductItemsLoading && !soldProductItemsError && soldProductItems && (
                <Typography data-testid="product-count">{`${soldProductItems.length} ${t(
                  soldProductsTranslations.products.key,
                  soldProductsTranslations.products.defaultValue,
                )}`}</Typography>
              )}
              {isAnyFilterSelected && (
                <IconWithText
                  data-testid="clear-all-button"
                  label={t(clearFilterTranslations.key, clearFilterTranslations.defaultValue)}
                  textOptions={{
                    type: 'body3',
                  }}
                  iconOptions={{
                    icon: Icons.TRASHBIN,
                    onClick: () => resetHandler(),
                    size: 'small',
                  }}
                  labelPosition="left"
                  padding={[2, 0, 2, 2]}
                />
              )}
            </FiltersWrapper>
          </SubHeaderWrapper>

          {!soldProductItemsLoading && soldProductItemsError && (
            <Animation
              animation={Animations.SAD}
              title={t(
                soldProductsTranslations.error.errorTitle.key,
                soldProductsTranslations.error.errorTitle.defaultValue,
              )}
              subtitle={t(
                soldProductsTranslations.error.errorSubtitle.key,
                soldProductsTranslations.error.errorSubtitle.defaultValue,
              )}
            />
          )}

          {isNoData && (
            <Animation
              animation={Animations.CONFUSED}
              title={t(
                soldProductsTranslations.noData.title.key,
                soldProductsTranslations.noData.title.defaultValue,
              )}
              subtitle={t(
                soldProductsTranslations.noData.subtitle.key,
                soldProductsTranslations.noData.subtitle.defaultValue,
              )}
            ></Animation>
          )}

          <TableWrapper>
            <ProductTable
              isLoading={soldProductItemsLoading}
              items={soldProductItems || []}
              selectedItemProductId={selectedProductId}
              onItemSelect={(item) => (item ? setSelectedProductId(item.productId) : setSelectedProductId(null))}
            />

            {selectedSoldProductItem && (
              <ProductDetailsSliderContainer>
                <ProductDetailsSlider
                  isVisible={true}
                  onClose={handleSliderClose}
                  productDetails={selectedSoldProductItem}
                />
              </ProductDetailsSliderContainer>
            )}
          </TableWrapper>
        </div>
      }
    />
  );
});
