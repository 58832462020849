import { format } from 'date-fns';
import { StorePerformanceRequest } from '../hooks';

export enum TransactionFilterType {
  PaymentType = 'paymentType',
  ReceiptSubType = 'receiptSubType',
  OperatorId = 'operatorId',
}

export enum StorePerformanceInterval {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
}

export const createStorePerformanceRequest = (params: {
  interval: StorePerformanceInterval;
  currentDate?: Date;
  fromDate?: Date;
  toDate?: Date;
}): StorePerformanceRequest => {
  const { interval, currentDate, fromDate, toDate } = params;
  if (interval === StorePerformanceInterval.DAILY) {
    return {
      interval: StorePerformanceInterval.DAILY,
      date: format(currentDate as Date, 'yyyy-MM-dd'),
    };
  }

  return {
    interval: StorePerformanceInterval.WEEKLY,
    fromDate: format(fromDate as Date, 'yyyy-MM-dd'),
    toDate: format(toDate as Date, 'yyyy-MM-dd'),
  };
};
