import { Divider } from '@mui/material';
import styled from 'styled-components';
import { TrendEnum } from '../../../../constants/budgetPlanned';
import { Container, Icon, Typography } from '../../../../stories/atoms';
import { Color, cardShadow } from '../../../../theme';

const getColor = (mode: string, trend: TrendEnum | null, color: Color) => {
  if (mode === 'dark') {
    return color.white;
  }

  switch (trend) {
    case TrendEnum.UP:
      return color.validationGreen;
    case TrendEnum.DOWN:
      return color.red;
    default:
      return color.primary;
  }
};

export const StyledDivider = styled(Divider)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.hoverGray};
`;

export const StyledContainer = styled(Container)`
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  ${cardShadow()};
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledTrendIcon = styled(Icon)<{ trend: TrendEnum }>`
  transform: ${({ trend }) => (trend === TrendEnum.UP ? 'rotate(-45deg)' : 'rotate(45deg)')};
  background-color: ${({ theme: { color, palette }, trend }) =>
    trend === TrendEnum.UP
      ? color.successLight
      : palette.mode === 'light'
      ? color.errorLight
      : color.backgroundMenuActive};
  color: ${({ theme: { color }, trend }) => (trend === TrendEnum.UP ? color.validationGreen : color.red)};
  padding: ${({ theme: { spacing } }) => spacing(1)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledBudgetPlannedIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(12)};
  height: ${({ theme: { spacing } }) => spacing(12)};
`;

export const StyledTypography = styled(Typography)<{ trend: TrendEnum | null }>`
  color: ${({ theme: { color, palette }, trend }) => getColor(palette.mode, trend, color)};
`;
