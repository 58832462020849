import styled from 'styled-components';
import { Spacing } from '../../../utils';
import { getSpacing } from '../../../utils/styleComponents';

export const StyledCheckboxWrapper = styled.div`
  margin: ${({ theme: { spacing } }) => spacing(2)};
`;

export const StyledHeaderWrapper = styled.div<{
  position: 'left' | 'center' | 'right' | undefined;
  padding?: Spacing;
}>`
  padding: ${({ theme: { spacing }, padding }) => padding && getSpacing(padding, spacing)};
  text-align: ${({ position }) => position && position};
`;
