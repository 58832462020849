import styled from 'styled-components';
import { Icon } from '../../../../../stories/atoms';
import { cardShadow } from '../../../../../theme';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled(FlexColumn)`
  row-gap: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
`;

export const StyledSection = styled(FlexRow)`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(2, 4)};
  background-color: ${({ theme: { color } }) => color.backgroundSelectedHover};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  align-items: center;
`;

export const CircleSection = styled(FlexRow)`
  width: ${({ theme: { spacing } }) => spacing(14)};
  height: ${({ theme: { spacing } }) => spacing(14)};
  justify-content: center;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  border-radius: ${({ theme: { spacing } }) => spacing(10)};
  margin: ${({ theme: { spacing } }) => spacing(0, 0, 0, 2)};
  ${cardShadow()}
`;

export const StyledIconContainer = styled(FlexRow)`
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(0, 0, 2)};
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(3)};
  height: ${({ theme: { spacing } }) => spacing(3)};
`;
