import { ToggleButton as MuiToggleButton, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConversionRatePerformanceItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { Charts } from '../../../../../stories/molecules/charts/Charts';
import { ToggleButton } from '../../../../../stories/molecules/toggleButton/ToggleButton';
import { homeTranslations } from '../../../../../translations';
import {
  getKpiTargetProps,
  getKpiTargetValue,
  getXAxisLabels,
  isFailureResponse,
  Translation,
} from '../../../../../utils';

import {
  createStorePerformanceRequest,
  Forecast,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { muiTheme } from '../../../../../theme';
import { ProgressIndicator } from '../../../common/progressIndicator/ProgressIndicator';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { StyledCustomLegends } from '../../../Home.styles';
import {
  StyledRightContainerWrapper,
  StyledTrendContainer,
  StyledView,
  StyledWrapper,
} from './ConversionRateWidget.styles';

type TrendInfoProps = {
  isMobile: boolean;
  timeFrame: 'lastWeek' | 'lastYear';
  today: number;
  previousAmount: number;
  valueFormatter: (value: number) => string;
  common: {
    [key: string]: Translation;
  };
};

export const TrendInfo: FC<TrendInfoProps> = ({
  isMobile,
  timeFrame,
  today,
  previousAmount,
  valueFormatter,
  common,
}) => {
  const { t } = useTranslation();
  return (
    <StyledTrendContainer isLeftElement={timeFrame === 'lastWeek'}>
      <Typography type="caption" margin={[0, 2, 0, 0]}>
        {isMobile
          ? timeFrame === 'lastWeek'
            ? 'LW'
            : 'LY'
          : t(common[timeFrame].key, common[timeFrame].defaultValue)}
      </Typography>
      <StyledTrendIconComponent
        originalAmount={today}
        previousAmount={previousAmount}
        direction="horizontal"
        valueFormatter={valueFormatter}
        textOptions={{ type: 'caption' }}
        iconTextOptions={{ type: 'caption' }}
      />
    </StyledTrendContainer>
  );
};

export type ConversionRateWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isNonSigninUser?: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const ConversionRateWidget: FC<ConversionRateWidgetProps> = observer(
  ({
    interval,
    currentDate,
    hasPermissionToEditKpi,
    isNonSigninUser,
    isToday,
    handleDropdownDetails,
    fromDate,
    toDate,
  }) => {
    const {
      get: { kpiTargetsProps },
    } = useUser();
    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { fetchConversionRateWidgetData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();
    const { mode } = useThemeContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<ConversionRatePerformanceItem>();

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyConversionRate');
    const [view, setView] = useState('view1');
    const [today, setToday] = useState(0);
    const [lastWeek, setLastWeek] = useState(0);
    const [lastYear, setLastYear] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyConversionRate')?.isLocked ?? false;

    const { t } = useTranslation();

    const { conversionRate: conversionRateTranslation, kpiTargets, common } = homeTranslations;

    const getConversionRate = (items: (ConversionRatePerformanceItem | null)[]) => {
      let hoursElapsed = 0;
      let payingCustomersCount = 0;
      let nonPayingCustomersCount = 0;
      let numberOfTillsCount = 0;

      items.forEach((item) => {
        if (item === null) return;
        hoursElapsed += 1;
        payingCustomersCount += item.payingCustomers;
        nonPayingCustomersCount += item.nonPayingCustomers;
        numberOfTillsCount += item.numberOfTills;
      });

      const averagePayingCustomers = payingCustomersCount / hoursElapsed;
      const averageNonPayingCustomers = nonPayingCustomersCount / hoursElapsed;
      const averageAmountOfTills = numberOfTillsCount / hoursElapsed;

      const averageTillObject = {
        hour: 'Average',
        payingCustomers: averagePayingCustomers,
        nonPayingCustomers: averageNonPayingCustomers,
        numberOfTills: Math.floor(averageAmountOfTills),
      };

      const conversionRate =
        payingCustomersCount === 0 && nonPayingCustomersCount === 0
          ? 0
          : (payingCustomersCount / (payingCustomersCount + nonPayingCustomersCount)) * 100;

      return {
        conversionRate,
        averageTillObject,
        averagePayingCustomers,
        averageNonPayingCustomers,
      };
    };

    const itemValueResolver = (item: ConversionRatePerformanceItem) => {
      const { payingCustomers, nonPayingCustomers } = item;
      if (nonPayingCustomers === 0 && payingCustomers === 0) {
        return 0;
      }
      return Math.floor((payingCustomers / (nonPayingCustomers + payingCustomers)) * 100);
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchConversionRateWidgetData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setToday(response.data.today);
      setLastWeek(response.data.lastWeek);
      setLastYear(response.data.lastYear);
      setLastUpdated(response.data.lastUpdated);

      return response.data.conversionRate;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<ConversionRatePerformanceItem>('conversionRate');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [isToday]);

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        valueResolver: itemValueResolver,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate, isNonSigninUser, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyConversionRate',
    });

    const { conversionRate, averageTillObject, averagePayingCustomers, averageNonPayingCustomers } =
      getConversionRate(filteredPerformanceItems);
    const { conversionRate: forecastConversionRate } = getConversionRate([
      ...filteredPerformanceItems,
      ...forecastPerformanceItems,
    ]);

    const conversionRateTillsData = [...performanceItems, averageTillObject];

    const getUsedTills = (dataIndex: number) => {
      const tills = conversionRateTillsData.find((item, index) => index === dataIndex);
      return tills;
    };

    const isEditKPIDisabled = !hasPermissionToEditKpi;

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
      if (view2 !== null) {
        setView(view2);
      }
    };

    const noOfPayingCustomers = performanceItems.map(({ payingCustomers }) => {
      return Math.floor(payingCustomers);
    });

    const noOfNonPayingCustomers = performanceItems.map(({ nonPayingCustomers }) => {
      return Math.floor(nonPayingCustomers);
    });

    const totalVisitors = [...noOfPayingCustomers, ...noOfNonPayingCustomers].reduce((acc, curr) => acc + curr, 0);

    const targetProgressPercent = target && target != 0 ? (forecastConversionRate / target) * 100 : 0;

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);
    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    const menuItems = [
      {
        key: target ? 'editKpi' : 'addKpi',
        disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
        hasTooltip: isEditKPIDisabled || isTargetLocked,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <WidgetCardContent
        headerIcon={Icons.SALESEFFICIENCY}
        headerText={t(conversionRateTranslation.title.key, conversionRateTranslation.title.defaultValue)}
        subHeaderText={t(conversionRateTranslation.subTitle.key, conversionRateTranslation.subTitle.defaultValue)}
        lastUpdatedDate={lastUpdated}
        isLoading={performanceItemsLoading}
        isError={performanceItemsError}
        errorText={t(conversionRateTranslation.error.key, conversionRateTranslation.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        headerSideContent={
          <StyledRightContainerWrapper>
            {performanceItems.length > 0 && (
              <StyledWrapper isMobile={isMobile}>
                <ToggleButton
                  value={view}
                  onChange={handleChangeView}
                  leftButton={
                    <MuiToggleButton value="view1" aria-label="left button">
                      <Icon data-testid="left-icon" icon={Icons.CONVERSION} size="small" />
                    </MuiToggleButton>
                  }
                  rightButton={'123'}
                />
              </StyledWrapper>
            )}

            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={menuItems}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItem) => {
                if (!isEditKPIDisabled) {
                  handleDropdownDetails('dailyConversionRate', selectedItem[0] as KpiDropdownActions);
                }
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledRightContainerWrapper>
        }
        content={
          <>
            <Container wrap="nowrap" data-testid="conversion-rate-wrapper" height="100%">
              <StyledView isMobile={isMobile}>
                {view === 'view1' && (
                  <>
                    {showForecast && (
                      <ProgressIndicator
                        valueType={Forecast.PERCENTAGE}
                        value={forecastConversionRate}
                        progressPercentage={targetProgressPercent}
                        padding={[1, 2]}
                        margin={[1, 2, 2]}
                      />
                    )}
                    <Container direction="horizontal" space="between" width="100%" padding={[0, 3]}>
                      <Typography>
                        {t(conversionRateTranslation.title.key, conversionRateTranslation.title.defaultValue)}:{' '}
                        {conversionRate.toFixed(2)}%
                      </Typography>
                      {target && (
                        <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[0]}>
                          {`${t(
                            homeTranslations.common.targetKPICR.key,
                            homeTranslations.common.targetKPICR.defaultValue,
                          )} ${target}%`}
                        </StyledCustomLegends>
                      )}
                    </Container>
                    <Container margin={[1, 0, 0]} padding={[0, 3]} direction="horizontal" position="right">
                      <TrendInfo
                        isMobile={isMobile}
                        timeFrame="lastWeek"
                        today={today}
                        previousAmount={lastWeek}
                        valueFormatter={(value) => value.toFixed(2)}
                        common={common}
                      />
                      <TrendInfo
                        isMobile={isMobile}
                        timeFrame="lastYear"
                        today={today}
                        previousAmount={lastYear}
                        valueFormatter={(value) => value.toFixed(2)}
                        common={common}
                      />
                    </Container>
                    <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                      <WidgetChart
                        type="bar"
                        performanceItemsChartName={t(
                          conversionRateTranslation.title.key,
                          conversionRateTranslation.title.defaultValue,
                        )}
                        performanceItems={performanceItems}
                        filteredPerformanceItems={filteredPerformanceItems}
                        forecastPerformanceItems={forecastPerformanceItems}
                        targetItems={targetItems}
                        itemValueResolver={itemValueResolver}
                        showXAxis={true}
                        yAxisFormatter={(value) => `${Math.floor(value)}%`}
                        tooltipFormatter={(value) => `${value.toFixed(2)}%`}
                        dataLabel={{
                          enabled: true,
                          enabledOnSeries: [0, 2],
                          offsetY: -28,
                          style: {
                            fontSize: isMobile ? '8px' : '12px',
                            fontWeight: '100',
                            colors: [mode === 'dark' ? muiTheme.color.white : muiTheme.color.primary],
                          },
                          formatter: function (value) {
                            return value ? value + '%' : '';
                          },
                        }}
                      />
                    </Container>
                  </>
                )}

                {view === 'view2' && (
                  <>
                    <Typography padding={isMobile ? [2, 6] : [2, 0, 0]}>
                      {t(
                        conversionRateTranslation.totalVisitors.key,
                        conversionRateTranslation.totalVisitors.defaultValue,
                      )}
                      : {totalVisitors.toLocaleString()}
                    </Typography>
                    <Container wrap="nowrap" data-testid="chart" height={isMobile ? '95%' : '90%'}>
                      <Charts
                        chartType="bar"
                        isStacked={true}
                        xAxisCategories={getXAxisLabels(interval, performanceItems).concat(
                          t(conversionRateTranslation.average.key, conversionRateTranslation.average.defaultValue),
                        )}
                        chartSeries={[
                          {
                            name: t(
                              conversionRateTranslation.payingCustomers.key,
                              conversionRateTranslation.payingCustomers.defaultValue,
                            ),
                            data: noOfPayingCustomers.concat(Math.floor(averagePayingCustomers)),
                          },
                          {
                            name: t(
                              conversionRateTranslation.nonPayingCustomers.key,
                              conversionRateTranslation.nonPayingCustomers.defaultValue,
                            ),
                            data: noOfNonPayingCustomers.concat(Math.floor(averageNonPayingCustomers)),
                          },
                        ]}
                        areaChartGradient={{ opacity: 1 }}
                        toolTip={{
                          enabled: true,
                          shared: true,
                          intersect: false,
                          marker: { show: true },
                          x: {
                            show: true,
                            formatter: (value, { dataPointIndex }) => {
                              const numberOfTillsUsed = getUsedTills(dataPointIndex);
                              return `${t(
                                conversionRateTranslation.numberOfTills.key,
                                conversionRateTranslation.numberOfTills.defaultValue,
                              )}: ${numberOfTillsUsed?.numberOfTills.toString() ?? '0'}`;
                            },
                          },
                          cssClass: 'custom-apex-tooltip',
                        }}
                        legend={{
                          markers: {
                            radius: 12,
                            offsetY: 1,
                          },
                        }}
                        isAnimationEnabled={false}
                      />
                    </Container>
                  </>
                )}
              </StyledView>
            </Container>
          </>
        }
        toolTipText={t(conversionRateTranslation.toolTip.key, conversionRateTranslation.toolTip.defaultValue)}
      />
    );
  },
);
