import { MenuItem } from '@mui/material';
import styled from 'styled-components';
import { Icon } from '../../../atoms';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(6, 6)};
`;

export const BackIcon = styled(Icon)`
  position: absolute;
  left: 0px;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  right: 0px;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const BodyContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
  padding: ${({ theme }) => theme.spacing(6)};
`;

export const FooterButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledDrawerMenuItem = styled(MenuItem)`
  height: ${({ theme }) => theme.spacing(15)};
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(6)};
`;
