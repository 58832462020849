import styled from 'styled-components';
import { Button } from '../../../../stories/molecules';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing(20)};
`;

export const StyledAddAvatarButton = styled(Button)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
`;

export const StyledSaveButton = styled(Button)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};
`;

export const StyledWrapper = styled.div`
  min-width: 40vw;
`;
