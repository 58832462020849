import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { intlFormat, format } from 'date-fns';
import { Delay } from '../../../../../constants';
import { MessageType, Message } from '../../../../../global-state/types';
import { useProfile, useUser } from '../../../../../hooks';
import { messageTranslation, commonTranslations } from '../../../../../translations';
import { getUniqueId, isFailureResponse, PatchOperation } from '../../../../../utils';
import { Icons, Icon, Typography, Container, Tooltip } from '../../../../atoms';
import { Button, Animation, Animations, DropdownMenu, Banner, BannerItemsType } from '../../../../molecules';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { Badge } from '../../../../atoms/badge/Badge';
import { useEventContext } from '../../../../../global-state/eventContext';
import {
  StyledMessageInfo,
  StyledLoadMore,
  StyledCheckIcon,
  StyledHeaderContainer,
  StyledAnimationContainer,
  StyledBadge,
  StyledDropDownContainer,
} from './MessageDropdown.styles';

export const MessageDropdown: FC<{ onStoreChange: (id: string, redirectUrl: string) => void }> = observer(
  ({ onStoreChange }) => {
    const {
      get: {
        messages,
        profile: { readMessages, upn },
        currentStoreId,
        stores,
      },
      set,
    } = useUser();
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownHeight, setDropdownHeight] = useState('50vh');
    const [unreadMessages, setUnreadMessages] = useState<Message[]>([]);
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);

    const { updateProfile, fetchMessages } = useProfile();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { mode } = useThemeContext();
    const { permissionEvents } = useEventContext();

    const messagesRef = useRef<Message[]>([]); // needed as "messages" is always returning as empty array in listenToEvents()

    const { notifications, unread, permission, markAllAsRead, error, noMessage } = messageTranslation;
    const { loadMore } = commonTranslations;

    const messageTranslationMapping = {
      [MessageType.Permission]: permission,
    };

    const linkMapping = {
      [MessageType.Permission]: `/employees/${currentStoreId}`,
    };

    const iconMapping = {
      [MessageType.Permission]: Icons.HEART,
    };

    const pageSize = 50;
    let customHeader: JSX.Element;

    useEffect(() => {
      getMessages();
    }, []);

    useEffect(() => {
      if (!permissionEvents?.length) return;
      pushNewMessage(permissionEvents[permissionEvents.length - 1]);
    }, [permissionEvents]);

    useEffect(() => {
      setUnreadMessages(messages.filter((message) => !readMessages?.includes(message.id)));
    }, [messages]);

    const handleLoadMore = () => {
      setIsLoading(true);

      setTimeout(() => {
        setPageNumber(pageNumber + 1);
        setIsLoading(false);
        setDropdownHeight('80vh');
      }, Delay.XSmall);
    };

    const pushNewMessage = (message: MessageEvent<string>) => {
      if (!message.data) return;

      const data = { type: message.type, ...JSON.parse(message.data) } as Message;
      messagesRef.current = [data, ...messagesRef.current];
      set({ messages: messagesRef.current });
    };

    const getMessages = async () => {
      const response = await fetchMessages(upn);

      if (isFailureResponse(response)) {
        setIsMessageError(true);
        return;
      }

      const data = (response.data || []).sort((previous, current) =>
        format(new Date(previous.created), 'yyyy/MM/dd HH:mm') <
        format(new Date(current.created), 'yyyy/MM/dd HH:mm')
          ? 1
          : -1,
      );

      set({ messages: data });
      setIsMessageError(false);
      messagesRef.current = data;
    };

    const handleMarkAsRead = async (readMessageIds: string[]) => {
      const addOperationWhenNoReadMessages = [
        {
          op: 'add',
          path: '/readMessages',
          value: [],
        } as PatchOperation,
        {
          op: 'add',
          path: '/readMessages/-',
          value: [...(readMessages || []), ...readMessageIds],
        } as PatchOperation,
      ];

      const addOperation = [
        {
          op: 'add',
          path: '/readMessages',
          value: [...(readMessages || []), ...readMessageIds],
        } as PatchOperation,
      ];

      const response = await updateProfile(upn, !readMessages ? addOperationWhenNoReadMessages : addOperation);

      if (isFailureResponse(response)) {
        setBannerItems((items) => [
          ...items,
          {
            id: getUniqueId(),
            type: 'error',
            label: t(messageTranslation.markAsReadError.key, messageTranslation.markAsReadError.defaultValue),
          },
        ]);

        return;
      }

      setUnreadMessages(messages.filter((data) => !(response.data?.readMessages || []).includes(data.id)));
      set({ profile: response.data });
    };

    const handleSelect = async (ids: string[]) => {
      const selectedId = ids[0]; // since we will always select only one message in the dropdown
      const isMessageUnread = !!unreadMessages.find(({ id }) => id === selectedId);
      const selectedMessage = messages.find(({ id }) => id === selectedId) as Message;
      const redirectUrl = linkMapping[selectedMessage.type];

      if (isMessageUnread) await handleMarkAsRead(ids);

      if (!selectedMessage.storeId || selectedMessage.storeId === currentStoreId) return navigate(redirectUrl);

      onStoreChange(selectedMessage.storeId, redirectUrl);
    };

    if (isMessageError) {
      customHeader = (
        <StyledAnimationContainer padding={[2, 10]}>
          <Animation
            title={''}
            subtitle={t(error.key, error.defaultValue)}
            animation={Animations.SAD}
            button={{
              label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
              onClick: getMessages,
              buttonContentProps: {
                iconOptions: {
                  icon: Icons.REFRESH,
                  color: 'white',
                },
              },
              isLoading: isLoading,
            }}
            size="small"
          />
        </StyledAnimationContainer>
      );
    } else if (!messages || messages.length === 0) {
      customHeader = (
        <StyledAnimationContainer padding={[2, 10]}>
          <Animation
            title=""
            subtitle={t(noMessage.key, noMessage.defaultValue)}
            animation={Animations.COOL}
            size="small"
          />
        </StyledAnimationContainer>
      );
    } else {
      customHeader = (
        <StyledHeaderContainer>
          <Container>
            {bannerItems.length ? <Banner items={bannerItems} onChange={() => null} /> : <></>}
            <Container direction="horizontal" space="between" position="center" padding={[4]}>
              <Container>
                <Typography type="body3">{t(notifications.key, notifications.defaultValue)}</Typography>
                <Typography type="body1" data-testid="unread-message-count">
                  {t(unread.key, {
                    defaultValue: unread.defaultValue,
                    count: messages.length,
                    unread: unreadMessages.length,
                  })}
                </Typography>
              </Container>
              <Tooltip
                text={<Typography color="white">{t(markAllAsRead.key, markAllAsRead.defaultValue)}</Typography>}
              >
                {unreadMessages.length > 0 ? (
                  <StyledCheckIcon
                    icon={Icons.CHECK_MARK}
                    size="small"
                    color="validationGreen"
                    onClick={() => handleMarkAsRead(unreadMessages.map(({ id }) => id))}
                  />
                ) : (
                  <></>
                )}
              </Tooltip>
            </Container>
          </Container>
        </StyledHeaderContainer>
      );
    }

    return (
      <StyledDropDownContainer>
        <DropdownMenu
          dropdownLabel={''}
          buttonContentProps={{
            iconOptions: { customIcon: <Icon icon={Icons.BELL} /> },
          }}
          customHeader={customHeader}
          menuItems={messages.slice(0, pageNumber * pageSize).map((message) => ({
            key: message.id,
            item: (
              <Container
                direction="horizontal"
                space="between"
                position="center"
                margin={[4, 0]}
                data-testid="menu-item"
              >
                <Icon icon={iconMapping[message.type]} margin={[0, 3, 0, 0]} size="large" />
                <StyledMessageInfo>
                  <Typography type="body1" padding={[0, 0, 1]}>
                    <Trans
                      i18nKey={messageTranslationMapping[message.type].key}
                      defaults={messageTranslationMapping[message.type].defaultValue}
                      components={{ b: <b /> }}
                      values={{ count: `${message.daysUntilExpired}` }}
                    />
                  </Typography>
                  {stores.length > 1 && message.storeId && (
                    <Typography type="caption" color="secondaryTextGray" padding={[0, 0, 2]}>
                      {stores.find(({ storeId }) => storeId === message.storeId)?.popularName}
                    </Typography>
                  )}
                  <Typography type="caption" color="secondaryTextGray">
                    {`${intlFormat(
                      new Date(message.created),
                      { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
                      { locale: i18n.language },
                    )}`}
                  </Typography>
                </StyledMessageInfo>
              </Container>
            ),
            backgroundColor: readMessages?.includes(message.id)
              ? mode === 'dark'
                ? 'primary'
                : 'white'
              : mode === 'dark'
              ? 'backgroundSelectedDark'
              : 'backgroundSelected',
          }))}
          customFooter={
            <StyledLoadMore>
              {pageNumber * pageSize < messages.length && (
                <Button
                  data-testid="load-more-button"
                  onClick={handleLoadMore}
                  label={t(loadMore.key, loadMore.defaultValue)}
                  fullWidth={true}
                  isLoading={isLoading}
                />
              )}
            </StyledLoadMore>
          }
          onSelect={handleSelect}
          maxWidth="clamp(400px, 20vw, 500px)"
          maxHeight={dropdownHeight}
          isCompact={true}
          backgroundColor={{
            selected: mode === 'dark' ? 'primary' : 'white',
            hover: mode === 'dark' ? 'backgroundSelectedDark' : 'hoverGray',
            selectedHover: mode === 'dark' ? 'backgroundSelectedDark' : 'hoverGray',
          }}
          menuPosition="right"
          isOnlyIcon
        />
        {!!unreadMessages.length && (
          <StyledBadge>
            <Badge backgroundColor="red">
              <Typography type="caption" color="white">
                {unreadMessages.length}
              </Typography>
            </Badge>
          </StyledBadge>
        )}
      </StyledDropDownContainer>
    );
  },
);
