import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import { FC } from 'react';
import { Typography } from '../../atoms';
import { Spacing } from '../../../utils';
import { Colors, Typographies } from '../../../theme';
import { StyledTabs, StyledTypography, TabAlignment } from './DynamicTab.styles';

export type IconPositionType = 'start' | 'top' | 'bottom' | 'end';

export type TabLabelProps = {
  tabName: string;
  isTabDisabled?: boolean;
  isTabVisible?: boolean;
  icon?: React.ReactElement | string;
  iconPosition?: IconPositionType;
  labelMargin?: Spacing;
  labelType?: Typographies;
};

export type TabContentProps = {
  tabContent: JSX.Element;
  visible?: boolean;
};

export type DynamicTabProps = {
  tabLabelArray: TabLabelProps[];
  content: TabContentProps[];
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  isCentered?: boolean;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  alignment?: TabAlignment | undefined;
  selectedLabelColor?: Colors;
  tabBgColor?: Colors;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
  value: index,
});

export const DynamicTab: FC<DynamicTabProps> = ({
  tabLabelArray,
  content,
  tabValue,
  handleTabChange,
  isCentered = false,
  variant = 'standard',
  alignment,
  selectedLabelColor = 'primary',
  tabBgColor = 'white',
}) => {
  return (
    <Box data-testid="tab-wrapper">
      <StyledTabs
        data-testid="tabs-container"
        value={tabValue}
        onChange={handleTabChange}
        centered={isCentered}
        variant={variant}
        alignment={alignment}
        scrollButtons={false}
        tabbackgroundcolor={tabBgColor}
      >
        {tabLabelArray.map(
          (tabLabel, index) =>
            tabLabel.isTabVisible && (
              <Tab
                key={`tab-${index}`}
                data-testid="tab"
                label={
                  <StyledTypography
                    margin={tabLabel.labelMargin}
                    type={tabLabel.labelType}
                    color={tabValue === index ? selectedLabelColor : 'primary'}
                  >
                    {tabLabel.tabName}
                  </StyledTypography>
                }
                disabled={tabLabel.isTabDisabled}
                icon={tabLabel.icon}
                iconPosition={tabLabel.iconPosition}
                {...a11yProps(index)}
              />
            ),
        )}
      </StyledTabs>
      <div data-testid="tab-panel-container">
        {content.map(
          (itemValue, index) =>
            itemValue.visible && (
              <TabPanel data-testid="tab-panel" key={index} value={tabValue} index={index}>
                {itemValue.tabContent}
              </TabPanel>
            ),
        )}
      </div>
    </Box>
  );
};
