import styled from 'styled-components';
import { BadgeProps } from './Badge';

export const StyledBadge = styled.div<BadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${({ backgroundColor, theme: { color } }) => backgroundColor && color[backgroundColor]};
  padding: ${({ theme: { spacing } }) => spacing(0.5, 2)};
  width: ${({ width }) => width && width};
  text-transform: capitalize;
  min-width: ${({ theme: { spacing } }) => spacing(5)};
`;
