import { Property } from 'csstype';
import { FC, useEffect, useState } from 'react';
import { Colors } from '../../../theme';
import { Spacing } from '../../../utils';
import { Icons, Tooltip } from '../../atoms';
import { ButtonContentProps } from '../button/Button';
import { Checkbox } from '../checkbox/Checkbox';
import { StyledButton, StyledMenu, StyledMenuItem, TitleWrapper } from './DropdownMenu.styles';

export type DropdownMenuItem = {
  key: string;
  item: string | JSX.Element;
  disabled?: boolean;
  hasTooltip?: boolean;
  tooltipText?: JSX.Element;
  backgroundColor?: Colors;
};

export type DropdownMenuProps = {
  dropdownLabel: string;
  menuPosition?: 'left' | 'right';
  menuItems: DropdownMenuItem[];
  multiSelect?: boolean;
  initialSelected?: string[];
  checkbox?: boolean;
  indeterminateKey?: string;
  backgroundColor?: { hover: Colors; selected: Colors; selectedHover?: Colors };
  maxHeight?: Property.MaxHeight;
  maxWidth?: Property.MaxWidth;
  minWidth?: Property.MinWidth;
  buttonMinWidth?: Spacing;
  buttonContentProps?: ButtonContentProps & { isDisabled?: boolean };
  title?: string;
  customHeader?: JSX.Element;
  customFooter?: JSX.Element;
  isCompact?: boolean;
  isOnlyIcon?: boolean;
  allowTooltip?: boolean;
  onSelect: (selectedItemKeys: string[]) => void;
  overrideAction?: () => void;
};

export const DropdownMenu: FC<React.PropsWithChildren<DropdownMenuProps>> = ({
  menuPosition = 'right',
  dropdownLabel,
  multiSelect = false,
  menuItems,
  initialSelected,
  checkbox,
  indeterminateKey,
  buttonContentProps,
  maxHeight,
  maxWidth,
  minWidth,
  buttonMinWidth,
  title,
  customHeader,
  customFooter,
  isCompact,
  isOnlyIcon = false,
  allowTooltip = false,
  onSelect,
  overrideAction,
}) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const isMenuOpen = Boolean(anchorElement);

  useEffect(() => {
    setSelectedItems(initialSelected || []);
  }, [initialSelected]);

  const buttonOnClickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (overrideAction) {
      overrideAction();
      return;
    }

    setAnchorElement(event.currentTarget);
  };
  const handleItemClick = (selectedItemKey: string) => {
    let newSelectedItems: string[] = [];

    if (!multiSelect) {
      setAnchorElement(null);
      newSelectedItems.push(selectedItemKey);
    } else if (selectedItems.includes(selectedItemKey)) {
      // If we are deselecting a key which is not the indeterminate key, filter out both the key AND the indeterminate key
      if (indeterminateKey !== selectedItemKey) {
        newSelectedItems = selectedItems.filter((item) => item !== selectedItemKey && item !== indeterminateKey);
      }
      // If we are selecting the indeterminate key, return all keys
    } else if (indeterminateKey === selectedItemKey) {
      newSelectedItems = menuItems.map(({ key }) => key);
      // If we select any other key, add new key to list
    } else {
      newSelectedItems = [...selectedItems, selectedItemKey];
      // Add indeterminate key if we have selected all children
      if (
        indeterminateKey &&
        !newSelectedItems.includes(indeterminateKey) &&
        newSelectedItems.length === menuItems.length - 1
      ) {
        newSelectedItems.push(indeterminateKey);
      }
    }

    setSelectedItems(newSelectedItems);
    onSelect(newSelectedItems);
  };

  return (
    <>
      <StyledButton
        label={dropdownLabel}
        buttonContentProps={{
          labelPosition: 'left',
          iconOptions: {
            icon: Icons.DROPDOWN,
            size: 'small',
            color: isMenuOpen ? 'red' : buttonContentProps?.isDisabled ? 'red' : undefined,
            ...(isMenuOpen ? { rotate: 'up' } : {}),
          },
          textOptions: {
            color: isMenuOpen ? 'red' : buttonContentProps?.isDisabled ? 'red' : undefined,
          },
          ...buttonContentProps,
        }}
        data-testid="dropdown-menu-button"
        id="dropdown-menu-button"
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-label="dropdown-button"
        onClick={buttonOnClickHandler}
        variant="text"
        disabled={buttonContentProps?.isDisabled}
        minWidth={isOnlyIcon ? [12] : buttonMinWidth ? buttonMinWidth : [20]}
      />
      {!overrideAction && (
        <StyledMenu
          id="dropdown-menu"
          data-testid="dropdown-menu"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: menuPosition,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: menuPosition,
          }}
          elevation={4}
          MenuListProps={{
            'aria-labelledby': 'dropdown-menu-button',
          }}
          anchorEl={anchorElement}
          open={isMenuOpen}
          onClose={() => setAnchorElement(null)}
          $shouldIndentChildren={!!indeterminateKey}
          $maxHeight={maxHeight}
          $maxWidth={maxWidth}
          $minWidth={minWidth}
          $isCompact={isCompact}
          $allowTooltip={allowTooltip}
        >
          {customHeader && customHeader}
          {title && <TitleWrapper type="body3">{title}</TitleWrapper>}
          {menuItems.map(({ key, item, disabled, backgroundColor: bgColor, hasTooltip, tooltipText }) => (
            <StyledMenuItem
              key={key}
              selected={selectedItems.includes(key)}
              onClick={(event) => {
                if (!disabled) {
                  // Needed since otherwise clicking checkbox label does not propagate
                  event.preventDefault();
                  handleItemClick(key);
                }
              }}
              data-testid="dropdown-menu-item"
              disableRipple
              disabled={disabled}
              $backgroundColor={bgColor}
            >
              {checkbox && (
                <Checkbox
                  onChange={() => null}
                  label={item as string}
                  isChecked={selectedItems.includes(key)}
                  isIndeterminateState={
                    indeterminateKey === key && selectedItems.length > 0 && selectedItems.length < menuItems.length
                  }
                />
              )}
              {!checkbox && hasTooltip && tooltipText && (
                <Tooltip position="bottom-start" text={tooltipText}>
                  <div>{item}</div>
                </Tooltip>
              )}
              {!checkbox && !hasTooltip && item}
            </StyledMenuItem>
          ))}
          {customFooter && customFooter}
        </StyledMenu>
      )}
    </>
  );
};
