import { FC } from 'react';
import { SkeletonLoader } from '../../../../stories/atoms';
import { StyledContainer } from './AvatarSettingsLoader.styles';

export const AddAvatarLoader: FC = () => {
  return (
    <StyledContainer data-testid="avatar-settings-loader">
      <SkeletonLoader variant="rect" height="30px" width="60%" gutterBottom={5} />
      <SkeletonLoader variant="rect" height="30px" gutterBottom={10} />
      <SkeletonLoader variant="circle" height="128px" width="128px" gutterBottom={10} />
      <SkeletonLoader variant="rect" height="50px" width="50%" gutterBottom={5} />
      <SkeletonLoader variant="rect" height="50px" width="50%" />
    </StyledContainer>
  );
};

export const EditAvatarLoader: FC = () => {
  return (
    <>
      <StyledContainer data-testid="edit-avatar-loader">
        <SkeletonLoader variant="rect" height="30px" width="50%" gutterBottom={5} />
        <SkeletonLoader variant="rect" height="30px" width="60%" gutterBottom={15} />
        <SkeletonLoader variant="rect" height="500px" width="420px" />
      </StyledContainer>
    </>
  );
};
