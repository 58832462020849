import { SummaryCostDetails, SummaryCostType } from '../../hooks';

export const generateCardTitle = (departmentID: string, costName: string, isParent: boolean): string => {
  return `${!isParent && departmentID !== '' ? `${departmentID} - ` : ''} ${costName}`;
};

const isValueBelowThreshold = (threshold: number, value: string) => {
  const numberWithoutCommas = value.replace(/,/g, '');
  return parseInt(numberWithoutCommas) < threshold;
};

export const getColor = (label: string, value: string) => {
  if (label === 'indexVsLY' || label === 'indexVsBudget') {
    return isValueBelowThreshold(100, value) ? 'red' : 'validationGreen';
  }
  return 'primary';
};

const calculateIndexValue = (originalValue: number, comparisonValue: number) => {
  if (comparisonValue === 0 || originalValue === 0) {
    return 0;
  }

  const percentageChange = ((originalValue - comparisonValue) / comparisonValue) * 100;
  const indexValue = percentageChange < 0 ? 100 - Math.abs(percentageChange) : 100 + percentageChange;
  return parseFloat(indexValue.toFixed());
};

const calculateSobValue = (value: number | null, compareValue: number | null) => {
  if (!value || value === 0 || !compareValue || compareValue === 0) {
    return 0;
  }
  return ((value / compareValue) * 100).toFixed(1);
};

const isSummaryCostType = (costName: string): costName is SummaryCostType => {
  const summaryCostTypes: SummaryCostType[] = ['salesExcVat', 'salesIncVat'];
  return summaryCostTypes.includes(costName as SummaryCostType);
};

export const profitLossPropertyToValueMap = (
  costName: string,
  details: SummaryCostDetails,
  compareCostDetails: SummaryCostDetails,
) => {
  const { actual, lastYear, budget } = details;
  const { actual: salesExclActual, lastYear: salesExclLastYear, budget: salesExclBudget } = compareCostDetails;

  const isSummary = isSummaryCostType(costName);

  const indexVsLY = calculateIndexValue(actual, lastYear ?? 0);
  const indexVsBudget = calculateIndexValue(actual, budget ?? 0);
  const sob = isSummary ? 100 : calculateSobValue(actual, salesExclActual);
  const sobLY = isSummary ? 100 : calculateSobValue(lastYear, salesExclLastYear);
  const sobBudget = isSummary ? 100 : calculateSobValue(budget, salesExclBudget);

  return [
    { key: 'actuals', value: actual },
    { key: 'indexVsLY', value: indexVsLY },
    { key: 'indexVsBudget', value: indexVsBudget },
    { key: 'sob', value: `${sob}%` },
    { key: 'sobLY', value: `${sobLY}%` },
    { key: 'sobBudget', value: `${sobBudget}%` },
  ];
};
