import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Delay } from '../../../constants';
import { LocalStorageKey } from '../../../constants/localStorageKey';
import { useUser, useLocalStorage } from '../../../hooks';
import { Container, Icons, Typography } from '../../../stories/atoms';
import { Animation, Modal } from '../../../stories/molecules';
import { commonTranslations } from '../../../translations';
import { selectStoreTranslation } from '../../../translations/selectStore';
import {
  StyledCloseButton,
  StyledContainer,
  StyledSwitchButton,
  StyledTypography,
} from './NavigateToSwitchStoreModal.styles';

export type NavigateToSwitchStoreModalProps = {
  isOpen: boolean;
  numberOfStores: number;
  onClose: () => void;
};

export const NavigateToSwitchStoreModal: FC<NavigateToSwitchStoreModalProps> = observer(
  ({ isOpen, numberOfStores, onClose }) => {
    const {
      get: { currentStoreId },
      set,
    } = useUser();
    const [showAnimation, setShowAnimation] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { removeItem } = useLocalStorage();

    const { switchingStore, viewStoresHeader, viewStoresMessage, viewStoresButton } = selectStoreTranslation;

    const handleOnClick = () => {
      set({ previousStoreId: currentStoreId });

      removeItem(LocalStorageKey.SelectedStore);

      set({ currentStoreId: '' });
      setShowAnimation(true);

      setTimeout(() => {
        onClose();
        navigate('/');
      }, Delay.Medium);
    };

    return (
      <>
        <Modal
          isOpen={isOpen}
          isFullScreen={showAnimation}
          transitionType="slide"
          ariaDescribedby="change-store-modal"
          handleClose={onClose}
        >
          {showAnimation ? (
            <Animation title={t(switchingStore.key, switchingStore.defaultValue)} />
          ) : (
            <StyledContainer>
              <StyledTypography type="h2" textAlign="center">
                {t(viewStoresHeader.key, viewStoresHeader.defaultValue)}
              </StyledTypography>
              <Typography margin={[8, 0]}>
                <Trans
                  i18nKey={viewStoresMessage.key}
                  defaults={viewStoresMessage.defaultValue}
                  components={{ b: <b /> }}
                  values={{ numberOfStores: numberOfStores }}
                />
              </Typography>
              <Container direction="horizontal" padding={[0, 0, 5, 0]} position="center">
                <StyledCloseButton
                  onClick={onClose}
                  label={t(commonTranslations.close.key, commonTranslations.close.defaultValue)}
                  variant="secondary"
                  data-testid="close-button"
                />
                <StyledSwitchButton
                  data-testid="switch-store-button"
                  onClick={handleOnClick}
                  label={t(viewStoresButton.key, viewStoresButton.defaultValue)}
                  buttonContentProps={{
                    iconOptions: { icon: Icons.SWITCH, color: 'white', size: 'small' },
                    textOptions: { color: 'white' },
                  }}
                />
              </Container>
            </StyledContainer>
          )}
        </Modal>
      </>
    );
  },
);
