import { ProfitLossResponse } from '../../hooks';

export const getRandomNumber = (min: number, max: number, decimals: number = 0): number => {
  const factor = Math.pow(10, decimals);
  return Math.round((Math.random() * (max - min) + min) * factor) / factor;
};

const otherCosts = [
  'Electricity',
  'Shopfitting and Bulbs',
  'Cleaning, Pest and Waste',
  'Hangers',
  'Travel Expense',
];
const reductionCosts = ['Reduction Textile', 'Reduction Cosmetics', 'Statistical Cost of Reduction'];
const salariesCosts = [
  'Fixed Salaries',
  'Flexible Salaries',
  'Overtime',
  'Illness',
  'Vacation',
  'Temporary Staff Agency',
  'Other personnel costs/Employee',
];
const scrappingCosts = ['Scrapping of Faulty Garments'];
const shrinkageCosts = ['Shrinkage'];
const surplus1 = ['Surplus1'];

export const MockPLReportData: ProfitLossResponse = {
  lastUpdated: new Date(),
  salesExcVat: {
    actual: getRandomNumber(100000, 10000000),
    lastYear: getRandomNumber(100000, 1000000),
    budget: getRandomNumber(100000, 10000000),
  },
  salesIncVat: {
    actual: getRandomNumber(100000, 10000000),
    lastYear: getRandomNumber(100000, 10000000),
    budget: getRandomNumber(100000, 10000000),
  },
  details: {
    otherCosts: otherCosts.map((item) => ({
      name: item,
      actual: getRandomNumber(1000, 100000),
      lastYear: getRandomNumber(1000, 100000),
      budget: getRandomNumber(1000, 100000),
    })),
    reductions: reductionCosts.map((item) => ({
      name: item,
      actual: getRandomNumber(1000, 100000),
      lastYear: getRandomNumber(1000, 100000),
      budget: getRandomNumber(1000, 100000),
    })),
    salaries: salariesCosts.map((item) => ({
      name: item,
      actual: getRandomNumber(1000, 100000),
      lastYear: getRandomNumber(1000, 100000),
      budget: getRandomNumber(1000, 100000),
    })),
    scrapping: scrappingCosts.map((item) => ({
      name: item,
      actual: getRandomNumber(1000, 100000),
      lastYear: getRandomNumber(1000, 100000),
      budget: getRandomNumber(1000, 100000),
    })),
    shrinkage: shrinkageCosts.map((item) => ({
      name: item,
      actual: getRandomNumber(1000, 100000),
      lastYear: getRandomNumber(1000, 100000),
      budget: getRandomNumber(1000, 100000),
    })),
    surplus1: surplus1.map((item) => ({
      name: item,
      actual: getRandomNumber(1000, 100000),
      lastYear: getRandomNumber(1000, 100000),
      budget: getRandomNumber(1000, 100000),
    })),
  },
};
