import styled from 'styled-components';
import { Typography } from '../../../../stories/atoms';
import { Button } from '../../../../stories/molecules';

export const StyledEditRoleView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledRoleContent = styled.div`
  display: flex;
  grid-area: content;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const StyledButtonWrapper = styled.div`
  grid-area: buttons;
  padding-top: ${({ theme }) => theme.spacing(8)};
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'backButton saveButton'
    '. errorMessage';
`;

export const StyledBackButton = styled(Button)`
  grid-area: backButton;
  justify-self: end;
  margin-right: ${({ theme }) => theme.spacing(6)};
`;

export const StyledSaveButton = styled(Button)`
  grid-area: saveButton;
`;
export const StyledErrorTypography = styled(Typography)`
  grid-area: errorMessage;
  width: 50%;
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(4)};

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    flex-direction: column;
    width: auto;
  }
`;
