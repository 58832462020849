import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({
    theme: {
      palette: { mode },
    },
  }) => (mode === 'light' ? 'rgba(255,255,255, 0.7)' : 'rgba(0,0,0, 0.6)')};
`;
