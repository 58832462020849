import styled from 'styled-components';

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(3)};
  overflow: auto;
  max-height: ${({ theme }) => theme.spacing(67)};
`;

export const StyledSection = styled.div`
  border-radius: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  border: 1px solid
    ${({ theme: { palette, color } }) => (palette.mode === 'light' ? color.borderGray : color.borderGrayDark)};
`;
