// TODO: Uncomment all commented code while integrating KPI target and forecast for SCO SOR widget [BTCOBUI-2630]
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import {
  SCOShareOfReceiptsItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icons, Typography } from '../../../../../stories/atoms';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { StyledBreakdownContainer, StyledSubHeader, StyledView } from '../shareOfReceipts/ShareOfReceipts.styles';

export type SCOShareOfReceiptsProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  transactionEventsCount: number;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const SCOShareOfReceipts: FC<SCOShareOfReceiptsProps> = observer(
  ({
    interval,
    currentDate,
    // hasPermissionToEditKpi,
    transactionEventsCount,
    isToday,
    // handleDropdownDetails,
    fromDate,
    toDate,
  }) => {
    const {
      get: {
        currentStoreId,
        // kpiTargetsProps
      },
    } = useUser();

    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { fetchSCOShareOfReceiptsData } = useTransaction();

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<SCOShareOfReceiptsItem>();

    // const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailySCOShareOfReceipts')?.isLocked ?? false;

    const { t } = useTranslation();
    // const target = getKpiTargetValue(kpiTargetsProps, 'dailySCOShareOfReceipts');

    const { scoShareOfReceipts: scoShareOfReceiptsTranslations, common: commonTranslations } = homeTranslations;

    const itemValueResolver = (item: SCOShareOfReceiptsItem) => {
      const { totalSCOTransactions, totalTransactions } = item;
      return totalTransactions === 0 ? 0 : parseInt(((totalSCOTransactions / totalTransactions) * 100).toFixed(2));
    };

    const fetchData = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchSCOShareOfReceiptsData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.shareOfReceiptsSCO;
    }, [interval, currentDate]);

    // const fetchForecastData = async () => {
    //   const response = await fetchForecastForKpis<SCOShareOfReceiptsItem>('shareOfReceipt');

    //   if (isFailureResponse(response)) {
    //     throw new Error();
    //   }

    //   return response.data;
    // };

    const getShareOfReceipts = (items: (SCOShareOfReceiptsItem | null)[]) => {
      let currentTotalSCOTransactions = 0;
      let currentTotalTransactions = 0;

      items.forEach((item) => {
        if (item === null) return;

        const { totalSCOTransactions, totalTransactions } = item;

        if (totalTransactions === 0) return;

        currentTotalSCOTransactions += totalSCOTransactions;
        currentTotalTransactions += totalTransactions;
      });

      const currentShareOfReceipts =
        currentTotalTransactions === 0
          ? 0
          : parseInt(((currentTotalSCOTransactions / currentTotalTransactions) * 100).toFixed(0));

      return {
        currentShareOfReceipts: currentShareOfReceipts,
        currentTotalSCOTransactions: currentTotalSCOTransactions,
      };
    };

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        // getForecastDataAction: fetchForecastData,
        valueResolver: itemValueResolver,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate, isToday, transactionEventsCount]);

    // TODO: change this to correct value while integrating KPI Target
    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailySCOShareOfReceipts',
    });

    // const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    // const isEditKPIDisabled = performanceItemsError || !hasPermissionToEditKpi;

    const { currentShareOfReceipts, currentTotalSCOTransactions } = getShareOfReceipts(filteredPerformanceItems);

    const isNoData = currentShareOfReceipts === 0;

    // const { currentShareOfReceipts: forecastShareOfReceipts } = getShareOfReceipts([
    //   ...filteredPerformanceItems,
    //   ...forecastPerformanceItems,
    // ]);

    // const targetProgressPercent = target && target != 0 ? (forecastShareOfReceipts / target) * 100 : 0;

    // const menuItems = [
    //   {
    //     key: target ? 'editKpi' : 'addKpi',
    //     disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
    //     hasTooltip: isEditKPIDisabled || isTargetLocked,
    //     tooltipText: (
    //       <Container padding={[1]}>
    //         <Typography color="white">
    //           {!hasPermissionToEditKpi
    //             ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
    //             : isTargetLocked
    //             ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
    //             : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
    //         </Typography>
    //       </Container>
    //     ),
    //     item: (
    //       <Typography margin={[0, 2, 0]}>
    //         {target
    //           ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
    //           : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
    //       </Typography>
    //     ),
    //   },
    // ];

    return (
      <WidgetCardContent
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(scoShareOfReceiptsTranslations.title.key, scoShareOfReceiptsTranslations.title.defaultValue)}
        subHeaderText={
          t(scoShareOfReceiptsTranslations.subTitle.key, scoShareOfReceiptsTranslations.subTitle.defaultValue) +
          ' ' +
          currentStoreId
        }
        isLoading={performanceItemsLoading}
        toolTipText={t(
          scoShareOfReceiptsTranslations.toolTip.key,
          scoShareOfReceiptsTranslations.toolTip.defaultValue,
        )}
        isError={performanceItemsError}
        errorText={t(scoShareOfReceiptsTranslations.error.key, scoShareOfReceiptsTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData || false}
        noDataText={t(commonTranslations.noSCOReceipts.key, commonTranslations.noSCOReceipts.defaultValue)}
        lastUpdatedDate={lastUpdated}
        // headerSideContent={
        // <StyledRightContainerWrapper>
        //   <DropdownMenu
        //     data-testid="kpiDropdown"
        //     dropdownLabel={''}
        //     buttonContentProps={{
        //       iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
        //     }}
        //     menuItems={menuItems}
        //     minWidth={'0px'}
        //     isCompact
        //     initialSelected={[]}
        //     onSelect={(selectedItem) => {
        //       if (!isEditKPIDisabled) {
        //         handleDropdownDetails('dailyShareOfReceipts', selectedItem[0] as KpiDropdownActions);
        //       }
        //     }}
        //     isOnlyIcon
        //     allowTooltip
        //   />
        // </StyledRightContainerWrapper>
        // }
        content={
          <Container wrap="nowrap" data-testid="sco-share-of-receipts-wrapper" height="100%">
            <StyledView>
              {/* {showForecast && (
                    <ProgressIndicator
                      valueType={Forecast.PERCENTAGE}
                      value={forecastShareOfReceipts}
                      progressPercentage={targetProgressPercent}
                      padding={[1, 2]}
                      margin={[2, 2, 1, 2]}
                    />
                  )} */}
              <StyledSubHeader>
                <StyledBreakdownContainer>
                  <Typography>{`${t(
                    homeTranslations.scoShareOfReceipts.totalShareOfReceipts.key,
                    homeTranslations.scoShareOfReceipts.totalShareOfReceipts.defaultValue,
                  )}: ${currentShareOfReceipts}%`}</Typography>
                  <Typography>{`${t(
                    homeTranslations.scoShareOfReceipts.totalSCOTransactions.key,
                    homeTranslations.scoShareOfReceipts.totalSCOTransactions.defaultValue,
                  )}: ${currentTotalSCOTransactions.toFixed()}`}</Typography>
                </StyledBreakdownContainer>

                {/* <StyledLegendContainer>
                      {target && (
                        <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[0]}>
                          {`${t(
                            homeTranslations.common.targetKPISOR.key,
                            homeTranslations.common.targetKPISOR.defaultValue,
                          )} ${target}%`}
                        </StyledCustomLegends>
                      )}
                    </StyledLegendContainer> */}
              </StyledSubHeader>
              <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, overflow: 'hidden' }}>
                <WidgetChart
                  performanceItemsChartName={t(
                    homeTranslations.scoShareOfReceipts.sor.key,
                    homeTranslations.scoShareOfReceipts.sor.defaultValue,
                  )}
                  performanceItems={performanceItems}
                  filteredPerformanceItems={filteredPerformanceItems}
                  forecastPerformanceItems={forecastPerformanceItems}
                  targetItems={targetItems}
                  itemValueResolver={itemValueResolver}
                  showXAxis={true}
                  yAxisFormatter={(value) => `${value}%`}
                  tooltipFormatter={(value) => `${Math.ceil(value)}`}
                />
              </Container>
            </StyledView>
          </Container>
        }
      />
    );
  },
);
