import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile, Client, AdditionalPermissions } from '../../../../global-state/types';
import { useUser, useProfile } from '../../../../hooks';
import { Container, Icon, Icons, Typography, Tooltip } from '../../../../stories/atoms';
import { Button, Checkbox } from '../../../../stories/molecules';
import { employeesTranslations, commonTranslations } from '../../../../translations';
import {
  isFailureResponse,
  PermissionType,
  permissionTypes,
  isSupportedSuamRole,
  RoleMapping,
  getSuamRoles,
  isEditPermissionCheckBoxDisabled,
  permissionTypeMapping,
} from '../../../../utils';
import { removeDuplicateCharacters } from '../../../../utils/formatter';
import {
  StyledCheckBoxWrapper,
  StyledButtonWrapper,
  StyledBackButton,
  StyledPermissionWrapper,
  StyledLabelWrapper,
  StyledPageTitle,
  StyledContainer,
  StyledWrapper,
  StyledCheckbox,
} from './EmployeeEditPermissionView.styles';

export type EmployeeNotificationType = 'editPermissions' | 'editRole';

export type EmployeeEditPermissionsViewProps = {
  employee: Profile;
  handleBack: () => void;
  handleSubmit: (isSuccess: boolean, type: EmployeeNotificationType) => void;
};

export const EmployeeEditPermissionsView: FC<EmployeeEditPermissionsViewProps> = observer(
  ({ employee, handleBack, handleSubmit }) => {
    const [employeeRoles, setEmployeeRoles] = useState<string[]>([]);
    const {
      get: { roleMatrix, profile, employees, currentStoreId },
      set,
    } = useUser();

    const { updateProfile } = useProfile();
    const { t } = useTranslation();

    const { additionalPermissions, upn, assignments: employeeAssignments } = employee;

    useEffect(() => {
      setEmployeeRoles(getSuamRoles(employee, currentStoreId));
    }, [employeeAssignments, currentStoreId]);

    const initialPermissions: AdditionalPermissions = JSON.parse(JSON.stringify(additionalPermissions || {})); // Deep copy to avoid mutating initialPermission

    const [permissions, setPermissions] = useState<AdditionalPermissions>(initialPermissions);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

    const {
      editPermissionModal: { permissionHeader, savePermission },
    } = employeesTranslations;

    /**
     * This function rearrange all the permission type characters in "CRUD" format.
     * example - "DUC" will be rearranged to "CUD" or "UCR" will be rearranged to "CRU"
     * useCase - Useful when comparing between two permissionTypes otherwise string comparison becomes difficult.
     */
    const rearrangeCharacters = (permissionType: string) => {
      return permissionTypes
        .map((character) => (permissionType.includes(character) ? character : ''))
        .join('')
        .trim();
    };

    const handleCheckBoxChange = (storeId: string, isChecked: boolean, feature: string, type: string) => {
      // If the storeId doesnt exist in permissions, we know that the checkbox has been selected and the type should be added
      if (!permissions[storeId]) {
        setPermissions({ ...permissions, [storeId]: { [feature]: { permissionType: type } } });
        setSaveButtonEnabled(true);
        return;
      }

      const updatedPermission = permissions;
      const existingPermissions = updatedPermission[storeId][feature]?.permissionType || '';
      const updatedPermissionType = isChecked ? existingPermissions + type : existingPermissions.replace(type, '');

      if (!updatedPermissionType) {
        // TODO: find alternative to remove delete to avoid mutating initialState and also remove JSON.stringify and JSON.parse from initialPermissions - BTCOBUI-847.
        delete updatedPermission[storeId][feature];
        if (Object.keys(updatedPermission[storeId]).length === 0) {
          delete updatedPermission[storeId];
        }
      }

      const updatedPermissionMatrix = updatedPermissionType
        ? {
            ...updatedPermission,
            [storeId]: {
              ...updatedPermission[storeId],
              ...updatedPermission.box, // Add previous box permissions to store permissions
              [feature]: {
                ...updatedPermission[storeId][feature],
                permissionType: rearrangeCharacters(removeDuplicateCharacters(updatedPermissionType)),
              },
            },
          }
        : updatedPermission;

      setPermissions(updatedPermissionMatrix);
      setSaveButtonEnabled(JSON.stringify(updatedPermissionMatrix) !== JSON.stringify(initialPermissions));
    };

    const handleSave = async () => {
      const response = await updateProfile(upn, [
        { op: 'add', path: '/additionalPermissions', value: permissions },
      ]);

      const successfulUpdate = !isFailureResponse(response);
      if (successfulUpdate) {
        set({
          employees: employees.map((currentEmployee) =>
            employee.id === currentEmployee.id ? response.data : currentEmployee,
          ),
        });
      } else {
        setPermissions(initialPermissions);
      }

      handleSubmit(successfulUpdate, 'editPermissions');
    };

    const getIsDisabled = (client: Client, feature: string, type: string) =>
      isEditPermissionCheckBoxDisabled(
        employeeRoles,
        roleMatrix,
        profile,
        currentStoreId,
        client as Client,
        feature,
        type,
      );

    const getIsChecked = (client: Client, feature: string, type: string) => {
      return employeeRoles.some((role) => {
        const employeeRole = isSupportedSuamRole(role) ? RoleMapping[role] : 'other';
        const hasPermissionInRoleMatrix =
          roleMatrix[client].featurePermissions[feature].permissions[employeeRole]?.includes(type);
        const hasAdditionalPermission =
          employee.additionalPermissions &&
          (employee.additionalPermissions[currentStoreId]
            ? Object.keys(employee.additionalPermissions[currentStoreId])
                .filter((key) => key === feature)
                .filter(
                  (key) =>
                    employee.additionalPermissions &&
                    employee.additionalPermissions[currentStoreId][key]?.permissionType.includes(type),
                ).length > 0
            : employee.additionalPermissions[client]
            ? Object.keys(employee.additionalPermissions[client])
                .filter((key) => key === feature)
                .filter(
                  (key) =>
                    employee.additionalPermissions &&
                    employee.additionalPermissions[client][key]?.permissionType.includes(type),
                ).length > 0
            : false);

        return hasPermissionInRoleMatrix || hasAdditionalPermission;
      });
    };

    const getPermissionContent = (client: Client) => {
      return (
        <StyledContainer key={client}>
          <Typography type="h4" margin={[0, 0, 2]}>
            {t(`common.client.${client}`, client) + ' ' + t(permissionHeader.key, permissionHeader.defaultValue)}
          </Typography>
          <StyledPermissionWrapper direction="horizontal" space="between">
            {roleMatrix[client as Client].featurePermissions &&
              Object.entries(roleMatrix[client as Client].featurePermissions).map(([key, { isActive }]) => {
                return (
                  isActive && (
                    <Container key={key} data-testid={key}>
                      <StyledLabelWrapper>
                        <Container direction="horizontal" padding={[3, 0, 5]} wrap="nowrap">
                          <StyledPageTitle type="body3">{t(`common.pages.${key}`, key)}</StyledPageTitle>
                          <Tooltip
                            position="right-start"
                            text={
                              <Container padding={[2]}>
                                <Typography color="white">{t(`common.pages.${key}`, key)}</Typography>
                              </Container>
                            }
                          >
                            <Icon data-testid="infoIcon" icon={Icons.INFO} size="small" margin={[0, 1]} />
                          </Tooltip>
                        </Container>
                      </StyledLabelWrapper>
                      <StyledCheckBoxWrapper>
                        {(Object.keys(permissionTypeMapping) as PermissionType[]).map((type) => {
                          const isCheckboxDisabled = getIsDisabled(client as Client, key, type);

                          return (
                            <StyledCheckbox key={type} data-testid={`checkbox-type-${type.toLocaleLowerCase()}`}>
                              <Checkbox
                                isChecked={getIsChecked(client as Client, key, type)}
                                isDisabled={isCheckboxDisabled}
                                label={t(
                                  permissionTypeMapping[type].key,
                                  permissionTypeMapping[type].defaultValue,
                                )}
                                labelColor={isCheckboxDisabled ? 'inActiveGray' : 'primary'}
                                onChange={(isChecked) =>
                                  handleCheckBoxChange(currentStoreId, isChecked, key, type)
                                }
                              />
                            </StyledCheckbox>
                          );
                        })}
                      </StyledCheckBoxWrapper>
                    </Container>
                  )
                );
              })}
          </StyledPermissionWrapper>
        </StyledContainer>
      );
    };

    return (
      <StyledWrapper data-testid="edit-permission-view">
        {Object.keys(roleMatrix).map((client) => {
          if (client === 'box') {
            return getPermissionContent('box');
          }
        })}
        <StyledButtonWrapper direction="horizontal" position="center">
          <StyledBackButton
            label={t(commonTranslations.back.key, commonTranslations.back.defaultValue)}
            variant="secondary"
            onClick={handleBack}
            data-testid="back-button"
          />
          <Button
            label={t(savePermission.key, savePermission.defaultValue)}
            onClick={handleSave}
            data-testid="save-button"
            disabled={!saveButtonEnabled}
          />
        </StyledButtonWrapper>
      </StyledWrapper>
    );
  },
);
