import styled from 'styled-components';
import { Accordion, AccordionSummary } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)`
  > div {
    align-items: center;
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    &.Mui-expanded {
      background-color: ${({ theme: { color, palette } }) =>
        palette.mode === 'dark' ? color.backgroundDark : color.baseGray};
    }
    ::before {
      background-color: transparent;
    }
  }
`;
