import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Typography } from '../../../../stories/atoms';
import { profileTranslations, employeesTranslations } from '../../../../translations';
import { EmployeeEditRoleView } from '../employee-edit-role-view/EmployeeEditRoleView';
import {
  EmployeeEditPermissionsView,
  EmployeeNotificationType,
} from '../employee-edit-permissions-view/EmployeeEditPermissionView';
import { useUser } from '../../../../hooks';
import { isSupportedSuamRole, RoleMapping } from '../../../../utils';
import { Profile } from '../../../../global-state/types';
import { muiTheme } from '../../../../theme';
import { StyledContainer, StyledHeader, StyledHeaderBody, StyledTypography } from './EmployeeOverViewModal.styles';
import { EmployeeOverviewScreen } from './employee-overview-screen/EmployeeOverviewScreen';

export type EmployeeNotificationProps = {
  isSuccess: boolean;
  type: EmployeeNotificationType;
  employeeInfo: Profile;
};

export type EmployeeOverviewModalProps = {
  employee: Profile;
  isModalOpen: boolean;
  isCurrentUser: boolean;
  handleClose: () => void;
  handleNotification: (notificationInfo: EmployeeNotificationProps) => void;
};

enum RolePermissionsView {
  OverView,
  EditRoles,
  EditPermissions,
}

const { fullName } = profileTranslations;
const {
  overviewRolesPermission: { header, headerMobile, headerDetails, manageRoleFor },
  editRolesModal,
  editPermissionModal,
  employees: { other },
} = employeesTranslations;

export const EmployeeOverviewModal: FC<EmployeeOverviewModalProps> = ({
  employee,
  isModalOpen,
  isCurrentUser,
  handleClose,
  handleNotification,
}) => {
  const [view, setView] = useState<RolePermissionsView>(RolePermissionsView.OverView);
  const { firstName, lastName, assignments } = employee;

  const { t } = useTranslation();
  const {
    get: { currentStoreId },
  } = useUser();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const [startTab, setStartTab] = useState<number>(0);

  const selectedEmployeeRole = [
    ...new Set(
      assignments
        ?.filter(({ locationCode }) => locationCode === currentStoreId)
        .map(({ suamRoleCode }) => suamRoleCode),
    ),
  ]?.map(
    (suamRoleCode, index) =>
      (index ? ', ' : '') +
      (isSupportedSuamRole(suamRoleCode)
        ? t(`employees.roles.${RoleMapping[suamRoleCode]}`, RoleMapping[suamRoleCode])
        : t(other.key, other.defaultValue)),
  );

  useEffect(() => {
    if (!isModalOpen) {
      setView(RolePermissionsView.OverView);
    }
  }, [isModalOpen]);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setStartTab(newValue);
  };

  return (
    <StyledContainer data-testid="overview-role-modal-layout">
      <StyledHeader>
        <StyledTypography type="h2" textAlign="center">
          {view === RolePermissionsView.EditPermissions
            ? t(editPermissionModal.header.key, editPermissionModal.header.defaultValue)
            : t(manageRoleFor.key, manageRoleFor.defaultValue)}{' '}
          {t(fullName.key, {
            defaultValue: fullName.defaultValue,
            firstName,
            lastName,
          })}
        </StyledTypography>
        <StyledHeaderBody>
          <StyledTypography type="body3" padding={[2, 0, 3, 0]} wrapText>
            {isMobileView
              ? t(headerMobile.key, headerMobile.defaultValue)
              : t(header.key, {
                  defaultValue: header.defaultValue,
                  firstName,
                  lastName,
                })}
            <Typography color="validationGreen" borderBottomColor="validationGreen" type="body3">
              {selectedEmployeeRole}
            </Typography>
          </StyledTypography>
          {view === RolePermissionsView.OverView && isMobileView && (
            <StyledTypography>
              {view === RolePermissionsView.OverView
                ? t(headerDetails.key, headerDetails.defaultValue)
                : view === RolePermissionsView.EditRoles
                ? t(editRolesModal.headerDetails.key, editRolesModal.headerDetails.defaultValue)
                : t(editPermissionModal.headerDetails.key, editPermissionModal.headerDetails.defaultValue)}
            </StyledTypography>
          )}
        </StyledHeaderBody>
      </StyledHeader>
      {view === RolePermissionsView.OverView && (
        <EmployeeOverviewScreen
          employee={employee}
          isCurrentUser={isCurrentUser}
          startTab={startTab}
          handleChangeTabs={handleChangeTabs}
          handleEditPermission={() => setView(RolePermissionsView.EditPermissions)}
          handleEditRole={() => setView(RolePermissionsView.EditRoles)}
        />
      )}
      {view === RolePermissionsView.EditRoles && (
        <EmployeeEditRoleView
          employee={employee}
          handleCancel={() => setView(RolePermissionsView.OverView)}
          handleSubmit={(isSuccess, type) => {
            setView(RolePermissionsView.OverView);
            handleClose();
            handleNotification({ employeeInfo: employee, isSuccess, type });
          }}
        />
      )}
      {view === RolePermissionsView.EditPermissions && (
        <EmployeeEditPermissionsView
          employee={employee}
          handleBack={() => setView(RolePermissionsView.OverView)}
          handleSubmit={(isSuccess, type) => {
            setView(RolePermissionsView.OverView);
            handleClose();
            handleNotification({ employeeInfo: employee, isSuccess, type });
          }}
        />
      )}
    </StyledContainer>
  );
};
