import { FC } from 'react';
import { Icons, Typography } from '../../../../../stories/atoms';
import { IconWithText } from '../../../../../stories/molecules';
import { Colors } from '../../../../../theme';
import { Container, ContentContainer, Indicator, IndicatorContainer } from './MetricItem.styles';

export const MetricItem: FC<{
  icon: Icons;
  percentValue: number;
  indicatorColor: Colors;
  titleText: string;
  descriptionText: string;
}> = ({ icon, percentValue, indicatorColor, titleText, descriptionText }) => {
  return (
    <Container>
      <ContentContainer>
        <IconWithText
          label={<Typography type="button">{titleText}</Typography>}
          iconOptions={{
            icon: icon,
            size: 'small',
          }}
          gutterBottom
          spaceBetween="small"
        />

        <Typography>{descriptionText}</Typography>
      </ContentContainer>

      <IndicatorContainer>
        <Indicator>
          <Typography type="h4" color={indicatorColor}>
            {percentValue}%
          </Typography>
        </Indicator>
      </IndicatorContainer>
    </Container>
  );
};
