export const selectRoleTranslation = {
  swicthingRoles: {
    key: 'selectRole.swicthingRoles',
    defaultValue: 'Assigning a new role...',
  },
  header: {
    key: 'selectRole.header',
    defaultValue: 'Want to select a new role?',
  },
  label: {
    key: 'selectRole.label',
    defaultValue: 'Select a role',
  },
  subHeader: {
    key: 'selectRole.subHeader',
    defaultValue: 'Select the role you want to work with. You can always switch to another role later.',
  },
  button: {
    key: 'selectRole.button',
    defaultValue: 'OK',
  },
  error: {
    key: 'selectRole.error',
    defaultValue: 'Could not assign you the selected role. \nTry again later!',
  },
};
