import React, { FC } from 'react';
import Paper from '@mui/material/Paper';
import { ToggleButton as MuiToggleButton } from '@mui/material';
import { Icon, Icons, Typography } from '../../atoms';
import { StyledToggleButtonGroup } from './ToggleButton.styles';

export type ToggleButtonProps = {
  leftButton: string | JSX.Element | Icons;
  rightButton: string | JSX.Element | Icons;
  value: string;
  onChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
};

export const ToggleButton: FC<ToggleButtonProps> = ({ leftButton, rightButton, value, onChange }) => {
  const getButtonType = (button: string | JSX.Element | Icons, view: string) => {
    return typeof button === 'object' ? (
      button
    ) : Object.values(Icons).includes(button as unknown as Icons) ? (
      <MuiToggleButton value={view} aria-label="right button">
        <Icon data-testid="right-icon" icon={button as Icons} size="small" />
      </MuiToggleButton>
    ) : (
      <MuiToggleButton value={view} aria-label="left button">
        <Typography type="caption">{button}</Typography>
      </MuiToggleButton>
    );
  };

  return (
    <div data-testid="toggle-button-wrapper">
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? theme.color.borderGrayDark : theme.palette.grey[300]}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup size="small" value={value} exclusive onChange={onChange} aria-label="change-view">
          {getButtonType(leftButton, 'view1')}
          {getButtonType(rightButton, 'view2')}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};
