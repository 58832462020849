import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: ${({ theme: { spacing } }) => spacing(1)};
  width: 100%;
  background-color: ${({ theme: { color } }) => color.borderGray};
`;

const InnerContainer = styled.div`
  height: 100%;
  background-color: ${({ theme: { color } }) => color.validationGreen};
`;

type ProgressLineProps = {
  valuePercent: number;
};

export const ProgressLine: FC<ProgressLineProps> = ({ valuePercent }) => {
  return (
    <Container>
      <InnerContainer style={{ width: `${valuePercent}%` }} />
    </Container>
  );
};
