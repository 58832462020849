import styled, { css } from 'styled-components';
import { Typography } from '../../atoms';
import { Sizes } from '../../../theme';
import { Button } from '../button/Button';
import { type InputFieldProps } from './InputField';

const sizeMapping = {
  small: '200px',
  medium: '300px',
  large: '400px',
};

export const StyledInputContainer = styled.div<Partial<InputFieldProps>>`
  display: inline-flex;
  flex-direction: ${({ iconPosition }) => iconPosition && (iconPosition === 'left' ? 'row' : 'row-reverse')};
  align-items: center;
  border: ${({ theme: { color }, status }) =>
    `1px solid ${
      status === 'error' ? color.red : status === 'success' ? color.validationGreen : color.inActiveGray
    }`};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  font-family: ${({ theme: { typography }, inputTypography: type }) => type && typography[type].fontFamily};
  color: ${({ status: inputStatus, theme: { color } }) => (inputStatus === 'error' ? color.red : color.primary)};
  background-color: ${({ theme: { color } }) => color.white};
  width: ${({ size }) => size && (size === 'fullWidth' ? '100%' : sizeMapping[size as Sizes])};

  &.is-focused {
    border: ${({ theme: { color } }) => `1px solid ${color.primary}`};
  }

  &.is-disabled {
    background-color: ${({ theme: { color } }) => color.inActiveGray};

    &:hover {
      cursor: not-allowed;
    }
  }

  :hover {
    cursor: pointer;
  }

  input {
    flex: 1;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
    border: none;
    outline: none;
    color: inherit;
    background: inherit;
    cursor: inherit;
  }

  [data-testid='input-icon'] {
    color: ${({ theme: { color }, status }) =>
      `${status === 'error' ? color.red : status === 'success' ? color.validationGreen : 'inherit'}`};
    margin: ${({ theme }) => theme.spacing(0, 2)};
  }

  [data-testid='toggle-password'] {
    border: none;
  }
`;

export const StyledPasswordToggleButton = styled(Button)`
  border: none;

  :hover {
    background-color: transparent;
  }
`;

export const StyledHelperText = styled(Typography)<{ isError: boolean; isDarkMode: boolean }>`
  color: ${({ theme: { color }, isError, isDarkMode }) =>
    `${isError ? color.red : isDarkMode ? color.white : color.textGray}`};
  background-color: transparent;
  margin-top: ${({ theme }) => theme.spacing(1)};
  text-align: left;
  line-height: 1.5;
`;

export const StyledLabel = styled(Typography)<{ isRequired?: boolean; disabled?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ isRequired }) =>
    isRequired &&
    css`
      :after {
        content: ' *';
        color: ${({ theme: { color } }) => color.red};
      }
    `}
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
