import styled from 'styled-components';
import { muiTheme as Theme } from '../../../theme';
import { getSpacing } from '../../../utils';
import type { ButtonProps } from './Button';

type ButtonVariantObject = {
  border?: string;
  darkModeBorder?: string;
  backgroundColor?: string;
  darkModeBackgroundColor?: string;
  textColor: string;
  darkModeTextColor: string;
  disabledBackgroundColor: string;
};

export type ButtonVariants = 'primary' | 'secondary' | 'text';

export type ButtonVariant = { [key in ButtonVariants]: ButtonVariantObject };

export const buttonVariant: ButtonVariant = {
  primary: {
    border: `none`,
    darkModeBorder: 'none',
    backgroundColor: `${Theme.color.primary}`,
    darkModeBackgroundColor: `${Theme.color.white}`,
    textColor: `${Theme.color.white}`,
    darkModeTextColor: `${Theme.color.primary}`,
    disabledBackgroundColor: `${Theme.color.borderGray}`,
  },
  secondary: {
    border: `1px solid ${Theme.color.primary}`,
    darkModeBorder: `1px solid ${Theme.color.white}`,
    backgroundColor: 'transparent',
    darkModeBackgroundColor: `transparent`,
    textColor: `${Theme.color.primary}`,
    darkModeTextColor: `${Theme.color.white}`,
    disabledBackgroundColor: `transparent`,
  },
  text: {
    border: 'none',
    darkModeBorder: 'none',
    backgroundColor: 'transparent',
    darkModeBackgroundColor: `transparent`,
    textColor: `${Theme.color.primary}`,
    darkModeTextColor: `${Theme.color.white}`,
    disabledBackgroundColor: `transparent`,
  },
};

export const getBackgroundColor = (
  isDarkMode: boolean = false,
  variant: string = 'primary',
  disabled: boolean = false,
) => {
  let backgroundColor;
  if (disabled) {
    backgroundColor = buttonVariant[variant as ButtonVariants].disabledBackgroundColor;
  } else {
    backgroundColor = isDarkMode
      ? buttonVariant[variant as ButtonVariants].darkModeBackgroundColor
      : buttonVariant[variant as ButtonVariants].backgroundColor;
  }
  return backgroundColor;
};

export const getButtonTextColor = (
  variant: string = 'primary',
  disabled: boolean = false,
  isDarkMode: boolean = false,
) => {
  let textColor;
  if (disabled) {
    textColor = Theme.color.textGray;
  } else {
    textColor = isDarkMode
      ? buttonVariant[variant as ButtonVariants].darkModeTextColor
      : buttonVariant[variant as ButtonVariants].textColor;
  }
  return textColor;
};

export const StyledButton = styled.button<Partial<ButtonProps>>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant, disabled, isDarkMode }) => getBackgroundColor(isDarkMode, variant, disabled)};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  height: ${({ theme: { spacing } }) => spacing(12)};
  border: ${({ variant, isDarkMode }) =>
    isDarkMode
      ? buttonVariant[variant as ButtonVariants].darkModeBorder
      : buttonVariant[variant as ButtonVariants].border};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: ${({ variant, theme: { spacing } }) => (variant === 'text' ? 0 : getSpacing([0, 6], spacing))};

  :active {
    transform: ${({ disabled }) => (disabled ? 'none' : 'scale(0.95)')};
  }
  :hover {
    opacity: 0.8;
  }
  text-transform: capitalize;
  min-width: ${({ minWidth, theme }) => (minWidth ? getSpacing(minWidth, theme.spacing) : theme.spacing(20))};

  > div {
    max-width: ${({ theme: { spacing }, fullWidth }) => (fullWidth ? '95%' : spacing(50))};
    > div {
      color: ${({ disabled, isDarkMode, variant }) => getButtonTextColor(variant, disabled, isDarkMode)};
      :hover {
        color: ${({ disabled, isDarkMode, variant }) => getButtonTextColor(variant, disabled, isDarkMode)};
      }
    }
  }
`;
