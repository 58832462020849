export const filterTranslations = {
  allFiltersText: {
    key: 'filterTranslations.allFiltersText',
    defaultValue: 'All Filters',
  },
  drawerClearButtonText: {
    key: 'filterTranslations.drawerClearButtonText',
    defaultValue: 'Clear All Filters',
  },
  drawerCloseButtonText: {
    key: 'filterTranslations.drawerCloseButtonText',
    defaultValue: 'Done',
  },
  clearAllButtonText: {
    key: 'filterTranslations.clearAllButtonText',
    defaultValue: 'Clear All',
  },
};
