import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '../../stories/atoms';
import { muiTheme } from '../../theme';
import { productCategoryTranslations } from '../../translations/productCategory';
import { formatAmountWithSeparator } from '../../utils';
import { columnWidths } from './ProductCategory.styles';
import { getSob } from './ProductCategoryPage';
import { TrendContent } from './TableItem/TrendContent';
import { getPercentDiff } from './util';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  height: ${({ theme: { spacing } }) => spacing(12)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top-left-radius: ${({ theme: { spacing } }) => spacing(2)};
  border-top-right-radius: ${({ theme: { spacing } }) => spacing(2)};
  padding: ${({ theme: { spacing } }) => spacing(0, 5)};
`;

const GroupHeaderCategoryItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ theme: { spacing } }) => spacing(8)};
  min-width: ${columnWidths.minPxWidth}px;
`;

const StyledSobHeaderItem = styled(HeaderItem)`
  justify-content: flex-start;
  padding-right: ${({ theme: { spacing } }) => spacing(0)};
  min-width: ${columnWidths.minPxWidth}px;
`;

const MobileInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const MobileHeaderItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2, 0)};

  & > :first-child {
    margin-bottom: 8px;
  }
`;

type Props = {
  totalSalesNet: number;
  totalSalesNetFiltered: number;
  salesNetLastWeek: number;
  salesNetLastYear: number;
};
export const TableFooter = (props: Props) => {
  const { t } = useTranslation();

  const { totalSalesNet, totalSalesNetFiltered, salesNetLastWeek, salesNetLastYear } = props;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const lastWeekDiff = totalSalesNetFiltered - salesNetLastWeek;
  const lastWeekPercentDiff = getPercentDiff(totalSalesNetFiltered, salesNetLastWeek);

  const lastYearDiff = totalSalesNetFiltered - salesNetLastYear;
  const lastYearPercentDiff = getPercentDiff(totalSalesNetFiltered, salesNetLastYear);

  const sob = getSob(totalSalesNetFiltered, totalSalesNet);

  return (
    <Container>
      {!isMobile && (
        <InnerContainer>
          <GroupHeaderCategoryItem style={{ flex: 1 }}>
            <Typography type="h4">
              {t(
                productCategoryTranslations.tableFooter.total.key,
                productCategoryTranslations.tableFooter.total.defaultValue,
              )}
            </Typography>
          </GroupHeaderCategoryItem>

          <HeaderItem style={{ width: `${columnWidths.salesNet}vw` }}>
            <Typography type="button">{formatAmountWithSeparator(totalSalesNetFiltered)}</Typography>
          </HeaderItem>

          <HeaderItem style={{ width: `${columnWidths.lastWeek}vw` }}>
            <TrendContent
              textOptions={{ type: 'button' }}
              value={salesNetLastWeek}
              diff={lastWeekDiff}
              percentageDiff={lastWeekPercentDiff}
            />
          </HeaderItem>

          <HeaderItem style={{ width: `${columnWidths.lastYear}vw` }}>
            <TrendContent
              textOptions={{ type: 'button' }}
              value={salesNetLastYear}
              diff={lastYearDiff}
              percentageDiff={lastYearPercentDiff}
            />
          </HeaderItem>

          <StyledSobHeaderItem style={{ width: `${columnWidths.sob}vw` }}>
            <Typography type="button">{sob.toFixed(2)}%</Typography>
          </StyledSobHeaderItem>
        </InnerContainer>
      )}

      {isMobile && (
        <MobileInnerContainer>
          <MobileHeaderItem>
            <Typography>{formatAmountWithSeparator(totalSalesNetFiltered)}</Typography>
            <Typography>
              {t(
                productCategoryTranslations.tableFooter.total.key,
                productCategoryTranslations.tableFooter.total.defaultValue,
              )}
            </Typography>
          </MobileHeaderItem>

          {/* <MobileHeaderItem>
            <TrendContent value={salesNetLastWeek} diff={lastWeekDiff} percentageDiff={lastWeekPercentDiff} />
            <Typography>
              {t(
                productCategoryTranslations.tableFooter.lastWeek.key,
                productCategoryTranslations.tableFooter.lastWeek.defaultValue,
              )}
            </Typography>
          </MobileHeaderItem>

          <MobileHeaderItem>
            <TrendContent value={salesNetLastYear} diff={lastYearDiff} percentageDiff={lastYearPercentDiff} />
            <Typography>
              {t(
                productCategoryTranslations.tableFooter.lastYear.key,
                productCategoryTranslations.tableFooter.lastYear.defaultValue,
              )}
            </Typography>
          </MobileHeaderItem> */}

          <MobileHeaderItem>
            <Typography>{sob.toFixed(2)}%</Typography>
            <Typography>
              {t(
                productCategoryTranslations.tableFooter.sob.key,
                productCategoryTranslations.tableFooter.sob.defaultValue,
              )}
            </Typography>
          </MobileHeaderItem>
        </MobileInnerContainer>
      )}
    </Container>
  );
};
