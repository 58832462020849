import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BreakdownCostDetails, BreakdownCostType, SummaryCostDetails } from '../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { profitAndLossTransalations } from '../../../translations';
import { StyledDetailsButton, TrendContainer } from '../ProfitAndLoss.styles';
import { generateCardTitle, getColor, profitLossPropertyToValueMap } from '../util';
import { ValueContainer } from './ValueContainer';

type PLCardProps = {
  costDetails: SummaryCostDetails | BreakdownCostDetails;
  compareCostDetails: SummaryCostDetails;
  onViewDetailsClick: (details: BreakdownCostDetails) => void;
  isParent: boolean;
  costName: string;
};

export const PLCardContent: FC<PLCardProps> = ({
  costDetails,
  compareCostDetails,
  onViewDetailsClick,
  isParent,
  costName,
}) => {
  const { t } = useTranslation();
  const { actual } = costDetails;
  const { reportProperties, viewDetailsButton, costNames } = profitAndLossTransalations;

  const typographyType = isParent ? 'button' : 'body3';

  const cardTitle = generateCardTitle(
    '',
    isParent
      ? t(costNames[costName as BreakdownCostType].key, costNames[costName as BreakdownCostType].defaultValue)
      : costName,
    isParent,
  );

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const profitLossValues = profitLossPropertyToValueMap(costName, costDetails, compareCostDetails);
  const indexVsBudget = profitLossValues.filter((item) => item.key === 'indexVsBudget')[0].value;

  const textColor = getColor('indexVsBudget', indexVsBudget.toString());

  return (
    <>
      {!isMobile && (
        <Container data-testid="pl-card-container" width="100%">
          <Typography margin={isParent ? [0, 0, 3] : [0, 0, 2]} type={typographyType}>
            {cardTitle}
          </Typography>
          <Container direction="horizontal" space="between" padding={[0, 1]}>
            {profitLossValues &&
              profitLossValues.map((item) => (
                <ValueContainer key={item.key} label={item.key} value={item.value?.toLocaleString()} />
              ))}
          </Container>
        </Container>
      )}
      {isMobile && (
        <Container data-testid="pl-card-container-mobile" width="100%">
          <Container direction="horizontal" space="between" wrap="nowrap">
            <Typography type={typographyType}>{cardTitle}</Typography>
            <StyledDetailsButton
              textOptions={{ type: 'caption' }}
              label={t(viewDetailsButton.key, viewDetailsButton.defaultValue)}
              onClick={() =>
                onViewDetailsClick({
                  ...costDetails,
                  name: costName,
                })
              }
            />
          </Container>
          <Container padding={[1, 0, 0]}>
            <Typography padding={[0, 0, 1]} type="caption">
              {t(reportProperties.actuals.key, reportProperties.actuals.defaultValue)}: {actual.toLocaleString()}
            </Typography>
            {isParent && (
              <TrendContainer shouldShowColor={true} isAboveThreshold={textColor} margin={[1, 0, 0]}>
                <Icon
                  margin={[0, 1, 0, 0]}
                  size="small"
                  icon={textColor === 'validationGreen' ? Icons.TRENDUP : Icons.TRENDDOWN}
                  color={textColor}
                />
                <Typography color={textColor}>
                  {t(reportProperties.indexVsBudget.key, reportProperties.indexVsBudget.defaultValue)}:{' '}
                  {indexVsBudget}
                </Typography>
              </TrendContainer>
            )}
          </Container>
        </Container>
      )}
    </>
  );
};
