import { ToggleButton as MuiToggleButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { AreaComparisonItem, SalesEfficiencyMetrics, useAsyncAction, useStores } from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { Badge } from '../../../../../stories/atoms/badge/Badge';
import { DynamicTab, IconPositionType } from '../../../../../stories/molecules';
import { ToggleButton } from '../../../../../stories/molecules/toggleButton/ToggleButton';
import { color } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { Spacing, Translation, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { StyledGrid } from './AreaComparison.styles';
import AreaComparisonCard from './areaComparisonCard/AreaComparisonCard';

type SalesEfficiencyEquationGroup = 'sales' | 'salesEquation' | 'efficiency';

type SalesEfficiencyMetricsValue = {
  translation: Translation;
  equationGroup: SalesEfficiencyEquationGroup;
};

const {
  common,
  areaComparison: areaComparisonTranslations,
  conversionRate,
  receiptSales,
  piecesPerReceipt,
  averagePurchase,
  effectiveHours,
  hourlyTurnover,
} = homeTranslations;

export const MetricsTranslationMapping: Record<SalesEfficiencyMetrics, SalesEfficiencyMetricsValue> = {
  averageNetSale: {
    translation: hourlyTurnover.title,
    equationGroup: 'sales',
  },
  averageVisitors: {
    translation: conversionRate.visitors,
    equationGroup: 'salesEquation',
  },
  averageConversionRate: {
    translation: conversionRate.title,
    equationGroup: 'salesEquation',
  },
  averageTakt1: {
    translation: receiptSales.takt1,
    equationGroup: 'efficiency',
  },
  averagePiecesPerReceipt: {
    translation: piecesPerReceipt.title,
    equationGroup: 'salesEquation',
  },
  averageSPT: {
    translation: receiptSales.spt,
    equationGroup: 'efficiency',
  },
  averagePurchasePerReceipt: {
    translation: averagePurchase.title,
    equationGroup: 'salesEquation',
  },
  averageEffectiveHours: {
    translation: effectiveHours.title,
    equationGroup: 'efficiency',
  },
};

export type AreaComparisonProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  fromDate?: Date;
  toDate?: Date;
};

const AreaComparisonWidget: FC<AreaComparisonProps> = observer(({ interval, currentDate, fromDate, toDate }) => {
  const { t } = useTranslation();

  const { fetchAreaComparisonData } = useStores();
  const [tabIndex, setTabIndex] = useState(0);
  const [view, setView] = useState('view1');

  const { mode } = useThemeContext();

  const {
    result: areaComparisonDetails,
    isLoading: areaComparisonLoading,
    isError: areaComparisonError,
    triggerAction: triggerFetchAreaComparisonDetails,
  } = useAsyncAction<AreaComparisonItem[]>();

  const fetchAreaComparison = async () => {
    const request = createStorePerformanceRequest({
      interval,
      ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
    });
    const response = await fetchAreaComparisonData(request);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const filterByEquationGroup = (group: SalesEfficiencyEquationGroup) => {
    return areaComparisonDetails?.filter(
      (areaComparisonDetail) => MetricsTranslationMapping[areaComparisonDetail.name]?.equationGroup === group,
    );
  };

  const salesGroup = filterByEquationGroup('sales');
  const salesEquationGroup = filterByEquationGroup('salesEquation');
  const efficiencyGroup = filterByEquationGroup('efficiency');

  const createTabConfig = (
    tabName: Translation,
    groupLength: number | undefined,
    iconPosition: IconPositionType = 'start',
    labelMargin: Spacing = [0, 0, 0, 2],
  ) => ({
    tabName: t(tabName.key, tabName.defaultValue),
    isTabVisible: true,
    icon: (
      <Badge backgroundColor={mode === 'dark' ? 'backgroundSelectedHover' : 'hoverGray'}>
        <Typography type="body3">{groupLength}</Typography>
      </Badge>
    ),
    iconPosition,
    labelMargin,
    isTabDisabled: groupLength === 0,
  });

  const createTabContent = (group: AreaComparisonItem[]) => (
    <StyledGrid data-testid="area-comparison-cards">
      {group?.map((areaComparisonDetail) => (
        <AreaComparisonCard
          key={areaComparisonDetail.name}
          areaComparisonDetail={areaComparisonDetail}
          view={view}
        />
      ))}
    </StyledGrid>
  );

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
    if (view2 !== null) {
      setView(view2);
    }
  };

  const fetchWidgetData = () => {
    triggerFetchAreaComparisonDetails(fetchAreaComparison);
  };

  useEffect(() => {
    fetchWidgetData();
  }, [currentDate, interval]);

  return (
    <WidgetCardContent
      headerIcon={Icons.MAP}
      headerText={t(areaComparisonTranslations.title.key, areaComparisonTranslations.title.defaultValue)}
      subHeaderText={t(areaComparisonTranslations.subTitle.key, areaComparisonTranslations.subTitle.defaultValue)}
      toolTipText={t(areaComparisonTranslations.toolTip.key, areaComparisonTranslations.toolTip.defaultValue)}
      isLoading={areaComparisonLoading}
      isError={areaComparisonError}
      errorText={t(areaComparisonTranslations.error.key, areaComparisonTranslations.error.defaultValue)}
      errorRetryHandler={fetchWidgetData}
      headerSideContent={
        <>
          {areaComparisonDetails && !!areaComparisonDetails.length && (
            <ToggleButton
              value={view}
              onChange={handleChangeView}
              leftButton={'123'}
              rightButton={
                <MuiToggleButton value="view2" aria-label="left button">
                  <Icon data-testid="left-icon" icon={Icons.CONVERSION} size="small" />
                </MuiToggleButton>
              }
            />
          )}
        </>
      }
      content={
        <>
          <Container
            data-testid="area-comparison-wrapper"
            wrap="nowrap"
            style={{ borderTop: `1px solid ${color.borderGray}` }}
          >
            <DynamicTab
              tabLabelArray={[
                createTabConfig(common.allTabName, areaComparisonDetails?.length),
                createTabConfig(areaComparisonTranslations.equationGroup.sales, salesGroup?.length),
                createTabConfig(
                  areaComparisonTranslations.equationGroup.salesEquation,
                  salesEquationGroup?.length,
                ),
                createTabConfig(areaComparisonTranslations.equationGroup.efficiency, efficiencyGroup?.length),
              ]}
              handleTabChange={handleTabChange}
              tabValue={tabIndex}
              variant="scrollable"
              tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundSelectedDark'}
              content={[
                {
                  tabContent: createTabContent(areaComparisonDetails ?? []),
                  visible: true,
                },
                {
                  tabContent: createTabContent(salesGroup ?? []),
                  visible: true,
                },
                {
                  tabContent: createTabContent(salesEquationGroup ?? []),
                  visible: true,
                },
                {
                  tabContent: createTabContent(efficiencyGroup ?? []),
                  visible: true,
                },
              ]}
            />
          </Container>
        </>
      }
    />
  );
});

export default AreaComparisonWidget;
