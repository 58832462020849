import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Animation, Animations } from '../../stories/molecules';
import { commonTranslations, errors } from '../../translations';
import { Page } from '../../stories/organisms';

export const NotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page
      body={
        <Animation
          title={t(errors.pageNotFound.title.key, errors.pageNotFound.title.defaultValue)}
          subtitle={t(errors.pageNotFound.subtitle.key, errors.pageNotFound.subtitle.defaultValue)}
          animation={Animations.CONFUSED}
          button={{
            label: t(commonTranslations.home.key, commonTranslations.home.defaultValue),
            buttonContentProps: {},
            onClick: () => navigate('/'),
          }}
        />
      }
    />
  );
};
