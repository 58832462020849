import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PLReportProperties } from '../../../global-state/types';
import { Icon, Icons, Typography } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { profitAndLossTransalations } from '../../../translations';
import { PLStyledValueContainer, TrendContainer } from '../ProfitAndLoss.styles';
import { getColor } from '../util';

type ValueContainerProps = {
  label: string;
  value: string;
};

export const ValueContainer: FC<ValueContainerProps> = ({ label, value }) => {
  const { t } = useTranslation();
  const { reportProperties } = profitAndLossTransalations;
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const shouldShowColor = label === 'indexVsLY' || label === 'indexVsBudget';
  const textColor = getColor(label, value);

  return (
    <PLStyledValueContainer data-testid="pl-value-container" isMobile={isMobile}>
      <TrendContainer shouldShowColor={shouldShowColor} isAboveThreshold={textColor}>
        <Typography type={isMobile ? 'button' : 'body2'} color={textColor}>
          {value}
        </Typography>
        {shouldShowColor && (
          <Icon
            margin={[0, 0, 0, 1]}
            size="small"
            icon={textColor === 'validationGreen' ? Icons.TRENDUP : Icons.TRENDDOWN}
            color={textColor}
          />
        )}
      </TrendContainer>
      <Typography color="textGray">
        {t(
          reportProperties[label as PLReportProperties].key,
          reportProperties[label as PLReportProperties].defaultValue,
        )}
      </Typography>
    </PLStyledValueContainer>
  );
};
