import styled from 'styled-components';

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;

export const SelectedFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: ${({ theme }) => theme.spacing(5)};
    &:first-child {
      margin-right: ${({ theme }) => theme.spacing(4)};
    }
    &:last-child {
      margin-right: ${({ theme }) => theme.spacing(0)};
    }
  }
`;

export const StyledWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 8)};
  > div {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }
`;
