// Dont include this file in Unit test coverage since its only for test environment
/* istanbul ignore file */
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, debounce } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from '../../hooks';
import { Container, Typography } from '../../stories/atoms';
import { Animation, Animations, Modal } from '../../stories/molecules';
import { commonTranslations } from '../../translations/common';
import { selectRoleTranslation } from '../../translations/selectRole';
import { StoreRoles, convertCamelCaseToTitleCase, isFailureResponse } from '../../utils';
import {
  StyledCloseButton,
  StyledContainer,
  StyledSwitchButton,
} from '../select-store/switch-store-modal/SwitchStoreModal.styles';

export type SelectRoleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRoleChangeSuccess?: () => void;
  showCloseButton?: boolean;
};

export const SelectRoleModal: FC<SelectRoleModalProps> = observer(
  ({ isOpen, onClose, onRoleChangeSuccess, showCloseButton = true }) => {
    const [showAnimation, setShowAnimation] = useState<'none' | 'loading' | 'error'>('none');
    const [selectedRole, setSelectedRole] = useState('');

    const { createProfile } = useProfile();
    const { t } = useTranslation();

    const { swicthingRoles, header, subHeader, button, label, error } = selectRoleTranslation;

    const handleChange = (event: SelectChangeEvent) => {
      setSelectedRole(event.target.value as string);
    };

    const sendRole = async () => {
      setShowAnimation('loading');

      const response = await createProfile(selectedRole);

      if (isFailureResponse(response)) {
        setShowAnimation('error');
      } else {
        if (onRoleChangeSuccess) {
          onRoleChangeSuccess(); // Triggering the callback on success
        }
        onClose();
      }
    };

    const handleRoleChange = useCallback(debounce(sendRole, 500), [selectedRole]);

    return (
      <>
        <Modal
          isOpen={isOpen}
          isFullScreen={showAnimation === 'loading'}
          transitionType="slide"
          ariaDescribedby="select-role-modal"
          handleClose={onClose}
          hasCloseIcon={showAnimation === 'error' || showCloseButton}
        >
          {showAnimation === 'loading' ? (
            <Animation title={t(swicthingRoles.key, swicthingRoles.defaultValue)} />
          ) : showAnimation === 'error' ? (
            <StyledContainer margin={[5]}>
              <Animation
                size="small"
                title=""
                errorText={t(error.key, error.defaultValue)}
                animation={Animations.SAD}
              />
            </StyledContainer>
          ) : (
            <StyledContainer>
              <Typography type="h2" textAlign="center">
                {t(header.key, header.defaultValue)}
              </Typography>
              <Typography margin={[6, 0]}>{t(subHeader.key, subHeader.defaultValue)} </Typography>
              <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="select-label">{t(label.key, label.defaultValue)}</InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select"
                  value={selectedRole}
                  label={t(label.key, label.defaultValue)}
                  onChange={handleChange}
                >
                  {Object.values(StoreRoles).map((role) => (
                    <MenuItem key={role} value={role}>
                      <Typography>{convertCamelCaseToTitleCase(role)}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Container direction="horizontal" padding={[8, 0, 5, 0]} position="center">
                {showCloseButton && (
                  <StyledCloseButton
                    onClick={onClose}
                    label={t(commonTranslations.close.key, commonTranslations.close.defaultValue)}
                    variant="secondary"
                    data-testid="close-button"
                  />
                )}

                <StyledSwitchButton
                  data-testid="select-role-button"
                  onClick={handleRoleChange}
                  label={t(button.key, button.defaultValue)}
                  buttonContentProps={{
                    textOptions: { color: 'white' },
                  }}
                />
              </Container>
            </StyledContainer>
          )}
        </Modal>
      </>
    );
  },
);
