/* istanbul ignore file */
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { LicenseInfo } from '@mui/x-license';
import React, { FC, StrictMode, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import { App } from './App';
import { AppNonSignin } from './AppNonSignin';
import { msalConfig, msalScopes } from './constants/msalConfig';
import { GlobalProvider } from './global-state/context';
import { EventProvider } from './global-state/eventContext';
import { ThemeProvider } from './global-state/themeContext';
import { TranslationProvider } from './global-state/translationContext';
import { GlobalStyle } from './globalStyles';
import { Icons } from './stories/atoms';
import { Animation, Animations } from './stories/molecules';
import { commonTranslations, errors } from './translations';
import { ErrorType, logError, logInfo } from './utils/splunkLogger';

const msalInstance = new PublicClientApplication(msalConfig);

const licensekey: string = process.env.REACT_APP_MUIX_LICENSE_KEY ?? '';
LicenseInfo.setLicenseKey(licensekey);

export const AppWrapper: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();

  const Loading = () => (
    <AnimationWrapper>
      <Animation
        title={t(commonTranslations.loading.key, commonTranslations.loading.defaultValue)}
        animation={Animations.LOADING}
      />
    </AnimationWrapper>
  );

  const Error = () => {
    return (
      <AnimationWrapper>
        <Animation
          title={t(errors.applicationUnavailable.key, errors.applicationUnavailable.defaultValue)}
          subtitle={t(errors.tryAgain.key, errors.tryAgain.defaultValue)}
          animation={Animations.SAD}
          button={{
            label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
            onClick: () => window.location.reload(),
            buttonContentProps: {
              iconOptions: {
                icon: Icons.REFRESH,
                color: 'white',
              },
            },
          }}
        />
      </AnimationWrapper>
    );
  };

  const FallbackError = () => {
    return (
      <FallBackErrorWrapper>
        <h2>{t(errors.applicationUnavailable.key, errors.applicationUnavailable.defaultValue)}</h2>
        <p>{t(errors.tryAgain.key, errors.tryAgain.defaultValue)}</p>
      </FallBackErrorWrapper>
    );
  };

  const storeIdParam = new URLSearchParams(window.location.search).get('storeId');
  const key = new URLSearchParams(window.location.search).get('key');
  const showNonSignInView = storeIdParam && key && window.location.pathname.includes('/home');

  // Log non-signin view load
  useEffect(() => {
    if (showNonSignInView) {
      logInfo('AppNonSignin view loaded', { storeId: storeIdParam, key });
    }
  }, [showNonSignInView, storeIdParam, key]);

  return (
    <StrictMode>
      <ErrorBoundary
        FallbackComponent={FallbackError}
        onError={(error, componentStack) =>
          logError({ error, errorType: ErrorType.GENERAL_ERROR_BOUNDARY, info: componentStack })
        }
      >
        <GlobalProvider>
          <ThemeProvider>
            <EventProvider>
              <TranslationProvider>
                {showNonSignInView ? (
                  <Suspense fallback={<Loading />}>
                    <GlobalStyle />
                    <Router>
                      <AppNonSignin />
                    </Router>
                  </Suspense>
                ) : (
                  <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate
                      interactionType={InteractionType.Redirect}
                      authenticationRequest={msalScopes}
                      errorComponent={() => <Error />}
                      loadingComponent={() => <Loading />}
                    >
                      <Suspense fallback={<Loading />}>
                        <GlobalStyle />
                        <Router>
                          <App />
                        </Router>
                      </Suspense>
                    </MsalAuthenticationTemplate>
                  </MsalProvider>
                )}
              </TranslationProvider>
            </EventProvider>
          </ThemeProvider>
        </GlobalProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

const AnimationWrapper = styled.div`
  height: 100vh;
`;

const FallBackErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
