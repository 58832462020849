import styled from 'styled-components';
import { cardShadow } from '../../../../theme';

export const StyledProductImage = styled.img`
  display: flex;
  margin: auto;
  width: 50%;
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  overflow: hidden;
`;

export const StyledAboutInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing(5, 3, 0, 3)};
  align-items: flex-start;

  & > * {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledDetailsCard = styled.div`
  margin: ${({ theme: { spacing } }) => spacing(5, 2)};
  padding: ${({ theme: { spacing } }) => spacing(3)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  ${cardShadow()};
`;

export const StyledDetailsProperty = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing(3)};

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledDrawerContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;
