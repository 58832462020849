import styled from 'styled-components';
import { Container } from '../../../../../stories/atoms';
import { cardShadow } from '../../../../../theme';

export const StyledMenuWrapper = styled(Container)`
  display: flex;
  align-items: center;
`;

export const StyledView = styled(StyledMenuWrapper)`
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
`;
export const StyledCircleContainer = styled.div`
  width: ${({ theme: { spacing } }) => spacing(37)};
  height: ${({ theme: { spacing } }) => spacing(37)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${cardShadow()};
  margin: ${({ theme: { spacing } }) => spacing(4)};
`;

export const StyledContainer = styled(Container)`
  align-items: center;
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.backgroundTableHeader};
  margin: ${({ theme: { spacing } }) => spacing(4)};
`;
