import styled from 'styled-components';
import { fontSizes, lineHeights } from '../../../theme';
import { getSpacing } from '../../../utils';
import type { TypographyProps } from './Typography';

export const StyledTypography = styled.div<TypographyProps>`
  color: ${({ theme: { color: themeColor, palette }, color }) =>
    palette.mode === 'dark' ? themeColor.white : color ? themeColor[color] : themeColor.primary};
  font-size: ${({ theme: { typography }, type }) => type && typography[type].fontSize};
  font-weight: ${({ theme: { typography }, type }) => type && typography[type].fontWeight};
  margin-bottom: ${({ gutterBottom, theme: { spacing } }) => gutterBottom && spacing(4)};
  padding: ${({ theme: { spacing }, padding }) => padding && getSpacing(padding, spacing)};
  margin: ${({ theme: { spacing }, margin }) => margin && getSpacing(margin, spacing)};
  ${({ theme: { color }, borderBottomColor }) =>
    borderBottomColor && `border-bottom: 1px solid ${color[borderBottomColor]};`}
  font-family: ${({ theme: { typography }, type }) => type && typography[type].fontFamily};
  background-color: ${({ theme: { color }, darkMode }) => darkMode && color.primary};
  :hover {
    color: ${({ theme: { color }, hoverColor }) => hoverColor && color[hoverColor]};
    ${({ theme: { color }, hoverBodderBottomColor }) =>
      hoverBodderBottomColor && `border-bottom: 1px solid ${color[hoverBodderBottomColor]};`}
  }
  white-space: ${({ whiteSpace }) => whiteSpace};
  width: ${({ wrapText }) => wrapText && 'fit-content'};
  text-overflow: ellipsis;
  overflow: ${({ overflow }) => overflow && overflow};
  font-style: ${({ italic }) => italic && 'italic'};
  line-height: ${({ theme: { typography }, type }) => type && typography[type].lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-decoration: ${({ decoration }) => decoration};

  // responsive fontSize
  ${({ theme: { breakpoints } }) => breakpoints.down('desktop')} {
    font-size: ${({ type }) => type && fontSizes[type].tabletPortrait};
    line-height: ${({ type }) => type && lineHeights[type].tabletPortrait};
  }
  ${({ theme: { breakpoints } }) => breakpoints.down('tabletPortrait')} {
    font-size: ${({ type }) => type && fontSizes[type].mobile};
  }
  text-align: ${({ textAlign }) => textAlign};
`;
