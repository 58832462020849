import { Property } from 'csstype';
import styled from 'styled-components';
import { AllSizes, Colors, Space } from '../../../theme';
import { Spacing, getSpacing } from '../../../utils';
import { Typography } from '../../atoms';
import type { PositionType } from './IconWithText';

type Position = { [key in PositionType]: string };

const labelPositionMapping: Position = {
  top: 'column-reverse',
  bottom: 'column',
  left: 'row-reverse',
  right: '',
};

const spaceBetweenMapping: Space = {
  small: 2,
  medium: 2,
  large: 3,
  xl: 6,
  xxl: 10,
};

export const StyledTypography = styled(Typography)<{
  labelPosition: PositionType;
  spaceBetween: AllSizes;
  hasIcon: boolean;
}>`
  display: flex;
  margin-left: ${({ labelPosition, spaceBetween, hasIcon, theme: { spacing } }) =>
    hasIcon && labelPosition === 'right' ? spacing(spaceBetweenMapping[spaceBetween]) : 0};
  margin-right: ${({ labelPosition, spaceBetween, hasIcon, theme: { spacing } }) =>
    hasIcon && labelPosition === 'left' ? spacing(spaceBetweenMapping[spaceBetween]) : 0};
  color: ${({ theme, color }) => color && theme.color[color]};
`;

export const Container = styled.div<{
  labelPosition: PositionType;
  padding?: Spacing;
  gutterBottom?: boolean;
  backgroundColor?: Colors;
  position?: Property.JustifyContent;
  hoverColor?: Colors;
}>`
  display: inline-flex;
  justify-content: ${({ position }) => position};
  align-items: center;
  flex-direction: ${({ labelPosition }) => labelPositionMapping[labelPosition]};
  padding: ${({ padding, theme }) => padding && getSpacing(padding, theme.spacing)};
  margin-bottom: ${({ gutterBottom, theme }) => gutterBottom && theme.spacing(2)};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  background-color: ${({ backgroundColor, theme }) => backgroundColor && theme.color[backgroundColor]};

  &:hover ${StyledTypography} {
    color: ${({ theme, hoverColor }) => hoverColor && theme.color[hoverColor]};
  }
`;
