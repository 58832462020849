import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StorePerformanceInterval,
  VendorMapping,
  VendorType,
  isSupportedVendorType,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { SortDirection } from '../../../../../global-state/types';
import { TenderItem, useAsyncAction, useStoreInformation, useTransaction } from '../../../../../hooks';
import { Container, Icons } from '../../../../../stories/atoms';
import { IconWithText, Row, Table } from '../../../../../stories/molecules';
import { homeTranslations, transactionSearchTranslations } from '../../../../../translations';
import { formatAmountWithCurrency, formatValue, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { StyledIcon, StyledTableWrapper, StyledTenderContainer } from './TenderOverviewWidget.styles';

type TenderOverviewWidgetProps = {
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

export const TenderOverviewWidget: FC<TenderOverviewWidgetProps> = observer(({ currentDate, interval }) => {
  const { t, i18n } = useTranslation();
  const { tenderOverview, common } = homeTranslations;

  const { currencyCode } = useStoreInformation();

  const { fetchTenderData } = useTransaction();
  const { transactionEventsCount } = useEventContext();

  const [rows, setRows] = useState<Row[]>([]);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

  const {
    result: tenderData,
    isLoading: tenderDataLoading,
    isError: tenderDataError,
    triggerAction: triggerFetchTenderData,
  } = useAsyncAction<TenderItem[]>();

  const fetchTendersData = async () => {
    const response = await fetchTenderData(format(currentDate, 'yyyy-MM-dd'));

    if (isFailureResponse(response)) {
      throw new Error();
    }

    setLastUpdated(response.data.lastUpdated);

    return response.data.tenders;
  };

  const sortTenderData = (property: keyof TenderItem, order: SortDirection) => {
    return (a: TenderItem, b: TenderItem) => {
      if (a[property] < b[property]) return order === 'asc' ? -1 : 1;
      if (a[property] > b[property]) return order === 'asc' ? 1 : -1;
      return 0;
    };
  };

  const getUpdatedRows = (data: TenderItem[]) => {
    return data.map(({ type, count, amount }, index) => ({
      id: index,
      tender: (
        <StyledTenderContainer direction="horizontal">
          <StyledIcon
            icon={
              isSupportedVendorType(type)
                ? (VendorMapping[type] as VendorType)
                : type === '' // When empty subTender type it equals to cash
                ? Icons.TRANSACTION_SEARCH
                : Icons.TENDER
            }
            size="large"
          />
          <IconWithText
            label={
              isSupportedVendorType(type)
                ? VendorMapping[type]
                : type === ''
                ? VendorMapping.CA // When empty subTender type it equals to cash
                : type
            }
            textOptions={{ padding: [0, 0, 0, 4] }}
          />
        </StyledTenderContainer>
      ),
      count: formatValue(count, { options: { minimumFractionDigits: 0 } }),
      amount: formatAmountWithCurrency(amount, i18n.language, currencyCode),
    }));
  };

  const fetchWidgetData = () => {
    if (interval === StorePerformanceInterval.WEEKLY) return;
    triggerFetchTenderData(fetchTendersData);
  };

  useEffect(() => {
    if (!tenderData || !tenderData.length) return;
    const sortedData = tenderData.sort(sortTenderData('amount', SortDirection.Descending));
    const updatedRows = getUpdatedRows(sortedData);
    setRows(updatedRows);
  }, [tenderData]);

  useEffect(() => {
    fetchWidgetData();
  }, [currentDate, interval, transactionEventsCount]);

  const sortData = (columnId: string, direction: SortDirection) => {
    if (!tenderData) return rows;
    const sortedData = tenderData.sort(sortTenderData(columnId as keyof TenderItem, direction));
    const updatedRows = getUpdatedRows(sortedData);
    return updatedRows;
  };

  return (
    <WidgetCardContent
      headerIcon={Icons.TRANSACTIONS}
      headerText={t(tenderOverview.title.key, tenderOverview.title.defaultValue)}
      subHeaderText={t(tenderOverview.subTitle.key, tenderOverview.subTitle.defaultValue)}
      toolTipText={t(tenderOverview.toolTip.key, tenderOverview.toolTip.defaultValue)}
      lastUpdatedDate={interval === StorePerformanceInterval.WEEKLY ? null : lastUpdated}
      isLoading={tenderDataLoading}
      isError={tenderDataError}
      errorText={t(tenderOverview.error.key, tenderOverview.error.defaultValue)}
      errorRetryHandler={fetchWidgetData}
      isNoData={tenderData !== null && tenderData.length === 0}
      isWeeklyViewDisabled={interval === StorePerformanceInterval.WEEKLY}
      content={
        <>
          <Container wrap="nowrap" data-testid="tender-overview-wrapper" height="100%">
            <StyledTableWrapper data-testid="tender-data-table">
              <Table
                stickyHeader
                noBorder
                fixedHeight="100%"
                rows={rows}
                columns={[
                  {
                    id: 'tender',
                    name: t(tenderOverview.tender.key, tenderOverview.tender.defaultValue),
                    width: '40%',
                  },
                  {
                    id: 'count',
                    name: t(common.count.key, common.count.defaultValue),
                    width: '40%',
                    isSortable: true,
                  },
                  {
                    id: 'amount',
                    name: t(
                      transactionSearchTranslations.amount.key,
                      transactionSearchTranslations.amount.defaultValue,
                    ),
                    width: '20%',
                    isSortable: true,
                  },
                ]}
                skeletonLoaderOptions={{ numberOfRows: 4 }}
                handleSorting={(columnId, direction) => sortData(columnId, direction)}
              ></Table>
            </StyledTableWrapper>
          </Container>
        </>
      }
    />
  );
});
