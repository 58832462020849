import { Colors } from '../theme';

export const getStarColor = (rating: number) => {
  const starColors: Record<number, Colors> = {
    5: 'success',
    4: 'success',
    3: 'info',
    2: 'warning',
    1: 'error',
  };

  return starColors[rating];
};

export const ratingFilters = [
  { id: 1, label: '1 star', color: 'error' },
  { id: 2, label: '2 star', color: 'warning' },
  { id: 3, label: '3 star', color: 'info' },
  { id: 4, label: '4 star', color: 'success' },
  { id: 5, label: '5 star', color: 'success' },
];
