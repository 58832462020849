import styled from 'styled-components';
import { Button } from '../../../../../stories/molecules';

export const StyledContent = styled.div`
  display: flex;
  grid-area: content;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(7, 3, 3)};
`;
export const StyledInfo = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 20, 0)};

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    padding: ${({ theme }) => theme.spacing(2, 0)};
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledRolesList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing(0, 20)};
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  width: 50%;

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    width: 100%;
  }
`;

export const StyledOverView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAppList = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(0, 30)};
`;

export const StyledRoles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledRoleDiv = styled.div`
  width: 30%;
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;

export const StyledTemporaryPasscode = styled.div`
  padding: ${({ theme }) => theme.spacing(4, 30)};
`;
export const StyledRememberAndApproveButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const StyledDisabledDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme: { color } }) => color.borderGray};
  text-align: center;
  width: 50%;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: not-allowed;
  > div {
    color: ${({ theme: { color } }) => color.secondaryTextGray};
  }
`;
