import styled from 'styled-components';

export const Container = styled.div<{ isFullScreen?: boolean }>`
  display: grid;
  grid-template-areas: ${({ isFullScreen }) =>
    isFullScreen
      ? `'sideMenu header'
    'sideMenu page'`
      : `'header header'
    'page page'`};
  grid-template-rows: ${({ theme: { sizing } }) => `${sizing.header} ${sizing.page}`};
  grid-template-columns: ${({ theme: { sizing }, isFullScreen }) =>
      !isFullScreen
        ? '100%'
        : `${sizing.sideMenu}`} auto; // After setting the breakpoints, we need to update this accordingly
`;

export const AnimationWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.backgroundDark};
`;

export const ActionAreaWrapper = styled.div`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(4)};
  bottom: ${({ theme: { spacing } }) => spacing(4)};
  z-index: 100;
`;

export const SpeedDialIconsWrapper = styled(ActionAreaWrapper)`
  z-index: 10000;
`;
