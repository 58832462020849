import styled from 'styled-components';
import { Typography } from '../../../../stories/atoms';
import { IconWithText } from '../../../../stories/molecules';
import { Direction } from '../../../../theme';

export const StyledWrapper = styled.div<{ direction: Direction }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.white};
`;

export const StyledTrendIconWithText = styled(IconWithText)`
  & > :nth-child(1) {
    color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.white};
  }
  & > :nth-child(2) {
    color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.white};
  }
`;
