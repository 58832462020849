import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { transactionSearchTranslations } from '../../../translations';
import { Icons, Icon, Typography } from '../../../stories/atoms';
import { RotateDirection } from '../../../stories/atoms/icon/Icon.styles';
import { Colors, Typographies } from '../../../theme';
import { StyledMenuItem, StyledCountIconWrapper } from './AllFiltersMenu.styles';

type FilterItemRowProps = {
  label: string;
  rotate?: RotateDirection;
  icon?: Icons;
  type?: Typographies;
  color?: Colors;
  isSelectedCountShown?: boolean;
  count?: string;
  handleClick?: () => void;
};

export const FilterItemRow: FC<FilterItemRowProps> = ({
  label,
  isSelectedCountShown,
  rotate,
  icon = Icons.ARROW,
  type = 'body2',
  color,
  count,
  handleClick,
}) => {
  const { t } = useTranslation();

  const { selected } = transactionSearchTranslations;
  return (
    <StyledMenuItem onClick={handleClick}>
      <Typography type={type} color={color}>
        {label}
      </Typography>
      <StyledCountIconWrapper>
        {isSelectedCountShown && (
          <Typography data-testid="selected-filters-count" padding={[0, 3, 0, 0]}>
            {count} {t(selected.key, selected.defaultValue)}
          </Typography>
        )}
        <Icon data-testid="arrow" icon={icon} rotate={rotate} />
      </StyledCountIconWrapper>
    </StyledMenuItem>
  );
};
