import { FC } from 'react';

import { Icon, Icons, Typography } from '../../../stories/atoms';
import {
  BodyContainer,
  Container,
  MessageContainer,
  PromptsContainer,
  PromptItem,
  StyledUserAvatar,
} from './MessageItem.styles';

type MessageItemProps = {
  isSender: boolean;
  text: string;
  prompts?: string[];
  avatar?: string;
  onPromptSelect?: (text: string) => void;
};

export const MessageItem: FC<MessageItemProps> = ({ isSender, text, prompts, avatar, onPromptSelect }) => {
  return (
    <Container isSender={isSender}>
      {!isSender && <Icon icon={Icons.CHATBOT} size="small" />}

      <BodyContainer isSender={isSender}>
        <MessageContainer isSender={isSender}>
          <Typography color={isSender ? 'white' : 'primary'}>{text}</Typography>
        </MessageContainer>

        {prompts && prompts.length > 0 && (
          <PromptsContainer>
            {prompts.map((prompt) => {
              return (
                <PromptItem onClick={() => onPromptSelect && onPromptSelect(prompt)}>
                  <Typography>{prompt}</Typography>
                </PromptItem>
              );
            })}
          </PromptsContainer>
        )}
      </BodyContainer>

      {isSender &&
        (avatar ? (
          <StyledUserAvatar src={avatar} alt="user-avatar" />
        ) : (
          <Icon icon={Icons.PROFILE} size="small" />
        ))}
    </Container>
  );
};
