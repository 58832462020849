import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Icon, Icons, Tooltip, Typography } from '../../../../stories/atoms';
import { homeTranslations } from '../../../../translations';

import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import {
  CommonStateContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  HeaderSideContentContainer,
  HeaderTextContainer,
  LastUpdatedDateContainer,
  StyledIcon,
} from './WidgetCardContent.styles';

export type WidgetCardContentProps = {
  headerIcon: Icons;
  headerText: string;
  subHeaderText: string;
  content: JSX.Element;
  lastUpdatedDate?: Date | null;

  isLoading?: boolean;

  isError?: boolean;
  errorText?: string;
  errorRetryHandler?: () => void;

  isNoData?: boolean;
  isWeeklyViewDisabled?: boolean;
  noDataText?: string;
  noDataContent?: JSX.Element;

  headerSideContent?: JSX.Element;
  toolTipText?: string;
};

export const WidgetCardContent = ({
  headerIcon,
  headerText,
  subHeaderText,
  content,
  lastUpdatedDate,

  isLoading = false,
  isError = false,
  errorText,
  errorRetryHandler,

  isNoData = false,
  isWeeklyViewDisabled = false,

  noDataText,
  noDataContent,

  headerSideContent,
  toolTipText,
}: WidgetCardContentProps) => {
  const { t } = useTranslation();
  const formattedDate = lastUpdatedDate ? format(new Date(lastUpdatedDate), "yyyy-MM-dd 'at' HH:mm") : null;

  return (
    <Container>
      <HeaderContainer>
        <Tooltip position="bottom-start" text={<Typography color="white">{toolTipText}</Typography>}>
          <StyledIcon data-testid="header-icon" icon={headerIcon} size="small" margin={[0, 4, 0, 0]} />
        </Tooltip>

        <HeaderTextContainer>
          <LastUpdatedDateContainer>
            <Typography data-testid="title" type="body3">
              {headerText}
            </Typography>
            {formattedDate && (
              <Tooltip
                position="bottom-start"
                text={
                  <Typography color="white">
                    {t(homeTranslations.common.lastUpdated.key, homeTranslations.common.lastUpdated.defaultValue)}:
                    <br /> {formattedDate}
                  </Typography>
                }
              >
                <Icon data-testid="header-icon" icon={Icons.CLOCKFILLED} size="small" margin={[0, 0, 0, 1]} />
              </Tooltip>
            )}
          </LastUpdatedDateContainer>

          <Typography data-testid="sub-title" type="legal" color="secondaryTextGray">
            {subHeaderText}
          </Typography>
        </HeaderTextContainer>

        <HeaderSideContentContainer data-testid="header-right-element">
          {headerSideContent}
        </HeaderSideContentContainer>
      </HeaderContainer>

      {!isWeeklyViewDisabled && isLoading && (
        <CommonStateContainer>
          <WidgetCommonState isLoading />
        </CommonStateContainer>
      )}

      {!isWeeklyViewDisabled && !isLoading && isError && (
        <CommonStateContainer>
          <WidgetCommonState isError errorText={errorText} errorRetryHandler={errorRetryHandler} />
        </CommonStateContainer>
      )}

      {!isLoading && !isError && !isWeeklyViewDisabled && isNoData && (
        <CommonStateContainer>
          {noDataContent ? noDataContent : <WidgetCommonState isNoData customNoDataText={noDataText} />}
        </CommonStateContainer>
      )}

      {!isLoading && isWeeklyViewDisabled && (
        <CommonStateContainer>
          <WidgetCommonState isNoData isNoWeeklyData />
        </CommonStateContainer>
      )}

      {!isLoading && !isError && !isNoData && !isWeeklyViewDisabled && (
        <ContentContainer>{content}</ContentContainer>
      )}
    </Container>
  );
};
