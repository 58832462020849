// TODO: Remove this when fixing the unit tests - BTCOBUI-1952
/* istanbul ignore file */
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey } from '../../constants/localStorageKey';
import { StoreDetails } from '../../global-state/types';
import { useLocalStorage, useUser } from '../../hooks';
import { Container, Icon, Icons, Typography } from '../../stories/atoms';
import LocationIcon from '../../stories/atoms/icon/assets/Location.svg';
import LocationActiveIcon from '../../stories/atoms/icon/assets/LocationActive.svg';
import LocationActiveHM from '../../stories/atoms/icon/assets/LocationActive_HM.svg';
import LocationHM from '../../stories/atoms/icon/assets/Location_HM.svg';
import { Button } from '../../stories/molecules';
import { Search } from '../../stories/molecules/search/Search';
import { muiTheme } from '../../theme';
import { selectStoreTranslation } from '../../translations';
import {
  StyledContainer,
  StyledLogoContainer,
  StyledMapContainer,
  StyledScreenContainer,
  StyledSearchContainer,
  StyledStoreDetails,
  StyledWrapper,
} from './SelectStore.styles';
import { Map } from './map/Map';

export const SelectStore: FC = observer(() => {
  const [selectedStoreId, setSelectedStoreId] = useState<string>();
  const [selectedStoreInfo, setSelectedStoreInfo] = useState<{
    name: string;
    address: string;
    storeId: string;
    status: string;
  } | null>(null);

  const {
    get: { storesDetails, previousStoreId },
    set,
  } = useUser();
  const { t } = useTranslation();
  const { setItem } = useLocalStorage();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const { storeIdText, findStore, storeSelectionText, selectStore, searchStoreLabel, storeStatus } =
    selectStoreTranslation;

  const center = useMemo(() => {
    if (storesDetails.length === 0) {
      return { latitude: 0, longitude: 0 };
    }

    const totalLatitude = storesDetails.reduce((sum, { latitude }) => sum + parseFloat(latitude), 0);
    const totalLongitude = storesDetails.reduce((sum, { longitude }) => sum + parseFloat(longitude), 0);

    const avgLatitude = totalLatitude / storesDetails.length;
    const avgLongitude = totalLongitude / storesDetails.length;

    return { latitude: avgLatitude, longitude: avgLongitude };
  }, [storesDetails]);

  const setSelectedMapStore = (store: StoreDetails) => {
    if (store) {
      setSelectedStoreInfo({
        name: store.name,
        address: store.address,
        storeId: store.storeId,
        status: store.status,
      });
      setSelectedStoreId(store.storeId);
    }
  };

  const handleMarkerClick = async (storeId: string) => {
    const storeMarker = storesDetails?.find((storeDetails) => storeDetails.storeId === storeId);
    const selectedStore = storesDetails?.find((storeDetails) => storeDetails.storeId === storeId);

    if (!storeMarker || !selectedStore) {
      return;
    }

    setSelectedMapStore(selectedStore);
  };

  const handleSearch = (selectedValue: StoreDetails | null) => {
    if (selectedValue) {
      setSelectedMapStore(selectedValue);
    } else {
      setSelectedStoreInfo(null);
    }
  };

  const onStoreSelect = () => {
    setItem(LocalStorageKey.SelectedStore, selectedStoreId as string);
    set({ currentStoreId: selectedStoreId });
    navigate('/home');
  };

  const customFilterFunction = (options: StoreDetails[], inputValue: string) => {
    return options.filter(
      (option) =>
        option.storeId.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const getLocationMarkerIcon = (storeId?: string) => {
    const targetStoreDetails = storesDetails?.find((storeDetails) => storeDetails.storeId === storeId);

    let corporateBrandId = 0;

    if (targetStoreDetails) {
      corporateBrandId = targetStoreDetails.corporateBrandId;
    }

    const isActive = selectedStoreId && selectedStoreId === storeId;

    return corporateBrandId === 0
      ? isActive
        ? LocationActiveHM
        : LocationHM
      : isActive
      ? LocationActiveIcon
      : LocationIcon;
  };

  const StoreInformation = () => {
    return (
      selectedStoreInfo && (
        <StyledStoreDetails
          data-testid={`selected-store-details-${isMobileView ? 'mobile' : 'desktop'}`}
          isMobile={isMobileView}
        >
          <div>
            <Typography type="body3">{selectedStoreInfo?.name}</Typography>
            <Typography>{selectedStoreInfo?.address}</Typography>
            <Typography>
              {t(storeIdText.key, storeIdText.defaultValue)} - {selectedStoreInfo?.storeId}
            </Typography>
            {selectedStoreInfo?.status !== '' && (
              <Container direction="horizontal">
                <Typography>{t(storeStatus.key, storeStatus.defaultValue)} - </Typography>
                <Typography gutterBottom color={selectedStoreInfo?.status === 'OPEN' ? 'primary' : 'red'}>
                  {selectedStoreInfo?.status}
                </Typography>
              </Container>
            )}
          </div>
          <Button
            label={t(selectStore.key, selectStore.defaultValue)}
            disabled={!selectedStoreId}
            onClick={onStoreSelect}
          />
        </StyledStoreDetails>
      )
    );
  };

  const renderMobileView = () => (
    <StyledWrapper>
      <StyledSearchContainer data-testid="search-container-mobile">
        <Typography type="h4"> {t(findStore.key, findStore.defaultValue)}</Typography>
        <Typography gutterBottom>
          {t(storeSelectionText.key, storeSelectionText.defaultValue, {
            numberOfStores: storesDetails?.length,
          })}
        </Typography>
        <Search
          fieldLabel={t(searchStoreLabel.key, searchStoreLabel.defaultValue)}
          options={storesDetails || []}
          getOptionLabel={(store) => store.name}
          onSelect={handleSearch}
          filterFunction={customFilterFunction}
        />
      </StyledSearchContainer>

      <StyledMapContainer data-testid="map-container-mobile" isMobile={isMobileView}>
        <Map
          center={center}
          zoom={2}
          selectedMarkerId={selectedStoreId || ''}
          markers={storesDetails.map(({ storeId, latitude, longitude }) => {
            return { id: storeId, latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
          })}
          prefferedProvider="Google"
          panOnMarkerSelect
          onMarkerSelectZoom={12}
          onMarkerClick={handleMarkerClick}
          getMarkerIconUrl={getLocationMarkerIcon}
        />
      </StyledMapContainer>
      <StoreInformation />
    </StyledWrapper>
  );

  const renderDesktopView = () => (
    <StyledContainer>
      <StyledMapContainer data-testid="map-container-desktop">
        <Map
          center={center}
          zoom={2}
          selectedMarkerId={selectedStoreId || ''}
          markers={storesDetails.map(({ storeId, latitude, longitude }) => {
            return { id: storeId, latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
          })}
          prefferedProvider="Google"
          panOnMarkerSelect
          onMarkerSelectZoom={12}
          onMarkerClick={handleMarkerClick}
          getMarkerIconUrl={getLocationMarkerIcon}
        />
      </StyledMapContainer>

      <StyledSearchContainer data-testid="search-container-desktop">
        <Typography type="h4"> {t(findStore.key, findStore.defaultValue)}</Typography>
        <Typography gutterBottom>
          {t(storeSelectionText.key, storeSelectionText.defaultValue, {
            numberOfStores: storesDetails?.length,
          })}
        </Typography>
        <Search
          fieldLabel={t(searchStoreLabel.key, searchStoreLabel.defaultValue)}
          options={storesDetails || []}
          getOptionLabel={(store) => `${store.storeId} - ${store.name}`}
          onSelect={handleSearch}
          filterFunction={customFilterFunction}
          markerOption={storesDetails?.find((storeDetails) => storeDetails.storeId === selectedStoreId)}
        />

        <StoreInformation />
      </StyledSearchContainer>
    </StyledContainer>
  );

  const navigateToPreviousSelectedStore = () => {
    if (previousStoreId) {
      setItem(LocalStorageKey.SelectedStore, previousStoreId as string);
      set({ currentStoreId: previousStoreId });

      navigate('/home');
    }
  };

  return (
    <StyledScreenContainer direction="horizontal" position="right">
      <StyledLogoContainer direction="horizontal" position="right" isMobile={isMobileView}>
        <Icon icon={Icons.HM_LOGO} size="large" onClick={navigateToPreviousSelectedStore} />
      </StyledLogoContainer>
      {isMobileView ? renderMobileView() : renderDesktopView()}
    </StyledScreenContainer>
  );
});
