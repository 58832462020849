import {
  af,
  ar,
  bg,
  bs,
  da,
  de,
  el,
  enGB,
  enUS,
  es,
  et,
  fi,
  fr,
  hi,
  hr,
  hu,
  is,
  it,
  ja,
  ka,
  kk,
  ko,
  lt,
  lv,
  mk,
  ms,
  nb,
  nl,
  pl,
  pt,
  ro,
  sk,
  sl,
  sq,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  zhCN,
  zhHK,
} from 'date-fns/locale';

export const localeMap: { [key: string]: Locale } = {
  'en-US': enUS,
  'en-GB': enGB,
  'da-DK': da,
  'de-DE': de,
  'sv-SE': sv,
  'es-ES': es,
  'it-IT': it,
  'sl-SI': sl,
  'fr-FR': fr,
  'nl-NL': nl,
  'is-IS': is,
  'fi-FI': fi,
  'pt-PT': pt,
  'pl-PL': pl,
  'kk-KZ': kk,
  'hu-HU': hu,
  'sk-SK': sk,
  'ro-RO': ro,
  'et-EE': et,
  'lt-LT': lt,
  'lv-LV': lv,
  'ko-KR': ko,
  'ja-JP': ja,
  'el-GR': el,
  'bg-BG': bg,
  'th-TH': th,
  'zh-CN': zhCN,
  'zh-HK': zhHK,
  'vi-VN': vi,
  'ms-MY': ms,
  'af-ZA': af,
  'uk-UA': uk,
  'sq-AL': sq,
  'hr-HR': hr,
  'bs-BA': bs,
  'mk-MK': mk,
  'ka-GE': ka,
  'sr-RS': sr,
  ar: ar,
  'tr-TR': tr,
  'hi-IN': hi,
  'nb-NO': nb,
};
