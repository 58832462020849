import styled from 'styled-components';
import type { IconSizes } from '..';
import { VendorType } from '../../../constants';
import { Colors } from '../../../theme';
import { Spacing, getSpacing } from '../../../utils';
import { Icons } from './index';
export type RotateDirection = 'left' | 'right' | 'up' | 'custom';

const fontSizeMapping = {
  // TODO: Update them as per standard design guideline(for each breakpoint) - BTCOBUI-719
  small: '16px',
  medium: '24px',
  large: '36px',
  'x-large': '60px',
};

type StyledProps = {
  size: IconSizes;
  icon: Icons | VendorType;
  color?: Colors;
  margin?: Spacing;
  rotate?: RotateDirection;
  rotateValue?: number;
  hoverColor?: Colors;
};

const getRotation = (direction: RotateDirection | undefined, rotateValue?: number) => {
  switch (direction) {
    case 'left':
      return 'rotate(90deg)';
    case 'right':
      return 'rotate(270deg)';
    case 'up':
      return 'rotate(180deg)';
    case 'custom':
      return rotateValue ? `rotate(${rotateValue}deg)` : undefined;
    default:
      return undefined;
  }
};

export const StyledSVG = styled.div<StyledProps>`
  height: 1em;
  width: 1em;
  font-size: ${({ size }) => fontSizeMapping[size]};
  color: ${({ theme: { color: themeColor, palette }, color }) =>
    color ? themeColor[color] : palette.mode === 'dark' ? themeColor.white : themeColor.primary};
  transform: ${({ rotate, rotateValue }) => getRotation(rotate, rotateValue)};
  margin: ${({ margin, theme }) => margin && getSpacing(margin, theme.spacing)};
  transform-origin: center;
  display: flex;

  > svg {
    height: 100%;
  }

  :hover {
    cursor: ${({ onClick }) => onClick && 'pointer'};
    color: ${({ theme, hoverColor }) => hoverColor && theme.color[hoverColor]};
  }

  :active,
  &.active {
    transform: ${({ onClick, rotate }) => onClick && `scale(0.9) ${getRotation(rotate)}`};
  }
`;
