import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { intlFormat } from 'date-fns';
import { useStoreInformation, useTransactions } from '../../../hooks';
import { Icon, Icons, Tooltip, Typography } from '../../../stories/atoms';
import { Animation, Animations, Table } from '../../../stories/molecules';
import { transactionSearchTranslations } from '../../../translations';
import { formatAmountWithCurrency } from '../../../utils';
import { isSupportedVendorType, VendorMapping } from '../../../constants';
import { StyledAnimationWrapper, StyledContainer } from './TransactionTable.styles';
const {
  date,
  time,
  noData,
  tenderType,
  paymentType: paymentTypeTranslation,
  transactionType: transactionTypeTranslation,
  receiptSubType: receiptSubTypeTranslation,
  paymentCardtype: paymentCardtypeTranslation,
  subTenderType,
  cashier,
  operatorId,
  rowsPerpageLabel,
} = transactionSearchTranslations;

export const TransactionTable: FC<{
  onRowClick: (selectedRowId: number) => void;
  onPageChange: (pageNo: number) => void;
  height: string;
  selectedRowIndex: number;
}> = observer(({ onRowClick, onPageChange, height, selectedRowIndex }) => {
  const { t, i18n } = useTranslation();
  const {
    get: { table, totalCount, isFetchingTransaction },
    set,
  } = useTransactions();

  const { currencyCode } = useStoreInformation();

  const getRows = () => {
    return table.rows.map((row, index) => {
      const {
        Transaction: { endDateTime, OperatorID },
        amount,
        paymentType,
        paymentCardtype,
        receiptNumber,
        receiptSubType,
        tillId,
      } = row;

      return {
        id: index,
        endDateTime: intlFormat(new Date(endDateTime), {}, { locale: i18n.language }),
        time: intlFormat(
          new Date(endDateTime),
          { hour: 'numeric', minute: 'numeric', second: 'numeric' },
          { locale: i18n.language },
        ),
        paymentType: paymentType.join(', '),
        paymentCardtype: paymentCardtype
          .map((type) => (isSupportedVendorType(type) ? VendorMapping[type] : type))
          .join(', '),
        amount: formatAmountWithCurrency(parseFloat(amount), i18n.language, currencyCode),
        cashier: OperatorID,
        tillId,
        receiptNumber,
        receiptSubType,
      };
    });
  };

  const tableHeaders = [
    { id: 'endDateTime', name: t(date.key, date.defaultValue), width: '12%' },
    { id: 'time', name: t(time.key, time.defaultValue), width: '11%' },
    {
      id: 'paymentType',
      name: (
        <StyledContainer direction="horizontal">
          <Typography whiteSpace="nowrap">{t(tenderType.key, tenderType.defaultValue)}</Typography>
          <Tooltip
            text={
              <Typography padding={[2]} color="white">
                {t(paymentTypeTranslation.key, paymentTypeTranslation.defaultValue)}
              </Typography>
            }
            position="top"
          >
            <Icon data-testid="infoIcon" size="small" icon={Icons.INFO} margin={[0, 0, 0, 2]} />
          </Tooltip>
        </StyledContainer>
      ),
      width: '11%',
    },
    {
      id: 'paymentCardtype',
      name: (
        <StyledContainer direction="horizontal">
          <Typography whiteSpace="nowrap">{t(subTenderType.key, subTenderType.defaultValue)}</Typography>
          <Tooltip
            text={
              <Typography padding={[2]} color="white">
                {t(paymentCardtypeTranslation.key, paymentCardtypeTranslation.defaultValue)}
              </Typography>
            }
            position="top"
          >
            <Icon data-testid="infoIcon" size="small" icon={Icons.INFO} margin={[0, 0, 0, 2]} />
          </Tooltip>
        </StyledContainer>
      ),
      width: '11%',
    },
    {
      id: 'receiptSubType',
      name: (
        <StyledContainer direction="horizontal">
          <Typography>{t(transactionTypeTranslation.key, transactionTypeTranslation.defaultValue)}</Typography>
          <Tooltip
            text={
              <Typography padding={[2]} color="white">
                {t(receiptSubTypeTranslation.key, receiptSubTypeTranslation.defaultValue)}
              </Typography>
            }
            position="top"
          >
            <Icon data-testid="infoIcon" size="small" icon={Icons.INFO} margin={[0, 0, 0, 2]} />
          </Tooltip>
        </StyledContainer>
      ),
      width: '11%',
    },
    {
      id: 'amount',
      name: t(transactionSearchTranslations.amount.key, transactionSearchTranslations.amount.defaultValue),
      width: '11%',
    },
    {
      id: 'receiptNumber',
      name: t(
        transactionSearchTranslations.receiptNumber.key,
        transactionSearchTranslations.receiptNumber.defaultValue,
      ),
      width: '11%',
    },
    {
      id: 'tillId',
      name: t(transactionSearchTranslations.tillId.key, transactionSearchTranslations.tillId.defaultValue),
      width: '11%',
    },
    {
      id: 'cashier',
      name: (
        <StyledContainer direction="horizontal">
          <Typography>{t(cashier.key, cashier.defaultValue)}</Typography>
          <Tooltip
            text={
              <Typography padding={[2]} color="white">
                {t(operatorId.key, operatorId.defaultValue)}
              </Typography>
            }
            position="top"
          >
            <Icon data-testid="infoIcon" size="small" icon={Icons.INFO} margin={[0, 0, 0, 2]} />
          </Tooltip>
        </StyledContainer>
      ),
      width: '11%',
    },
  ];

  if (!table.rows.length && !isFetchingTransaction) {
    return (
      <StyledAnimationWrapper>
        <Animation
          animation={Animations.CONFUSED}
          title={t(noData.title.key, noData.title.defaultValue)}
          subtitle={t(noData.subtitle.key, noData.subtitle.defaultValue)}
        ></Animation>
      </StyledAnimationWrapper>
    );
  }

  return (
    <Table
      stickyHeader
      useZebraStyles
      rows={getRows()}
      columns={tableHeaders}
      onRowClick={onRowClick}
      paginationOptions={{
        enabled: totalCount > 0,
        rowsPerPage: table.pagination.pageSize,
        rowsPerPageOptions: table.pagination.rowsPerPageOptions,
        totalRows: totalCount,
        defaultPageIndex: table.pagination.currentPageNumber === 1 ? 0 : undefined, // reset when user select new dropdown filter or date selection
        nextIconButtonProps: {
          disabled:
            isFetchingTransaction || table.pagination.currentPageNumber * table.pagination.pageSize >= totalCount,
        },
      }}
      fixedHeight={height}
      selectedRowIndex={selectedRowIndex}
      onPageChange={onPageChange}
      onRowsPerPageChange={(rowsPerPage) =>
        set({ table: { ...table, pagination: { ...table.pagination, pageSize: rowsPerPage } } })
      }
      labelRowsPerPage={t(rowsPerpageLabel.key, rowsPerpageLabel.defaultValue)}
      isLoading={isFetchingTransaction && table.pagination.currentPageNumber === 1}
      whiteSpace="nowrap"
    />
  );
});
