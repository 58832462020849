import { useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { KpiType, getKPIIcons, getUnit } from '../../../constants';
import { SortDirection } from '../../../global-state/types';
import { AreaTargetResponse, useStoreInformation, useStores } from '../../../hooks';
import { useAsyncAction } from '../../../hooks/useAsyncAction';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../stories/atoms';
import { Animation, Animations, Modal, Row, Table } from '../../../stories/molecules';
import { muiTheme } from '../../../theme';
import { kpiTargetTransalations } from '../../../translations';
import { formatAmountWithCurrency, isFailureResponse } from '../../../utils';
import {
  StyledContainer,
  StyledHeader,
  StyledIcon,
  StyledTableContainer,
  StyledTypography,
} from './AreaTargetView.styles';

// AreaTableComponent is a functional component that renders a sortable table for area target view.
type AreaTableComponentProps = {
  isTablet: boolean;
  rows: Row[];
  areaViewLoading: boolean;
  handleSorting: (columnId: string, direction: SortDirection) => Row[];
  storeIdText: string;
  currentTargetText: string;
  recommendedTargetText: string;
};

const AreaTableComponent: FC<AreaTableComponentProps> = ({
  isTablet,
  rows,
  areaViewLoading,
  handleSorting,
  storeIdText,
  currentTargetText,
  recommendedTargetText,
}) => (
  <StyledTableContainer data-testid="area-view-table">
    <Table
      stickyHeader
      noBorder
      useZebraStyles
      fixedHeight={isTablet ? '700px' : '300px'}
      rows={rows}
      columns={[
        {
          id: 'storeId',
          name: storeIdText,
          width: '33%',
          isSortable: true,
        },
        {
          id: 'currentTarget',
          name: currentTargetText,
          width: '33%',
          isSortable: true,
        },
        {
          id: 'recommendedTarget',
          name: recommendedTargetText,
          width: '33%',
          isSortable: true,
        },
      ]}
      isLoading={areaViewLoading}
      whiteSpace="nowrap"
      skeletonLoaderOptions={{ numberOfRows: isTablet ? 12 : 5 }}
      handleSorting={(columnId, direction) => handleSorting(columnId, direction)}
    ></Table>
  </StyledTableContainer>
);

export type AreaTargetViewProps = {
  kpiType: KpiType;
  onClose: () => void;
  isOpen: boolean;
};

const AreaTargetView: FC<AreaTargetViewProps> = ({ kpiType, onClose, isOpen }) => {
  const { t, i18n } = useTranslation();
  const { kpiWidgets, kpiProperties, areaViewModal, notSet, error, noData } = kpiTargetTransalations;
  const { currencyCode: storeCurrency, areaCode } = useStoreInformation();
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const unit = getUnit(kpiType, storeCurrency);
  const { fetchAreaStoresTarget } = useStores();

  const {
    result: areaViewData,
    isLoading: areaViewLoading,
    isError: areaViewError,
    triggerAction: triggerFetchAreaView,
  } = useAsyncAction<AreaTargetResponse[]>();

  // Fetch Area Targets and recommended targets
  const fetchAreaTargets = async () => {
    const response = await fetchAreaStoresTarget(kpiType);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  // Get formatted value
  const getFormattedValue = (kpiValue: number) => {
    const newValue =
      unit === storeCurrency
        ? formatAmountWithCurrency(kpiValue, i18n.language, storeCurrency || '')
        : kpiValue.toLocaleString() + (unit ?? '');

    return newValue;
  };

  const getAreaRows = (areaData: AreaTargetResponse[]) =>
    areaData.map(({ storeId, currentTarget, recommendedTarget, storeName }, index) => ({
      id: index,
      storeId: (
        <Container direction="horizontal" wrap="nowrap">
          <Typography>{storeId}</Typography>
          <Tooltip
            position="right-end"
            arrowedToolTip
            text={
              <Container padding={[2]}>
                <Typography color="white">{storeName}</Typography>
              </Container>
            }
          >
            <Icon data-testid="infoIcon" color="primary" icon={Icons.INFO} size="small" margin={[0, 1]} />
          </Tooltip>
        </Container>
      ),
      currentTarget: currentTarget ? (
        getFormattedValue(currentTarget)
      ) : (
        <Typography color="textGray" data-testid="not-set">
          {t(notSet.key, notSet.defaultValue)}
        </Typography>
      ),
      recommendedTarget: recommendedTarget ? (
        getFormattedValue(recommendedTarget)
      ) : (
        <Typography color="textGray" data-testid="not-set">
          {t(notSet.key, notSet.defaultValue)}
        </Typography>
      ),
    }));

  const sortObjects = (property: keyof AreaTargetResponse, order: SortDirection) => {
    return (a: AreaTargetResponse, b: AreaTargetResponse) => {
      if (a[property] === null) return 1; // move nulls to the end
      if (b[property] === null) return -1; // move nulls to the start
      if (a !== null && b !== null && a[property] !== null && b[property] !== null) {
        if (property === 'storeId') {
          const aValue = a[property] as string;
          const bValue = b[property] as string;
          return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else {
          const aValue = a[property] as number;
          const bValue = b[property] as number;
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
      }
      return 0;
    };
  };

  const handleSorting = (columnId: string, direction: SortDirection): Row[] => {
    if (!areaViewData) return [];
    if (!columnId) return getAreaRows(areaViewData);
    const sortedData = areaViewData.sort(sortObjects(columnId as keyof AreaTargetResponse, direction));
    return getAreaRows(sortedData);
  };

  // Fetch Area Targets on load
  useEffect(() => {
    triggerFetchAreaView(fetchAreaTargets);
  }, [kpiType]);

  const rows = areaViewData ? getAreaRows(areaViewData) : [];

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <StyledContainer wrap="nowrap" data-testid="area-view-modal-container" width={isTablet ? '' : '1000px'}>
        <StyledHeader data-testid="area-view-modal-header">
          <StyledIcon icon={Icons[getKPIIcons(kpiType)]} size="small" margin={[0, 2, 0, 0]} />
          <Container>
            <Typography type="button" data-testid="modal-title">
              {t(kpiWidgets[kpiType].key, kpiWidgets[kpiType].defaultValue)}
            </Typography>
            <div data-testid="modal-subtitle">
              <StyledTypography color="secondaryTextGray">
                {t(areaViewModal.subtitleText1.key, areaViewModal.subtitleText1.defaultValue)}
              </StyledTypography>
              <StyledTypography color="secondaryTextGray">
                {' '}
                {t(kpiWidgets[kpiType].key, kpiWidgets[kpiType].defaultValue)}{' '}
              </StyledTypography>
              <StyledTypography color="secondaryTextGray">
                {t(areaViewModal.subtitleText2.key, {
                  defaultValue: areaViewModal.subtitleText2.defaultValue,
                  areaName: areaCode,
                })}
              </StyledTypography>
            </div>
          </Container>
        </StyledHeader>
        {areaViewData && !areaViewData.length && !areaViewError && (
          <Animation title={''} subtitle={t(noData.key, noData.defaultValue)} animation={Animations.SAD} />
        )}
        {areaViewError && (
          <Animation
            title={t(error.errorTitle.key, error.errorTitle.defaultValue)}
            subtitle={t(error.errorSubtitle.key, error.errorSubtitle.defaultValue)}
            animation={Animations.CONFUSED}
          />
        )}
        {((areaViewData && areaViewData.length !== 0) || areaViewLoading) && (
          <AreaTableComponent
            isTablet={isTablet}
            rows={rows}
            areaViewLoading={areaViewLoading}
            handleSorting={handleSorting}
            storeIdText={t(areaViewModal.storeid.key, areaViewModal.storeid.defaultValue)}
            currentTargetText={t(areaViewModal.currentTarget.key, areaViewModal.currentTarget.defaultValue)}
            recommendedTargetText={t(
              kpiProperties.recommendedTarget.title.key,
              kpiProperties.recommendedTarget.title.defaultValue,
            )}
          />
        )}
      </StyledContainer>
    </Modal>
  );
};

export default AreaTargetView;
