const receipt = {
  image: {
    key: 'transactionSearch.receipt.image',
    defaultValue: 'Receipt Image',
  },
  error: {
    key: 'transactionSearch.receipt.error',
    defaultValue: "This receipt can't be shown right now.",
  },
};

export const transactionSearchTranslations = {
  date: {
    key: 'transactionSearch.date',
    defaultValue: 'Date',
  },
  time: {
    key: 'transactionSearch.time',
    defaultValue: 'Time',
  },
  tenderType: {
    key: 'transactionSearch.tenderType',
    defaultValue: 'Tender type',
  },
  paymentType: {
    key: 'transactionSearch.paymentType',
    defaultValue: 'Payment type',
  },
  subTenderType: {
    key: 'transactionSearch.subTenderType',
    defaultValue: 'Sub Tender type',
  },
  paymentCardtype: {
    key: 'transactionSearch.paymentCardtype',
    defaultValue: 'Payment card type',
  },
  transactionType: {
    key: 'transactionSearch.transactionType',
    defaultValue: 'Transaction',
  },
  receiptSubType: {
    key: 'transactionSearch.receiptSubType',
    defaultValue: 'Receipt sub type',
  },
  amount: {
    key: 'transactionSearch.amount',
    defaultValue: 'Amount',
  },
  receiptNumber: {
    key: 'transactionSearch.receiptNumber',
    defaultValue: 'Receipt no.',
  },
  tillId: {
    key: 'transactionSearch.tillId',
    defaultValue: 'Till ID',
  },
  tillType: {
    key: 'transactionSearch.tillType',
    defaultValue: 'Till Type',
  },
  cashier: {
    key: 'transactionSearch.cashier',
    defaultValue: 'Cashier',
  },
  operatorId: {
    key: 'transactionSearch.operatorId',
    defaultValue: 'Operator ID',
  },
  selectedFilters: {
    key: 'transactionSearch.selectedFilters',
    defaultValue: 'Filters Selected:',
  },
  transactions: {
    key: 'transactionSearch.transactions',
    defaultValue: 'Transactions',
  },
  currentDateText: {
    key: 'transactionSearch.currentdateText',
    defaultValue: 'Receipt date based on: ',
  },
  datePicker: {
    key: 'transactionSearch.datePicker',
    defaultValue: 'Date Picker',
  },
  clearFilter: {
    key: 'transactionSearch.clearAll',
    defaultValue: 'Clear All',
  },
  clearAllFilterButton: {
    key: 'transactionSearch.clearAllFilterButton',
    defaultValue: 'Clear All Filters',
  },
  doneButton: {
    key: 'transactionSearch.doneButton',
    defaultValue: 'Done',
  },
  allFilters: {
    key: 'transactionSearch.allFilters',
    defaultValue: 'All Filters',
  },
  error: {
    key: 'transactionSearch.error',
    defaultValue: "Oh no! We can't open transaction search right now.",
  },
  noData: {
    title: {
      key: 'transactionSearch.noData.title',
      defaultValue: "Hmm... We couldn't find any results.",
    },
    subtitle: {
      key: 'transactionSearch.noData.subtitle',
      defaultValue: 'Maybe try searching with another set of filters?',
    },
  },
  selected: {
    key: 'transactionSearch.selected',
    defaultValue: 'selected',
  },
  noDataFilter: {
    title: {
      key: 'transactionSearch.noDataFilter.title',
      defaultValue: 'Oh no! We can’t show you this specific filter at the moment.',
    },
  },
  disabledFilter: {
    key: 'transactionSearch.disabledFilter',
    defaultValue: 'No content available.<br />Try another set of filters.',
  },
  warningBanner: {
    key: 'transactionSearch.warningBanner',
    defaultValue:
      'We are facing some momentary issues. Some of the filters are not responding, but you can still use transaction search.',
  },
  rowsPerpageLabel: {
    key: 'transactionSearch.rowsPerpageLabel',
    defaultValue: 'Rows per page:',
  },
  receipt,
};
