import { FC, useEffect, useRef, useState, KeyboardEvent } from 'react';
import { Colors, Sizes, Typographies } from '../../../theme';
import { Icons, Tooltip, TooltipProps } from '../../atoms';
import { Spacing } from '../../../utils';
import {
  StyledCheckboxWrapper,
  StyledIcon,
  StyledLabelWrapper,
  StyledTypography,
  StyledLabel,
  StyledCheckbox,
} from './Checkbox.styles';

export type LabelPosition = 'top' | 'bottom' | 'left' | 'right';

export type CheckboxProps = {
  size?: Sizes;
  label?: string;
  labelPosition?: LabelPosition;
  labelType?: Typographies;
  tabIndex?: number;
  isDisabled?: boolean;
  isChecked?: boolean;
  isIndeterminateState?: boolean;
  onChange: (isChecked: boolean) => void;
  tooltip?: TooltipProps;
  iconColor?: Colors;
  labelColor?: Colors;
  margin?: Spacing;
};

export const Checkbox: FC<React.PropsWithChildren<CheckboxProps>> = ({
  size = 'small',
  label,
  labelPosition = 'right',
  labelType = 'body2',
  tabIndex = 0,
  isDisabled = false,
  isChecked = false,
  isIndeterminateState = false,
  iconColor = 'primary',
  onChange,
  tooltip,
  labelColor = 'primary',
  margin,
}) => {
  const [checked, setChecked] = useState(isChecked || false);
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current && isIndeterminateState) {
      checkboxRef.current.indeterminate = isIndeterminateState;
      return;
    }

    setChecked(isChecked);
  }, [isChecked, isIndeterminateState]);

  const handleKeyPress = (keyboardEvent: KeyboardEvent<HTMLDivElement>) => {
    if (isDisabled || isIndeterminateState) return;

    switch (keyboardEvent.key) {
      case 'Enter':
      case ' ':
        setChecked(!checked);
        break;
      default:
        break;
    }
  };

  return (
    <StyledCheckboxWrapper isDisabled={isDisabled} tabIndex={tabIndex} onKeyDown={handleKeyPress} margin={margin}>
      <StyledLabelWrapper label={label} labelPosition={labelPosition} data-testid="checkbox-label-wrapper">
        <input
          type="checkbox"
          checked={checked}
          onChange={(element) => {
            setChecked(element.currentTarget.checked);
            onChange(element.currentTarget.checked);
          }}
          data-indeterminate={isIndeterminateState}
          disabled={isDisabled}
          ref={checkboxRef}
          data-testid="input-checkbox"
        />
        <StyledCheckbox data-testid="checkbox" isDisabled={isDisabled} labelPosition={labelPosition}>
          {isIndeterminateState && (
            <StyledIcon
              icon={Icons.CHECKBOX_INDETERMINATE}
              size={size}
              isDisabled={isDisabled}
              data-testid="checkbox-icon"
              color={iconColor}
            />
          )}
          {checked && !isIndeterminateState && (
            <StyledIcon
              icon={Icons.CHECK_MARK}
              size={size}
              isDisabled={isDisabled}
              data-testid="checkbox-icon"
              color={iconColor}
            />
          )}
        </StyledCheckbox>
        {label && (
          <StyledLabel>
            {tooltip && <Tooltip {...tooltip} />}
            <StyledTypography type={labelType} color={labelColor}>
              {label}
            </StyledTypography>
          </StyledLabel>
        )}
      </StyledLabelWrapper>
    </StyledCheckboxWrapper>
  );
};
