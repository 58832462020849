import styled from 'styled-components';
import { Icon } from '../../stories/atoms';

export const WidgetsSectionContainer = styled.div<{ isMobileView: boolean }>`
  display: flex;
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
  padding: ${({ theme: { spacing } }) => spacing(4, 5)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};
  background: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  border-radius: ${({ theme: { spacing } }) => spacing(5)};
`;

export const WidgetsSectionTitleContainer = styled.div`
  height: fit-content;
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2, 0)};
`;

export const OptionsContainer = styled.div<{ isMobileView: boolean }>`
  flex: 2;
  max-height: ${({ isMobileView, theme: { spacing } }) => (isMobileView ? 'none' : spacing(44))};
  display: flex;
  flex-direction: column;
  flex-wrap: ${({ isMobileView }) => (isMobileView ? 'nowrap' : 'wrap')};

  ${OptionContainer} {
    max-width: ${({ isMobileView, theme: { spacing } }) => (isMobileView ? 'initial' : spacing(85))};
  }
`;

export const OptionNameContainer = styled.div`
  flex: 1;
`;

export const OptionSwitchContainer = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(0, 2)};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: ${({ theme: { spacing } }) => spacing(100)};
  display: flex;
  align-self: flex-end;
  & > *:first-child {
    margin-right: ${({ theme: { spacing } }) => spacing(5)};
  }
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
  justify-content: center;
  cursor: pointer;
`;
