import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { cardShadow } from '../../../theme';

export const StyledCharts = styled(Chart)`
  .apexcharts-canvas {
    .apexcharts-xaxistooltip {
      background-color: ${({ theme: { color } }) => color.white};
      border: none;
      color: ${({ theme: { color } }) => color.primary};
      border-radius: 8px;
      ${cardShadow()}
      &:before {
        border-bottom-color: transparent;
      }
    }
  }
`;
