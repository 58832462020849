import { authorizationEndpoint } from '../constants';
import { RoleMatrixType, Client } from '../global-state/types';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';

export const useRoleMatrix = () => {
  const { authAxiosRequest } = useAuthAxiosRequest();

  const fetchRoleMatrix = async (client: Client, market?: string) => {
    const roleMatrixResponse = await authAxiosRequest<RoleMatrixType>(`${authorizationEndpoint}/${client}`, {
      params: { market },
    });

    return roleMatrixResponse;
  };

  return { fetchRoleMatrix };
};
