import { FC, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Slide, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Drawer, Icon, Icons, SkeletonLoader, Typography } from '../../../stories/atoms';
import { Animation, Animations } from '../../../stories/molecules';
import { useTransactions } from '../../../hooks/useController';
import { navigateLeftId, navigateRightId } from '../../../constants';
import { commonTranslations, errors, transactionSearchTranslations } from '../../../translations';
import { muiTheme } from '../../../theme';
import {
  ReceiptImageWrapper,
  ReceiptWrapper,
  NavigationWrapper,
  SkeletonImageWrapper,
  NavigationIcons,
  ReceiptDashLine,
  ReceiptItemContainer,
  NoReceiptData,
  ReceiptItem,
} from './Receipt.styles';

const {
  receipt: { error, image },
} = transactionSearchTranslations;

export type NavigationDirection = 'previous' | 'next';

export type ReceiptProps = {
  isVisible: boolean;
  onClose: () => void;
  onNavigate: (direction: NavigationDirection) => void;
  onRetry: () => void;
  height: string;
};

export const splitItemsByWhitespace = (line: string, whiteSpaceCharacter = 2) => {
  const pattern = `\\s{${whiteSpaceCharacter},}`;
  const regex = new RegExp(pattern, 'g');

  return line.trim().split(regex);
};

export const getItemsInfo = (
  line: string,
  maxRowLength: number,
  whiteSpaceCharacter = 6,
  paddingLeftOffset = 5,
  paddingRightOffset = 5,
) => {
  const items = splitItemsByWhitespace(line, whiteSpaceCharacter);
  const lastIndexLength = items[items.length - 1].length;
  const startItemIndex = line.indexOf(items[0]);
  const lastItemIndex = line.indexOf(items[items.length - 1]);
  const isCenterAligned =
    line.trim().length >= maxRowLength && items.length === 1
      ? true
      : startItemIndex > 0 &&
        lastItemIndex > 0 &&
        (startItemIndex - lastItemIndex === 0 ||
          startItemIndex - lastItemIndex === 5 ||
          startItemIndex - lastItemIndex === -5);

  const paddingLeft = startItemIndex && !isCenterAligned ? `${startItemIndex * paddingLeftOffset}px` : '0';
  const paddingRight =
    lastItemIndex && !isCenterAligned
      ? `${(maxRowLength - (lastIndexLength as number) - lastItemIndex) * paddingRightOffset}px`
      : '0';

  return {
    items,
    paddingLeft,
    paddingRight,
    isCenterAligned,
  };
};

export const Receipt: FC<React.PropsWithChildren<ReceiptProps>> = observer(
  ({ isVisible, onClose, onNavigate, height, onRetry }) => {
    const [shouldOpen, setShouldOpen] = useState(isVisible);
    const navigateLeftRef = useRef<HTMLDivElement>(null);
    const navigateRightRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();
    const {
      get: { selectedReceipt, isReceiptError },
    } = useTransactions();

    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

    useEffect(() => {
      setShouldOpen(isVisible);
    }, [isVisible]);

    useEffect(() => {
      document.addEventListener('keydown', (event) => onKeyPress(event, true));
      document.addEventListener('keyup', (event) => onKeyPress(event, false));

      return () => {
        document.removeEventListener('keydown', (event) => onKeyPress(event, true));
        document.removeEventListener('keyup', (event) => onKeyPress(event, false));
      };
    }, []);

    const onKeyPress = (event: KeyboardEvent, isKeyDown: boolean) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (isKeyDown) navigateLeftRef?.current?.classList.add('active');
          else {
            navigateLeftRef?.current?.classList.remove('active');
            navigateLeftRef?.current?.click();
          }
          break;
        case 'ArrowRight':
          if (isKeyDown) navigateRightRef?.current?.classList.add('active');
          else {
            navigateRightRef?.current?.classList.remove('active');
            navigateRightRef?.current?.click();
          }
          break;
        default:
      }
    };

    const handleClose = () => {
      setShouldOpen(false);
      if (onClose) onClose();
    };

    const getDashLineElement = (repeatNoOfDash: number, paddingRight: string, key: number) => {
      // Increase the line to always fit the content
      const item = '-'.repeat(repeatNoOfDash);

      return (
        <ReceiptDashLine paddingRight={paddingRight} key={key} data-testid="receipt-line">
          {item}
        </ReceiptDashLine>
      );
    };

    const getNewLineElement = (key: number) => {
      return <br key={key} data-testid="receipt-item-new-line" />;
    };

    const getReceiptItemElement = (
      line: string,
      key: number,
      maxRowLength: number,
      whiteSpaceCharacter: number,
    ) => {
      const { items, paddingLeft, paddingRight, isCenterAligned } = getItemsInfo(
        line,
        maxRowLength,
        whiteSpaceCharacter,
      );

      const flexBasis = `${100 / items.length}%`;
      const itemsElement = items.map(
        (item, index) =>
          item && (
            <ReceiptItem
              flexBasis={flexBasis}
              textAlign={isCenterAligned ? 'center' : index > 0 ? 'right' : 'left'}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {item}
            </ReceiptItem>
          ),
      );
      return (
        <ReceiptItemContainer
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          key={key}
          data-testid="receipt-item-container"
        >
          {itemsElement}
        </ReceiptItemContainer>
      );
    };

    const getFormattedReceipt = (receipt: string[]) => {
      let isHeader = true;
      const receiptLine = '-'.repeat(20);
      const maxRowLength = receipt[0].length;

      return receipt.map((line: string, key) => {
        // receipt dash line
        if (line.trim().includes(receiptLine)) {
          isHeader = false;
          const repeatNoOfDash = line.trim().length >= 40 ? 100 : 50;
          const paddingRight = line.trim().length >= 40 ? '0' : '50%';

          return getDashLineElement(repeatNoOfDash, paddingRight, key);
        }

        // new line
        if (line.trim().length === 0) {
          return getNewLineElement(key);
        }

        // receipt items
        return getReceiptItemElement(line, key, maxRowLength, isHeader ? 2 : 6);
      });
    };

    const ReceiptCard = () => {
      return (
        <ReceiptWrapper height={height}>
          <div>
            <Icon data-testid="closeIcon" icon={Icons.CLOSE} size="small" onClick={handleClose} />
          </div>
          <NavigationWrapper>
            <Typography>{t(image.key, image.defaultValue)}</Typography>
            <NavigationIcons>
              <Icon
                ref={navigateLeftRef}
                onClick={() => onNavigate && onNavigate('previous')}
                icon={Icons.DROPDOWN}
                size="small"
                rotate="left"
                data-testid={navigateLeftId}
              />
              <Icon
                ref={navigateRightRef}
                icon={Icons.DROPDOWN}
                size="small"
                onClick={() => onNavigate && onNavigate('next')}
                margin={[0, 2]}
                rotate="right"
                data-testid={navigateRightId}
              />
            </NavigationIcons>
          </NavigationWrapper>

          {isReceiptError ? (
            <NoReceiptData data-testid="no-receipt">
              <Animation
                animation={Animations.SAD}
                title={t(error.key, error.defaultValue)}
                subtitle={t(errors.tryAgain.key, errors.tryAgain.defaultValue)}
                button={{
                  label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
                  onClick: onRetry,
                  buttonContentProps: { iconOptions: { icon: Icons.REFRESH, color: 'white', size: 'small' } },
                }}
                textConfig={{ title: { type: 'h4' } }}
              />
            </NoReceiptData>
          ) : selectedReceipt.length === 0 ? (
            <SkeletonImageWrapper>
              <SkeletonLoader variant="paragraph" lines={25} height="14px" />
            </SkeletonImageWrapper>
          ) : (
            <ReceiptImageWrapper data-testid="receipt-image">
              {getFormattedReceipt(selectedReceipt)}
            </ReceiptImageWrapper>
          )}
        </ReceiptWrapper>
      );
    };

    return (
      <>
        {isMobile ? (
          <Drawer
            variant="temporary"
            position="right"
            isOpen={shouldOpen}
            onClose={handleClose}
            drawerWidth={muiTheme.spacing(90)}
          >
            <div>
              <ReceiptCard />
            </div>
          </Drawer>
        ) : (
          <Slide in={shouldOpen} direction="left" timeout={300} data-testid="receipt" mountOnEnter unmountOnExit>
            <div>
              <ReceiptCard />
            </div>
          </Slide>
        )}
      </>
    );
  },
);
