import { CSSProperties, FC } from 'react';
import { Typographies } from '../../../theme';
import { Typography } from '../../atoms';
import { Container, LabelContainer, StyledSwitch } from './Switch.styles';

export type SwitchProps = {
  isActive: boolean;
  disabled?: boolean;
  label?: string;
  containerWidth?: number;
  labelPosition?: 'left' | 'right';
  labelType?: Typographies;
  style?: CSSProperties;
  onClick: () => void;
};

export const Switch: FC<React.PropsWithChildren<SwitchProps>> = ({
  isActive,
  disabled = false,
  label,
  containerWidth,
  labelPosition = 'right',
  labelType = 'caption',
  style,
  onClick,
}) => {
  return (
    <Container
      style={{ width: containerWidth, flexDirection: labelPosition === 'right' ? 'row' : 'row-reverse', ...style }}
    >
      <StyledSwitch data-testid="switch" checked={isActive} disabled={disabled} onClick={onClick} />
      <LabelContainer labelPosition={labelPosition}>
        <Typography type={labelType}>{label}</Typography>
      </LabelContainer>
    </Container>
  );
};
