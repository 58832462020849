import styled from 'styled-components';
import { Divider } from '@mui/material';
import { Icon } from '../../atoms';
import { BorderType } from './Header';

export const StyledHeaderContainer = styled.div<{ borderType: BorderType }>`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundDark : color.white};
  border-bottom: ${({ theme: { color }, borderType }) => `1px ${borderType} ${color.borderGray}`};
`;

export const StyledWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: ${({ isMobile, theme: { spacing } }) => (isMobile ? spacing(4, 4) : spacing(4, 8))};
`;

export const StyledHeaderMenu = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  > div,
  button {
    :last-child {
      margin-right: 0;
    }
  }
`;

export const LanguageItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > :first-child {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing }, isSelected }) => (isSelected ? spacing(4, 0) : spacing(6, 2))};
`;

export const StyledCheckedIcon = styled(Icon)`
  margin-right: ${({ theme: { spacing } }) => spacing(4)};
  height: ${({ theme: { spacing } }) => spacing(5)};
  width: ${({ theme: { spacing } }) => spacing(5)};
`;

export const StyledIcon = styled(Icon)`
  width: auto;
`;
export const StyledDivider = styled(Divider)`
  height: ${({ theme: { spacing } }) => spacing(6)};
  margin: ${({ theme: { spacing } }) => spacing(0, 2)};
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.borderGrayDark};
`;

export const StyledContainer = styled.div`
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing(0, 0, 0, 1)};
  align-items: center;
`;
