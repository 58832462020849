import { rest } from 'msw';

import {
  baseUrl,
  soldProductsEndpoint,
  topReturnedGarmentEndpoint,
  topSellingGarmentEndpoint,
} from '../../constants';

import { SoldProductItem } from '../../hooks';
import { getGarments, mockProductIndex } from '../mocks';

export const getProducts = (count: number): SoldProductItem[] => {
  const garments: SoldProductItem[] = [];

  for (let i = 0; i < count; i++) {
    const garment: SoldProductItem = {
      productName: `Garment ${i + 1}`,
      productId: Math.floor(Math.random() * 10000000)
        .toString()
        .padStart(3, '0'),
      price:
        Math.random() < 0.5
          ? parseFloat((Math.random() * 1000).toFixed(2))
          : parseFloat((Math.random() * 12450).toFixed(2)),
      soldPieces: Math.floor(Math.random() * 10),
      departmentId: `${Math.floor(Math.random() * 1000000)}`,
      departmentNumber: `${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}`,
      departmentName: Math.random() < 0.33 ? 'Dividend' : Math.random() < 0.66 ? 'H&M Kids' : 'H&M Menswear',
      season: Math.random() < 0.33 ? 'Summer' : 'Winter',
      salesNet: Math.floor(Math.random() * 1000),
      mostSoldArticle: '001',
    };

    garments.push(garment);
  }

  return garments;
};

export const fetchSoldProductsPage = (addDelay = false) =>
  rest.get(`${baseUrl}${soldProductsEndpoint}/:storeId/soldProducts`, (_, res, ctx) => {
    const mockData = getProducts(324);

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchProductIndex = (addDelay = false) =>
  rest.get(`${baseUrl}${soldProductsEndpoint}/:storeId/productIndex`, (_, res, ctx) => {
    const mockData = mockProductIndex;

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchTopSellingGarmentData = (addDelay = false) =>
  rest.get(`${baseUrl}${soldProductsEndpoint}/:storeId${topSellingGarmentEndpoint}`, (_, res, ctx) => {
    const mockData = getGarments('selling');

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchTopReturningGarmentData = (addDelay = false) =>
  rest.get(`${baseUrl}${soldProductsEndpoint}/:storeId${topReturnedGarmentEndpoint}`, (_, res, ctx) => {
    const mockData = getGarments('returning');

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
