import { styled as muiStyled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import styled, { css } from 'styled-components';

type CustomPickerDayProps = {
  day: Date;
  isSelected: boolean;
  isHovered: boolean;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
};

export const StyledButtonWrapper = styled.div<{ isDropdown: boolean }>`
  ${({ isDropdown, theme: { spacing } }) =>
    !isDropdown &&
    css`
      display: flex;
      align-items: center;
      > button {
        padding: ${spacing(0, 2)};
      }
    `}
  > button {
    height: ${({ isDropdown, theme: { spacing } }) => !isDropdown && spacing(8)};
  }
`;

export const CustomPickersDay = muiStyled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.getDay() === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.getDay() === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));
