export const messageTranslation = {
  notifications: {
    key: 'messages.notification',
    defaultValue: 'Notifications',
  },
  unread: {
    key: 'messages.unread',
    defaultValue: 'You have {{unread}} unread messages.',
  },
  markAllAsRead: {
    key: 'messages.markAllAsRead',
    defaultValue: 'Mark all as read',
  },
  permission: {
    key: 'messages.permission',
    defaultValue: '<b>Your permission has been updated</b> go to Roles and Permission to inspect',
  },
  error: {
    key: 'message.error',
    defaultValue: "We can't find your message at the moment.",
  },
  noMessage: {
    key: 'message.noMessage',
    defaultValue: 'You have no messages. Enjoy that.',
  },
  markAsReadError: {
    key: 'message.markAsReadError',
    defaultValue: 'Something has gone wrong and we can’t mark all your messages as read',
  },
};
