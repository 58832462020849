import { TransactionFilterType } from '../constants';
import { PermissionType } from '../utils';

export type Transaction = {
  tillId: string;
  receiptNumber: string;
  countryCode: string;
  storeId: string;
  tillType: string;
  sequenceNumber: string;
  paymentType: string[];
  paymentCardtype: string[];
  receiptType: string;
  businessDayDate: string;
  receiptSubType: string;
  amount: string;
  Transaction: {
    beginDateTime: string;
    endDateTime: string;
    OperatorID: string;
    currencyCode: string;
  };
};

export type PageInfo = {
  currentPageNumber: number;
  pageSize: number;
  rowsPerPageOptions: number[];
  nextBusinessDayDate: string | undefined;
  countFromOldPartition: number | undefined;
  nextTransactionSubType: string | null;
};

export type TransactionFilter = Record<TransactionFilterType, { item: string; count: string }[]> | null;

export type Client = 'box';

type Role = { [key: string]: string | null };
type Roles = { [key: string]: Role };

type FeaturePermissionsType = {
  permissions: Role;
  isActive: boolean;
};

type FeaturePermissions = { [key: string]: FeaturePermissionsType };

export type RoleMatrixType = {
  id: string;
  modelKey: string;
  roleManagement: Roles;
  featurePermissions: FeaturePermissions;
};

export type RoleMatrix = {
  [key in Client]: RoleMatrixType;
};

export type Avatar = {
  url: string;
  lastUpdated: Date;
};

type Permission = {
  [key: string]: {
    permissionType: string;
    endDate?: string;
  };
};

export type AdditionalPermissions = {
  [key: string]: Permission;
};

export type Assignment = {
  suamRoleCode: string;
  locationCode: string;
  isActive: boolean;
  startDate?: string;
  endDate?: string;
  title?: string;
  managerId?: string;
  countryCode?: string;
  jobRoleCode?: string;
  categoryCode?: string;
  brandId?: number;
  brandName?: string;
  isPrimary?: boolean;
};

export type Profile = {
  upn: string;
  id: string;
  accountName?: string;
  emailAddress?: string;
  firstName: string;
  lastName: string;
  avatar?: Avatar;
  additionalPermissions?: AdditionalPermissions;
  completedOnboardingItems?: CompletedOnboardingItems;
  assignments?: Assignment[];
  readMessages?: string[];
  appRoles?: string[];
  isDeveloper?: boolean;
  settings?: {
    darkMode?: boolean;
    defaultLanguage?: string;
  };
  jobTitles?: {
    storeId: string;
    jobTitles: string[];
  }[];
};

export type Kpi = {
  name: string;
  target: number;
  locked?: boolean;
};

// Note: More fields available in store, but not used currently
export type Store = {
  id: string;
  storeId: string;
  popularName: string;
  countryCode: string;
  status: string;
  openingHours?: DefaultOpeningHours;
  openingHourExceptions?: OpeningHourExceptions | '';
  kpis?: Kpi[];
  hmRegion?: string;
  currency?: string;
  latitude?: string;
  longitude?: string;
  area?: string;
  corporateBrandId?: number;
};

export type StoreDetails = {
  storeId: string;
  name: string;
  area: string;
  longitude: string;
  latitude: string;
  address: string;
  timezone: string;
  status: string;
  corporateBrandId: number;
};

export type StoreOpeningHours = {
  day: string;
  exceptions:
    | {
        date: string;
        openingHours: {
          opens: string;
          closes: string;
        };
        isClosedAllDay: boolean;
      }
    | undefined;
  defaultOpeningHours: {
    open: {
      opens: string;
      closes: string;
    };
    exception: {
      opens: string;
      closes: string;
    };
  };
};

export type OpeningHourExceptions = {
  item: {
    date: string;
    openingHours: {
      opens: string;
      closes: string;
    };
    isClosedAllDay: boolean;
  }[];
};

export type OpeningHours = {
  open: {
    opens: string;
    closes: string;
  };
  exception: {
    opens: string;
    closes: string;
  };
};

export type DefaultOpeningHours = {
  [day: string]: OpeningHours;
};

export type Days = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export type Message = {
  id: string;
  type: MessageType;
  created: string;
  storeId?: string;
  daysUntilExpired?: string;
};

export enum MessageType {
  Permission = 'permission',
}

export type OnboardingItem = 'tour' | 'avatarCreation' | 'employeesAndRoles' | 'transactionSearch';

export type CompletedOnboardingItems = {
  items: OnboardingItem[];
  allCompleted: boolean;
};

export type OnboardingMetaData = {
  permissionRequired: { feature: string; permission: PermissionType } | null;
  imageName: string;
  includeInWidget: boolean;
};

export type OnboardingItems = {
  [key in OnboardingItem]: OnboardingMetaData;
};

export enum SalesReceiptType {
  Takt1 = 'takt1',
  Spt = 'spt',
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export type PLReportProperties = 'actuals' | 'indexVsLY' | 'indexVsBudget' | 'sob' | 'sobLY' | 'sobBudget';
