import { observable } from 'mobx';
import { userStore } from './userStore';
import { transactionStore } from './transactionStore';

export const globalStore = observable({
  userStore,
  transactionStore,
});

export type GlobalStore = typeof globalStore;
