export const feedbackTranslations = {
  title: {
    key: 'feedbackTranslations.title',
    defaultValue: 'Provide Feedback',
  },
  ratings: {
    key: 'feedbackTranslations.ratings',
    defaultValue: 'Overall Experience:',
  },
  comments: {
    key: 'feedbackTranslations.comments',
    defaultValue: 'Suggest Improvements',
  },
  errorMessage: {
    key: 'feedbackTranslations.errorMessage',
    defaultValue: 'Oops! Failed to submit feedback. Try again later!',
  },
  successMessage: {
    key: 'feedbackTranslations.successMessage',
    defaultValue: 'Thank you for your feedback!',
  },
  submitButton: {
    key: 'feedbackTranslations.submitButton',
    defaultValue: 'Submit',
  },
};
