import styled from 'styled-components';
import { BorderType } from '../header';

export const Container = styled.div<{ hasHeader?: boolean }>`
  grid-area: page;
  display: grid;
  grid-template-areas: ${({ hasHeader }) =>
    hasHeader
      ? `'navbar'
    'body'`
      : `'body'
    'body'`};
  grid-template-rows: ${({ theme: { sizing } }) =>
    `${sizing.pageHeader} calc((100vh - ${sizing.header}) - ${sizing.pageHeader})`};
`;

export const NavBar = styled.div<{ borderType: BorderType }>`
  grid-area: navbar;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundDark : color.white};
  border-bottom: ${({ theme: { color }, borderType }) => `1px ${borderType} ${color.borderGray}`};
  overflow: auto;
`;

export const Body = styled.div`
  grid-area: body;
  display: flex;
  justify-content: space-evenly;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundDark : color.baseGray};
  overflow: auto;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
