import { sha256 } from 'js-sha256';
import { dreamCatcherSecret, dreamCatcherUrl } from '../constants';

type AssetType = 'descriptiveStillLife' | 'descriptiveDetail' | 'environment' | 'logbook';

type Rendition = 'thumbnail' | 'extraSmall' | 'medium' | 'large' | 'extraLarge' | 'extraExtraLarge';

export const getArticleAssetUrl = ({
  articleId,
  assetType,
  rendition,
}: {
  articleId: string;
  assetType: AssetType;
  rendition: Rendition;
}) => {
  const assetPath = `articles/${articleId}?assetType=${assetType}&rendition=${rendition}`;
  const assetPathUpperCase = assetPath.toUpperCase();

  const auth = sha256(`${assetPathUpperCase}${dreamCatcherSecret}`).toUpperCase();

  return `${dreamCatcherUrl}/${assetPath}&auth=${auth.slice(0, 10)}`;
};
