import { FC, useEffect } from 'react';
import { Breakpoint } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Icons } from '../../atoms';
import { getTransitionComponent } from '../../../utils/styleComponents';
import { muiTheme } from '../../../theme';
import { StyledDialog, StyledIcon, Position } from './Modal.styles';

export type TransitionType = 'fade' | 'slide' | 'collapse' | 'grow' | 'zoom';

export type ModalProps = {
  isOpen: boolean;
  position?: Position;
  handleClose: () => void;
  isFullScreen?: boolean;
  isFullWidth?: boolean;
  onBackdropClick?: () => void;
  transitionType?: TransitionType;
  hideBackdrop?: boolean;
  ariaDescribedby?: string;
  maxWidthProps?: false | Breakpoint;
  setAutoClose?: number;
  hasCloseIcon?: boolean;
};

export const Modal: FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  isOpen,
  position = 'center',
  handleClose,
  setAutoClose = 0,
  transitionType,
  isFullScreen = false,
  isFullWidth = false,
  onBackdropClick,
  hideBackdrop = false,
  maxWidthProps = false,
  ariaDescribedby,
  hasCloseIcon = true,
}) => {
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const fullScreen = isMobileView || isFullScreen;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen && setAutoClose) {
      timer = setTimeout(() => {
        handleClose();
      }, setAutoClose);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  const mobileMaxWidth = 'tabletLandscape';

  return (
    <StyledDialog
      open={isOpen}
      keepMounted
      TransitionComponent={getTransitionComponent(transitionType)}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth={isMobileView ? true : isFullWidth}
      maxWidth={isMobileView ? mobileMaxWidth : maxWidthProps || false}
      onBackdropClick={onBackdropClick}
      aria-describedby={isOpen ? ariaDescribedby : ''}
      aria-labelledby=""
      hideBackdrop={hideBackdrop}
      position={position}
      data-testid="modal"
    >
      {hasCloseIcon && <StyledIcon data-testid="closeIcon" icon={Icons.CLOSE} onClick={handleClose} />}
      {children}
    </StyledDialog>
  );
};
