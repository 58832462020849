import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export type TranslationKey = {
  [key: string]: string;
};

export type Translations = {
  [language: string]: TranslationKey;
};

type TranslationContextType = {
  translations: Translations;
  setTranslations: Dispatch<SetStateAction<Translations>>;
};

// Create the context with the new type
const TranslationContext = createContext({} as TranslationContextType);

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const [translations, setTranslations] = useState<Translations>({} as Translations);

  return (
    <TranslationContext.Provider value={{ translations, setTranslations }}>{children}</TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
