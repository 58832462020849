import { rest } from 'msw';
import { baseUrl, messagesEndpoint } from '../../constants';
import { getMockMessages } from '../mocks/messages.mock';

export const fetchMessageData = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${messagesEndpoint}`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getMockMessages()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
