import { ModalProps } from '@mui/material';
import { FC } from 'react';
import { DrawerVariants, StyledDrawer } from './Drawer.styles';

export type DrawerProps = {
  position?: 'left' | 'right' | 'bottom';
  isOpen?: boolean;
  onClose?: () => void;
  variant?: DrawerVariants;
  ModalProps?: Partial<ModalProps>;
  drawerWidth?: string;
  hasBorderRadius?: boolean;
};

export const Drawer: FC<React.PropsWithChildren<DrawerProps>> = ({
  position = 'left',
  isOpen = true,
  variant = 'permanent',
  onClose,
  children,
  drawerWidth,
  hasBorderRadius = false,
}) => {
  return (
    <StyledDrawer
      data-testid="drawer"
      open={isOpen}
      variant={variant}
      anchor={position}
      onClose={onClose}
      $hasBorderRadius={hasBorderRadius}
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {children}
    </StyledDrawer>
  );
};
