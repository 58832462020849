import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Icons, Tooltip, Typography } from '../../../../../stories/atoms';
import { Colors, muiTheme } from '../../../../../theme';
import { commonTranslations } from '../../../../../translations';
import {
  BreakdownItem,
  BreakdownItemsContainer,
  ChartContainer,
  DetailsContainer,
  HeaderLeftContainer,
  HeaderRightContainer,
  IconsContainer,
  MetricContainer,
  MetricHeader,
} from './RfidApprovalsWidget.styles';
import { DifferenceChart } from './differenceChart/DifferenceChart';

export const MetricItem: FC<{
  icon: Icons;
  chartPercent: number;
  chartColor: Colors;
  titleText: string;
  pieces: number;
  descriptionText: string;
  approvalRateText: number;
  approvalRate: number;
  piecesExtraText: string;
  approvedPieces: number;
  totalPieces: number;
}> = ({
  icon,
  chartPercent,
  chartColor,
  titleText,
  pieces,
  descriptionText,
  approvalRateText,
  approvalRate,
  piecesExtraText,
  approvedPieces,
  totalPieces,
}) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const { t } = useTranslation();

  return (
    <MetricContainer isMobile={isMobile}>
      <MetricHeader>
        <HeaderLeftContainer>
          <IconsContainer>
            <Icon data-testid="thumbsDownIcon" icon={icon} size={isMobile ? 'small' : 'medium'} />

            {isMobile && (
              <Tooltip position="bottom-start" text={<Typography color="white">{descriptionText}</Typography>}>
                <Icon icon={Icons.INFO} size="small" margin={[0, 1, 0, 0]} />
              </Tooltip>
            )}
          </IconsContainer>

          <Typography type={isMobile ? 'caption' : 'button'} margin={[2, 0, 0, 0]}>
            {titleText}
          </Typography>

          <Typography margin={[1, 0, 0, 0]}>
            {pieces} {t(commonTranslations.pieces.key, commonTranslations.pieces.defaultValue)}
          </Typography>
        </HeaderLeftContainer>

        <HeaderRightContainer>
          <ChartContainer>
            <DifferenceChart percentValue={chartPercent} color={chartColor} isMobile={isMobile} />
          </ChartContainer>
        </HeaderRightContainer>
      </MetricHeader>

      <DetailsContainer>
        <Typography>{descriptionText}</Typography>
      </DetailsContainer>

      <BreakdownItemsContainer>
        <BreakdownItem>
          <Typography type="caption" margin={isMobile ? [0] : [0, 0, 2, 0]}>
            {piecesExtraText}
          </Typography>
          <Typography type={isMobile ? 'caption' : 'button'}>
            {approvedPieces}/{totalPieces}
          </Typography>
        </BreakdownItem>

        <BreakdownItem>
          <Typography type="caption" margin={isMobile ? [0] : [0, 0, 2, 0]}>
            {approvalRateText}
          </Typography>

          <Typography type={isMobile ? 'caption' : 'button'}>{approvalRate}%</Typography>
        </BreakdownItem>
      </BreakdownItemsContainer>
    </MetricContainer>
  );
};
