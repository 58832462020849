import { Typography } from '@mui/material';
import { Checkbox } from '../../../checkbox/Checkbox';
import { StyledDrawerMenuItem } from '../FilterDrawer.styles';

export type MultiSelectListOption = {
  key: string;
  title: string;
  disabled?: boolean;
};

type Props = {
  options: MultiSelectListOption[];
  selectedOptionsKeys: string[];
  onSelect: (selectedItemKeys: string[]) => void;
};

export const MultiSelectListContent = ({ options, selectedOptionsKeys, onSelect }: Props) => {
  const onOptionSelect = (key: string) => {
    const keyIndex = selectedOptionsKeys.indexOf(key);

    if (keyIndex === -1) {
      onSelect([...selectedOptionsKeys, key]);
      return;
    }

    const updatedSelectedOptionKeys = [...selectedOptionsKeys];
    updatedSelectedOptionKeys.splice(keyIndex, 1);
    onSelect(updatedSelectedOptionKeys);
  };

  return (
    <>
      {options.map((option) => {
        const { key, title, disabled = false } = option;

        const isChecked = selectedOptionsKeys.some((optionKey) => optionKey === key);

        return (
          <StyledDrawerMenuItem key={key} onClick={() => onOptionSelect(key)} disabled={disabled}>
            <Checkbox onChange={() => null} isChecked={isChecked} />
            <Typography>{title}</Typography>
          </StyledDrawerMenuItem>
        );
      })}
    </>
  );
};
