const strengthMapping = {
  low: 2,
  medium: 3,
  high: 5,
};

export const getUniqueId = ({
  strength = 'medium',
  options,
  joinBy = '-',
}: {
  strength?: 'low' | 'medium' | 'high' | 'weak';
  options?: { allowAlphabetsOnly: boolean };
  joinBy?: string;
} = {}) => {
  const getIdSegment = () =>
    Math.random()
      .toString(36)
      .replace(options?.allowAlphabetsOnly ? /[^a-z]+/g : /[^a-z0-9]+/g, '');

  return strength !== 'weak'
    ? Array.from({ length: strengthMapping[strength] })
        .map(() => getIdSegment())
        .join(joinBy)
    : getIdSegment();
};
