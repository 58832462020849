export const useLocalStorage = () => {
  const getItem = (key: string) => {
    return window.localStorage.getItem(key);
  };

  const setItem = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
  };

  const removeItem = (key: string) => {
    window.localStorage.removeItem(key);
  };

  return { getItem, setItem, removeItem };
};
