import styled from 'styled-components';
import { Typography } from '../../../../stories/atoms';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-areas:
    'info '
    'permissions'
    'timeLimitedPermissions'
    'buttons';
  padding: ${({ theme }) => theme.spacing(13)};
  row-gap: ${({ theme }) => theme.spacing(6)};
  max-width: 40vw;

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    max-width: 100vw;
  }
`;

export const ProfileData = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  > div {
    :first-child {
      min-width: ${({ theme }) => theme.spacing(25)};
      padding-right: ${({ theme }) => theme.spacing(6)};
    }
  }
`;

export const StyledInfo = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledPermissions = styled.div`
  grid-area: permissions;
`;

export const StyledTimeLimitedPermissions = styled.div`
  grid-area: timeLimitedPermissions;
`;

export const StyledBody = styled.div`
  :last-child {
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const StyledButtonWrapper = styled.div`
  grid-area: buttons;
  justify-content: center;
  display: flex;
  width: 100%;
  > button:first-child {
    margin-right: ${({ theme }) => theme.spacing(6)};
  }
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StyledPermissionsList = styled.div<{ isTimeLimitPermissions?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isTimeLimitPermissions, theme }) =>
    theme.breakpoints.down('tabletLandscape') ? '1fr' : !isTimeLimitPermissions && '1fr 1fr 1fr'};
  grid-auto-flow: ${({ isTimeLimitPermissions }) => isTimeLimitPermissions && 'row'};
  column-gap: ${({ theme }) => theme.spacing(6)};
  list-style: none;
`;

export const StyledWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledText = styled.div`
  display: flex;
  min-width: 30%;
`;
