import styled from 'styled-components';
import { Container } from '../../../stories/atoms';

export const StyledAnimationWrapper = styled.div`
  margin-top: 20vh;
`;

export const StyledContainer = styled(Container)`
  align-items: center;
`;
