import { FC, ForwardedRef, forwardRef } from 'react';
import { VendorType } from '../../../constants';
import { Colors, Sizes } from '../../../theme';
import { Spacing } from '../../../utils';
import { RotateDirection, StyledSVG } from './Icon.styles';
import IconList, { Icons } from './index';

export type IconSizes = Sizes | 'x-large';

export type IconRoles = 'checkbox' | 'radio';
export type IconProps = {
  size?: IconSizes;
  icon: Icons | VendorType;
  onClick?: () => void;
  color?: Colors;
  hoverColor?: Colors;
  role?: IconRoles;
  margin?: Spacing;
  rotate?: RotateDirection;
  rotateValue?: number;
  ref?: ForwardedRef<HTMLDivElement>;
};

export const Icon: FC<React.PropsWithChildren<IconProps>> = forwardRef<HTMLDivElement, IconProps>(
  ({ size = 'medium', icon, onClick, color, ...props }, ref) => {
    return (
      <StyledSVG
        ref={ref}
        tabIndex={onClick ? 0 : undefined}
        data-testid="icon"
        size={size}
        icon={icon}
        onClick={onClick}
        color={color}
        onKeyUp={({ key }) => {
          if (key === 'Enter' && onClick) onClick();
        }}
        {...props}
      >
        {IconList[icon]}
      </StyledSVG>
    );
  },
);
