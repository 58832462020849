import { FC, useEffect, useRef, useState } from 'react';
import { Icon, Icons } from '../../atoms';
import { Sizes, Typographies } from '../../../theme';
import { useThemeContext } from '../../../global-state/themeContext';
import {
  StyledContainer,
  StyledInputContainer,
  StyledHelperText,
  StyledPasswordToggleButton,
  StyledLabel,
} from './InputField.styles';

type InputType = 'text' | 'number' | 'password';

type InputState = 'success' | 'error' | undefined;

export type InputFieldProps = {
  type?: InputType;
  size?: Sizes | 'fullWidth';
  inputTypography?: Typographies;
  labelTypography?: Typographies;
  initialValue?: string;
  label?: string;
  helperText?: string;
  icon?: Icons;
  iconPosition?: 'left' | 'right';
  iconSize?: Sizes;
  status?: InputState;
  isDisabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  delay?: number;
  tabIndex?: number;
  minLength?: number;
  maxLength?: number;
  allowSpace?: boolean;
  validateOnEmpty?: boolean;
  onReset?: () => void;
  onChange: (value: string) => void;
};

export const InputField: FC<React.PropsWithChildren<InputFieldProps>> = ({
  initialValue = '',
  type: defaultType = 'text',
  size = 'medium',
  inputTypography = 'body2',
  labelTypography = 'body2',
  iconPosition = 'left',
  iconSize = 'small',
  allowSpace = false,
  icon,
  label,
  helperText,
  status = undefined,
  isDisabled = false,
  isRequired = false,
  placeholder,
  delay,
  tabIndex,
  minLength,
  maxLength,
  validateOnEmpty = false,
  onReset,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue);
  const [type, setType] = useState(defaultType);
  const [isFocused, setIsFocused] = useState(false);
  const [inputState, setInputState] = useState(status);
  const inputElement = useRef<HTMLInputElement>(null);

  const { mode } = useThemeContext();

  useEffect(() => {
    if (allowSpace) {
      setValue(initialValue);
    } else setValue(initialValue.trim());
  }, [initialValue]);

  useEffect(() => {
    if (!value && !validateOnEmpty) {
      setInputState(undefined);
      return;
    }

    setInputState(status);
  }, [status, value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  const handleTogglePassword = () => {
    setType((t) => (t === 'password' ? 'text' : 'password'));
  };

  const handleCrossIconClick = () => {
    setValue('');
    inputElement.current?.focus();

    if (onReset) {
      onReset();
    }
  };

  // As per fabric designs, we align cross and tick icon to the right. So we need to override the icon related props.
  const updatedIconPosition = inputState === 'success' || inputState === 'error' ? 'right' : iconPosition;
  const updatedIcon = inputState === 'error' ? Icons.CLOSE : inputState === 'success' ? Icons.CHECK_MARK : icon;

  return (
    <StyledContainer data-testid="text-input-container">
      {label && (
        <StyledLabel type={labelTypography} data-testid="input-label" isRequired={isRequired}>
          {label}
        </StyledLabel>
      )}
      <StyledInputContainer
        inputTypography={inputTypography}
        iconPosition={updatedIconPosition}
        status={inputState}
        size={size}
        className={`${isFocused ? 'is-focused' : ''} ${isDisabled ? 'is-disabled' : ''}`}
        data-testid="input-container"
      >
        {updatedIcon && (
          <Icon
            icon={updatedIcon}
            size={iconSize}
            data-testid="input-icon"
            onClick={() => inputState === 'error' && handleCrossIconClick()}
          />
        )}
        <input
          data-testid="input"
          value={value}
          onChange={(e) => (allowSpace ? setValue(e.target.value) : setValue(e.target.value.trim()))}
          type={type}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={placeholder}
          disabled={isDisabled}
          ref={inputElement}
          tabIndex={tabIndex}
          minLength={minLength}
          maxLength={maxLength}
          required={isRequired}
          aria-label={label}
        />
        {defaultType === 'password' && (
          <StyledPasswordToggleButton
            label={type === 'password' ? 'SHOW' : 'HIDE'}
            variant="text"
            onClick={handleTogglePassword}
            data-testid="toggle-password"
          />
        )}
      </StyledInputContainer>
      {helperText && (
        <StyledHelperText
          type="caption"
          isError={inputState === 'error'}
          isDarkMode={mode === 'dark'}
          data-testid="helper-text"
        >
          {helperText}
        </StyledHelperText>
      )}
    </StyledContainer>
  );
};
