import { Grow } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Icons, Typography } from '../../../../stories/atoms';
import { IconWithText } from '../../../../stories/molecules';
import { Sizes } from '../../../../theme';
import {
  NoAvatarIcon,
  StyledAvatarImage,
  StyledContainer,
  StyledHeaderContainer,
} from './AvatarPlaceholder.styles';

type AvatarPlaceholderProps = {
  header: string | ReactElement;
  subHeader?: string | ReactElement;
  icon?: Icons;
  avatarUrl?: string;
  avatarSize?: Sizes;
  isCompact?: boolean;
  hideImage?: boolean;
};

export const AvatarPlaceHolder: FC<React.PropsWithChildren<AvatarPlaceholderProps>> = ({
  header,
  subHeader,
  icon,
  avatarUrl,
  avatarSize = 'medium',
  isCompact = false,
  children,
  hideImage = false,
}) => {
  return (
    <StyledContainer isCompact={isCompact}>
      <StyledHeaderContainer>
        {icon ? (
          <div data-testid="avatar-placeholder-header-with-icon">
            <IconWithText
              data-testid="success-notification"
              iconOptions={{ icon, size: 'large', color: 'validationGreen' }}
              textOptions={{ type: 'h4' }}
              spaceBetween="medium"
              label={header}
            />
          </div>
        ) : (
          <div data-testid="avatar-placeholder-header">
            <Typography type="h4">{header}</Typography>
          </div>
        )}
        {subHeader && (
          <div data-testid="avatar-placeholder-subheader">
            <Typography>{subHeader}</Typography>
          </div>
        )}
      </StyledHeaderContainer>
      {avatarUrl ? (
        <Grow in={!!avatarUrl}>
          <div>
            <StyledAvatarImage
              data-testid="avatar"
              src={`${avatarUrl}${process.env.REACT_APP_CDN_TOKEN}`}
              avatarSize={avatarSize}
              alt="Avatar Icon"
            />
          </div>
        </Grow>
      ) : hideImage ? null : (
        <NoAvatarIcon
          data-testid="no-avatar"
          src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" // Display an empty image
          avatarSize={avatarSize}
          alt="No Icon Found"
        />
      )}
      {children}
    </StyledContainer>
  );
};
