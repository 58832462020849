import styled from 'styled-components';
import { Direction } from '../../../theme';
import { getSpacing } from '../../../utils/styleComponents';
import type { ContainerProps, Position, Space } from './Container';

const spaceMapping = {
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
};

const getJustifyContent = (direction: Direction, position?: Position, space?: Space) => {
  if (space) return spaceMapping[space];

  switch (direction) {
    case 'vertical':
    default:
      switch (position) {
        case 'top':
          return 'flex-start';
        case 'center':
          return 'center';
        case 'bottom':
          return 'flex-end';
        default:
          return false;
      }
    case 'horizontal':
      switch (position) {
        case 'left':
          return 'flex-start';
        case 'center':
          return 'center';
        case 'right':
          return 'flex-end';
        default:
          return false;
      }
  }
};

export const StyledContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  flex-wrap: ${({ wrap }) => wrap && wrap};
  justify-content: ${({ direction, position, space }) =>
    direction && (position || space) && getJustifyContent(direction, position, space)};
  margin: ${({ margin, theme: { spacing } }) => margin && getSpacing(margin, spacing)};
  padding: ${({ padding, theme: { spacing } }) => padding && getSpacing(padding, spacing)};
  height: ${({ height }) => height && height};
  width: ${({ width }) => width && width};
  background: ${({ backgroundColor, theme: { color } }) => backgroundColor && color[backgroundColor]};
  align-items: ${({ direction, position }) => direction && position && getJustifyContent(direction, position)};
`;
