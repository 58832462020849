import styled, { css } from 'styled-components';
import { Container } from '../../stories/atoms';

export const StyledScreenContainer = styled(Container)`
  height: 100vh;
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.backgroundDark};
`;

const wrapper = css`
  display: flex;
  height: 100%;
  width: 100%;
  padding: ${({ theme: { spacing } }) => spacing(5, 0)};
`;

export const StyledWrapper = styled.div`
  flex-direction: column;
  ${wrapper};
`;

export const StyledContainer = styled.div`
  flex-direction: row;
  ${wrapper};
`;

export const StyledLogoContainer = styled(Container)<{ isMobile?: boolean }>`
  padding: ${({ theme: { spacing }, isMobile }) => !isMobile && spacing(6)};
`;

export const StyledStoreCard = styled.div<{ isSelected: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  height: ${({ theme: { spacing } }) => spacing(45)};
  width: ${({ theme: { spacing } }) => spacing(50)};
  border: ${({ theme: { color } }) => `1px solid ${color.red}`};
  padding: ${({ theme: { spacing } }) => spacing(5, 3)};
  margin: ${({ theme: { spacing } }) => spacing(0, 5)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  background-color: ${({ theme: { color }, isSelected }) => isSelected && color.red};
  > div {
    color: ${({ theme: { color }, isSelected }) => isSelected && color.white};
  }

  ${({ isSelected }) =>
    !isSelected &&
    css`
      :hover {
        background-color: ${({ theme: { color } }) => color.red};
        > div {
          color: ${({ theme: { color } }) => color.white};
        }
      }
    `}
`;

export const StyledAnimationContainer = styled(Container)`
  > div {
    max-width: ${({ theme: { spacing } }) => spacing(150)};
  }
`;

export const StyledMapContainer = styled.div<{ isMobile?: boolean }>`
  height: 80vh;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '68vw')};
  padding: ${({ theme: { spacing } }) => spacing(6)};
`;

export const StyledSearchContainer = styled.div`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(4, 6)};
`;

export const StyledStoreDetails = styled.div<{ isMobile?: boolean }>`
  margin-top: ${({ theme: { spacing }, isMobile }) => !isMobile && spacing(10)};
  padding: ${({ theme: { spacing }, isMobile }) => isMobile && spacing(0, 6)};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  align-items: ${({ isMobile }) => isMobile && 'center'};
  ${({ isMobile }) => isMobile && `justify-content: space-between;`}/* Apply space-between only for mobile */
`;
