import styled from 'styled-components';
import { Paper } from '@mui/material';
import { Property } from 'csstype';
import { Colors } from '../../../theme';

export const StyledTableWrapper = styled.div<{
  columnBackgroundColor: Colors | undefined;
  rowHoverColor: Colors | undefined;
  selectedRowColor: Colors | undefined;
  useZebraStyles: boolean;
  maxHeight: string | undefined;
  fixedHeight?: string;
  noBorder?: boolean;
  whiteSpace?: Property.WhiteSpace;
}>`
  height: ${({ fixedHeight }) => fixedHeight && fixedHeight};
  .MuiTableContainer-root {
    max-height: ${({ maxHeight }) => maxHeight && maxHeight};
    height: ${({ fixedHeight }) => fixedHeight && fixedHeight};
  }

  th {
    background-color: ${({ columnBackgroundColor, theme: { color } }) =>
      columnBackgroundColor && color[columnBackgroundColor]};
    border-bottom: ${({ noBorder }) => noBorder && 'none'};
    white-space: ${({ whiteSpace }) => whiteSpace};
    > div {
      flex-wrap: ${({ whiteSpace }) => whiteSpace && 'nowrap'};
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background-color: ${({ theme: { color, palette }, useZebraStyles }) =>
          useZebraStyles && (palette.mode === 'dark' ? color.backgroundPaperDark : color.baseGray)};
      }

      &.MuiTableRow-root.MuiTableRow-hover:hover,
      &.MuiTableRow-root.MuiTableRow-hover.highlightedRow {
        background-color: ${({ rowHoverColor, theme: { color } }) => rowHoverColor && color[rowHoverColor]};
      }

      &.MuiTableRow-root.Mui-selected {
        background-color: ${({ selectedRowColor, theme: { color } }) =>
          selectedRowColor && color[selectedRowColor]};
      }
    }
    td {
      &.MuiTableCell-root {
        border-bottom: ${({ noBorder }) => noBorder && 'none'};
      }
    }
  }
`;

export const StyledPaper = styled(Paper)`
  box-shadow: none;
  height: 100%;
`;

export const StyledPaginationWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => height && height};
`;
