import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Forecast,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import {
  HourlyTurnoverPerformanceItem,
  useKpiTargetDistriubution,
  useStoreInformation,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { ContainerDrawer } from '../../../../../stories/molecules/container-drawer/ContainerDrawer';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { formatValue, getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { StyledCustomLegends } from '../../../Home.styles';
import { getBudgetAndPlannedPermissions } from '../../../common/budgetPlannedContent/BudgetPlannedContent';
import { ProgressIndicator } from '../../../common/progressIndicator/ProgressIndicator';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { TrendInfo } from '../conversionRate/ConversionRateWidget';
import BudgetSlider from './BudgetSlider';
import { StyledView, StyledWrapper } from './HourlyTurnoverWidget.styles';

type HourlyTurnoverWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const HourlyTurnoverWidget: FC<HourlyTurnoverWidgetProps> = observer(
  ({ interval, currentDate, hasPermissionToEditKpi, isToday, handleDropdownDetails, fromDate, toDate }) => {
    const {
      get: { userPermissions, kpiTargetsProps },
    } = useUser();
    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { currencyCode } = useStoreInformation();
    const { fetchHourlyTurnoverWidgetData, fetchNetSalesBudget } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [isBudgetSliderOpen, setIsBudgetSliderOpen] = useState(false);
    const [today, setToday] = useState(0);
    const [lastWeek, setLastWeek] = useState(0);
    const [lastYear, setLastYear] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<HourlyTurnoverPerformanceItem>();

    const defaultBudget = {
      budget: 0,
      planned: 0,
    };

    const [budget, setBudget] = useState({
      data: defaultBudget,
      isLoading: false,
      isError: false,
    });

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyTurnOver');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyTurnOver')?.isLocked ?? false;

    const { t, i18n } = useTranslation();
    const { hourlyTurnover, kpiTargets, budgetPlanned, common } = homeTranslations;

    const { showBudgetAndPlanned } = getBudgetAndPlannedPermissions(userPermissions);

    const itemValueResolver = (item: HourlyTurnoverPerformanceItem) => {
      return Math.floor(item.amount);
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchHourlyTurnoverWidgetData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setToday(response.data.today);
      setLastWeek(response.data.lastWeek);
      setLastYear(response.data.lastYear);
      setLastUpdated(response.data.lastUpdated);

      return response.data.netSales;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<HourlyTurnoverPerformanceItem>('netSale');

      if (isFailureResponse(response)) {
        throw new Error();
      }
      return response.data;
    }, [isToday]);

    const getTotalNetSales = (items: (HourlyTurnoverPerformanceItem | null)[]) => {
      return items.reduce((acc, val) => acc + (val ? val.amount : 0), 0);
    };

    const fetchBudget = async () => {
      setBudget({ data: defaultBudget, isLoading: true, isError: false });

      const date = format(currentDate as Date, 'yyyy-MM-dd');
      const response = await fetchNetSalesBudget(date);

      if (isFailureResponse(response)) {
        setBudget({ data: defaultBudget, isLoading: false, isError: true });
        return;
      }

      const { data } = response;

      setBudget({ data, isLoading: false, isError: false });
    };

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        valueResolver: itemValueResolver,
      });
    };

    useEffect(() => {
      fetchBudget();
    }, [currentDate, transactionEventsCount]);

    useEffect(() => {
      fetchWidgetData();
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyTurnOver',
    });

    const totalNetSales = getTotalNetSales(performanceItems);
    const forecastTotalNetSales = getTotalNetSales([...performanceItems, ...forecastPerformanceItems]);

    const isEditKPIDisabled = !hasPermissionToEditKpi;

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);
    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    const targetProgressPercent = target && target != 0 ? (forecastTotalNetSales / target) * 100 : 0;

    const getTooltipText = () => {
      let translation = '';
      if (budget.isError) {
        translation = t(homeTranslations.common.noReceipts.key, homeTranslations.common.noReceipts.defaultValue);
      }

      if (!showBudgetAndPlanned) {
        translation = t(
          budgetPlanned.noPermissionBudgetTooltip.key,
          budgetPlanned.noPermissionBudgetTooltip.defaultValue,
        );
      }

      return (
        <Container padding={[1]}>
          <Typography color="white">{translation}</Typography>
        </Container>
      );
    };
    const menuItems = [
      {
        key: target ? 'editkpi' : 'addkpi',
        disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
        hasTooltip: isEditKPIDisabled || isTargetLocked,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
      {
        key: 'showBudget',
        disabled: budget.isError || !showBudgetAndPlanned || interval !== StorePerformanceInterval.DAILY, // Show budget only for daily interval until BE adds support for it.
        hasTooltip: budget.isError || !showBudgetAndPlanned || interval !== StorePerformanceInterval.DAILY,
        tooltipText: getTooltipText(),
        item: (
          <Typography margin={[0, 2, 0]}>
            {t(budgetPlanned.budgetDropdown.key, budgetPlanned.budgetDropdown.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <ContainerDrawer
        isOpen={isBudgetSliderOpen}
        onClose={() => setIsBudgetSliderOpen(false)}
        sliderContent={
          <BudgetSlider
            budgetData={{
              clippedHourlyTurnoverItems: performanceItems,
              netSaleBudget: budget.data.budget,
              netSalePlanned: budget.data.planned,
              isNoHourlyTurnoverData: isNoData,
            }}
          />
        }
        width="250px"
      >
        <WidgetCardContent
          headerIcon={Icons.SALESEFFICIENCY}
          headerText={t(hourlyTurnover.title.key, hourlyTurnover.title.defaultValue)}
          subHeaderText={t(hourlyTurnover.subTitle.key, hourlyTurnover.subTitle.defaultValue)}
          lastUpdatedDate={lastUpdated}
          isLoading={performanceItemsLoading || budget.isLoading}
          isError={performanceItemsError}
          errorText={t(hourlyTurnover.error.key, hourlyTurnover.error.defaultValue)}
          errorRetryHandler={() => {
            fetchBudget();
            fetchWidgetData();
          }}
          isNoData={isNoData}
          headerSideContent={
            <StyledWrapper>
              <DropdownMenu
                data-testid="kpiDropdown"
                dropdownLabel={''}
                buttonContentProps={{
                  iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
                }}
                menuItems={menuItems}
                minWidth={'0px'}
                isCompact
                initialSelected={[]}
                onSelect={(selectedItems) => {
                  if ((selectedItems[0] === 'editkpi' || selectedItems[0] === 'addkpi') && !isEditKPIDisabled) {
                    handleDropdownDetails('dailyTurnOver', selectedItems[0] as KpiDropdownActions);
                  } else if (selectedItems[0] === 'showBudget') {
                    setIsBudgetSliderOpen(true);
                  }
                }}
                isOnlyIcon
                allowTooltip
              />
            </StyledWrapper>
          }
          content={
            <>
              <Container wrap="nowrap" data-testid="hourly-turnover-wrapper" height="100%">
                <StyledView isMobile={isMobile}>
                  {showForecast && (
                    <ProgressIndicator
                      valueType={Forecast.CURRENCY}
                      value={forecastTotalNetSales}
                      progressPercentage={targetProgressPercent}
                      padding={[1, 2]}
                      margin={[1, 2, 2]}
                    />
                  )}
                  <Container direction="horizontal" space="between" width="100%" padding={[0, 3]}>
                    <Typography>
                      {t(hourlyTurnover.totalSales.key, hourlyTurnover.totalSales.defaultValue)}:{' '}
                      {`${formatValue(totalNetSales, {
                        locale: i18n.language,
                      })} ${currencyCode}`}
                    </Typography>
                    {target && (
                      <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[0]}>
                        {t(common.targetKPIHourly.key, common.targetKPIHourly.defaultValue)} {target}
                      </StyledCustomLegends>
                    )}
                  </Container>
                  <Container margin={[1, 0, 0]} padding={[0, 3]} direction="horizontal" position="right">
                    <TrendInfo
                      isMobile={isMobile}
                      timeFrame="lastWeek"
                      today={today}
                      previousAmount={lastWeek}
                      valueFormatter={(value) => Math.round(value).toLocaleString().toString()}
                      common={common}
                    />
                    <TrendInfo
                      isMobile={isMobile}
                      timeFrame="lastYear"
                      today={today}
                      previousAmount={lastYear}
                      valueFormatter={(value) => Math.round(value).toLocaleString().toString()}
                      common={common}
                    />
                  </Container>
                  <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                    <WidgetChart
                      performanceItemsChartName={t(
                        hourlyTurnover.subTitle.key,
                        hourlyTurnover.subTitle.defaultValue,
                      )}
                      performanceItems={performanceItems}
                      filteredPerformanceItems={filteredPerformanceItems}
                      forecastPerformanceItems={forecastPerformanceItems}
                      targetItems={targetItems}
                      itemValueResolver={itemValueResolver}
                      showXAxis={true}
                      yAxisFormatter={(value) =>
                        `${Intl.NumberFormat(i18n.language, { notation: 'compact' }).format(value)}`
                      }
                      tooltipFormatter={(value) =>
                        `${formatValue(value, {
                          locale: i18n.language,
                        })} ${currencyCode}`
                      }
                    />
                  </Container>
                </StyledView>
              </Container>
            </>
          }
          toolTipText={t(hourlyTurnover.toolTip.key, hourlyTurnover.toolTip.defaultValue)}
        />
      </ContainerDrawer>
    );
  },
);
