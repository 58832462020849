import styled from 'styled-components';
import { getSpacing } from '../../../utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: ${({ theme: { spacing } }) => spacing(6)};
  padding: ${({ theme: { spacing } }) => getSpacing([6, 0, 10], spacing)};
`;
