import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 0;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;

export const MetricHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderRightContainer = styled.div``;

export const IconsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ChartContainer = styled.div`
  width: ${({ theme: { spacing } }) => spacing(22)};
  height: ${({ theme: { spacing } }) => spacing(22)};
`;

export const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BreakdownItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;

export const BreakdownItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color } }) => color.backgroundSelectedDark};
`;

export const MetricContainer = styled.div<{ isMobile: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === 'dark' ? theme.color.borderGrayDark : theme.color.borderGray)};
  box-sizing: border-box;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  border-radius: ${({ theme: { spacing } }) => spacing(5)};

  ${({ isMobile, theme: { spacing } }) =>
    isMobile &&
    `
    padding: ${spacing(4, 4)};

    ${MetricHeader} {
      flex-direction: column;
      flex: 1;
    }

    ${HeaderRightContainer} {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    ${ChartContainer} {
      width: ${spacing(30)};
      height: ${spacing(30)};
    }

    ${DetailsContainer} {
      display: none;
    }

    ${BreakdownItemsContainer} {
      flex-direction: column;
      gap: ${spacing(4)};
    }

    ${BreakdownItem} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: ${spacing(0)};
      background-color: unset;
    }
    `}
`;
