import { useForkRef } from '@mui/material';
import {
  DateRange,
  FieldType,
  LocalizationProvider,
  DateRangePicker as MUIDateRangePicker,
  SingleInputDateRangeFieldProps,
  StaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transactionSearchTranslations } from '../../../translations';
import { Icons } from '../../atoms';
import { Button } from '../button/Button';
import { StyledContainer, StyledPickersLayout } from './DateRangePicker.styles';

interface DateRangeButtonFieldProps extends SingleInputDateRangeFieldProps<Date> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

type DateRangeButtonFieldComponent = ((
  props: DateRangeButtonFieldProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { fieldType?: FieldType };

export const DateRangeButtonField = React.forwardRef(
  (props: DateRangeButtonFieldProps, ref: React.Ref<HTMLElement>) => {
    const { t } = useTranslation();
    const {
      setOpen,
      open,
      id,
      InputProps: { ref: containerRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <Button
        data-testid="date-picker-button"
        label={t(
          transactionSearchTranslations.datePicker.key,
          transactionSearchTranslations.datePicker.defaultValue,
        )}
        onClick={() => setOpen?.((prev) => !prev)}
        buttonContentProps={{
          labelPosition: 'left',
          iconOptions: {
            icon: Icons.DROPDOWN,
            size: 'small',
            ...(open ? { rotate: 'up' } : {}),
          },
        }}
        variant="text"
        ref={handleRef}
        aria-label={ariaLabel}
        id={id}
      />
    );
  },
) as DateRangeButtonFieldComponent;

DateRangeButtonField.fieldType = 'single-input';

export type DateRangePickerProps = {
  disableFutureDates: boolean;
  inputSelectedDates: DateRange<Date>;
  onAccept: (dates: [Date, Date]) => void;
  onChange?: (dates: [Date, Date]) => void;
  type?: 'overlay' | 'integrated';
  isOpen?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onClose?: () => void;
};

export const DateRangePicker: FC<React.PropsWithChildren<DateRangePickerProps>> = ({
  disableFutureDates = true,
  inputSelectedDates,
  onAccept,
  onChange,
  onClose,
  minDate,
  maxDate,
  type = 'overlay',
  isOpen = false,
}) => {
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>(inputSelectedDates);
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setSelectedDate(inputSelectedDates);
  }, [inputSelectedDates]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {type === 'overlay' ? (
        <MUIDateRangePicker
          disableAutoMonthSwitching
          calendars={1}
          open={open}
          disableFuture={disableFutureDates}
          value={selectedDate}
          onAccept={(date) => {
            setSelectedDate(date);
            setOpen(false);
            if (onAccept) onAccept(date as [Date, Date]);
          }}
          minDate={minDate}
          maxDate={maxDate}
          onClose={() => {
            if (onClose) onClose();
            setOpen(false);
          }}
          onChange={(date) => {
            if (onChange) onChange(date as [Date, Date]);
          }}
          slotProps={{
            field: { setOpen, open } as never,
          }}
          slots={{ field: DateRangeButtonField, layout: StyledPickersLayout }}
          displayWeekNumber
        />
      ) : (
        <StyledContainer data-testid="static-date-range-picker">
          <StaticDateRangePicker
            disableAutoMonthSwitching
            displayStaticWrapperAs="desktop"
            disableFuture={disableFutureDates}
            calendars={1}
            value={selectedDate}
            onAccept={(date) => {
              setSelectedDate(date);
              setOpen(false);
              if (onAccept) onAccept(date as [Date, Date]);
              if (onClose) onClose();
            }}
            onChange={(date) => {
              if (onChange) onChange(date as [Date, Date]);
            }}
            minDate={minDate}
            maxDate={maxDate}
            displayWeekNumber
            slots={{ layout: StyledPickersLayout }}
          />
        </StyledContainer>
      )}
    </LocalizationProvider>
  );
};
