import { FC, useEffect, useState } from 'react';
import { BannerItem, BannerItemsType } from './banner-item/BannerItem';
import { StyledWrapper } from './Banner.styles';

export type BannerProps = {
  items: BannerItemsType[];
  onChange: (items: BannerItemsType[]) => void;
  isSticky?: boolean;
};

export const Banner: FC<BannerProps> = ({ items, onChange, isSticky = false }) => {
  const [idsMarkedForClose, setIdsMarkedForClose] = useState<string[]>([]);

  useEffect(() => {
    onChange(items.filter(({ id }) => !idsMarkedForClose.includes(id)));
  }, [idsMarkedForClose]);

  const handleClose = (id: string) => {
    setIdsMarkedForClose((previousIds) => [...previousIds, id]);
  };

  return (
    <StyledWrapper data-testid="banner-wrapper" isSticky={isSticky}>
      {items.map((props) => (
        <BannerItem key={props.id} onClose={handleClose} {...props} />
      ))}
    </StyledWrapper>
  );
};
