import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaComparisonItem } from '../../../../../../hooks';
import { Container, Typography } from '../../../../../../stories/atoms';
import { homeTranslations } from '../../../../../../translations';
import { formatAmountWithCurrency } from '../../../../../../utils';
import { MetricsTranslationMapping } from '../AreaComparison';
import { StyledSection } from '../AreaComparison.styles';

export type AreaComparisonCardProps = {
  areaComparisonDetail: AreaComparisonItem;
  view: string;
};

const AreaComparisonCard: FC<AreaComparisonCardProps> = ({ areaComparisonDetail, view }) => {
  const { t, i18n } = useTranslation();
  const { common, areaComparison: areaComparisonTranslations } = homeTranslations;

  const calculateIndexValue = (originalValue: number, comparisonValue: number) => {
    if (comparisonValue === 0 || originalValue === 0) {
      return 0;
    }

    const percentageChange = ((originalValue - comparisonValue) / comparisonValue) * 100;
    const indexValue = percentageChange < 0 ? 100 - Math.abs(percentageChange) : 100 + percentageChange;
    return parseFloat(indexValue.toFixed(1));
  };

  const getIndexValue = () => {
    const originalValue = areaComparisonDetail.today ?? 0;
    const lastWeekValue = areaComparisonDetail.lastWeek;
    const lastYearValue = areaComparisonDetail.lastYear;
    const lastWeekAreaValue = areaComparisonDetail.lastWeekArea;
    const lastYearAreaValue = areaComparisonDetail.lastYearArea;

    const lastWeekIndexValue = calculateIndexValue(originalValue, lastWeekValue);
    const lastYearIndexValue = calculateIndexValue(originalValue, lastYearValue);
    const lastWeekAreaIndexValue = calculateIndexValue(originalValue, lastWeekAreaValue);
    const lastYearAreaIndexValue = calculateIndexValue(originalValue, lastYearAreaValue);

    return {
      lastWeekIndexValue,
      lastYearIndexValue,
      lastWeekAreaIndexValue,
      lastYearAreaIndexValue,
    };
  };

  const { lastWeekIndexValue, lastYearIndexValue, lastWeekAreaIndexValue, lastYearAreaIndexValue } =
    getIndexValue();

  const getColor = (value: number) => {
    if (value === 0) {
      return 'red';
    }
    return areaComparisonDetail.today && areaComparisonDetail.today > value ? 'validationGreen' : 'red';
  };

  return (
    <StyledSection key={areaComparisonDetail.name} data-testid="area-comparison-card-wrapper">
      <Typography color="textGray" textAlign="start" type="caption" padding={[0, 1, 2]}>
        {t(
          areaComparisonTranslations.equationGroup[
            MetricsTranslationMapping[areaComparisonDetail.name].equationGroup
          ].key,
          areaComparisonTranslations.equationGroup[
            MetricsTranslationMapping[areaComparisonDetail.name].equationGroup
          ].defaultValue,
        )}
      </Typography>

      <Container direction="horizontal">
        <Typography type="button" padding={[0, 1, 0]}>
          {t(
            MetricsTranslationMapping[areaComparisonDetail.name].translation.key,
            MetricsTranslationMapping[areaComparisonDetail.name].translation.defaultValue,
          )}
        </Typography>
        <Typography type="button">
          -{' '}
          {formatAmountWithCurrency(
            areaComparisonDetail.today ? areaComparisonDetail.today : 0,
            i18n.language,
            '',
          )}
        </Typography>
      </Container>

      <Typography textAlign="end" padding={[0, 2]}>
        {t(areaComparisonTranslations.title.key, areaComparisonTranslations.title.defaultValue)}
      </Typography>

      <Container direction="horizontal" wrap="nowrap" padding={[1]} space="between">
        <Typography color="textGray">{t(common.lastWeek.key, common.lastWeek.defaultValue)}</Typography>
        <Typography color={getColor(areaComparisonDetail.lastWeek)}>
          {view === 'view2'
            ? lastWeekIndexValue
            : formatAmountWithCurrency(areaComparisonDetail.lastWeek, i18n.language, '')}
        </Typography>
        <Typography color={getColor(areaComparisonDetail.lastWeekArea)}>
          {view === 'view2'
            ? lastWeekAreaIndexValue
            : formatAmountWithCurrency(areaComparisonDetail.lastWeekArea, i18n.language, '')}
        </Typography>
      </Container>

      <Container direction="horizontal" wrap="nowrap" padding={[0, 1]} space="between">
        <Typography color="textGray">{t(common.lastYear.key, common.lastYear.defaultValue)}</Typography>
        <Typography color={getColor(areaComparisonDetail.lastYear)}>
          {view === 'view2'
            ? lastYearIndexValue
            : formatAmountWithCurrency(areaComparisonDetail.lastYear, i18n.language, '')}
        </Typography>
        <Typography color={getColor(areaComparisonDetail.lastYearArea)}>
          {view === 'view2'
            ? lastYearAreaIndexValue
            : formatAmountWithCurrency(areaComparisonDetail.lastYearArea, i18n.language, '')}
        </Typography>
      </Container>
    </StyledSection>
  );
};

export default AreaComparisonCard;
