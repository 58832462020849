import type { Spacing } from '@mui/system';
import { Collapse, Fade, Grow, Slide, Zoom } from '@mui/material';

export const getSpacing = (spacing: [number, number?, number?, number?], themeSpacing: Spacing) => {
  switch (spacing.length) {
    case 1:
    default:
      return themeSpacing(spacing[0]);
    case 2:
      return themeSpacing(spacing[0], spacing[1] as number);
    case 3:
      return themeSpacing(spacing[0], spacing[1] as number, spacing[2] as number);
    case 4:
      return themeSpacing(spacing[0], spacing[1] as number, spacing[2] as number, spacing[3] as number);
  }
};

export const getTransitionComponent = (transitionType: string | undefined) => {
  switch (transitionType) {
    case 'slide':
      return Slide;
    case 'collapse':
      return Collapse;
    case 'grow':
      return Grow;
    case 'zoom':
      return Zoom;
    case 'fade':
    default:
      return Fade;
  }
};
