import styled from 'styled-components';
import { Typography } from '../../../../stories/atoms';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'content'
    'content'
    'buttons';
  place-items: center;
  padding: ${({ theme }) => theme.spacing(10, 10)};
  min-width: 40vw;
  width: 50vw;

  ${({ theme: { breakpoints } }) => breakpoints.down('tabletLandscape')} {
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing(18)};
  }
`;

export const StyledHeader = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  grid-area: header;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const StyledHeaderBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 85%;
`;

export const StyledTypography = styled(Typography)`
  display: flex;
  line-height: 1.2;
`;
