import styled from 'styled-components';
import { Container } from '../../../stories/atoms';
import { Spacing, getSpacing } from '../../../utils';
import { Button, IconWithText } from '../../../stories/molecules';

export const StyledContainer = styled(Container)<{ isDesktop?: boolean }>`
  width: ${({ theme: { spacing }, isDesktop }) => (isDesktop ? spacing(150) : '')};
  padding: ${({ theme: { spacing } }) => spacing(8)};
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(0, 2)};
`;

export const SetKPIContainer = styled.div`
  display: flex;
`;

export const InputContainer = styled.div<{ margin: Spacing }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ margin, theme: { spacing } }) => margin && getSpacing(margin, spacing)};
  > :nth-child(1) {
    margin-bottom: ${({ theme: { spacing } }) => spacing(3)};
  }
`;

export const StyledKPIAccordionContainer = styled.div<{ isTablet: boolean }>`
  min-height: ${({ isTablet }) => (isTablet ? 'calc(100vh - max(240px, 5vh))' : '50vh')};
  max-height: ${({ isTablet }) => (isTablet ? 'calc(100vh - max(240px, 5vh))' : '50vh')};
  overflow: auto;
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
`;

export const StyledIconWithText = styled(IconWithText)<{ shouldRotate: boolean }>`
  & > :nth-child(1) {
    transform: ${({ shouldRotate }) => shouldRotate && 'rotate(45deg)'};
  }
`;
