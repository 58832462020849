import { Slide, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../assets/articlePlaceholder.png';
import { useThemeContext } from '../../../global-state/themeContext';
import { SoldProductItem } from '../../../hooks';
import { Drawer, Icon, Icons, Typography } from '../../../stories/atoms';
import { DynamicTab, TabLabelProps } from '../../../stories/molecules';
import { Typographies, muiTheme } from '../../../theme';
import { soldProductsTranslations } from '../../../translations';
import { Spacing } from '../../../utils';
import { getArticleAssetUrl } from '../../../utils/soldProducts';
import {
  SliderWrapper,
  StyledAboutInfo,
  StyledDetailsCard,
  StyledDetailsProperty,
  StyledDetailsView,
  StyledProductImage,
  StyledWrapper,
} from './ProductDetailsSlider.styles';

const { productDetailsView, departmentId, season, salesNet, piecesSold, productId } = soldProductsTranslations;

export type ProductDetailsSliderProps = {
  isVisible: boolean;
  productDetails: SoldProductItem;
  onClose: () => void;
};

export const ProductDetailsSlider: FC<React.PropsWithChildren<ProductDetailsSliderProps>> = observer(
  ({ isVisible, onClose, productDetails }) => {
    const [shouldOpen, setShouldOpen] = useState(isVisible);
    const [tabIndex, setTabIndex] = useState(0);

    const { t } = useTranslation();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
    const { mode } = useThemeContext();

    useEffect(() => {
      setShouldOpen(isVisible);
    }, [isVisible]);

    const getTabs = (): TabLabelProps[] => {
      return [
        {
          tabName: t(productDetailsView.about.key, productDetailsView.about.defaultValue),
          isTabVisible: true,
          labelType: 'body3' as Typographies,
        },
        {
          tabName: t(productDetailsView.details.key, productDetailsView.details.defaultValue),
          isTabVisible: true,
          labelType: 'body3' as Typographies,
          labelMargin: [0] as Spacing,
          isTabDisabled: true,
        },
        {
          tabName: t(productDetailsView.materials.key, productDetailsView.materials.defaultValue),
          isTabVisible: true,
          labelType: 'body3' as Typographies,
          labelMargin: [0] as Spacing,
          isTabDisabled: true,
        },
      ];
    };

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const handleClose = () => {
      setShouldOpen(false);
      if (onClose) onClose();
    };

    if (!productDetails) {
      return null; // Render nothing if product details are not available
    }

    const productProperties = [
      {
        label: t(productDetailsView.department.key, productDetailsView.department.defaultValue),
        value: productDetails.departmentName,
      },
      { label: t(departmentId.key, departmentId.defaultValue), value: productDetails.departmentNumber },
      { label: t(season.key, season.defaultValue), value: productDetails.season },
      { label: t(piecesSold.key, piecesSold.defaultValue), value: productDetails.soldPieces },
      { label: t(salesNet.key, salesNet.defaultValue), value: productDetails.salesNet.toFixed(2) },
    ];

    const AboutTabView = () => {
      const imageUrl = getArticleAssetUrl({
        articleId: `${productDetails.productId}${productDetails.mostSoldArticle}`,
        assetType: 'descriptiveStillLife',
        rendition: 'large',
      });

      return (
        <div data-testid="aboutView">
          <StyledProductImage
            data-testid="product-image"
            src={imageUrl}
            alt=""
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = articlePlaceholder;
            }}
          />
          <StyledAboutInfo>
            <div>
              <Typography type="body3" margin={[0, 0, 2, 0]}>
                {productDetails.productName}
              </Typography>
              <Typography type="body3">{productDetails.price}</Typography>
            </div>

            <Typography textAlign="left" color="textGray" type="legal">
              {t(productId.key, productId.defaultValue)}: {productDetails.productId}
            </Typography>
          </StyledAboutInfo>

          <StyledDetailsCard data-testid="details-card">
            {productProperties.map((property, index) => (
              <StyledDetailsProperty key={index}>
                <Typography>{property.label}</Typography>
                {property.value}
              </StyledDetailsProperty>
            ))}
          </StyledDetailsCard>
        </div>
      );
    };

    const ProductDetailsView = () => {
      return (
        <SliderWrapper>
          <StyledWrapper>
            <div>
              <Typography type="h3">
                {t(productDetailsView.title.key, productDetailsView.title.defaultValue)}
              </Typography>
              {/* <StyledSubheaderWrapper>
                <Badge backgroundColor={stockAvailable ? 'successLight' : 'errorLight'}>
                  <Typography type="body3" color={stockAvailable ? 'validationGreen' : 'red'}>
                    {stockAvailable
                      ? t(inStock.key, inStock.defaultValue)
                      : t(outOfStock.key, outOfStock.defaultValue)}
                  </Typography>
                </Badge>
                <Typography>{remainingPieces} pcs left</Typography>
              </StyledSubheaderWrapper> */}
            </div>
            <Icon data-testid="closeIcon" icon={Icons.CLOSE} onClick={handleClose} />
          </StyledWrapper>

          <StyledDetailsView data-testid="product-details-view">
            <DynamicTab
              tabLabelArray={getTabs()}
              handleTabChange={handleTabChange}
              tabValue={tabIndex}
              variant="scrollable"
              tabBgColor={mode === 'light' ? 'white' : 'inherit'}
              content={[
                {
                  tabContent: <AboutTabView />,
                  visible: true,
                },
              ]}
            />
          </StyledDetailsView>
        </SliderWrapper>
      );
    };

    return (
      <>
        {isMobile ? (
          <Drawer
            variant="temporary"
            position="right"
            isOpen={shouldOpen}
            onClose={handleClose}
            drawerWidth={muiTheme.spacing(90)}
          >
            <ProductDetailsView />
          </Drawer>
        ) : (
          <Slide
            in={shouldOpen}
            direction="left"
            timeout={300}
            data-testid="slider-view"
            mountOnEnter
            unmountOnExit
            style={{ height: '100%' }}
          >
            <div>
              <ProductDetailsView />
            </div>
          </Slide>
        )}
      </>
    );
  },
);
