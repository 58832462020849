import styled, { css, keyframes } from 'styled-components';
import { Colors, Sizes } from '../../../theme';

type SpinnerSizeObject = {
  height: string;
  width: string;
};

type SpinnerSize = { [key in Sizes]: SpinnerSizeObject };

const spinnerSize: SpinnerSize = {
  small: {
    height: '25px',
    width: '25px',
  },
  medium: {
    height: '50px',
    width: '50px',
  },
  large: {
    height: '100px',
    width: '100px',
  },
};

const rotate = keyframes`
 0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
`;

export const StyledSpinner = styled.div<{
  color?: Colors;
}>`
  margin: 50px auto;
  width: auto;
  height: auto;
  text-align: center;

  transform: skew(-22.5deg, 0);
  > div {
    background-color: ${({ theme: { color: themeColor }, color }) => color && themeColor[color]};
  }
`;

const skewedBar = css`
  width: 7px;
  margin: 0 3px 0 0;
  display: inline-block;
  animation: ${rotate} 1.2s infinite ease-in-out;
`;

export const Rect1 = styled.div<{
  size?: Sizes;
}>`
  height: ${({ size }) => size && spinnerSize[size].height};
  ${skewedBar};
`;

export const Rect2 = styled.div<{
  size?: Sizes;
}>`
  height: ${({ size }) => size && spinnerSize[size].height};
  ${skewedBar};
  animation-delay: -1.1s;
`;

export const Rect3 = styled.div<{
  size?: Sizes;
}>`
  height: ${({ size }) => size && spinnerSize[size].height};
  ${skewedBar};
  animation-delay: -1s;
`;

export const Rect4 = styled.div<{
  size?: Sizes;
}>`
  height: ${({ size }) => size && spinnerSize[size].height};
  ${skewedBar};
  animation-delay: -0.9s;
`;

export const Rect5 = styled.div<{
  size?: Sizes;
}>`
  height: ${({ size }) => size && spinnerSize[size].height};
  ${skewedBar};
  animation-delay: -0.8s;
`;

const ring = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledRingSpinner = styled.div<{
  color?: Colors;
  size?: Sizes;
}>`
  width: auto;
  height: auto;
  margin: 50px auto;
  text-align: center;

  :after {
    content: ' ';
    display: block;
    height: ${({ size }) => size && spinnerSize[size].height};
    width: ${({ size }) => size && spinnerSize[size].height};
    border-radius: 50%;
    border: 6px solid ${({ theme: { color: themeColor }, color }) => color && themeColor[color]};
    border-color: ${({ theme: { color: themeColor }, color }) => color && themeColor[color]} transparent
      ${({ theme: { color: themeColor }, color }) => color && themeColor[color]} transparent;
    animation: ${ring} 1.2s linear infinite;
  }
`;

export const StyledBox = styled.div`
  margin: 50px auto;
  width: auto;
  height: auto;
  text-align: center;
`;

export const boxRotate = keyframes`
 0% {  
   transform-origin: bottom left;
    transform: translate(-5px, -105px) rotate(0deg) scale(1.1);
  }
  
  25% { 
    transform-origin: bottom left;
    transform: translate(-5px, -105px) rotate(-180deg) scale(1.1);
  }
  
  26% {
    transform-origin: top left;
    transform: translate(-5px, 105px) rotate(-180deg) scale(1.1);
  }
   
  50% {
    transform-origin: top left;
    transform: translate(-5px, 105px) rotate(-360deg) scale(1.1);
  }
    
  51% { 
    transform-origin: top right;
    transform: translate(5px, 105px) rotate(-360deg) scale(1.1);
  }
   
  75% { 
    transform-origin: top right;
    transform: translate(5px, 105px) rotate(-540deg) scale(1.1);
  }
   
  76% { 
    transform-origin: bottom right;
    transform: translate(5px, -105px) rotate(-540deg) scale(1.1)
  }

  100% {
    transform-origin: bottom right
    transform: translate(5px, -105px) rotate(-720deg) scale(1.1);
  }
  
`;

export const Loader = styled.div<{
  color?: Colors;
  size?: Sizes;
}>`
  height: ${({ size }) => size && spinnerSize[size].height};
  width: ${({ size }) => size && spinnerSize[size].height};
  background-color: ${({ theme: { color: themeColor }, color }) => color && themeColor[color]};
  outline: 6px solid ${({ theme: { color: themeColor }, color }) => color && themeColor[color]};
  outline-offset: -1px;
  transform: rotate(45deg) scale(0.5);

  :before {
    content: '';
    display: block;
    height: ${({ size }) => size && spinnerSize[size].height};
    width: ${({ size }) => size && spinnerSize[size].height};
    background-color: ${({ theme: { color: themeColor }, color }) => color && themeColor[color]};
    animation: ${boxRotate} 3s infinite;
  }
`;

export const OverLaySpinner = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${({ theme: { zIndex } }) => zIndex.modal};
  background: rgba(212, 207, 207, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;
