import { format, intlFormat } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval } from '../../../../../constants';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { SortDirection } from '../../../../../global-state/types';
import { Status, Tills, useAsyncAction, useStoreInformation, useStores } from '../../../../../hooks';
import { Badge, Container, Icon, Icons, Tooltip, Typography } from '../../../../../stories/atoms';
import { DynamicTab, IconPositionType, Row, Table } from '../../../../../stories/molecules';
import { Colors } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { Spacing, formatAmountWithCurrency, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { StyledTillView } from './AssetManagement.styles';

const statusMapping: { [key in Status]: { key: string; defaultValue: string } } = {
  OK: homeTranslations.assetManagement.active,
  WARNING: homeTranslations.assetManagement.active,
  UNKNOWN: homeTranslations.assetManagement.offline,
  CLOSED: homeTranslations.assetManagement.closed,
};

type BackgroundColor = { [key in Status]: Colors };

const backgroundColorMapping: BackgroundColor = {
  OK: 'validationGreen',
  WARNING: 'validationGreen',
  UNKNOWN: 'red',
  CLOSED: 'primary',
};

type AssetManagementWidgetProps = {
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

export const AssetManagementWidget: FC<AssetManagementWidgetProps> = observer(({ currentDate, interval }) => {
  const { t, i18n } = useTranslation();
  const { currencyCode } = useStoreInformation();

  const { assetManagement } = homeTranslations;

  const [allRows, setAllRows] = useState<Row[]>([]);
  const [activeRows, setActiveRows] = useState<Row[]>([]);
  const [closedRows, setClosedRows] = useState<Row[]>([]);
  const [offlineRows, setOfflineRows] = useState<Row[]>([]);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [tabIndex, setTabIndex] = useState(0);

  const {
    result: assetManagementData,
    isLoading: assetManagementLoading,
    isError: assetManagementError,
    triggerAction: triggerFetchAssetManagementData,
  } = useAsyncAction<Tills[]>();

  const { fetchAssetManagementWidgetData } = useStores();
  const { mode } = useThemeContext();

  const fetchAssetManagementData = async () => {
    const response = await fetchAssetManagementWidgetData(format(currentDate, 'yyyy-MM-dd'));

    if (isFailureResponse(response)) {
      throw new Error();
    }

    setLastUpdated(response.data.lastUpdated);

    return response.data.tills;
  };

  const allWorkStations = assetManagementData;
  const activeWorksStations = assetManagementData
    ? assetManagementData.filter((row) => row.status === 'OK' || row.status === 'WARNING')
    : [];
  const closedWorkStations = assetManagementData
    ? assetManagementData.filter((row) => row.status === 'CLOSED')
    : [];
  const offlineWorkStations = assetManagementData
    ? assetManagementData.filter((row) => row.status === 'UNKNOWN')
    : [];

  const sortAssetManagementData = (property: keyof Tills, order: SortDirection) => {
    if (property === 'totalAmount') {
      return (a: Tills, b: Tills) => {
        if (a[property] < b[property]) return order === 'asc' ? -1 : 1;
        if (a[property] > b[property]) return order === 'asc' ? 1 : -1;
        return 0;
      };
    } else if (property === 'latestTransaction') {
      return (a: Tills, b: Tills) => {
        if (a.latestTransaction && b.latestTransaction) {
          if (Date.parse(a.latestTransaction.timestamp) < Date.parse(b.latestTransaction.timestamp))
            return order === 'asc' ? -1 : 1;
          if (Date.parse(a.latestTransaction.timestamp) > Date.parse(b.latestTransaction.timestamp))
            return order === 'asc' ? 1 : -1;
          return 0;
        }
        return 0;
      };
    }
  };

  const getUpdatedRows = (rowData: Tills[]) => {
    return rowData.map(({ name, latestTransaction, status, amounts }, index) => {
      return {
        id: index,
        name: <Typography type="body3">{name}</Typography>,
        latestTransaction:
          !latestTransaction || latestTransaction.timestamp === '' ? (
            <>-</>
          ) : (
            <>
              <Typography type="body3" margin={[0, 0, 1]}>
                {intlFormat(
                  new Date(latestTransaction.timestamp),
                  { hour: 'numeric', minute: 'numeric' },
                  { locale: i18n.language },
                )}
              </Typography>
              <Typography type="legal" color="secondaryTextGray">
                {latestTransaction.type}
              </Typography>
            </>
          ),
        totalAmount: (
          <Typography type="body3">
            {amounts.map(({ amount }, idx) => {
              const formattedValue = formatAmountWithCurrency(amount, i18n.language, currencyCode);

              if (idx !== amounts.length - 1) {
                return formattedValue + ', ';
              }

              return formattedValue;
            })}
          </Typography>
        ),

        status: (
          <Badge width="fit-content" backgroundColor={backgroundColorMapping[status]}>
            <Typography color="white" type="body3">
              {t(statusMapping[status].key, statusMapping[status].defaultValue)}
            </Typography>
            {status === 'WARNING' && (
              <Tooltip
                position="top"
                text={
                  <Container padding={[2]}>
                    <Typography color="white">
                      {t(assetManagement.warningTooltip.key, assetManagement.warningTooltip.defaultValue)}
                    </Typography>
                  </Container>
                }
              >
                <Icon data-testid="infoIcon" icon={Icons.INFO} size="small" margin={[0, 1]} color="white" />
              </Tooltip>
            )}
          </Badge>
        ),
      };
    });
  };

  const fetchWidgetData = () => {
    if (interval === StorePerformanceInterval.WEEKLY) return;
    triggerFetchAssetManagementData(fetchAssetManagementData);
  };

  useEffect(() => {
    fetchWidgetData();
  }, [currentDate, interval]);

  useEffect(() => {
    if (!assetManagementData || !assetManagementData.length) return;

    setAllRows(getUpdatedRows(allWorkStations ?? []));
    setActiveRows(getUpdatedRows(activeWorksStations));
    setClosedRows(getUpdatedRows(closedWorkStations));
    setOfflineRows(getUpdatedRows(offlineWorkStations));
  }, [assetManagementData]);

  const getTabs = () => {
    return [
      {
        tabName: t(assetManagement.workstations.key, assetManagement.workstations.defaultValue),
        isTabVisible: true,
        icon: (
          <Badge>
            <Typography type="body3">{allRows.length}</Typography>
          </Badge>
        ),
        iconPosition: 'start' as IconPositionType,
        labelMargin: [0, 0, 0, 2] as Spacing,
      },
      {
        tabName: t(assetManagement.active.key, assetManagement.active.defaultValue),
        isTabDisabled: activeWorksStations.length === 0,
        isTabVisible: true,
        icon: (
          <Badge backgroundColor="validationGreen">
            <Typography type="body3" color="white">
              {activeWorksStations.length}
            </Typography>
          </Badge>
        ),
        iconPosition: 'start' as IconPositionType,
        labelMargin: [0, 0, 0, 2] as Spacing,
      },
      {
        tabName: t(assetManagement.closed.key, assetManagement.closed.defaultValue),
        isTabDisabled: closedWorkStations.length === 0,
        isTabVisible: true,
        icon: (
          <Badge backgroundColor="primary">
            <Typography type="body3" color="white">
              {closedWorkStations.length}
            </Typography>
          </Badge>
        ),
        iconPosition: 'start' as IconPositionType,
        labelMargin: [0, 0, 0, 2] as Spacing,
      },
      {
        tabName: t(assetManagement.offline.key, assetManagement.offline.defaultValue),
        isTabDisabled: offlineWorkStations.length === 0,
        isTabVisible: true,
        icon: (
          <Badge backgroundColor="red">
            <Typography type="body3" color="white">
              {offlineWorkStations.length}
            </Typography>
          </Badge>
        ),
        iconPosition: 'start' as IconPositionType,
        labelMargin: [0, 0, 0, 2] as Spacing,
      },
    ];
  };

  const getColoumns = () => {
    return [
      {
        id: 'name',
        name: t(assetManagement.name.key, assetManagement.name.defaultValue),
        width: '25%',
      },
      {
        id: 'latestTransaction',
        name: t(assetManagement.latestTransaction.key, assetManagement.latestTransaction.defaultValue),
        width: '25%',
        isSortable: true,
      },
      {
        id: 'totalAmount',
        name: t(assetManagement.totalAmount.key, assetManagement.totalAmount.defaultValue),
        width: '25%',
        isSortable: true,
      },

      {
        id: 'status',
        name: t(assetManagement.status.key, assetManagement.status.defaultValue),
        width: '25%',
      },
    ];
  };

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const dataTable = (filteredRows: Row[], workStations: Tills[]) => {
    const sortData = (columnId: string, direction: SortDirection) => {
      if (!columnId) return filteredRows;

      const newWorStations = workStations.map((station) => {
        if (!station.latestTransaction) {
          station.latestTransaction = {
            timestamp: '',
          };
        }
        return station;
      });

      if (columnId === 'latestTransaction' || columnId === 'totalAmount') {
        const sortedData = newWorStations.sort(sortAssetManagementData(columnId, direction));
        return getUpdatedRows(sortedData);
      }

      return filteredRows;
    };
    return (
      <Table
        data-testid="till-view-table"
        stickyHeader
        noBorder
        rows={filteredRows}
        columns={getColoumns()}
        isLoading={assetManagementLoading}
        skeletonLoaderOptions={{ numberOfRows: 4 }}
        handleSorting={(columnId, direction) => sortData(columnId, direction)}
        fixedHeight={'270px'}
      ></Table>
    );
  };

  return (
    <WidgetCardContent
      headerIcon={Icons.TRANSACTIONS}
      headerText={t(assetManagement.title.key, assetManagement.title.defaultValue)}
      subHeaderText={t(assetManagement.subTitle.key, assetManagement.subTitle.defaultValue)}
      lastUpdatedDate={lastUpdated}
      isLoading={assetManagementLoading}
      isError={assetManagementError}
      errorText={t(assetManagement.error.key, assetManagement.error.defaultValue)}
      errorRetryHandler={fetchWidgetData}
      isNoData={!!assetManagementData && assetManagementData.length === 0}
      isWeeklyViewDisabled={interval === StorePerformanceInterval.WEEKLY}
      toolTipText={t(assetManagement.toolTip.key, assetManagement.toolTip.defaultValue)}
      content={
        <>
          <Container wrap="nowrap" data-testid="asset-management-overview" height="100%">
            <StyledTillView data-testid="till-view">
              <DynamicTab
                tabLabelArray={getTabs()}
                handleTabChange={handleTabChange}
                tabValue={tabIndex}
                variant="scrollable"
                tabBgColor={mode === 'light' ? 'white' : 'inherit'}
                content={[
                  { tabContent: dataTable(allRows, allWorkStations ?? []), visible: true },
                  { tabContent: dataTable(activeRows, activeWorksStations), visible: true },
                  { tabContent: dataTable(closedRows, closedWorkStations), visible: true },
                  { tabContent: dataTable(offlineRows, offlineWorkStations), visible: true },
                ]}
              />
            </StyledTillView>
          </Container>
        </>
      }
    />
  );
});
