export const profileTranslations = {
  avatar: {
    key: 'profile.avatar',
    defaultValue: 'Avatar',
  },
  name: {
    key: 'profile.name',
    defaultValue: 'Name',
  },
  fullName: {
    key: 'profile.fullName',
    defaultValue: '{{firstName}} {{lastName}}',
  },
  upn: {
    key: 'profile.email',
    defaultValue: 'Email',
  },
  addEmail: {
    key: 'profile.addEmail',
    defaultValue: 'Add email',
  },
  details: {
    key: 'profile.details',
    defaultValue: 'Details',
  },
  staffId: {
    key: 'profile.staffId',
    defaultValue: 'Staff ID',
  },
  role: {
    key: 'profile.role',
    defaultValue: 'Role',
  },
  requested: {
    key: 'profile.requested',
    defaultValue: 'Requested',
  },
};
