import { format, subDays } from 'date-fns';
import { FC } from 'react';
import { Props } from 'react-apexcharts';
import { useThemeContext } from '../../../global-state/themeContext';
import { Colors, muiTheme } from '../../../theme';
import { StyledCharts } from './Charts.styles';

export type ChartProps = {
  xAxisCategories: number[] | string[];
  chartSeries: ApexAxisChartSeries | ApexNonAxisChartSeries;
  stroke?: ApexStroke;
  chartType?: Props['type'];
  lineColor?: string;
  strokeWidth?: number | number[];
  isStacked?: boolean;
  areaChartGradient?: ApexFill;
  toolTip?: ApexTooltip;
  chartPlotOptions?: ApexPlotOptions['bar'] | ApexPlotOptions['radialBar'] | ApexPlotOptions['pie'];
  yAxis?: ApexYAxis;
  xAxis?: ApexXAxis;
  dataLabel?: ApexDataLabels;
  legend?: ApexLegend;
  isAnimationEnabled?: boolean;
  targetValue?: number | null;
  chartColors?: Colors[];
  showGridYAxis?: boolean;
  title?: ApexTitleSubtitle;
  subtitle?: ApexTitleSubtitle;
  isYesterdayData?: boolean;
  dashArray?: number[];
  chartForecast?: ApexForecastDataPoints;
};

export const Charts: FC<ChartProps> = ({
  xAxisCategories,
  chartSeries,
  chartType,
  stroke = 'smooth',
  lineColor = muiTheme.color.validationGreen,
  strokeWidth = 3,
  isStacked,
  areaChartGradient = {
    type: ['gradient', 'solid'],
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.3,
      opacityTo: 0.6,
      stops: [0, 60, 70],
    },
  },
  toolTip,
  chartPlotOptions,
  yAxis,
  xAxis,
  dataLabel,
  legend,
  targetValue,
  isAnimationEnabled = true,
  chartColors,
  showGridYAxis = true,
  title,
  subtitle,
  isYesterdayData = false,
  dashArray = [],
  chartForecast,
}) => {
  const { mode } = useThemeContext();

  const getColors = () => {
    if (chartColors) {
      return chartColors.map((color) => muiTheme.color[color as Colors]);
    }
  };

  const options = {
    // Chart
    chart: {
      animations: {
        enabled: isAnimationEnabled,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: isStacked,
      fontFamily: muiTheme.typography.body2.fontFamily,
      fontSize: muiTheme.typography.body2.fontSize,
      foreColor: mode === 'dark' ? muiTheme.color.white : muiTheme.color.textGray,
    },

    title: { ...title },
    subtitle: { ...subtitle },

    // Colors
    colors: chartColors ? getColors() : [lineColor, muiTheme.color.inActiveGray],
    forecastDataPoints: {
      count: 0,
      ...chartForecast,
    },

    // X-axis
    xaxis: {
      categories: xAxisCategories,
      labels: {
        offsetX: 0,
        style: {
          colors: xAxisCategories.map((category) => {
            const isCurrentDay = isYesterdayData
              ? format(subDays(new Date(), 1), 'EEE')
              : format(new Date(), 'EEE');

            return category === isCurrentDay
              ? muiTheme.color.red
              : mode === 'dark'
              ? muiTheme.color.white
              : muiTheme.color.primary;
          }),
        },
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
      ...xAxis,
    },

    // Target Lines
    annotations: {
      yaxis: [
        targetValue
          ? {
              y: targetValue,
              borderColor: muiTheme.color.tagBlue,
              borderWidth: 2,
              strokeDashArray: 5,
            }
          : { borderColor: 'transparent' },
      ],
    },

    // Y-axis
    yaxis: {
      labels: {
        offsetX: -5,
        formatter: function (value: number) {
          if (value === Infinity) {
            return '';
          }
          if (value >= 1000) {
            return (value / 1000).toFixed() + 'K';
          }
          return value.toFixed() + '';
        },
      },
      ...yAxis,
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: muiTheme.color.white,
    },

    // Tooltip
    tooltip: {
      x: {
        show: false,
      },
      marker: { show: false },
      theme: mode === 'dark' ? 'dark' : 'light',
      ...toolTip,
    },

    // DataLabels
    dataLabels: {
      enabled: false,
      style: {
        fontFamily: 'HMSans-Regular',
        fontWeight: 400,
        colors: [mode === 'dark' ? muiTheme.color.white : muiTheme.color.textGray],
      },
      ...dataLabel,
    },

    // Fill
    fill: {
      ...areaChartGradient,
      gradient: {
        ...areaChartGradient.gradient,
        gradientToColors: [mode === 'dark' ? muiTheme.color.backgroundPaperDark : muiTheme.color.white],
      },
    },

    // States
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },

    // Stroke
    stroke: {
      width: strokeWidth,
      curve: stroke,
      dashArray: dashArray,
      lineCap: 'round',
    } as ApexStroke,

    // Grid
    grid: {
      yaxis: {
        lines: {
          show: showGridYAxis,
        },
      },
      strokeDashArray: 3,
      borderColor: mode === 'dark' ? muiTheme.color.textGray : muiTheme.color.borderGray,
    },

    //Plot Options
    plotOptions: {
      // Bar
      bar: {
        borderRadius: 2,
        columnWidth: '12px',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        ...chartPlotOptions,
      },
      radialBar: {
        ...chartPlotOptions,
      },
      pie: {
        donut: {
          size: '100%',
        },
        ...chartPlotOptions,
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: String(13),
      position: 'top',
      horizontalAlign: 'right',
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: mode === 'dark' ? muiTheme.color.white : muiTheme.color.primary,
      },
      ...legend,
    },
    labels: xAxisCategories,
  };

  return (
    <StyledCharts
      data-testid="react-apex-chart"
      options={options}
      series={chartSeries}
      type={chartType}
      width="100%"
      height="100%"
    />
  );
};
