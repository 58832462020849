import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { isToday, startOfToday, isEqual, format, sub, add, intlFormat } from 'date-fns';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useMediaQuery } from '@mui/material';
import { isFailureResponse, getUniqueId } from '../../utils';
import { useTransaction, TransactionCount } from '../../hooks';
import { useUser, useTransactions } from '../../hooks/useController';
import { TransactionFilter, PageInfo, Transaction } from '../../global-state/types';
import { Icons, Typography, Tooltip } from '../../stories/atoms';
import { Animation, Animations, Banner, Button, DropdownMenu, IconWithText } from '../../stories/molecules';
import { Page } from '../../stories/organisms/page/Page';
import { commonTranslations, transactionSearchTranslations, errors } from '../../translations';
import { DateRangePicker } from '../../stories/molecules/date-range-picker/DateRangePicker';
import { muiTheme } from '../../theme';
import { TransactionFilterType } from '../../constants';
import { BannerItemsType } from '../../stories/molecules/banner';
import { useThemeContext } from '../../global-state/themeContext';
import { TransactionTable } from './transaction-table/TransactionTable';
import { AllFiltersMenu } from './quick-filters/AllFiltersMenu';
import { Receipt, NavigationDirection } from './receipt-image/Receipt';
import {
  HeaderFiltersWrapper,
  SelectionWrapper,
  TableReceiptWrapper,
  SelectedFilters,
  FiltersWrapper,
  StyledFilterMenuWrapper,
  StyledFilterLabel,
  StyledFilterCount,
  AnimationWrapper,
} from './TransactionSearch.styles';

const {
  currentDateText,
  transactions: transactionsTranslations,
  clearFilter,
  allFilters,
  error: errorTranslation,
  noDataFilter,
  disabledFilter,
  warningBanner,
} = transactionSearchTranslations;
const { selectedFilters: selectedFilterTranslation, tryAgain } = commonTranslations;

export const filterTranslationMapping = {
  [TransactionFilterType.OperatorId]: transactionSearchTranslations.cashier,
  [TransactionFilterType.PaymentType]: transactionSearchTranslations.tenderType,
  [TransactionFilterType.ReceiptSubType]: transactionSearchTranslations.transactionType,
};

export const TransactionSearch = observer(() => {
  const [showReceipt, setShowReceipt] = useState(false);
  const [currentDates, setCurrentDates] = useState<[Date, Date]>([startOfToday(), startOfToday()]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [prevDates, setPrevDates] = useState<DateRange<Date>>([null, null]);
  const [prevPageSize, setPrevPageSize] = useState<number>(0);
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>([]);
  const [prevSelectedFilterKeys, setPrevSelectedFilterKeys] = useState<string[]>([]);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [tableHeight, setTableHeight] = useState('');
  const [receiptHeight, setReceiptHeight] = useState('');
  const [lastFilterKey, setLastFilterKey] = useState<string | undefined>(undefined);
  const selectedFilterRef = useRef<HTMLDivElement>(null);
  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [minDate, setMinDate] = useState<Date>(sub(new Date(), { months: 18 }));
  const [maxDate, setMaxDate] = useState<Date>();

  const {
    get: { currentStoreId },
  } = useUser();
  const {
    get: {
      table,
      quickFilterData,
      isOperatorIdError,
      isPaymentTypeError,
      isReceiptSubTypeError,
      isFetchingCount,
      isFetchingTransaction,
      isTransactionsConnectedToWidgetData,
      totalCount,
      isTransactionError,
      selectedFilters,
    },
    set,
  } = useTransactions();

  const { fetchTransactions, fetchCount, fetchReceiptData } = useTransaction();

  const { t, i18n } = useTranslation();
  const { mode } = useThemeContext();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const isRangeSelected = !isEqual(currentDates[0], currentDates[1]);
  const isAnyFilterSelected = selectedFilterKeys.length > 0 || !isToday(currentDates[0]);
  const selectedRangeFilter = isRangeSelected
    ? `${intlFormat(currentDates[0], {}, { locale: i18n.language })} - ${intlFormat(
        currentDates[1],
        {},
        { locale: i18n.language },
      )}`
    : `${intlFormat(currentDates[0], {}, { locale: i18n.language })}`;

  useEffect(() => {
    if (isFetchingTransaction && isFetchingCount) return;

    setHeight();
    window.addEventListener('resize', () => setHeight());

    return () => window.removeEventListener('resize', () => setHeight());
  }, [isFetchingTransaction, isFetchingCount]);

  useEffect(() => {
    if (!currentStoreId) return;

    const hasDateChanged =
      prevDates[0]?.toDateString() !== currentDates[0]?.toDateString() ||
      prevDates[1]?.toDateString() !== currentDates[1]?.toDateString();

    const hasFilterSelectionChanged =
      (prevSelectedFilterKeys.length !== selectedFilterKeys.length &&
        !selectedFilterKeys.every((key) => prevSelectedFilterKeys.includes(key))) ||
      !prevSelectedFilterKeys.every((key) => selectedFilterKeys.includes(key));

    const hasPageSizeChanged = prevPageSize !== table.pagination.pageSize;

    if (selectedFilters.length > 0 && selectedFilterKeys.length === 0) {
      setSelectedFilterKeys(selectedFilters);
    }

    if (hasDateChanged || hasFilterSelectionChanged || hasPageSizeChanged) {
      handleReceiptClose();
      setPrevDates(currentDates);
      setPrevSelectedFilterKeys(selectedFilterKeys);
      setPrevPageSize(table.pagination.pageSize);

      if (hasFilterSelectionChanged) {
        setLastFilterKey(
          selectedFilterKeys.length > prevSelectedFilterKeys.length
            ? selectedFilterKeys.find((key) => !prevSelectedFilterKeys.includes(key))
            : prevSelectedFilterKeys.find((key) => !selectedFilterKeys.includes(key)),
        );
      }

      set({
        isFetchingCount: true,
        isFetchingTransaction: true,
        totalCount: 0,
        table: {
          ...table,
          rows: [],
          pagination: { ...table.pagination, currentPageNumber: 1 },
        },
      });
    }
  }, [currentStoreId, selectedFilters, currentDates, selectedFilterKeys, table.pagination.pageSize]);

  // clearing the widget data when unmounting
  useEffect(() => {
    return () => {
      set({ selectedFilters: [], isTransactionsConnectedToWidgetData: false });
    };
  }, []);

  // Fetch count
  useEffect(() => {
    if (!isFetchingCount || isTransactionsConnectedToWidgetData) return;

    const getData = async () => {
      let data: TransactionFilter = null;
      let totalRows = 0;

      const lastSelectedFilterType = selectedFilterKeys.length
        ? Object.values(TransactionFilterType).find(
            (key) => quickFilterData && quickFilterData[key].some(({ item }) => item === lastFilterKey),
          )
        : undefined;

      const countResponse = await Promise.all(
        Object.values(TransactionFilterType).map(async (type) => {
          if (quickFilterData && type === lastSelectedFilterType)
            return Promise.resolve({
              totalCount: '0',
              filterData: { [type]: quickFilterData[type] },
            } as TransactionCount);

          return fetchCountData(type);
        }),
      );

      countResponse.forEach((response) => {
        if (response) {
          data = {
            ...data,
            ...response.filterData,
          } as TransactionFilter;
          if (totalRows < +response.totalCount) totalRows = +response.totalCount;
        }
      });

      set({
        isFetchingCount: false,
        quickFilterData: data,
        totalCount: +totalRows,
      });
    };

    getData();
  }, [isFetchingCount]);

  // Fetch transaction
  useEffect(() => {
    if (!isFetchingTransaction || isTransactionsConnectedToWidgetData) return; // verify this

    const getData = async (pageNumber: number, pageInfo: PageInfo, previousRows: Transaction[] = []) => {
      const response = await fetchTransactionData(pageNumber, pageInfo);

      if (!response || !response.data.length) {
        set({ isFetchingTransaction: false });
        return;
      }

      const {
        data,
        paging: { nextBusinessDayDate, nextTransactionSubType, countFromOldPartition },
      } = response;

      set({
        table: {
          ...table,
          rows: [...table.rows, ...previousRows, ...data],
          pagination: {
            ...table.pagination,
            nextBusinessDayDate,
            nextTransactionSubType,
            countFromOldPartition,
          },
        },
        isFetchingTransaction: false,
      });

      return response;
    };

    const fetchDataInBatch = async () => {
      // pageNumber here reflect the actual page that we want to fetch from backend.
      const pageNumber =
        table.pagination.currentPageNumber > 1
          ? table.pagination.currentPageNumber + 1
          : table.pagination.currentPageNumber;

      const updatedPagination =
        pageNumber === 1
          ? {
              ...table.pagination,
              // reset paging info, if not done we get back wrong data from backend.
              nextBusinessDayDate: undefined,
              countFromOldPartition: 0,
              nextTransactionSubType: null,
            }
          : table.pagination;

      const response = await getData(pageNumber, updatedPagination);
      const fetchMoreData = response?.data.length === table.pagination.pageSize;

      if (table.pagination.currentPageNumber === 1 && fetchMoreData) {
        // fetch data for page no 2 only.
        const { paging } = response;
        await getData(pageNumber + 1, { ...updatedPagination, ...paging }, response.data);
      }
    };

    fetchDataInBatch();
  }, [table.pagination.currentPageNumber, isFetchingTransaction]);

  // Reset banner state when count api returns success
  useEffect(() => {
    if (!isOperatorIdError && !isPaymentTypeError && !isReceiptSubTypeError) {
      setBannerItems([]);
    }
  }, [isOperatorIdError, isPaymentTypeError, isReceiptSubTypeError]);

  // Count API
  const fetchCountData = async (type: TransactionFilterType) => {
    const response = await fetchCount(
      format(currentDates[0], 'yyyy-MM-dd'),
      format(currentDates[1], 'yyyy-MM-dd'),
      type,
      getSelectedFilterByType(TransactionFilterType.PaymentType),
      getSelectedFilterByType(TransactionFilterType.ReceiptSubType),
      getSelectedFilterByType(TransactionFilterType.OperatorId),
    );

    if (isFailureResponse(response)) {
      switch (type) {
        case TransactionFilterType.OperatorId:
          set({ isOperatorIdError: true });
          break;
        case TransactionFilterType.PaymentType:
          set({ isPaymentTypeError: true });
          break;
        default:
          set({ isReceiptSubTypeError: true });
          break;
      }
      setBannerItems(() => [
        {
          id: getUniqueId(),
          label: t(warningBanner.key, warningBanner.defaultValue),
          type: 'warning',
          delay: 0,
        },
      ]);
      return;
    }

    switch (type) {
      case TransactionFilterType.OperatorId:
        set({ isOperatorIdError: false });
        break;
      case TransactionFilterType.PaymentType:
        set({ isPaymentTypeError: false });
        break;
      default:
        set({ isReceiptSubTypeError: false });
        break;
    }

    return response.data;
  };

  // Transaction API
  const fetchTransactionData = async (pageNo: number, pageInfo: PageInfo) => {
    const { pageSize, countFromOldPartition, nextBusinessDayDate } = pageInfo;

    const response = await fetchTransactions(
      format(currentDates[0], 'yyyy-MM-dd'),
      format(currentDates[1], 'yyyy-MM-dd'),
      pageNo,
      pageSize,
      getSelectedFilterByType(TransactionFilterType.PaymentType),
      getSelectedFilterByType(TransactionFilterType.ReceiptSubType),
      getSelectedFilterByType(TransactionFilterType.OperatorId),
      countFromOldPartition,
      nextBusinessDayDate,
    );

    if (isFailureResponse(response)) {
      set({ isTransactionError: true });
      return;
    }

    return response.data;
  };

  // Receipt API
  const fetchReceipt = async (selectedRowId: number) => {
    set({ selectedReceipt: [], isReceiptError: false });
    const { receiptNumber, businessDayDate } = table.rows[selectedRowId];

    const receiptResponse = await fetchReceiptData(format(new Date(businessDayDate), 'yyyy-MM-dd'), receiptNumber);

    if (isFailureResponse(receiptResponse)) {
      set({ isReceiptError: true });
      return;
    }

    set({ selectedReceipt: receiptResponse.data });
  };

  const getSelectedFilterByType = (type: TransactionFilterType) => {
    return quickFilterData && quickFilterData[type]
      ? quickFilterData[type]
          .filter(({ item }) => {
            return selectedFilterKeys.includes(item);
          })
          .map(({ item }) => item)
          .join(',') || undefined
      : undefined;
  };

  const handleRowClick = async (selectedRowId: number) => {
    if (selectedRowIndex === selectedRowId) return;

    setSelectedRowIndex(selectedRowId);
    setShowReceipt(true);
    await fetchReceipt(selectedRowId);
  };

  const handleReceiptClose = () => {
    setSelectedRowIndex(-1);
    setShowReceipt(false);
  };

  const handleNavigation = (direction: NavigationDirection) => {
    if (direction === 'previous' && selectedRowIndex > 0) {
      handleRowClick(selectedRowIndex - 1);
    }
    if (direction === 'next' && selectedRowIndex < table.rows.length - 1) {
      handleRowClick(selectedRowIndex + 1);
    }
  };

  const handleRemoveFilters = (key: string) => {
    setSelectedFilterKeys(selectedFilterKeys.filter((selectedKey) => selectedKey !== key));
  };

  const handlePageChange = (pageNumber: number) => {
    set({
      table: {
        ...table,
        pagination: { ...table.pagination, currentPageNumber: pageNumber + 1 },
      },
      isFetchingTransaction:
        totalCount > table.rows.length && table.rows.length < (pageNumber + 2) * table.pagination.pageSize,
    });
  };

  const handleFilterRetry = async (key: TransactionFilterType) => {
    const data = await fetchCountData(key);

    if (data && data.filterData) {
      set({
        quickFilterData: { ...quickFilterData, ...data.filterData },
      });
    }
  };

  const resetHandler = () => {
    setSelectedFilterKeys([]);
    setCurrentDates([startOfToday(), startOfToday()]);

    if (selectedFilters.length > 0) {
      set({ selectedFilters: [] });
    }

    set({ isTransactionsConnectedToWidgetData: false });
  };

  const hasFilterItems = (key: TransactionFilterType) => {
    return quickFilterData && quickFilterData[key] && quickFilterData[key].some(({ count }) => +count > 0);
  };

  const hasFilterError = (key: TransactionFilterType) => {
    return (
      (key === TransactionFilterType.OperatorId && isOperatorIdError) ||
      (key === TransactionFilterType.PaymentType && isPaymentTypeError) ||
      (key === TransactionFilterType.ReceiptSubType && isReceiptSubTypeError)
    );
  };

  const setHeight = () => {
    const { page, pageHeader } = muiTheme.sizing;
    const filterWrapperHeight = selectedFilterRef.current?.clientHeight || 0;
    const paginationHeight = document.querySelector('[data-testid="pagination"]')?.clientHeight || 0;
    const offset = 20;

    setTableHeight(
      `calc(${page} - ${pageHeader} - ${filterWrapperHeight}px - ${paginationHeight}px - ${offset}px)`,
    );
    setReceiptHeight(`calc(${page} - ${pageHeader} - ${filterWrapperHeight}px - ${offset}px)`);
  };

  const getCustomHeader = (key: TransactionFilterType) => {
    if (hasFilterError(key)) {
      return (
        <AnimationWrapper>
          <Animation
            title=""
            subtitle={t(noDataFilter.title.key, noDataFilter.title.defaultValue)}
            animation={Animations.SAD}
            size="small"
            button={{
              label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
              onClick: () => handleFilterRetry(key),
              buttonContentProps: {
                iconOptions: {
                  icon: Icons.REFRESH,
                  color: 'white',
                },
              },
            }}
          />
        </AnimationWrapper>
      );
    }
  };

  const getQuickFilterMenuItems = (key: TransactionFilterType) => {
    if (!quickFilterData || !hasFilterItems(key) || hasFilterError(key)) return [];

    return quickFilterData[key].map(({ item, count }) => ({
      key: item,
      item: (
        <StyledFilterMenuWrapper key={item}>
          <StyledFilterLabel data-testid="filter-item">{item}</StyledFilterLabel>
          <StyledFilterCount data-testid="filter-count">{count}</StyledFilterCount>
        </StyledFilterMenuWrapper>
      ),
      disabled: +count === 0 || isFetchingCount,
    }));
  };

  const isFilterDisabled = (key: TransactionFilterType) => {
    return !hasFilterItems(key) && !hasFilterError(key);
  };

  const getDynamicFilter = (key: TransactionFilterType) => {
    return (
      <DropdownMenu
        key={key}
        multiSelect
        dropdownLabel={t(filterTranslationMapping[key].key, filterTranslationMapping[key].defaultValue)}
        menuItems={getQuickFilterMenuItems(key)}
        checkbox={true}
        menuPosition="left"
        initialSelected={selectedFilterKeys}
        onSelect={setSelectedFilterKeys}
        maxHeight={'50vh'}
        buttonContentProps={{ isDisabled: isFilterDisabled(key) }}
        customHeader={getCustomHeader(key)}
        buttonMinWidth={[30]}
        backgroundColor={{
          hover: mode === 'dark' ? 'backgroundPaperDark' : 'borderGray',
          selected: mode === 'dark' ? 'backgroundPaperDark' : 'backgroundSelected',
        }}
      />
    );
  };

  if (isTransactionError)
    return (
      <Page
        body={
          <Animation
            animation={Animations.SAD}
            title={t(errorTranslation.key, errorTranslation.defaultValue)}
            subtitle={t(errors.tryAgain.key, errors.tryAgain.defaultValue)}
            button={{
              label: t(tryAgain.key, tryAgain.defaultValue),
              buttonContentProps: { iconOptions: { icon: Icons.REFRESH, color: 'white' } },
              onClick: () => window.location.reload(),
            }}
          />
        }
      />
    );

  return (
    <Page
      header={
        <HeaderFiltersWrapper data-testid="filters">
          <DateRangePicker
            inputSelectedDates={currentDates}
            onAccept={(dates) => {
              setCurrentDates(dates);
            }}
            onChange={(dates) => {
              if (!dates[0]) return;
              setMinDate(sub(dates[0], { days: 5 }));
              setMaxDate(add(dates[0], { days: 5 }));
            }}
            onClose={() => {
              setMinDate(sub(new Date(), { months: 18 }));
              setMaxDate(undefined);
            }}
            minDate={minDate}
            maxDate={maxDate}
            disableFutureDates
          />
          {Object.values(TransactionFilterType).map((key) => {
            return isFilterDisabled(key) ? (
              <Tooltip
                key={key}
                text={
                  <Typography padding={[3]} color="white">
                    <Trans
                      i18nKey={disabledFilter.key}
                      defaults={disabledFilter.defaultValue}
                      components={{ br: <br /> }}
                    />
                  </Typography>
                }
              >
                <div>{getDynamicFilter(key)}</div>
              </Tooltip>
            ) : (
              getDynamicFilter(key)
            );
          })}
          <Button
            label={t(allFilters.key, allFilters.defaultValue)}
            buttonContentProps={{
              labelPosition: 'left',
              iconOptions: {
                icon: Icons.FILTER,
                size: 'small',
              },
              textOptions: {
                whiteSpace: 'nowrap',
              },
            }}
            data-testid="all-filters-button"
            id="all-filters-button"
            aria-haspopup="true"
            aria-expanded={showAllFilters ? 'true' : undefined}
            onClick={() => setShowAllFilters(true)}
            variant="text"
          />
          <AllFiltersMenu
            isOpen={showAllFilters}
            onClearAllFilters={resetHandler}
            onClose={() => setShowAllFilters(false)}
            selectedDates={currentDates}
            onDateChange={setCurrentDates}
            initialSelectedFilterKeys={selectedFilterKeys}
            handleFilters={setSelectedFilterKeys}
          />
        </HeaderFiltersWrapper>
      }
      body={
        <div data-testid="transaction-search">
          <Banner items={bannerItems} onChange={(items) => setBannerItems(items)} isSticky />
          <SelectionWrapper>
            <SelectedFilters data-testid="selected-filters" ref={selectedFilterRef}>
              {isAnyFilterSelected && (
                <Typography data-testid="selected-filter-text" type="body3">
                  {t(selectedFilterTranslation.key, selectedFilterTranslation.defaultValue)}
                </Typography>
              )}
              {selectedFilterKeys.length > 0 &&
                quickFilterData &&
                selectedFilterKeys
                  .filter((key) =>
                    (Object.keys(quickFilterData) as TransactionFilterType[]).some((type) =>
                      quickFilterData[type].some(({ item }) => item === key),
                    ),
                  )
                  .map((key) => (
                    <IconWithText
                      data-testid="filter-tags"
                      key={key}
                      label={key}
                      iconOptions={{
                        icon: Icons.CLOSE,
                        onClick: () => {
                          handleRemoveFilters(key);
                        },
                        margin: [0, 2],
                        size: 'small',
                      }}
                      backgroundColor={mode === 'dark' ? 'primary' : 'white'}
                      labelPosition="left"
                      padding={[2, 0, 2, 2]}
                    />
                  ))}
              {!isRangeSelected && isToday(currentDates[0]) ? (
                <Typography data-testid="today-date" padding={[2, 0]}>
                  {t(currentDateText.key, currentDateText.defaultValue)}{' '}
                  {intlFormat(currentDates[0], {}, { locale: i18n.language })}
                </Typography>
              ) : (
                <IconWithText
                  label={selectedRangeFilter}
                  iconOptions={{
                    icon: Icons.CLOSE,
                    color: 'white',
                    onClick: () => setCurrentDates([startOfToday(), startOfToday()]),
                    margin: [0, 2],
                    size: 'small',
                  }}
                  backgroundColor="primary"
                  textOptions={{ color: 'white' }}
                  labelPosition="left"
                  padding={[2, 0, 2, 2]}
                />
              )}
            </SelectedFilters>
            <FiltersWrapper>
              {!isFetchingCount && (
                <Typography data-testid="transaction-count">
                  {`${totalCount} ${t(transactionsTranslations.key, transactionsTranslations.defaultValue)}`}
                </Typography>
              )}
              {isAnyFilterSelected && (
                <IconWithText
                  data-testid="clear-all-button"
                  label={t(clearFilter.key, clearFilter.defaultValue)}
                  textOptions={{
                    type: 'body3',
                  }}
                  iconOptions={{
                    icon: Icons.TRASHBIN,
                    onClick: () => resetHandler(),
                    size: 'small',
                  }}
                  labelPosition="left"
                  padding={[2, 0, 2, 2]}
                />
              )}
            </FiltersWrapper>
          </SelectionWrapper>
          <TableReceiptWrapper isReceiptVisible={showReceipt}>
            <TransactionTable
              onRowClick={handleRowClick}
              height={tableHeight}
              selectedRowIndex={selectedRowIndex}
              onPageChange={handlePageChange}
            />
            <Receipt
              isVisible={showReceipt}
              onClose={handleReceiptClose}
              onNavigate={handleNavigation}
              onRetry={() => fetchReceipt(selectedRowIndex)}
              height={!isMobile ? receiptHeight : ''}
            />
          </TableReceiptWrapper>
        </div>
      }
    />
  );
});
