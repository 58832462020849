import { Box, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { muiTheme } from '../../../../theme';
import { filterTranslations } from '../../../../translations/filter';
import { Drawer, Icons, Typography } from '../../../atoms';
import { Button } from '../../button/Button';
import { DateRangeContent } from './DateRangeContent/DateRangeContent';
import {
  BackIcon,
  BodyContainer,
  CloseIcon,
  ContentContainer,
  FooterButtonContainer,
  FooterContainer,
  HeaderContainer,
} from './FilterDrawer.styles';
import { FilterSpecItem } from './FilterSpecItem/FilterSpecItem';
import { MultiSelectListContent, MultiSelectListOption } from './MultiSelectListContent/MultiSelectListContent';
import { RadioListOption, RadiolListContent } from './RadiolListContent/RadiolListContent';

export enum FilterType {
  MultiSelectList = 'MultiSelectList',
  RadiolList = 'RadiolList',
  DateRange = 'DateRange',
}

type MultiSelectListSpec = {
  key: string;
  type: FilterType.MultiSelectList;
  title: string;
  options: MultiSelectListOption[];
  selectedOptionsKeys: string[];
  onSelect: (selectedItemKeys: string[]) => void;
};

type RadioListSpec = {
  key: string;
  type: FilterType.RadiolList;
  title: string;
  options: RadioListOption[];
  selectedOptionKey: null | string;
  onSelect: (key: string) => void;
};

type DateRangeSpec = {
  key: string;
  type: FilterType.DateRange;
  title: string;
  currentDates: [Date, Date];
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onAccept: (dates: [Date, Date]) => void;
  onChange?: ((dates: [Date, Date]) => void) | undefined;
  onClose?: (() => void) | undefined;
};

export type FilterSpec = MultiSelectListSpec | RadioListSpec | DateRangeSpec;

export type Props = {
  isOpen: boolean;
  filtersSpecs: FilterSpec[];
  totalResultEntries?: number;
  totalResultEntriesText?: string;
  onDrawerClose: () => void;
  onClearAllFilters: () => void;
};

export const FilterDrawer = ({
  isOpen,
  filtersSpecs,
  totalResultEntries,
  totalResultEntriesText,
  onDrawerClose,
  onClearAllFilters,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const [activeFilterViewKey, setActiveFilterViewKey] = useState<string | null>(null);

  const getTitle = (filterViewKey: string) => {
    const filterSpec = filtersSpecs.find((item) => item.key === filterViewKey);

    return filterSpec?.title || '';
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Enter')
    ) {
      return;
    }

    onDrawerClose();
  };

  const clearButtonHandler = () => {
    onClearAllFilters();
    onDrawerClose();
  };

  useEffect(() => {
    if (!isOpen) setActiveFilterViewKey(null);
  }, [isOpen]);

  return (
    <Drawer variant="temporary" position="right" isOpen={isOpen} onClose={onDrawerClose}>
      <Box
        data-testid="filters-menu"
        sx={{
          width: isMobile ? '100vw' : 500,
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'relative',
        }}
        role="presentation"
        onKeyDown={toggleDrawer()}
      >
        {activeFilterViewKey && (
          <BackIcon
            data-testid="closeIcon"
            margin={[5, 5, 5, 5]}
            icon={Icons.ARROW}
            size="medium"
            rotate="up"
            onClick={() => setActiveFilterViewKey(null)}
          />
        )}

        <CloseIcon
          data-testid="closeIcon"
          margin={[5, 5, 5, 5]}
          icon={Icons.CLOSE}
          size="medium"
          onClick={onDrawerClose}
        />
        <HeaderContainer>
          <Typography type="h4">
            {!activeFilterViewKey
              ? t(filterTranslations.allFiltersText.key, filterTranslations.allFiltersText.defaultValue)
              : getTitle(activeFilterViewKey)}
          </Typography>
        </HeaderContainer>

        <ContentContainer>
          <BodyContainer>
            {!activeFilterViewKey &&
              filtersSpecs.map((spec) => {
                const { key, type, title } = spec;

                let isSelected = false;
                let count;

                if (type === FilterType.MultiSelectList) {
                  const { selectedOptionsKeys } = spec;
                  isSelected = selectedOptionsKeys.length !== 0;
                  count = selectedOptionsKeys.length || undefined;
                }

                if (type === FilterType.RadiolList) {
                  const { selectedOptionKey } = spec;
                  isSelected = !!selectedOptionKey;
                }

                return (
                  <FilterSpecItem
                    key={key}
                    isSelected={isSelected}
                    label={title}
                    count={count}
                    onClick={() => setActiveFilterViewKey(key)}
                  />
                );
              })}

            {activeFilterViewKey &&
              filtersSpecs.map((spec) => {
                const { key, type } = spec;

                if (key !== activeFilterViewKey) return null;

                if (type === FilterType.MultiSelectList) {
                  const { options, selectedOptionsKeys, onSelect } = spec;

                  return (
                    <MultiSelectListContent
                      key={key}
                      options={options}
                      selectedOptionsKeys={selectedOptionsKeys}
                      onSelect={onSelect}
                    />
                  );
                }

                if (type === FilterType.RadiolList) {
                  const { options, selectedOptionKey, onSelect } = spec;

                  return (
                    <RadiolListContent
                      options={options}
                      selectedOptionKey={selectedOptionKey}
                      onSelect={onSelect}
                    />
                  );
                }

                if (type === FilterType.DateRange) {
                  const { currentDates, minDate, maxDate, onChange, onAccept, onClose } = spec;

                  return (
                    <DateRangeContent
                      currentDates={currentDates}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={onChange}
                      onAccept={onAccept}
                      onClose={onClose}
                    />
                  );
                }
              })}
          </BodyContainer>
        </ContentContainer>

        <FooterContainer>
          {totalResultEntries !== undefined && (
            <Typography data-testid="transaction-count" margin={[4, 0]}>
              {totalResultEntries} {totalResultEntriesText}
            </Typography>
          )}

          <FooterButtonContainer>
            <Button
              label={t(
                filterTranslations.drawerClearButtonText.key,
                filterTranslations.drawerClearButtonText.defaultValue,
              )}
              variant="secondary"
              onClick={clearButtonHandler}
              fullWidth
            />
            <Button
              label={t(
                filterTranslations.drawerCloseButtonText.key,
                filterTranslations.drawerCloseButtonText.defaultValue,
              )}
              onClick={onDrawerClose}
              buttonContentProps={{
                textOptions: { color: 'white' },
              }}
              fullWidth
            />
          </FooterButtonContainer>
        </FooterContainer>
      </Box>
    </Drawer>
  );
};
