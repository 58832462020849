import styled from 'styled-components';
import { SelectedFilters } from '../employees/role-management/Employees.styles';

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const HeaderFiltersWrapper = styled(FiltersWrapper)`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 8)};
`;

export const StyledAnimationWrapper = styled.div`
  margin-top: 20vh;
`;

export const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(0, 8)};
  min-height: 0;
`;

export const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectedFiltersArea = styled(SelectedFilters)`
  align-items: center;
  row-gap: ${({ theme }) => theme.spacing(2)};
`;

export const ProductDetailsSliderContainer = styled.div`
  width: ${({ theme }) => theme.spacing(88)};
  height: 100%;
  overflow: hidden;
`;
