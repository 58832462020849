import { rest } from 'msw';
import { authorizationEndpoint, baseUrl, blacklistPermissions, permissionsEndpoint } from '../../constants';
import {
  getBlacklistPermissionsMockResponse,
  mockPermissions,
  postBlacklistPermissionsMockResponse,
} from '../mocks';

export const fetchPermissions = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${authorizationEndpoint}${permissionsEndpoint}/:upn`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockPermissions),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const getBlacklistPermissionsData = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${authorizationEndpoint}${blacklistPermissions}/:storeId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getBlacklistPermissionsMockResponse),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const postBlacklistPermissionsData = (addDelay?: boolean) =>
  rest.post(`${baseUrl}${authorizationEndpoint}${blacklistPermissions}/:storeId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(postBlacklistPermissionsMockResponse),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
