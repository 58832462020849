import { Dialog } from '@mui/material';
import styled from 'styled-components';
import { Icon } from '../../atoms';

export type Position = 'center' | 'top' | 'bottom';

type PositionType = { [key in Position]: string };

const positionMapping: PositionType = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export const StyledDialog = styled(Dialog)<{ hideBackdrop?: boolean; position?: 'center' | 'top' | 'bottom' }>`
  .MuiBackdrop-root {
    background-color: ${({ hideBackdrop }) => !hideBackdrop && 'rgba(19, 6, 6, 0.6)'};
  }

  .MuiDialog-container {
    align-items: ${({ position }) => position && positionMapping[position]};
  }
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(6)};
  top: ${({ theme }) => theme.spacing(6)};
  z-index: 9999;
`;
