import { FC } from 'react';
import { SkeletonLoader } from '../../../../stories/atoms';
import { StyledContainer, StyledTable } from './EmployeesLoader.styles';

export const EmployeesLoader: FC = () => {
  return (
    <StyledContainer data-testid="employees-loader">
      <SkeletonLoader variant="rect" height="30px" width="30%" gutterBottom={12} />

      <StyledTable>
        <SkeletonLoader variant="rect" height="20px" width="30%" gutterBottom={5} />
        <SkeletonLoader variant="list" lines={2} height="5vh" gutterBottom={10} animationType="wave" />
      </StyledTable>
      <StyledTable>
        <SkeletonLoader variant="rect" height="20px" width="30%" gutterBottom={5} />
        <SkeletonLoader variant="list" lines={3} height="5vh" gutterBottom={10} animationType="wave" />
      </StyledTable>
      <StyledTable>
        <SkeletonLoader variant="rect" height="20px" width="30%" gutterBottom={5} />
        <SkeletonLoader variant="list" lines={2} height="5vh" animationType="wave" />
      </StyledTable>
    </StyledContainer>
  );
};
