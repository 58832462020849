import { useContext } from 'react';
import { globalContext } from '../global-state/context';

export const useUser = () => {
  const controller = useContext(globalContext);
  if (!controller) throw new Error('Context not available, did you forget to wrap your component in a Provider?');
  return controller.userController;
};

export const useTransactions = () => {
  const controller = useContext(globalContext);
  if (!controller) throw new Error('Context not available, did you forget to wrap your component in a Provider?');
  return controller.transactionController;
};
