import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { Container, Icons, Typography } from '../../stories/atoms';
import { Modal } from '../../stories/molecules';
import { commonTranslations, headerTranslations, logoutTranslation } from '../../translations';
import {
  StyledCloseButton,
  StyledContainer,
  StyledIcon,
  StyledSignoutButton,
  StyledTypography,
} from './LogoutModal.styles';

export type LogoutModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LogoutModal: FC<LogoutModalProps> = observer(({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { instance } = useMsal();

  const { title, subTitle } = logoutTranslation;
  const { signout } = headerTranslations;

  return (
    <>
      <Modal isOpen={isOpen} transitionType="slide" ariaDescribedby="change-store-modal" handleClose={onClose}>
        <StyledContainer>
          <StyledIcon data-testid="box-icon" icon={Icons.BOX} />
          <StyledTypography data-testid="logout-title" type="h4" textAlign="center">
            {t(title.key, title.defaultValue)}
          </StyledTypography>
          <Typography margin={[0, 0, 4]}>{t(subTitle.key, subTitle.defaultValue)}</Typography>
          <Container direction="horizontal" padding={[0, 0, 0, 0]} position="center" width="100%">
            <StyledCloseButton
              onClick={onClose}
              label={t(commonTranslations.close.key, commonTranslations.close.defaultValue)}
              variant="secondary"
              data-testid="close-button"
            />
            <StyledSignoutButton
              data-testid="signout-button"
              onClick={() => instance.logoutRedirect()}
              label={t(signout.key, signout.defaultValue)}
              buttonContentProps={{
                iconOptions: { icon: Icons.SIGNOUT, color: 'white', size: 'small' },
                textOptions: { color: 'white' },
              }}
            />
          </Container>
        </StyledContainer>
      </Modal>
    </>
  );
});
