import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile } from '../../../../../global-state/types';
import { useUser } from '../../../../../hooks';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../../../stories/atoms';
import { employeesTranslations } from '../../../../../translations';

import { useThemeContext } from '../../../../../global-state/themeContext';
import { DynamicTab, IconPositionType } from '../../../../../stories/molecules';
import { Typographies, muiTheme } from '../../../../../theme';
import {
  StyledButton,
  StyledContent,
  StyledInfo,
  StyledOverView,
  StyledRoleDiv,
  StyledRoles,
  StyledRolesList,
} from './EmployeeOverviewScreen.styles';

const {
  overviewRolesPermission: {
    editRole,
    editPermission,
    editRoleDetails,
    rolesList,
    editPermissionDetailsText1,
    editPermissionDetailsText2,
    noPermissionTooltip,
  },
} = employeesTranslations;

export type EmployeeOverviewScreenProps = {
  employee: Profile;
  isCurrentUser: boolean;
  startTab: number;
  handleChangeTabs: (event: React.SyntheticEvent, value: number) => void;
  handleEditPermission: () => void;
  handleEditRole: () => void;
};

export const EmployeeOverviewScreen: FC<EmployeeOverviewScreenProps> = ({
  isCurrentUser,
  startTab,
  handleChangeTabs,
  handleEditPermission,
  handleEditRole,
}) => {
  const {
    get: { roleMatrix, userPermissions },
  } = useUser();

  const { mode } = useThemeContext();

  const { t } = useTranslation();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const canEditPermissions = userPermissions.employeePermissions.canUpdate;

  const getTooltip = () => {
    return (
      <Tooltip
        position="right-end"
        arrowedToolTip
        text={
          <Container padding={[2]}>
            <Typography color="white">{t(noPermissionTooltip.key, noPermissionTooltip.defaultValue)}</Typography>
          </Container>
        }
      >
        <Icon data-testid="infoIcon" color="primary" icon={Icons.INFO} size="small" margin={[0, 1]} />
      </Tooltip>
    );
  };

  const isEditPermissionTabDisabled = isCurrentUser || !canEditPermissions;

  const getTabs = () => {
    return [
      {
        tabName: t(editPermission.key, editPermission.defaultValue),
        isTabDisabled: isEditPermissionTabDisabled,
        isTabVisible: true,
        icon: isEditPermissionTabDisabled ? getTooltip() : <></>,
        iconPosition: 'end' as IconPositionType,
        labelType: 'button' as Typographies,
      },
      {
        tabName: t(editRole.key, editRole.defaultValue),
        isTabDisabled: !!isCurrentUser,
        isTabVisible: false, // Hide the role tab for now
        icon: !!isCurrentUser ? getTooltip() : <></>,
        iconPosition: 'end' as IconPositionType,
        labelType: 'button' as Typographies,
      },
    ];
  };

  const availableStoreRoles = Object.keys(roleMatrix.box.roleManagement).map((role) => (
    <StyledRoleDiv key={role}>
      <li data-testid="store-roles">
        <Typography>{t(`employees.roles.${role}`, role)} </Typography>
      </li>
    </StyledRoleDiv>
  ));
  return (
    <StyledOverView data-testid="overview-screen">
      <DynamicTab
        handleTabChange={handleChangeTabs}
        tabLabelArray={getTabs()}
        isCentered
        tabValue={startTab}
        alignment="space-evenly"
        selectedLabelColor="red"
        tabBgColor={mode === 'light' ? 'white' : 'inherit'}
        content={[
          {
            tabContent: (
              <StyledContent>
                <StyledInfo>
                  <Typography textAlign="center">
                    {t(editPermissionDetailsText1.key, editPermissionDetailsText1.defaultValue)}
                  </Typography>
                  <Typography gutterBottom textAlign="center">
                    {t(editPermissionDetailsText2.key, editPermissionDetailsText2.defaultValue)}
                  </Typography>
                </StyledInfo>
                <StyledButton
                  label={t(editPermission.key, editPermission.defaultValue)}
                  onClick={handleEditPermission}
                  disabled={isEditPermissionTabDisabled}
                />
              </StyledContent>
            ),
            visible: true,
          },
          {
            tabContent: (
              <StyledContent>
                <StyledRoles>
                  <Typography gutterBottom textAlign="center" padding={isMobile ? [0, 0] : [0, 12]}>
                    {t(editRoleDetails.key, editRoleDetails.defaultValue)}
                  </Typography>
                  {!isMobile && (
                    <>
                      <Typography type="body3" textAlign="center">
                        {t(rolesList.key, rolesList.defaultValue)}
                      </Typography>
                      <StyledRolesList>{availableStoreRoles}</StyledRolesList>
                    </>
                  )}
                </StyledRoles>
                <StyledButton label={t(editRole.key, editRole.defaultValue)} onClick={handleEditRole} />
              </StyledContent>
            ),
            visible: true,
          },
        ]}
      />
    </StyledOverView>
  );
};
