import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: ${({ theme: { spacing } }) => spacing(4)};
`;

export const MetricItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;
