import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Accordion, InputField } from '../../../../stories/molecules';
import { InputContainer, SetKPIContainer } from '../BulkUpdateModal.styles';
import { homeTranslations, kpiTargetTransalations } from '../../../../translations';
import { muiTheme } from '../../../../theme';
import { KpiModalType, KpiType, getErrorStatus, getKPIIcons, hasPercentageinKpi } from '../../../../constants';
import { Icons } from '../../../../stories/atoms';
import { StyledTypography } from '../../KpiTarget.styles';

export type KpiAccordionProps = {
  isModalOpen: boolean;
  kpiName: KpiType;
  isLocked: boolean;
  target: number | null;
  areaCap: number | null;
  canEditKpi: boolean;
  canEditAreaCap: boolean;
  isAccordionExpanded?: boolean;
  onkpiExpand: (value: { [key: string]: boolean }) => void;
  onKpiChange: (
    value: number | null,
    isValid: boolean,
    isUpdated: boolean,
    kpiName: KpiType,
    isAreaCap: boolean,
  ) => void;
};

const KpiAccordion: FC<KpiAccordionProps> = ({
  isModalOpen,
  kpiName,
  isLocked,
  target,
  areaCap,
  canEditKpi,
  canEditAreaCap,
  isAccordionExpanded = false,
  onkpiExpand,
  onKpiChange,
}) => {
  const { t } = useTranslation();

  const { kpiWidgets, kpiAccordion, kpiProperties, kpiLock } = kpiTargetTransalations;
  const { kpiTargets } = homeTranslations;
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const hasPercentage = hasPercentageinKpi(kpiName);

  const [targetInput, setTargetInput] = useState(target ? target.toString() : '');
  const [areaCapInput, setAreaCapInput] = useState(areaCap ? areaCap.toString() : '');

  // handle accordion expand
  const handleAccordionExpand = () => {
    onkpiExpand({
      [kpiName]: !isAccordionExpanded,
    });
  };

  // handle isUpdated for input field
  const checkCompleted = (inputValue: string, value: number | null) => {
    return value ? inputValue !== value.toString() : inputValue !== '';
  };

  // handle validation for input fields
  const targetValidationError = getErrorStatus(hasPercentage, targetInput, target);
  const areaCapValidationError = getErrorStatus(hasPercentage, areaCapInput, areaCap);

  const targetErrorTextKey = targetValidationError.text
    ? (targetValidationError.text as keyof typeof kpiTargets)
    : null;

  const areaCapErrorTextKey = areaCapValidationError.text
    ? (areaCapValidationError.text as keyof typeof kpiTargets)
    : null;

  const computeUpdatedItems = (value: string, isAreaCap: boolean) => {
    const updatedValue = value === '' ? null : parseInt(value);
    const isValid = isAreaCap
      ? !getErrorStatus(hasPercentage, value, areaCap).status
      : !getErrorStatus(hasPercentage, value, target).status;

    const isUpdated = isAreaCap ? checkCompleted(value, areaCap) : checkCompleted(value, target);
    onKpiChange(updatedValue, isValid, isUpdated, kpiName, isAreaCap);
  };

  const handleTargetInputChange = (value: string) => {
    setTargetInput(value);
    computeUpdatedItems(value, false);
  };

  const handleAreaCapInputChange = (value: string) => {
    setAreaCapInput(value);
    computeUpdatedItems(value, true);
  };

  // handle modal error
  const isFormError = targetValidationError.status || areaCapValidationError.status;

  const isTargetUpdated = checkCompleted(targetInput, target);
  const isAreaCapUpdated = checkCompleted(areaCapInput, areaCap);

  useEffect(() => {
    setTargetInput(target ? target.toString() : '');
    setAreaCapInput(areaCap ? areaCap.toString() : '');
  }, [isModalOpen]);

  return (
    <Accordion
      headerText={t(kpiWidgets[kpiName].key, kpiWidgets[kpiName].defaultValue)}
      isCompleted={isFormError || isTargetUpdated || isAreaCapUpdated}
      isCompletedIcon={isFormError ? Icons.CLOSE : Icons.CHECK_MARK}
      iconColor={isFormError ? 'red' : 'validationGreen'}
      icon={Icons[getKPIIcons(kpiName)]}
      isExpanded={isAccordionExpanded}
      handleExpanded={() => handleAccordionExpand()}
      isLocked={isLocked}
      lockedDetails={
        <StyledTypography data-testid="target-locked" type="body3" padding={[1, 2]} margin={[0, 2, 0]}>
          {t(kpiLock.locked.key, kpiLock.locked.defaultValue)}
        </StyledTypography>
      }
      accordionDetails={
        <SetKPIContainer data-testid="kpi-container-accordion">
          {canEditKpi && (
            <InputContainer
              margin={canEditAreaCap ? [0, 0, 0, 12] : [0, 2, 0, 12]}
              data-testid="target-input-container"
            >
              <InputField
                label={t(kpiProperties.target.title.key, kpiProperties.target.title.defaultValue)}
                placeholder={t(kpiAccordion.targetPlaceholder.key, kpiAccordion.targetPlaceholder.defaultValue)}
                onChange={(value) => handleTargetInputChange(value)}
                size={isTablet ? 'fullWidth' : canEditAreaCap ? 'medium' : 'large'}
                status={targetValidationError.status ? 'error' : 'success'}
                validateOnEmpty
                helperText={
                  targetErrorTextKey !== null
                    ? t(kpiTargets[targetErrorTextKey].key, {
                        defaultValue: kpiTargets[targetErrorTextKey].defaultValue,
                        modalType: KpiModalType.KPI,
                      })
                    : ''
                }
                initialValue={targetInput}
                isDisabled={isLocked}
              />
            </InputContainer>
          )}
          {canEditAreaCap && (
            <InputContainer margin={[0, 2, 0, 4]} data-testid="areacap-input-container">
              <InputField
                label={t(kpiProperties.areaCap.title.key, kpiProperties.areaCap.title.defaultValue)}
                placeholder={t(kpiAccordion.areaCapPlaceholder.key, kpiAccordion.areaCapPlaceholder.defaultValue)}
                onChange={(value) => handleAreaCapInputChange(value)}
                initialValue={areaCapInput}
                status={areaCapValidationError.status ? 'error' : 'success'}
                validateOnEmpty
                helperText={
                  areaCapErrorTextKey !== null
                    ? t(kpiTargets[areaCapErrorTextKey].key, {
                        defaultValue: kpiTargets[areaCapErrorTextKey].defaultValue,
                        modalType: KpiModalType.AREA_CAP,
                      })
                    : ''
                }
                size={isTablet ? 'fullWidth' : 'medium'}
              />
            </InputContainer>
          )}
        </SetKPIContainer>
      }
    />
  );
};

export default KpiAccordion;
