import styled from 'styled-components';
import { Container, Icon } from '../../../../../stories/atoms';

export const StyledTenderContainer = styled(Container)`
  align-items: center;
  flex-wrap: nowrap;
`;

export const StyledTableWrapper = styled.div`
  height: 100%;
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(10)};
  height: ${({ theme: { spacing } }) => spacing(10)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.white : color.borderGray};
  justify-content: center;
  color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.primary};
`;
