import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { BudgetPlanned, TrendEnum } from '../../../../../constants/budgetPlanned';
import { BudgetPlannedData, EffectiveHoursResponse, useTransaction, useUser } from '../../../../../hooks';
import { useAsyncAction } from '../../../../../hooks/useAsyncAction';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { ContainerDrawer } from '../../../../../stories/molecules/container-drawer/ContainerDrawer';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { computeBudgetPlannedData } from '../../../../../utils/budgetPlanned';
import {
  BudgetPlannedContent,
  getBudgetAndPlannedPermissions,
} from '../../../common/budgetPlannedContent/BudgetPlannedContent';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import {
  StyledCircleContainer,
  StyledContainer,
  StyledMenuWrapper,
  StyledView,
} from './EffectiveHoursWidget.styles';

const {
  common,
  effectiveHours: effectiveHoursTranslation,
  budgetPlanned: budgetPlannedTranslation,
  salesNet,
} = homeTranslations;

export type EffectiveHoursWidgetProps = {
  isToday: boolean;
  currentDate: Date;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export const EffectiveHoursWidget: FC<EffectiveHoursWidgetProps> = observer(
  ({ isToday, currentDate, interval, fromDate, toDate }) => {
    const { t } = useTranslation();
    const {
      get: { userPermissions },
    } = useUser();
    const { fetchEffectiveHoursData, fetchEffectiveHoursBudget } = useTransaction();

    const {
      result: effectiveHours,
      isLoading: effectiveHoursLoading,
      isError: effectiveHoursError,
      triggerAction: triggerFetchEffectiveHours,
    } = useAsyncAction<EffectiveHoursResponse>();

    const {
      result: budgetPlanned,
      isLoading: budgetPlannedLoading,
      isError: budgetPlannedError,
      triggerAction: triggerFetchBudgetPlanned,
    } = useAsyncAction<BudgetPlannedData>();

    const [isBudgetSliderOpen, setIsBudgetSliderOpen] = useState(false);

    const { canViewBudget, canViewPlanned, showBudgetAndPlanned } =
      getBudgetAndPlannedPermissions(userPermissions);

    const fetchEffectiveHours = async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchEffectiveHoursData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    };

    const fetchBudgetPlanned = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchEffectiveHoursBudget(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    };

    const fetchWidgetData = () => {
      triggerFetchEffectiveHours(fetchEffectiveHours);
      triggerFetchBudgetPlanned(fetchBudgetPlanned);
    };

    useEffect(() => {
      fetchWidgetData();
    }, [currentDate, interval]);

    const {
      todayValue: todaysEffectiveHours,
      budgetValue: effectivehoursBudget,
      plannedValue: effectivehoursPlanned,
      budgetCalc,
      plannedCalc,
    } = computeBudgetPlannedData(effectiveHours ? effectiveHours.today : null, budgetPlanned);

    const budgetData: BudgetPlanned = {
      trend: budgetCalc < 100 ? TrendEnum.DOWN : TrendEnum.UP,
      data: effectivehoursBudget.toLocaleString(),
      percentage: budgetCalc,
    };
    const plannedData: BudgetPlanned = {
      trend: plannedCalc < 100 ? TrendEnum.DOWN : TrendEnum.UP,
      data: effectivehoursPlanned.toLocaleString(),
      percentage: plannedCalc,
    };

    const isNoData = !!effectiveHours && Object.keys(effectiveHours).length === 0;

    const todayValue = isToday ? effectivehoursPlanned : todaysEffectiveHours;

    return (
      <ContainerDrawer
        isOpen={isBudgetSliderOpen}
        onClose={() => setIsBudgetSliderOpen(false)}
        sliderContent={
          <BudgetPlannedContent
            canViewBudget={canViewBudget}
            canViewPlanned={canViewPlanned}
            budget={budgetData}
            planned={plannedData}
            budgetNoData={budgetPlannedError || budgetPlanned?.budget === 0}
            plannedNoData={budgetPlannedError || budgetPlanned?.planned === 0}
          />
        }
        width="250px"
      >
        <WidgetCardContent
          headerIcon={Icons.SALESEFFICIENCY}
          headerText={t(effectiveHoursTranslation.title.key, effectiveHoursTranslation.title.defaultValue)}
          lastUpdatedDate={effectiveHours?.lastUpdated}
          isLoading={effectiveHoursLoading || budgetPlannedLoading}
          isError={effectiveHoursError}
          errorText={t(effectiveHoursTranslation.error.key, effectiveHoursTranslation.error.defaultValue)}
          errorRetryHandler={fetchWidgetData}
          isNoData={isNoData}
          headerSideContent={
            <StyledMenuWrapper>
              <DropdownMenu
                data-testid="kpiDropdown"
                dropdownLabel={''}
                buttonContentProps={{
                  iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
                }}
                menuItems={[
                  {
                    key: 'showBudget',
                    disabled: !showBudgetAndPlanned,
                    hasTooltip: !showBudgetAndPlanned,
                    tooltipText: (
                      <Container padding={[1]}>
                        <Typography color="white">
                          {t(
                            budgetPlannedTranslation.noPermissionBudgetTooltip.key,
                            budgetPlannedTranslation.noPermissionBudgetTooltip.defaultValue,
                          )}
                        </Typography>
                      </Container>
                    ),
                    item: (
                      <Typography margin={[0, 2, 0]}>
                        {t(
                          budgetPlannedTranslation.budgetDropdown.key,
                          budgetPlannedTranslation.budgetDropdown.defaultValue,
                        )}
                      </Typography>
                    ),
                  },
                ]}
                minWidth={'0px'}
                isCompact
                initialSelected={[]}
                onSelect={(selectedItems) => {
                  if (selectedItems[0] === 'showBudget') {
                    setIsBudgetSliderOpen(true);
                  }
                }}
                isOnlyIcon
                allowTooltip
              />
            </StyledMenuWrapper>
          }
          subHeaderText={t(
            effectiveHoursTranslation.subTitle.key,
            effectiveHoursTranslation.subTitle.defaultValue,
          )}
          content={
            <>
              <StyledView wrap="nowrap" data-testid="effective-hours-wrapper" height="100%">
                <StyledCircleContainer data-testid="todays-hours">
                  <Icon margin={[0, 0, 1]} icon={Icons.CLOCK} size="large" />
                  <Typography type="h4" margin={[0, 0, 3, 0]}>
                    {Math.round(todayValue).toString()}
                  </Typography>
                  <Typography color="textGray">
                    {isToday
                      ? t(budgetPlannedTranslation.planned.key, budgetPlannedTranslation.planned.defaultValue)
                      : t(salesNet.actual.key, salesNet.actual.defaultValue)}
                  </Typography>
                </StyledCircleContainer>
                <StyledContainer data-testid="historical-data-wrapper" width="90%">
                  <div>
                    <Typography gutterBottom>{t(common.lastWeek.key, common.lastWeek.defaultValue)}</Typography>
                    <StyledTrendIconComponent
                      originalAmount={todaysEffectiveHours}
                      previousAmount={effectiveHours ? effectiveHours.lastWeek : 0}
                      direction="horizontal"
                      valueFormatter={(value) => Math.round(value).toString()}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>{t(common.lastYear.key, common.lastYear.defaultValue)}</Typography>
                    <StyledTrendIconComponent
                      originalAmount={todaysEffectiveHours}
                      previousAmount={effectiveHours ? effectiveHours.lastYear : 0}
                      direction="horizontal"
                      valueFormatter={(value) => Math.round(value).toString()}
                    />
                  </div>
                </StyledContainer>
              </StyledView>
            </>
          }
        />
      </ContainerDrawer>
    );
  },
);
