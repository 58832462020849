import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2, 4)};
  gap: ${({ theme: { spacing } }) => spacing(2)};
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === 'dark' ? theme.color.borderGrayDark : theme.color.borderGray)};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
`;

export const IndicatorContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const Indicator = styled.div`
  width: ${({ theme: { spacing } }) => spacing(18)};
  height: ${({ theme: { spacing } }) => spacing(18)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${({ theme: { color } }) => color.backgroundSelectedDark};
`;
