import { MenuItem } from '@mui/material';
import styled from 'styled-components';
import { Icon, Typography } from '../../../stories/atoms';

export const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

export const StyledAllFiltersModal = styled.div`
  animation: slide 0.2s forwards;

  @keyframes slide {
    0% {
      transform: translateX(450px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`;

export const StyledFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(6)};
  .MuiMenuItem-root {
    padding: ${({ theme }) => theme.spacing(0, 10)};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
  position: absolute;
  width: 100%;
  bottom: ${({ theme }) => theme.spacing(6)};
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: inherit;
  justify-content: space-evenly;
  padding: ${({ theme }) => theme.spacing(6)};

  > button:first-child {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const StyledDatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing(6, 0)};

  .MuiMenuItem-root {
    width: 100%;
    padding: ${({ theme }) => theme.spacing(4, 12, 4, 12)};
  }

  .Mui-selected {
    background-color: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.backgroundPaperDark : color.backgroundSelected};
  }
`;

export const StyledCountIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCountTypography = styled(Typography)`
  color: ${({ theme: { color } }) => color.textGray};
`;
