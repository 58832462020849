// TODO: Remove this when fixing the unit tests - BTCOBUI-1952
/* istanbul ignore file */

import { useEffect, useState } from 'react';
import { SkeletonLoader } from '../../../stories/atoms';
import { ToggleButton } from '../../../stories/molecules/toggleButton/ToggleButton';
import { GoogleMapView } from './GoogleMapView';
import { Container, MapContainer } from './Map.styles';
import { TencentMapView } from './TencentMapView';

type MapProvider = 'Google' | 'Tencent';

export type CenterCoordinates = {
  latitude: number;
  longitude: number;
};

export type MarkerDetails = {
  id: string;
  latitude: number;
  longitude: number;
};

type MapProps = {
  prefferedProvider: MapProvider;
  zoom: number;
  center: CenterCoordinates;
  selectedMarkerId: string;
  markers: MarkerDetails[];
  panOnMarkerSelect?: boolean;
  onMarkerSelectZoom?: number;
  onMarkerClick: (markerId: string) => void;
  getMarkerIconUrl: (markerId?: string, forceIsActive?: boolean) => string | undefined;
};

export const Map = ({
  prefferedProvider,
  zoom,
  center,
  selectedMarkerId,
  markers,
  panOnMarkerSelect = false,
  onMarkerSelectZoom = 12,
  onMarkerClick,
  getMarkerIconUrl,
}: MapProps) => {
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [isTencentMapsLoaded, setIsTencentMapsLoaded] = useState(false);

  const [selectedMapProvider, setSelectedMapProvider] = useState<MapProvider>(prefferedProvider);

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, view: string) => {
    if (view === 'view1') {
      setSelectedMapProvider('Google');
    }

    if (view === 'view2') {
      setSelectedMapProvider('Tencent');
    }
  };

  useEffect(() => {
    if (isGoogleMapsLoaded && !isTencentMapsLoaded) {
      setSelectedMapProvider('Google');
    }

    if (isTencentMapsLoaded && !isGoogleMapsLoaded) {
      setSelectedMapProvider('Tencent');
    }
  }, [isGoogleMapsLoaded, isTencentMapsLoaded]);

  return (
    <Container>
      {!isGoogleMapsLoaded && !isTencentMapsLoaded && <SkeletonLoader variant="rect" height="100%" width="100%" />}

      {isGoogleMapsLoaded && isTencentMapsLoaded && (
        <ToggleButton
          value={selectedMapProvider === 'Google' ? 'view1' : 'view2'}
          onChange={handleChangeView}
          leftButton={'Google'}
          rightButton={'Tencent'}
        />
      )}

      <MapContainer isVisible={selectedMapProvider === 'Google' && isGoogleMapsLoaded}>
        <GoogleMapView
          zoom={zoom}
          center={center}
          selectedMarkerId={selectedMarkerId}
          markers={markers}
          panOnMarkerSelect={panOnMarkerSelect}
          onMarkerSelectZoom={onMarkerSelectZoom}
          onLoaded={() => setIsGoogleMapsLoaded(true)}
          onMarkerClick={onMarkerClick}
          getMarkerIconUrl={getMarkerIconUrl}
        />
      </MapContainer>

      <MapContainer isVisible={selectedMapProvider === 'Tencent' && isTencentMapsLoaded}>
        <TencentMapView
          zoom={zoom}
          center={center}
          selectedMarkerId={selectedMarkerId}
          markers={markers}
          panOnMarkerSelect={panOnMarkerSelect}
          onMarkerSelectZoom={onMarkerSelectZoom}
          onLoaded={() => setIsTencentMapsLoaded(true)}
          onMarkerClick={onMarkerClick}
          getMarkerIconUrl={getMarkerIconUrl}
        />
      </MapContainer>
    </Container>
  );
};
