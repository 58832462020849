import styled from 'styled-components';
import { Container, Icon } from '../../../stories/atoms';

export const StyledContainer = styled(Container)<{ isDesktop?: boolean }>`
  width: ${({ theme: { spacing }, isDesktop }) => (isDesktop ? spacing(150) : '')};
  padding: ${({ theme: { spacing } }) => spacing(8)};
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(0, 2)};
`;

export const StyledContent = styled(Container)`
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  &:hover {
    background-color: ${({ theme: { palette, color } }) =>
      palette.mode === 'dark' ? color.backgroundDark : color.baseGray};
  }
`;

export const StyledCheckboxContainer = styled.div<{ isTablet: boolean }>`
  min-height: ${({ isTablet }) => (isTablet ? 'calc(100vh - max(240px, 5vh))' : '50vh')};
  max-height: ${({ isTablet }) => (isTablet ? 'calc(100vh - max(240px, 5vh))' : '50vh')};
  overflow: auto;
  margin: ${({ theme: { spacing } }) => spacing(0, 0, 2)};
`;

export const StyledCheckboxHeader = styled(Container)`
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedDark : color.borderGray};
  justify-content: center;
  cursor: pointer;
`;
