import { GarmentResponse, ProductIndexItem, TopReturningResponse, TopSellingResponse } from '../../hooks';

export const mockProductIndex: ProductIndexItem[] = [
  {
    id: '1001223',
    lastUpdated: '2024-07-01T13:15:26.333Z',
    productName: 'Olga 5pk Thong Seamless MR',
    departmentName: 'Lingerie Shopbasket',
    departmentNumber: '1336',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Womens Underwear',
    sectionName: 'Womens Lingerie',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Lingeries/Tights',
    subindex: 'Womens Lingerie',
    customerGroup: 'Womenswear',
    garmentGroup: 'Under-, Nightwear',
    salesNet: 4347.1,
    salesNetLastWeek: 8994,
    salesNetLastYear: 0,
  },
  {
    id: '1002227',
    lastUpdated: '2024-07-02',
    productName: 'AT Trey Tapered Cargo',
    departmentName: 'Trousers Style Platforms',
    departmentNumber: '5686',
    season: 202409,
    whitePrice: 2699,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Trousers',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Menswear Collection',
    customerGroup: 'Mens',
    garmentGroup: 'Trousers',
    salesNet: 22806.55,
    salesNetLastWeek: 27799.699999999997,
    salesNetLastYear: 26720.1,
  },
  {
    id: '1002288',
    lastUpdated: '2024-07-01T13:15:26.726Z',
    productName: 'Sigge Dressed Belt',

    departmentName: 'Belts',
    departmentNumber: '7389',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Footwear, Bags and Belts',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Accessories',
    customerGroup: 'Mens',
    garmentGroup: 'Accessories',
    salesNet: 2848.1,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1003662',
    lastUpdated: '2024-07-01T13:15:29.015Z',
    productName: 'JEFF LO RN T-SHIRT',

    departmentName: 'Light Basic Jersey',
    departmentNumber: '5832',
    season: 202409,
    whitePrice: 699,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Basic',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'H&M Men Basics',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Basic',
    salesNet: 1398,
    salesNetLastWeek: 3285.2999999999997,
    salesNetLastYear: 2027.1,
  },
  {
    id: '1004335',
    lastUpdated: '2024-10-18',
    productName: 'PQ Paris Leather Glove',

    departmentName: 'Soft Accessories',
    departmentNumber: '3519',
    season: 202410,
    whitePrice: 1899,
    currency: 'INR',
    divisionName: 'Womens Accessories',
    sectionName: 'Womens Big accessories',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear Accessories',
    subindex: 'Womens Accessories',
    customerGroup: 'Womenswear',
    garmentGroup: 'Accessories',
    salesNet: 1709.1,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1007334',
    lastUpdated: '2024-10-18',
    productName: '5pk basic regular socks',

    departmentName: 'Socks Bin',
    departmentNumber: '7188',
    season: 202410,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men UW, Socks & Lounge',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Under / Socks / Swim',
    customerGroup: 'Mens',
    garmentGroup: 'Socks and Tights',
    salesNet: 4474.4,
    salesNetLastWeek: 756.92,
    salesNetLastYear: 0,
  },
  {
    id: '1008549',
    lastUpdated: '2024-07-11',
    productName: 'Straight Regular LP Jeans',

    departmentName: 'Denim trousers',
    departmentNumber: '5672',
    season: 202409,
    whitePrice: 1799,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Denim',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Denim Men',
    customerGroup: 'Mens',
    garmentGroup: 'Trousers Denim',
    salesNet: 39595.98999999999,
    salesNetLastWeek: 16716.800000000003,
    salesNetLastYear: 11213.300000000001,
  },
  {
    id: '1009288',
    lastUpdated: '2024-07-02',
    productName: 'Lilly leggings',

    departmentName: 'Kids Girl Jersey Fancy',
    departmentNumber: '7616',
    season: 202409,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Kids',
    sectionName: 'Kids Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    customerGroup: 'Kids',
    garmentGroup: 'Jersey Fancy',
    salesNet: 3076.15,
    salesNetLastWeek: 0,
    salesNetLastYear: 2237.2,
  },
  {
    id: '1010215',
    lastUpdated: '2024-07-01T13:15:26.628Z',
    productName: 'TRUNKS LIC KB 5PACK',

    departmentName: 'Kids Boy UW/NW',
    departmentNumber: '4215',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Kids',
    sectionName: 'Kids Essentials',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Boy 92-140. Basic, NW, UW & Socks',
    customerGroup: 'Kids',
    garmentGroup: 'Under-, Nightwear',
    salesNet: 1499,
    salesNetLastWeek: 0,
    salesNetLastYear: 1499,
  },
  {
    id: '1010387',
    lastUpdated: '2024-07-01T13:15:26.022Z',
    productName: 'Wow Printed Regular Hood 19.9',

    departmentName: 'Heavy Graphic Jersey',
    departmentNumber: '7273',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Graphics',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Menswear Collection',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Fancy',
    salesNet: 2848.1,
    salesNetLastWeek: 2848.1,
    salesNetLastYear: 2998,
  },
  {
    id: '1011444',
    lastUpdated: '2024-07-02',
    productName: 'Camille 5pk hipster MR',

    departmentName: 'Lingerie Shopbasket',
    departmentNumber: '1336',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Womens Underwear',
    sectionName: 'Womens Lingerie',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Lingeries/Tights',
    subindex: 'Womens Lingerie',
    customerGroup: 'Womenswear',
    garmentGroup: 'Under-, Nightwear',
    salesNet: 1499,
    salesNetLastWeek: 1499,
    salesNetLastYear: 1499,
  },
  {
    id: '1011828',
    lastUpdated: '2024-07-02',
    productName: 'Sweater crew neck Miho',

    departmentName: 'Jersey Tops (ED Jersey)',
    departmentNumber: '1636',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Womens Jersey & Knitwear',
    sectionName: 'Womens Jersey Fancy',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'Womenswear Collection',
    customerGroup: 'Womenswear',
    garmentGroup: 'Jersey Fancy',
    salesNet: 6495,
    salesNetLastWeek: 1299,
    salesNetLastYear: 14762.52,
  },
  {
    id: '1011890',
    lastUpdated: '2024-07-02',
    productName: 'OWEN LO ZIP HOODIE',

    departmentName: 'Heavy Basic Jersey',
    departmentNumber: '5833',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Basic',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'H&M Men Basics',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Basic',
    salesNet: 4047.2999999999997,
    salesNetLastWeek: 2848.1,
    salesNetLastYear: 4497,
  },
  {
    id: '1012056',
    lastUpdated: '2024-07-01T13:15:27.124Z',
    productName: 'EZRA LO JOGGERS',

    departmentName: 'Heavy Basic Jersey',
    departmentNumber: '5833',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Basic',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'H&M Men Basics',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Basic',
    salesNet: 15683.6,
    salesNetLastWeek: 8469.25,
    salesNetLastYear: 4272.15,
  },
  {
    id: '1013956',
    lastUpdated: '2024-07-01T13:15:29.527Z',
    productName: 'AU Miles Reg Oxford Shirt',

    departmentName: 'Shirts Big Products',
    departmentNumber: '5767',
    season: 202409,
    whitePrice: 1499,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Shirts',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Contemporary Casual',
    customerGroup: 'Mens',
    garmentGroup: 'Shirts',
    salesNet: 999,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1014750',
    lastUpdated: '2024-10-18',
    productName: '3p Trainer Shaftless',

    departmentName: 'Wall Socks',
    departmentNumber: '3610',
    season: 202410,
    whitePrice: 699,
    currency: 'INR',
    divisionName: 'Womens Underwear',
    sectionName: 'Womens Nightwear, Socks & Tights',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Lingeries/Tights',
    subindex: 'Womens Socks & Tights',
    customerGroup: 'Womenswear',
    garmentGroup: 'Socks and Tights',
    salesNet: 699,
    salesNetLastWeek: 699,
    salesNetLastYear: 1598,
  },
  {
    id: '1016734',
    lastUpdated: '2024-09-11',
    productName: 'Joy mini plates XMAS',

    departmentName: 'HD table',
    departmentNumber: '9232',
    season: 202410,
    whitePrice: 199,
    currency: 'INR',
    divisionName: 'Home',
    sectionName: 'Destination Eat',
    indexGroupName: 'Home',
    indexDescription: 'Home',
    subindex: 'Eat',
    customerGroup: 'Lifestyle Brands',
    garmentGroup: 'Hard Goods',
    salesNet: 1074.6,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020258',
    lastUpdated: '2024-07-02',
    productName: 'Maxi claw',

    departmentName: 'Hair',
    departmentNumber: '4343',
    season: 202409,
    whitePrice: 249,
    currency: 'INR',
    divisionName: 'Womens Accessories',
    sectionName: 'Womens Small accessories',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear Accessories',
    subindex: 'Womens Small Accessories',
    customerGroup: 'Womenswear',
    garmentGroup: 'Accessories',
    salesNet: 722.1,
    salesNetLastWeek: 747,
    salesNetLastYear: 249,
  },
  {
    id: '1023822',
    lastUpdated: '2024-08-19',
    productName: 'EQ Palmita tee TVP',

    departmentName: 'Kids Girl Equatorial',
    departmentNumber: '7820',
    season: 202410,
    whitePrice: 399,
    currency: 'INR',
    divisionName: 'Kids',
    sectionName: 'Kids Local Relevance',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    customerGroup: 'Kids',
    garmentGroup: 'Special Offers ',
    salesNet: 399,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1023823',
    lastUpdated: '2024-08-13',
    productName: 'EQ Dragonfly dress TVP',

    departmentName: 'Kids Girl Equatorial',
    departmentNumber: '7820',
    season: 202410,
    whitePrice: 399,
    currency: 'INR',
    divisionName: 'Kids',
    sectionName: 'Kids Local Relevance',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    customerGroup: 'Kids',
    garmentGroup: 'Special Offers ',
    salesNet: 758.1,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1023835',
    lastUpdated: '2024-08-11',
    productName: 'EQ Happy tee TVP',

    departmentName: 'Kids Boy Equatorial',
    departmentNumber: '7878',
    season: 202410,
    whitePrice: 299,
    currency: 'INR',
    divisionName: 'Kids',
    sectionName: 'Kids Local Relevance',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Boy 92-140 H&M Kids',
    customerGroup: 'Kids',
    garmentGroup: 'Special Offers ',
    salesNet: 1734.2,
    salesNetLastWeek: 1136.2,
    salesNetLastYear: 0,
  },
  {
    id: '1024256',
    lastUpdated: '2024-07-02',
    productName: 'Slim LP Jeans',

    departmentName: 'Denim trousers',
    departmentNumber: '5672',
    season: 202409,
    whitePrice: 1799,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Denim',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Denim Men',
    customerGroup: 'Mens',
    garmentGroup: 'Trousers Denim',
    salesNet: 5397.299999999999,
    salesNetLastWeek: 0,
    salesNetLastYear: 6516.88,
  },
  {
    id: '1024674',
    lastUpdated: '2024-10-18',
    productName: 'Unknown Product Name',
    departmentName: 'Unknown Department Name',
    departmentNumber: 'Unknown Department Number',
    season: 0,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    customerGroup: 'UKN',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 2429.1,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1024711',
    lastUpdated: '2024-07-01T13:16:26.324Z',
    productName: 'Slim MP Jeans',

    departmentName: 'Denim trousers',
    departmentNumber: '5672',
    season: 202409,
    whitePrice: 2299,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Denim',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Denim Men',
    customerGroup: 'Mens',
    garmentGroup: 'Trousers Denim',
    salesNet: 17317.78,
    salesNetLastWeek: 4598,
    salesNetLastYear: 2069.1,
  },
  {
    id: '1028144',
    lastUpdated: '2024-07-02',
    productName: 'CU Zed Lse SSL',

    departmentName: 'Shirt Street',
    departmentNumber: '5783',
    season: 202409,
    whitePrice: 1299,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Shirts',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Contemporary Street',
    customerGroup: 'Mens',
    garmentGroup: 'Shirts',
    salesNet: 3652.35,
    salesNetLastWeek: 1299,
    salesNetLastYear: 0,
  },
  {
    id: '1029317',
    lastUpdated: '2024-07-01T13:15:26.419Z',
    productName: 'ROMAN RG LS T-SHIRT',

    departmentName: 'Light Basic Jersey',
    departmentNumber: '5832',
    season: 202409,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Basic',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'H&M Men Basics',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Basic',
    salesNet: 5988.83,
    salesNetLastWeek: 0,
    salesNetLastYear: 1518.1,
  },
  {
    id: '1032136',
    lastUpdated: '2024-07-01T13:16:24.041Z',
    productName: 'Greta 3pk Hipster CT MR',

    departmentName: 'Lingerie Tops (Expressive & Romantic)',
    departmentNumber: '1338',
    season: 202409,
    whitePrice: 999,
    currency: 'INR',
    divisionName: 'Womens Underwear',
    sectionName: 'Womens Lingerie',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Lingeries/Tights',
    subindex: 'Womens Lingerie',
    customerGroup: 'Womenswear',
    garmentGroup: 'Under-, Nightwear',
    salesNet: 1518.1,
    salesNetLastWeek: 799,
    salesNetLastYear: 2381.24,
  },
  {
    id: '1032522',
    lastUpdated: '2024-07-01T13:15:28.158Z',
    productName: 'Lux LO tee',

    departmentName: 'Light Graphic Jersey',
    departmentNumber: '7272',
    season: 202409,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Graphics',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Menswear Collection',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Fancy',
    salesNet: 14068.38,
    salesNetLastWeek: 13103.6,
    salesNetLastYear: 0,
  },
  {
    id: '1033301',
    lastUpdated: '2024-07-02',
    productName: 'Class Eline hoop pk',

    departmentName: 'Jewellery',
    departmentNumber: '4344',
    season: 202409,
    whitePrice: 699,
    currency: 'INR',
    divisionName: 'Womens Accessories',
    sectionName: 'Womens Small accessories',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear Accessories',
    subindex: 'Womens Small Accessories',
    customerGroup: 'Womenswear',
    garmentGroup: 'Accessories',
    salesNet: 629.1,
    salesNetLastWeek: 2336.1,
    salesNetLastYear: 0,
  },
  {
    id: '1034065',
    lastUpdated: '2024-07-01T13:16:26.622Z',
    productName: 'Splash LO Tee 14.99',

    departmentName: 'Light Graphic Jersey',
    departmentNumber: '7272',
    season: 202409,
    whitePrice: 1299,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Graphics',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Menswear Collection',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Fancy',
    salesNet: 6179.35,
    salesNetLastWeek: 9382.5,
    salesNetLastYear: 5066.1,
  },
  {
    id: '1034574',
    lastUpdated: '2024-07-02',
    productName: 'Cozy Basic Claw',

    departmentName: 'Hair',
    departmentNumber: '4343',
    season: 202409,
    whitePrice: 399,
    currency: 'INR',
    divisionName: 'Womens Accessories',
    sectionName: 'Womens Small accessories',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear Accessories',
    subindex: 'Womens Small Accessories',
    customerGroup: 'Womenswear',
    garmentGroup: 'Accessories',
    salesNet: 269.1,
    salesNetLastWeek: 1266.1,
    salesNetLastYear: 3098.1,
  },
  {
    id: '1035207',
    lastUpdated: '2024-07-02',
    productName: 'CU JASON OZ RN T-SHIRT',

    departmentName: 'Jersey Fancy Street Inactive from S.1',
    departmentNumber: '5882',
    season: 202409,
    whitePrice: 1299,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Fancy',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Contemporary Street',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Fancy',
    salesNet: 1299,
    salesNetLastWeek: 2351.19,
    salesNetLastYear: 0,
  },
  {
    id: '1035873',
    lastUpdated: '2024-07-02',
    productName: 'Leggings Nina',

    departmentName: 'Jersey Tops (ED Jersey)',
    departmentNumber: '1636',
    season: 202409,
    whitePrice: 1299,
    currency: 'INR',
    divisionName: 'Womens Jersey & Knitwear',
    sectionName: 'Womens Jersey Fancy',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'Womenswear Collection',
    customerGroup: 'Womenswear',
    garmentGroup: 'Jersey Fancy',
    salesNet: 8891.1,
    salesNetLastWeek: 16583.4,
    salesNetLastYear: 18056.1,
  },
  {
    id: '1036648',
    lastUpdated: '2024-07-01T13:16:25.629Z',
    productName: 'Christine slacks',

    departmentName: 'Tailoring Trousers Inactive from S.2',
    departmentNumber: '1717',
    season: 202409,
    whitePrice: 1999,
    currency: 'INR',
    divisionName: 'Womens Heavy Woven',
    sectionName: 'Womens Trouser & Shorts',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'H&M Womens Tailoring',
    customerGroup: 'Womenswear',
    garmentGroup: 'Trousers',
    salesNet: 1899,
    salesNetLastWeek: 3418.2,
    salesNetLastYear: 1979.1,
  },
  {
    id: '1038701',
    lastUpdated: '2024-10-18',
    productName: 'Basic rolltops 3PACK antislip',

    departmentName: 'Baby Socks',
    departmentNumber: '6541',
    season: 202410,
    whitePrice: 499,
    currency: 'INR',
    divisionName: 'Baby',
    sectionName: 'Baby Essentials & Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Baby Sizes 50-98',
    subindex: 'Baby Underwear & Acc',
    customerGroup: 'Kids',
    garmentGroup: 'Socks and Tights',
    salesNet: 1497,
    salesNetLastWeek: 0,
    salesNetLastYear: 998,
  },
  {
    id: '1040820',
    lastUpdated: '2024-08-23',
    productName: 'EQ LIC Giggs ss tee 3PACK TVP',

    departmentName: 'Kids Boy Equatorial',
    departmentNumber: '7878',
    season: 202410,
    whitePrice: 1999,
    currency: 'INR',
    divisionName: 'Kids',
    sectionName: 'Kids Local Relevance',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Boy 92-140 H&M Kids',
    customerGroup: 'Kids',
    garmentGroup: 'Special Offers ',
    salesNet: 5597.2,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1041574',
    lastUpdated: '2024-07-01T13:16:25.335Z',
    productName: 'Lic. Comet oversize tee',

    departmentName: 'Young Girl Jersey Fancy',
    departmentNumber: '8716',
    season: 202409,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Young',
    sectionName: 'Young Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 134-170',
    subindex: 'Girl 134-170 H&M Young',
    customerGroup: 'Kids',
    garmentGroup: 'Jersey Fancy',
    salesNet: 694.68,
    salesNetLastWeek: 1438.2,
    salesNetLastYear: 0,
  },
  {
    id: '1045459',
    lastUpdated: '2024-07-02',
    productName: 'Hudson HW Wide Denim',
    departmentName: 'Trouser (ED Trouser)',
    departmentNumber: '1722',
    season: 202409,
    whitePrice: 2299,
    currency: 'INR',
    divisionName: 'Womens Heavy Woven',
    sectionName: 'Womens Trouser & Shorts',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'Womenswear Collection',
    customerGroup: 'Womenswear',
    garmentGroup: 'Trousers',
    salesNet: 50662.4,
    salesNetLastWeek: 32783.6,
    salesNetLastYear: 6322.25,
  },
  {
    id: '1045626',
    lastUpdated: '2024-10-02',
    productName: 'Unknown Product Name',
    departmentName: 'Unknown Department Name',
    departmentNumber: 'Unknown Department Number',
    season: 202410,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    customerGroup: 'UKN',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 399,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1048152',
    lastUpdated: '2024-08-28',
    productName: 'Knoxville OZ Terry Sweatshirt',
    departmentName: 'Heavy Graphic Jersey',
    departmentNumber: '7273',
    season: 202410,
    whitePrice: 1999,
    currency: 'INR',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Graphics',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Menswear Collection',
    customerGroup: 'Mens',
    garmentGroup: 'Jersey Fancy',
    salesNet: 3998,
    salesNetLastWeek: 0,
    salesNetLastYear: 1999,
  },
  {
    id: '1049251',
    lastUpdated: '2024-07-01T13:18:19.219Z',
    productName: 'Top Halle',
    departmentName: 'Jersey Tops (ED Jersey)',
    departmentNumber: '1636',
    season: 202409,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Womens Jersey & Knitwear',
    sectionName: 'Womens Jersey Fancy',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'Womenswear Collection',
    customerGroup: 'Womenswear',
    garmentGroup: 'Jersey Fancy',
    salesNet: 2317.1,
    salesNetLastWeek: 799,
    salesNetLastYear: 0,
  },
  {
    id: '1056633',
    lastUpdated: '2024-07-01T13:18:15.944Z',
    productName: 'T-shirt Tonia',
    departmentName: 'Jersey Tops (ED Jersey)',
    departmentNumber: '1636',
    season: 202409,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Womens Jersey & Knitwear',
    sectionName: 'Womens Jersey Fancy',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'Womenswear Collection',
    customerGroup: 'Womenswear',
    garmentGroup: 'Jersey Fancy',
    salesNet: 2387.21,
    salesNetLastWeek: 799,
    salesNetLastYear: 0,
  },
  {
    id: '1058911',
    lastUpdated: '2024-10-18',
    productName: 'Ebba 7PACK shaftless',
    departmentName: 'Girls Socks & Tights',
    departmentNumber: '7921',
    season: 202410,
    whitePrice: 799,
    currency: 'INR',
    divisionName: 'Young',
    sectionName: 'Young Essentials & Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140. Basic, NW, UW & Socks',
    customerGroup: 'Kids',
    garmentGroup: 'Socks and Tights',
    salesNet: 1598,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
];

function generateGarments(count: number): GarmentResponse[] {
  const categories = ['Ladieswear', 'Baby/Children', 'Menswear', 'Divided'];
  const garments: GarmentResponse[] = [];

  for (let i = 0; i < count; i++) {
    const garment: GarmentResponse = {
      title: `Garment ${i + 1}`,
      productId: Math.floor(Math.random() * 1000)
        .toString()
        .padStart(1, '0'),
      whitePrice: Math.floor(Math.random() * 500) + 50,
      articleIdentifier: Math.floor(Math.random() * 1000)
        .toString()
        .padStart(3, '0'),
      category: categories[Math.floor(Math.random() * categories.length)],
    };
    garments.push(garment);
  }

  return garments;
}

const Garments: GarmentResponse[] = generateGarments(20);

const getSellingGarments = (): TopSellingResponse => {
  const topSellingGarments = Garments.map((item) => ({
    ...item,
    soldPieces: Math.floor(Math.random() * 10),
    moneyGenerated: `${Math.floor(Math.random() * 10000)}`,
  }));

  return {
    topSellingGarments,
    lastUpdated: new Date(),
  };
};

const getReturningGarments = (): TopReturningResponse => {
  const topReturningGarments = Garments.map((item) => {
    const storeReturnPieces = Math.floor(Math.random() * (50 + 1));
    const onlineReturnPieces = Math.floor(Math.random() * (50 + 1));

    // Ensure some items have either storeReturnPieces or onlineReturnPieces as 0
    const randomCase = Math.random();
    if (randomCase < 0.33) {
      return {
        ...item,
        storeReturnPieces,
        onlineReturnPieces: 0,
      };
    } else if (randomCase < 0.66) {
      return {
        ...item,
        storeReturnPieces: 0,
        onlineReturnPieces,
      };
    } else {
      return {
        ...item,
        storeReturnPieces,
        onlineReturnPieces,
      };
    }
  });

  return {
    topReturningGarments,
    lastUpdated: new Date(),
  };
};

export const getGarments = (type: 'selling' | 'returning') => {
  return type === 'selling' ? getSellingGarments() : getReturningGarments();
};
