import { Icons } from '../stories/atoms';

// Mapping from corporateBrandId to Icons
export const corporateBrandIcons: Record<number, Icons> = {
  0: Icons.HM_LOGO,
  1: Icons.COS,
  2: Icons.MONKI,
  3: Icons.WEEKDAY,
  4: Icons.CHEAPMONDAY,
  5: Icons.OTHERSTORIES,
  6: Icons.ARKET,
  7: Icons.AFOUND,
  9: Icons.PLEAT,
};
