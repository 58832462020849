import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Typography } from '../../atoms';

export const StyledContainer = styled.div`
  grid-area: sideMenu;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4, 0)};
  margin: ${({ theme }) => theme.spacing(4)};
  justify-content: space-evenly;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedDark : color.baseGray};
  border-radius: ${({ theme }) => theme.spacing(3)};
`;

export const StyledProfileImage = styled.img`
  display: flex;
  align-self: center;
  height: ${({ theme }) => theme.spacing(12)};
  margin: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

export const StyledProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const StyledSection = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledLinkWrapper = styled.div<{ isVisited?: boolean }>`
  display: flex;
  background-color: ${({ theme: { color, palette }, isVisited }) =>
    isVisited && (palette.mode === 'dark' ? color.backgroundSelectedDark : color.backgroundMenuActive)};
  border-right: ${({ theme: { color }, isVisited }) => (isVisited ? `3px solid ${color.red}` : 'none')};
  &:hover {
    background-color: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.backgroundSelectedDark : color.baseGray};
  }
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${({ theme: { color } }) => color.borderGray};
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  text-transform: capitalize;
`;

export const StyledNavLink = styled(Link)`
  text-decoration: none;
`;

export const StyledIcon = styled(Icon)`
  transform: scale(0.75);
  width: unset;
`;
