import { KpiType } from '../constants';
import { getClippedTargetItems, getKpiDistribution } from '../utils';
import { useUser } from './useController';
import { StorePerformanceItem } from './useTransaction';

type KpiDistriubutionParams = {
  kpiName: KpiType;
  performanceItems: StorePerformanceItem[];
};

export const useKpiTargetDistriubution = () => {
  const {
    get: { kpiTargetsProps },
  } = useUser();

  const getKpiTargetDistriubution = (params: KpiDistriubutionParams) => {
    const { kpiName, performanceItems } = params;
    const kpiDistribution = getKpiDistribution(kpiTargetsProps, kpiName);

    return getClippedTargetItems({
      kpiDistribution,
      performanceItems,
    });
  };

  return { getKpiTargetDistriubution };
};
