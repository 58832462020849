export const commonTranslations = {
  cancel: {
    key: 'common.cancel',
    defaultValue: 'Cancel',
  },
  close: {
    key: 'common.close',
    defaultValue: 'Close',
  },
  back: {
    key: 'common.back',
    defaultValue: 'Back',
  },
  yes: {
    key: 'common.yes',
    defaultValue: 'Yes',
  },
  no: {
    key: 'common.no',
    defaultValue: 'No',
  },
  you: {
    key: 'common.you',
    defaultValue: 'You',
  },
  create: {
    key: 'common.create',
    defaultValue: 'Create',
  },
  update: {
    key: 'common.update',
    defaultValue: 'Update',
  },
  read: {
    key: 'common.read',
    defaultValue: 'Read',
  },
  delete: {
    key: 'common.delete',
    defaultValue: 'Delete',
  },
  selectedFilters: {
    key: 'common.selectedFilters',
    defaultValue: 'Filters Selected:',
  },
  tryAgain: {
    key: 'common.tryAgain',
    defaultValue: 'Try Again',
  },
  home: {
    key: 'common.home',
    defaultValue: 'Go Home',
  },
  confirm: {
    key: 'common.confirm',
    defaultValue: 'Confirm',
  },
  loading: {
    key: 'common.loading',
    defaultValue: 'Loading BoX, the Customer Centric Store App with supplies...',
  },
  loadMore: {
    key: 'common.loadMore',
    defaultValue: 'Load More',
  },
  next: {
    key: 'common.next',
    defaultValue: 'Next',
  },
  complete: {
    key: 'common.complete',
    defaultValue: 'Complete',
  },
  mon: {
    key: 'common.monday',
    defaultValue: 'Monday',
  },
  tue: {
    key: 'common.tuesday',
    defaultValue: 'Tuesday',
  },
  wed: {
    key: 'common.wednesday',
    defaultValue: 'Wednesday',
  },
  thu: {
    key: 'common.thursday',
    defaultValue: 'Thursday',
  },
  fri: {
    key: 'common.friday',
    defaultValue: 'Friday',
  },
  sat: {
    key: 'common.saturday',
    defaultValue: 'Saturday',
  },
  sun: {
    key: 'common.sunday',
    defaultValue: 'Sunday',
  },
  pieces: {
    key: 'common.pieces',
    defaultValue: 'pieces',
  },
  allFilters: {
    key: 'common.allFilters',
    defaultValue: 'All Filters',
  },
};
